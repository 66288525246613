import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { PoliciesContext } from "../../Context/PolicyContext";
import ListApps from "./ListApps";

const GoogleIframe = ({ add }) => {
  const [token, setToken] = useState("");
  const [installType, setInstallType] = useState("FORCE_INSTALLED");
  const [defaultPermissionPolicy, setDefaultPermissionPolicy] =
    useState("GRANT");
  const enterpriseId = "LC041od557"; // Substitua pelo seu enterpriseId correto
  const [error, setError] = useState(null);
  const { selectedApps, setSelectedApps } = useContext(PoliciesContext);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch(
          `/api/v1/google/google-play-token/${enterpriseId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(`Erro HTTP! status: ${response.status}`);
        }
        const data = await response.json();
        setToken(data.token);
      } catch (err) {
        console.error("Erro ao obter o token:", err);
        setError("Falha ao carregar o iframe da Google Play.");
      }
    };

    fetchToken();
  }, [enterpriseId]);

  // Função para carregar o script do Google API
  const loadGapiScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://apis.google.com/js/api.js";
      script.onload = resolve;
      document.body.appendChild(script);
    });
  };

  const handleAddNewApp = (event) => {
    setSelectedApps((prevSelectedApps) => {
      const exists = prevSelectedApps.some(
        (app) => app.packageName === event.packageName
      );

      if (!exists) {
        return [
          ...prevSelectedApps,
          {
            packageName: event.packageName,
            installType,
            defaultPermissionPolicy,
          },
        ];
      }

      return prevSelectedApps; // Retorna o array original se já existir o packageName
    });
  };

  useEffect(() => {
    const initializeIframe = async () => {
      if (token) {
        await loadGapiScript(); // Garantir que o script seja carregado
        if (window.gapi) {
          window.gapi.load("gapi.iframes", () => {
            const options = {
              url: `https://play.google.com/work/embedded/search?token=${token}&mode=SELECT`,
              where: document.getElementById("iframe-container"),
              attributes: {
                style: "width: 100%; height: 500px; border: none;",
              },
            };

            const iframe = window.gapi.iframes.getContext().openChild(options);

            // Registrar o evento de seleção
            iframe.register(
              "onproductselect",
              (event) => {
                console.log(event); // Acessar os detalhes do evento
                handleAddNewApp(event);
              },
              window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER
            );
          });
        }
      }
    };

    initializeIframe();
  }, [token]);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <Row>
        <Col xl={6}>
          <Form.Group controlId="installType">
            <Form.Label>Install Type</Form.Label>
            <Form.Control
              as="select"
              value={installType}
              onChange={(e) => setInstallType(e.target.value)}
            >
              <option value="FORCE_INSTALLED">Force Installed</option>
              <option value="PRE_INSTALLED">Pre Installed</option>
              <option value="BLOCKED">Blocked</option>
              <option value="AVAILABLE">Available</option>
              <option value="REQUIRED_FOR_SETUP">Required for Setup</option>
            </Form.Control>
          </Form.Group>
        </Col>

        <Col xl={6}>
          <Form.Group controlId="defaultPermissionPolicy">
            <Form.Label>Default Permission Policy</Form.Label>
            <Form.Control
              as="select"
              value={defaultPermissionPolicy}
              onChange={(e) => setDefaultPermissionPolicy(e.target.value)}
            >
              <option value="GRANT">Grant</option>
              <option value="PROMPT">Prompt</option>
              <option value="DENY">Deny</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <div className="my-2">
        <ListApps />
      </div>

      <div id="iframe-container"></div>
    </>
  );
};

export default GoogleIframe;
