export const LogOut = async () => {
  const apiUrl = "/api/v1/logout";
  sessionStorage.clear();
  // console.clear();
  // localStorage.clear();

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      mode: "cors",
    });

    window.location.replace("/signin");

    if (response.ok) {
      //    window.location.replace("/signin");
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchAddress = async (lat, lng) => {
  if (!lat || !lng) {
    return;
  }
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&addressdetails=1`
    );
    const data = await response.json();
    if (data && data.address) {
      const addressComponents = [
        data.address.road,
        data.address.suburb,
        data.address.city,
        data.address.state,
        data.address.postcode,
        data.address.country,
      ];
      const validComponents = addressComponents.filter(
        (component) => component && component !== "undefined"
      );
      return validComponents.join(", ");
    }
  } catch (error) {
    console.error("Error fetching address:", error);
  }
};

// utils.js

export const fetchCoordinates = async (address) => {
  if (!address) {
    return null;
  }
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
        address
      )}`
    );
    const data = await response.json();
    if (data && data.length > 0) {
      const { lat, lon } = data[0];
      return {
        lat: parseFloat(lat),
        lng: parseFloat(lon),
      };
    } else {
      console.error("Nenhum resultado encontrado para o endereço fornecido.");
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar coordenadas:", error);
    return null;
  }
};
