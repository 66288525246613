import React, { useEffect } from "react";
import { Button, ButtonGroup } from "react-bootstrap";

const PaginationUi = ({
  currentPage,
  totalPages,
  onPageChange,
  searchTerm,
}) => {
  if (searchTerm && searchTerm !== "") {
    return (
      <ButtonGroup aria-label="Pagination">
        <Button
          variant="primary"
          style={{
            borderRadius: "99px",
          }}
          disabled
        >
          &lt;
        </Button>
        <span className="mx-2 align-self-center text-dark">
          <span className="me-1">1</span>
          of
          <span className="mx-1">1</span>
        </span>
        <Button
          style={{
            borderRadius: "99px",
          }}
          variant="primary"
          className="tw-rounded-full"
          disabled
        >
          &gt;
        </Button>
      </ButtonGroup>
    );
  }

  return (
    <ButtonGroup aria-label="Pagination">
      <button
        className="btn-pagination btn-primary"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1 || currentPage === 0}
      >
        {"<"}
      </button>
      <span className="mx-2 align-self-center text-dark">
        <span className="me-1">{currentPage}</span>
        of
        <span className="me-1"> {totalPages}</span>
      </span>
      <button
        className="btn-pagination btn-primary"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        {">"}
      </button>
    </ButtonGroup>
  );
};

export default PaginationUi;
