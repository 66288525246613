import React, { createContext, useState } from "react";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { MdContacts } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { FaRegBuilding, FaTag } from "react-icons/fa";
import { IoIosDocument } from "react-icons/io";
import { IoDocumentText } from "react-icons/io5";
import { LiaPeopleCarrySolid } from "react-icons/lia";
import { TiDelete } from "react-icons/ti";
import { FaLocationDot } from "react-icons/fa6";
import { FaBell } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { LuInfo } from "react-icons/lu";
import { useTranslation } from "react-i18next";
import { MdDataUsage } from "react-icons/md";

export const SysadminContext = createContext();

export const SysadminProvider = ({ children }) => {
  const { t } = useTranslation();
  const navs = [
    {
      id: 0,
      label: t("Status"),
      icon: <HiOutlineStatusOnline />,
    },
    {
      id: 1,
      label: t("Apps"),
      icon: <IoLogoGooglePlaystore />,
    },
    {
      id: 4,
      label: t("Contacts"),
      icon: <MdContacts />,
    },
    {
      id: 3,
      label: t("Location"),
      icon: <FaLocationDot />,
    },
    {
      id: 15,
      label: t("Data Usage"),
      icon: <MdDataUsage />,
      torto: true,
    },
    {
      id: 6,
      label: t("Departments"),
      icon: <FaRegBuilding />,
      torto: true,
    },
    {
      id: 7,
      label: t("Invoices"),
      icon: <IoIosDocument />,
    },
    {
      id: 8,
      label: t("Terms"),
      icon: <IoDocumentText />,
      torto: true,
    },
    {
      id: 10,
      label: t("Employees"),
      icon: <LiaPeopleCarrySolid />,
      torto: true,
    },
    {
      id: 11,
      label: t("Notifications"),
      icon: <FaBell />,
      torto: false,
    },
    {
      id: 12,
      label: t("Calls"),
      icon: <FaPhone />,
      torto: false,
    },
    {
      id: 14,
      label: t("Tags"),
      icon: <FaTag />,
      torto: false,
    },
    {
      id: 13,
      label: t("About"),
      icon: <LuInfo />,
      torto: false,
    },
    {
      id: 5,
      label: t("Remove Device"),
      icon: <TiDelete />,
      torto: true,
    },
  ];

  const [activeNav, setActiveNav] = useState(0);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [availableDevices, setAvailableDevices] = useState([]); // devicesData from src/data/devices.js
  const [event, setEvent] = useState(null);
  const [tags, setTags] = useState(null);
  // console.log("ContextSays:", selectedDevice);

  return (
    <SysadminContext.Provider
      value={{
        navs,
        activeNav,
        setActiveNav,
        selectedDevice,
        setSelectedDevice,
        availableDevices,
        setAvailableDevices,
        event,
        setEvent,
      }}
    >
      {children}
    </SysadminContext.Provider>
  );
};
