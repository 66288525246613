// src/components/Utils/FilterModal.js
import { t } from "i18next";
import React, { useState } from "react";
import { Modal, Button, FormControl } from "react-bootstrap";

const FilterModal = ({ show, handleClose, filter, setFilter }) => {
  const validTypes = [
    "ALL",
    "PASSWORD_CHANGE",
    "DELETE_USER",
    "USER_REGISTER",
    "PASSWORD_RESET",
    "SEND_INVITE",
    "PERMISSION_CHANGE",
    "LOGIN",
    "CREATE_DEPARTMENT",
    "DELETE_DEPARTMENT",
    "EDIT_DEPARTMENT",
    "ATTACH_DEVICE_IN_DEPARTMENT",
    "REMOVE_DEVICE_FROM_DEPARTMENT",
    "UPLOAD_INVOICE",
    "DELETE_INVOICE",
    "EDIT_INVOICE",
    "DOWNLOAD_INVOICE",
    "ATTACH_INVOICE_TO_DEVICE",
    "REMOVE_INVOICE_FROM_DEVICE",
    "CREATE_TAG",
    "REMOVE_TAG",
    "ATTACH_TAG_IN_DEVICE",
    "REMOVE_TAG_FROM_DEVICE",
    "EDIT_TAG",
    "UPLOAD_TERM",
    "DELETE_TERM",
    "DOWNLOAD_TERM",
    "EDIT_TERM",
    "REMOVE_TERM_FROM_DEVICE",
    "ATTACH_TERM_TO_DEVICE",
    "ACTIVATE_MFA",
    "CONFIRM_MFA",
    "DISABLE_MFA",
    "RESET_MFA",
    "CREATE_POLICY",
    "DELETE_POLICY",
  ];

  const [search, setSearch] = useState("");

  const filteredTypes = validTypes.filter((type) =>
    type.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Modal centered className="fixed-modal-5" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Filter Logs")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mh-100 overflow-auto">
        <FormControl
          type="text"
          placeholder={`${t("Search...")}`}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="mb-3"
        />
        {filteredTypes.map((type) => (
          <div
            xl={4}
            className={`tw-shadow-sm border tw-border-gray-200 tw-rounded-md p-2 mb-2 ${
              filter === type ? "tw-bg-datadike-blue tw-text-white" : ""
            }`}
            key={type}
            active={filter === type}
            onClick={() => {
              setFilter(type);
              handleClose();
            }}
            style={{ cursor: "pointer" }}
          >
            {type}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModal;
