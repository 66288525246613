import React, { useEffect, useState, useContext } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import TableLine from "../components/Lines/TableLine.js";
import CreateLineModal from "../components/Lines/CreateLine.js";
import { useGet } from "../hooks/get.js";
import { SysadminContext } from "../Context/SysadminContext.js";
import { useTranslation } from "react-i18next";

const Lines = () => {
  let isMounted = false;

  const { t } = useTranslation();

  document.title = `${t("DataDike MDM | Lines")}`;
  const [showModal, setShowModal] = useState(false);
  const [refreshState, setRefreshState] = useState(false);
  const { selectedDevice, setActiveNav, setEvent, event } =
    useContext(SysadminContext);

  useEffect(() => {
    if (event && event.key == "add line") {
      setShowModal(true);
    }
  }, [event]);

  isMounted = true;
  return (
    <React.Fragment>
      {showModal && (
        <CreateLineModal
          show={showModal}
          preSelected={selectedDevice}
          submit={() => {
            setRefreshState(!refreshState);
            setShowModal(false);
            setEvent(null);
          }}
          close={() => {
            setShowModal(false);
            setEvent(null);
          }}
        />
      )}
      <div className="">
        <div className="flex align-items-center justify-content-between mb-3 ms-1">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">{t("Functionalities")}</Link>
              </li>
              <li className="d-none breadcrumb-item active" aria-current="page">
                {t("Lines Management")}
              </li>
            </ol>
            <h4 className="main-title mb-0">{t("Management of lines")}</h4>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <button
              onClick={() => setShowModal(true)}
              className="btn btn-primary"
            >
              {t("Add a new line")}
            </button>
          </div>
        </div>

        <Row className="mt-2 p-3">
          {isMounted && (
            <TableLine
              setRefreshState={setRefreshState}
              refreshState={refreshState}
            />
          )}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Lines;
