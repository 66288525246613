export const validatePolicyData = (data) => {
  const errors = [];

  const checkForNullOrEmpty = (key, value) => {
    if (
      value === null ||
      value === undefined ||
      (Array.isArray(value) &&
        value.length === 0 &&
        key !== "applications" &&
        key !== "longSupportMessage" && //pode ser null ou ""
        key !== "shortSupportMessage" && //pode ser null ou ""
        key !== "deviceOwnerLockScreenInfo")
    ) {
      errors.push(`${key} cannot be null, undefined or empty`);
    }
  };

  // Validação para geral
  checkForNullOrEmpty("encryptionPolicy", data.policyOptions.encryptionPolicy);
  checkForNullOrEmpty(
    "defaultPermissionPolicy",
    data.policyOptions.defaultPermissionPolicy
  );
  checkForNullOrEmpty("locationMode", data.policyOptions.locationMode);
  checkForNullOrEmpty(
    "keyguardDisabledFeatures",
    data.policyOptions.keyguardDisabledFeatures
  );
  checkForNullOrEmpty(
    "stayOnPluggedModes",
    data.policyOptions.stayOnPluggedModes
  );
  checkForNullOrEmpty("systemUpdateType", data.policyOptions.systemUpdate.type);

  // Validação para display
  checkForNullOrEmpty(
    "maximumTimeToLock",
    data.policyOptions.maximumTimeToLock
  );

  // Validação para mensagens
  checkForNullOrEmpty(
    "deviceOwnerLockScreenInfo",
    data.policyOptions.deviceOwnerLockScreenInfo?.defaultMessage
  );
  checkForNullOrEmpty(
    "shortSupportMessage",
    data.policyOptions.shortSupportMessage?.defaultMessage
  );
  checkForNullOrEmpty(
    "longSupportMessage",
    data.policyOptions.longSupportMessage?.defaultMessage
  );

  // Validação para aplicações
  checkForNullOrEmpty(
    "installAppsDisabled",
    data.policyOptions.installAppsDisabled
  );
  checkForNullOrEmpty(
    "uninstallAppsDisabled",
    data.policyOptions.uninstallAppsDisabled
  );
  checkForNullOrEmpty(
    "googlePlayProtectVerifyApps",
    data.policyOptions.advancedSecurityOverrides.googlePlayProtectVerifyApps
  );
  checkForNullOrEmpty(
    "untrustedAppsPolicy",
    data.policyOptions.advancedSecurityOverrides.untrustedAppsPolicy
  );
  checkForNullOrEmpty("playStoreMode", data.policyOptions.playStoreMode);
  checkForNullOrEmpty(
    "appAutoUpdatePolicy",
    data.policyOptions.appAutoUpdatePolicy
  );

  // Não validar o array applications pois ele pode ser vazio
  // Ex: checkForNullOrEmpty("applications", data.policyOptions.applications);

  // Validação para department
  checkForNullOrEmpty("departmentId", data.departmentId);
  checkForNullOrEmpty("departmentName", data.departmentName);

  return errors;
};
