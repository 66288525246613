import React, { useEffect, useState, useContext } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";
import { IoTimeOutline } from "react-icons/io5"; // Import IoTimeOutline
import {
  FiPhoneMissed,
  FiPhoneIncoming,
  FiPhoneOutgoing,
} from "react-icons/fi"; // Import additional call icons
import { SysadminContext } from "../../Context/SysadminContext";
import useSearch from "../../hooks/useSearch";
import {
  convertToLocalTime,
  getBrowserTimeZone,
  convertToLocalTimeReq,
} from "../../hooks/convertDate";
import DateRangePickerModal from "../Utils/DateRangePickerModal";
import { useTranslation } from "react-i18next";
import { options } from "../../data/dropdown.js";

const Calls = () => {
  const { selectedDevice } = useContext(SysadminContext);
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [start, setStart] = useState(new Date(Date.now() - 30 * 60000)); // 30 min ago
  const [end, setEnd] = useState(new Date());
  const [selectedTimeRange, setSelectedTimeRange] = useState(t("Last 30 min"));
  const timezone = getBrowserTimeZone();
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("ALL");
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState(null);

  const validTypes = [
    "ALL",
    "OUTGOING",
    "INCOMING",
    "MISSED",
    "REJECTED",
    "VOICEMAIL",
    "BLOCKED",
    "ANSWERED_EXTERNALLY",
  ];

  const fetchData = async () => {
    setLoading(true);
    const startDate = convertToLocalTimeReq(start);
    const endDate = convertToLocalTimeReq(end);
    try {
      const response = await fetch(
        `/api/v1/sysadmin/device-call-history/${selectedDevice?.deviceId}?startTime=${startDate}&endTime=${endDate}&timezone=${timezone}&type=${filter}`
      );

      const data = await response.json();

      if (data) {
        const convertedData = data.map((item) => ({
          date: item.date ? convertToLocalTime(item.date) : null,
          duration: item.duration,
          type: item.type,
          contactName: item.contactName,
          number: item.number,
        }));

        console.log(convertedData);
        setData(convertedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [timezone, start, end, filter, selectedDevice]);

  const updateDateRangeAndFetch = (min, label) => {
    const now = new Date();
    setStart(new Date(now.getTime() - min * 60000));
    setEnd(now);
    setSelectedTimeRange(label);
  };

  const { filteredData, searchTerm, setSearchTerm } = useSearch(data, [
    "date",
    "type",
    "duration",
    "number",
    "contactName",
  ]);

  const getCallIcon = (type) => {
    switch (type) {
      case "OUTGOING":
        return <FiPhoneOutgoing className="tw-text-green-500" />;
      case "INCOMING":
        return <FiPhoneIncoming className="tw-text-blue-500" />;
      case "MISSED":
        return <FiPhoneMissed className="tw-text-red-500" />;
      case "REJECTED":
        return <FiPhoneMissed className="tw-text-orange-500" />;
      case "VOICEMAIL":
        return <FiPhoneMissed className="tw-text-purple-500" />;
      case "BLOCKED":
        return <FiPhoneMissed className="tw-text-red-500" />;
      case "ANSWERED_EXTERNALLY":
        return <FiPhoneIncoming className="tw-text-teal-500" />;
      default:
        return <FiPhoneMissed className="tw-text-gray-900" />;
    }
  };

  const getCallColor = (type) => {
    switch (type) {
      case "OUTGOING":
        return "tw-text-green-600";
      case "INCOMING":
        return "tw-text-blue-600";
      case "MISSED":
        return "tw-text-red-600";
      case "REJECTED":
        return "tw-text-red-700";
      case "VOICEMAIL":
        return "tw-text-purple-500";
      case "BLOCKED":
        return "tw-text-red-500";
      case "ANSWERED_EXTERNALLY":
        return "tw-text-teal-500";
      default:
        return "tw-text-gray-900";
    }
  };

  if (loading) {
    return (
      <Card style={{ height: "740px" }} className="">
        <Card.Header>
          <h5 className="mb-0 text-dark">{t("Calls")}</h5>
        </Card.Header>

        <Card.Body className="jc ac">
          <h4 className="loading-icon">
            <VscLoading />
          </h4>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card style={{ height: "740px" }} className="">
      {show == true && (
        <DateRangePickerModal
          show={show}
          handleClose={() => setShow(false)}
          startDate={start}
          endDate={end}
          onApply={(start, end) => {
            setStart(start);
            setEnd(end);
            setShow(false);
          }}
        />
      )}
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h4 className="mb-0">{t("Calls")}</h4>

        <div className="d-flex w-60 justify-content-end">
          <input
            className={"form-control w-60"}
            disabled
            value={
              inputValue
                ? inputValue
                : `${convertToLocalTime(start)} - ${convertToLocalTime(end)}`
            }
          />

          <Dropdown className="mx-1 outline-secondary">
            <Dropdown.Toggle
              className="outline-secondary"
              variant="outline-secondary"
              id="dropdown-basic"
            >
              <span
                className="me-1"
                style={{ position: "relative", top: "-0.5px" }}
              >
                <IoTimeOutline />
              </span>
              {t(selectedTimeRange)}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {options.map((option) => (
                <Dropdown.Item
                  key={option.value}
                  active={selectedTimeRange === option.text}
                  onClick={() =>
                    updateDateRangeAndFetch(option.value, option.text)
                  }
                >
                  {t(option.text)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className="me-1 outline-secondary">
            <Dropdown.Toggle
              className="outline-secondary"
              variant="outline-secondary"
              id="dropdown-filter"
            >
              {t("Filter")}: {t(filter)}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {validTypes.map((type) => (
                <Dropdown.Item
                  key={type}
                  active={filter === type}
                  onClick={() => setFilter(type)}
                >
                  {t(type)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <button onClick={() => setShow(true)} className="btn btn-primary">
            <i className="ri-calendar-line" />
          </button>
        </div>
      </Card.Header>

      <Card.Body>
        <div className="mb-3 d-flex je w-100">
          {data && data.length > 0 && (
            <input
              className="form-control w-30"
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
              placeholder="Search..."
            />
          )}
        </div>
        {data && data.length > 0 ? (
          filteredData.map((item, index) => (
            <div
              style={{
                borderBottom: "1px solid #e4e9f0",
                marginBottom: "10px",
              }}
              key={index}
            >
              <h5 className="mb-2 text-semidark d-flex ac">
                <span
                  style={{
                    fontSize: "16px",
                  }}
                  className="me-2 tw-text-blue-400"
                >
                  <i className="ri-calendar-line" />
                </span>
                {item.date || "-"}
              </h5>

              <div className="mb-2 text-secondary">
                {t("Phone Number")}:
                <span className="text-semidark ms-1">{item.number || "-"}</span>
              </div>

              <div className="mb-2 text-secondary">
                {t("Contact")}:
                <span className="text-semidark ms-1">
                  {item.contactName || "-"}
                </span>
              </div>

              <div className="mb-2 text-secondary d-flex">
                {t("Type")}:
                <span className={`ms-1 icone-torto`}>
                  {getCallIcon(item.type)}
                </span>
                <span className={`ms-1 d-flex text-dark`}>
                  {item.type || "-"}
                </span>
              </div>

              <div className="mb-2 text-secondary">
                {t("Duration")}:
                <span className="text-semidark ms-1">
                  {item.duration || "-"}
                </span>
              </div>
            </div>
          ))
        ) : (
          <div className="d-flex jc">
            <h6 className="mb-0">
              {t("No calls history. Try select another time range")}
            </h6>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default Calls;
