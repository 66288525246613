import React, { useEffect, useState } from "react";
import { Card, Table, Button, Form } from "react-bootstrap";
import { useSort } from "../../hooks/useSort";
import useSearch from "../../hooks/useSearch";
import { convertToLocalTime } from "../../hooks/convertDate";
import { useTranslation } from "react-i18next";

const DevicesTable = ({ refreshState, selectedTag, setRefreshState }) => {
  const { t } = useTranslation();
  const [devicesData, setDevicesData] = useState([]);
  const [error, setError] = useState(null);
  const [devicesToDelete, setDevicesToDelete] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const response = await fetch(
        `/api/v1//tags/devices-with-tag/${selectedTag.tagId}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = await response.json();
      if (data && data.length > 0) {
        const newData = data.map((device) => ({
          deviceId: device.deviceId,
          deviceName: device.deviceName,
          addedBy: device.addedBy,
          addedAt: device.addedAt ? convertToLocalTime(device.addedAt) : "",
        }));
        setDevicesData(newData);
      } else {
        setError(t("No devices found"));
      }
    };

    if (selectedTag.tagId) {
      fetchData();
    }

    setLoading(false);
  }, [refreshState, selectedTag, t]);

  const handleSelectDevice = (device) => {
    setDevicesToDelete((prev) => {
      if (prev.some((d) => d.deviceId === device.deviceId)) {
        return prev.filter((d) => d.deviceId !== device.deviceId);
      } else {
        return [...prev, device];
      }
    });
  };

  const handleDeleteSelected = async () => {
    const body = {
      tagId: selectedTag.tagId,
      devices: devicesToDelete.map((device) => ({
        deviceId: device.deviceId,
        deviceName: device.deviceName,
      })),
    };

    const response = await fetch(`/api/v1/manage-device/remove-tag`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    const data = await response.json();
    if (response.ok) {
      setDevicesData((prev) =>
        prev.filter(
          (device) =>
            !devicesToDelete.some((d) => d.deviceId === device.deviceId)
        )
      );
      setTimeout(() => {
        setRefreshState(refreshState + 1);
      }, 300);
    } else {
      setError(data.message || t("Failed to delete devices."));
    }
  };

  const { sortedData, requestSort } = useSort(devicesData);
  const { filteredData, searchTerm, setSearchTerm } = useSearch(sortedData, [
    "deviceName",
    "addedBy",
    "addedAt",
  ]);

  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleSelectAll = () => {
    if (!isAllSelected) {
      setDevicesToDelete(filteredData.map((device) => device));
      setIsAllSelected(true);
    } else {
      setDevicesToDelete([]);
      setIsAllSelected(false);
    }
  };

  return (
    <Card className="custom-card-1" style={{ height: "500px" }}>
      <Card.Header>
        <h4>
          {t("Devices with")} {selectedTag.name}
        </h4>
        <input
          className="form-control w-30"
          placeholder={t("Search...")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Card.Header>
      <Card.Body>
        {devicesData && devicesData.length > 0 ? (
          <Table className="table-users" hover>
            <thead>
              <tr>
                <th onClick={handleSelectAll} className="center-text">
                  #
                </th>
                <th onClick={() => requestSort("deviceName")}>
                  {t("Device Name")}
                </th>
                <th onClick={() => requestSort("addedBy")}>{t("Added By")}</th>
                <th onClick={() => requestSort("addedAt")}>{t("Added At")}</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((device) => (
                <tr key={device.deviceId}>
                  <td className="center-text-td">
                    <Form.Check
                      value={device.deviceId}
                      checked={devicesToDelete.some(
                        (d) => d.deviceId === device.deviceId
                      )}
                      onChange={() => handleSelectDevice(device)}
                    />
                  </td>

                  <td>{device.deviceName}</td>
                  <td>{device.addedBy}</td>
                  <td>{device.addedAt}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div>{t(error)}</div>
        )}
      </Card.Body>
      {devicesToDelete.length > 0 && (
        <Card.Footer>
          <Button onClick={handleDeleteSelected} variant="danger">
            {t("Delete Selected Devices")}
          </Button>
        </Card.Footer>
      )}
    </Card>
  );
};

export default DevicesTable;
