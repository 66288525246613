import React from "react";
import {
  Accordion,
  Form,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
} from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import Select from "react-select";
import AppForm from "./AppForm.js";
// import AppOptionsForm from "./AppOptionsForm.js";

import { t } from "i18next";
import { PoliciesContext } from "../../Context/PolicyContext";
import GoogleIframe from "./GoogleIframe.js";
import ListApps from "./ListApps.js";

const PolicyForm = ({ policyData, setPolicyData, submit }) => {
  const handleOptionChange = (section, sectionIndex, optionKey) => {
    const updatedPolicyData = { ...policyData };
    if (
      updatedPolicyData[section] &&
      updatedPolicyData[section][sectionIndex]
    ) {
      updatedPolicyData[section][sectionIndex].selectedOption = optionKey;
      setPolicyData(updatedPolicyData);
    }
  };

  const handleMultipleOptionChange = (
    section,
    sectionIndex,
    selectedOptions
  ) => {
    const updatedPolicyData = { ...policyData };
    if (
      updatedPolicyData[section] &&
      updatedPolicyData[section][sectionIndex]
    ) {
      updatedPolicyData[section][sectionIndex].selectedOptions =
        selectedOptions;
      setPolicyData(updatedPolicyData);
    }
  };

  const handleTextChange = (section, sectionIndex, value) => {
    const updatedPolicyData = { ...policyData };
    if (
      updatedPolicyData[section] &&
      updatedPolicyData[section][sectionIndex]
    ) {
      updatedPolicyData[section][sectionIndex].selectedOption = value;
      setPolicyData(updatedPolicyData);
    }
  };

  const handleApplicationChange = (appSection, appIndex, keyName, value) => {
    const updatedPolicyData = { ...policyData };
    if (
      updatedPolicyData.applications[appSection] &&
      updatedPolicyData.applications[appSection][appIndex]
    ) {
      updatedPolicyData.applications[appSection][appIndex][keyName] = value;
      setPolicyData(updatedPolicyData);
    }
  };

  const handleAddApplication = (app) => {
    console.log(app);
    setPolicyData((prevData) => ({
      ...prevData,
      addedApps: [...(prevData.addedApps || []), app],
    }));
  };

  const handleAppOptions = (options) => {
    setPolicyData((prevData) => ({
      ...prevData,
      applicationsOptions: options, // Ou use o spread para adicionar a um array
      // applicationsOptions: [...(prevData.applicationsOptions || []), options],
    }));
  };

  const ensureSelectedOptionsExist = (item) => {
    if (!item.selectedOptions && item.keyType === "optionsMultiple") {
      return [];
    }
    return item.selectedOptions || [];
  };

  const handleSubmit = () => {
    submit(policyData);
  };

  if (!policyData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Accordion defaultActiveKey="0">
        {Object.keys(policyData)
          .filter((section) => section !== "addedApps") // Filtra a seção "addedApps"
          .map((section, sectionIndex) => (
            <Accordion.Item eventKey={sectionIndex.toString()} key={section}>
              <Accordion.Header>
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  {Array.isArray(policyData[section])
                    ? policyData[section].map((item, index) => (
                        <Col key={index} xs={12} md={6} className="mb-4">
                          <h6 className="jsb mb-3 ac pe-1">
                            <span className="tw-break-words pe-2">
                              {item.name}
                            </span>
                            {item.info && (
                              <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip>{item.info}</Tooltip>}
                              >
                                <span className="tw-text-blue-400">
                                  <FaInfoCircle />
                                </span>
                              </OverlayTrigger>
                            )}
                          </h6>
                          {item.keyType === "text" ? (
                            <Form.Control
                              type="text"
                              value={item.selectedOption || ""}
                              onChange={(e) =>
                                handleTextChange(section, index, e.target.value)
                              }
                            />
                          ) : item.keyType === "optionsUnique" ? (
                            <Form.Control
                              as="select"
                              value={item.selectedOption}
                              onChange={(e) =>
                                handleOptionChange(
                                  section,
                                  index,
                                  e.target.value
                                )
                              }
                            >
                              {item.options.map((option, i) => (
                                <option key={i} value={option.key}>
                                  {option.key}
                                </option>
                              ))}
                            </Form.Control>
                          ) : item.keyType === "optionsMultiple" ? (
                            <Select
                              className="w-100"
                              isMulti
                              value={(item.selectedOptions || []).map(
                                (opt) => ({
                                  value: opt,
                                  label: opt,
                                })
                              )}
                              options={item.options.map((option) => ({
                                value: option.key,
                                label: option.key,
                              }))}
                              onChange={(selected) =>
                                handleMultipleOptionChange(
                                  section,
                                  index,
                                  selected.map((s) => s.value)
                                )
                              }
                            />
                          ) : null}
                        </Col>
                      ))
                    : null}

                  {section === "applications" && (
                    <div className="">
                      <AppForm
                        handleAppOptions={handleAppOptions}
                        add={handleAddApplication}
                      />
                    </div>
                  )}
                  {section === "playStore" && (
                    <div className="">
                      <GoogleIframe add={handleAddApplication} />
                    </div>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          ))}
      </Accordion>

      <div className="mt-3 border-top">
        <div className="d-flex justify-content-end mt-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            {t("Submit")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PolicyForm;
