import React, { useState } from "react";
import { Modal, Button, InputGroup, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const SubmitModal = ({ show, close, submit }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [description, setDescription] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : "Upload a file");
  };

  const handleClick = () => {
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };

  const OnSubmit = async () => {
    const formData = new FormData();
    formData.append("term", file);
    formData.append("description", description);
    const response = await fetch("/api/v1/terms/upload-term", {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      close();
      submit();
    }
  };

  return (
    <Modal centered show={show} onHide={close}>
      <Modal.Body className="d-flex justify-content-center align-items-center">
        <div className="mb-3 w-100">
          <h6 className="mb-0">{t("Term description")}</h6>
          <Form.Control
            className="w-100"
            placeholder={`${t("Description")}`}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <InputGroup className="mb-3">
          <Button variant="outline-primary" onClick={handleClick}>
            {t("Choose File")}
          </Button>
          <Form.Control
            aria-label="File name"
            value={fileName || t("Upload a file")}
            readOnly
          />
          <Form.Control
            id="fileInput"
            type="file"
            accept=".pdf, .xml"
            onChange={handleFileChange}
            style={{ display: "none" }}
            required
          />
        </InputGroup>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end">
        <Button variant="secondary" onClick={close}>
          {t("Close")}
        </Button>
        <Button variant="primary" onClick={() => OnSubmit()}>
          {t("Upload")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubmitModal;
