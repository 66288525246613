import React, { createContext, useState } from "react";

export const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {
  const toggleSidebar = (e) => {
     // e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  };

  const openSidebar = (e) => {
     // e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");

    if (isOffset) {
      document.body.classList.add("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.add("sidebar-show");
      } else {
        document.body.classList.remove("sidebar-hide");
      }
    }
  };
  const closeSidebar = (e) => {
     // e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");

    if (isOffset) {
      document.body.classList.remove("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.remove("sidebar-show");
      } else {
        document.body.classList.add("sidebar-hide");
      }
    }
  };

  return (
    <LayoutContext.Provider
      value={{
        closeSidebar,
        openSidebar,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
