import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";

import { useGet } from "../../hooks/get";
import { SysadminContext } from "../../Context/SysadminContext";
import { useContext } from "react";
import useSearch from "../../hooks/useSearch";

import { FaBluetooth } from "react-icons/fa";
import { FaWifi } from "react-icons/fa";
import ProgressBar from "../Utils/ProgressBar";
import { VscLoading } from "react-icons/vsc";
import { useTranslation } from "react-i18next";

const Contacts = () => {
  const { selectedDevice } = useContext(SysadminContext);
  const { data, loading, error } = useGet(
    `/api/v1/sysadmin/device-contacts/${selectedDevice?.deviceId}`,
    selectedDevice?.deviceId
  );
  const { t } = useTranslation();

  const [contacts, setContacts] = useState(null);

  useEffect(() => {
    if (data && data.length > 0 && !error) {
      const sortedNameData = data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      setContacts(sortedNameData);
    } else if (error) {
      setContacts([]);
    }
  }, [data, error, loading]);

  const { filteredData, searchTerm, setSearchTerm } = useSearch(contacts, [
    "name",
    "phone",
  ]);

  /**{
  {
        "id": "1",
        "name": "DataDike | Walter Neto",
        "phone": "11 96577-5419"
    },
} */

  if (loading) {
    return (
      <Card style={{ height: "740px" }} className="">
        <Card.Header>
          <h5 className="mb-0 text-dark"></h5>
        </Card.Header>

        <Card.Body className="jc ac">
          <h4 className="loading-icon">
            <VscLoading />
          </h4>
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card style={{ height: "740px" }} className="">
        <Card.Header>
          <h5 className="mb-0 text-dark"> {t("Contacts")}</h5>
        </Card.Header>

        <Card.Body className="jc">
          <h6 className="">{t("No contacts found")}</h6>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card style={{ height: "740px" }} className="">
      <Card.Header>
        <h5 className="mb-0 text-dark"> {t("Contacts")}</h5>

        <input
          className="form-control w-20"
          type="text"
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={`${t("Search...")}`}
          value={searchTerm}
        />
      </Card.Header>

      <Card.Body>
        <Row>
          {contacts && contacts.length > 0 ? (
            filteredData.map((con, index) => (
              <div
                style={{
                  borderBottom:
                    index === contacts.length - 1
                      ? "none"
                      : "1px solid #e4e9f0",
                  marginBottom: "10px",
                }}
                key={index}
              >
                <h5 className="mb-2 text-dark">{con.name || ""}</h5>

                <div className="mb-2 text-secondary">
                  {t("Phone Number")}:
                  <span className="text-semidark ms-1">{con.phone || ""}</span>
                </div>
              </div>
            ))
          ) : (
            <div className="ac jc">
              <h6 className="">{t("No contacts found")}</h6>
            </div>
          )}
        </Row>
      </Card.Body>

      <Card.Footer>
        <div className="text-secondary">
          Total:
          <span className="ms-1 text-dark">{contacts && contacts.length}</span>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default Contacts;
