import React, { useState, useEffect } from "react";

const usePolicyData = () => {
  const [policyData, setPolicyData] = useState({
    general: [
      {
        name: "Encryption Policy",
        keyName: "encryptionPolicy",
        keyType: "optionsUnique",
        info: "Whether encryption is enabled, with or without password. In the case of encryption with a password, a password will be requested at the equipment's boot.",
        selectedOption: "ENCRYPTION_POLICY_UNSPECIFIED",
        options: [
          { key: "ENCRYPTION_POLICY_UNSPECIFIED" },
          { key: "ENABLED_WITHOUT_PASSWORD" },
          { key: "ENABLED_WITH_PASSWORD" },
        ],
      },
      {
        name: "Default permission policy",
        keyType: "optionsUnique",
        keyName: "defaultPermissionPolicy",
        info: "The default permission policy for runtime permission requests.",
        selectedOption: "PROMPT",
        options: [{ key: "PROMPT" }, { key: "GRANT" }, { key: "DENY" }],
      },
      {
        name: "Location mode",
        keyName: "locationMode",
        keyType: "optionsUnique",

        selectedOption: "LOCATION_MODE_UNSPECIFIED",
        info: "Enable location setting on the device. On devices with Android 8 or lower, the user can change this value if he has access to the device's settings.",
        options: [
          { key: "LOCATION_MODE_UNSPECIFIED" },
          { key: "LOCATION_USER_CHOICE" },
          { key: "LOCATION_ENFORCED" },
          { key: "LOCATION_DISABLED" },
        ],
      },
      {
        keyName: "stayOnPluggedModes",
        keyType: "optionsMultiple",
        name: "Stay on when plugged on",
        selectedOptions: ["BATTERY_PLUGGED_MODE_UNSPECIFIED"],
        info: "The battery plugged in modes for which the device stays on. When using this setting, it is recommended to clear maximumTimeToLock so that the device doesn't lock itself while it stays on.",
        options: [
          { key: "BATTERY_PLUGGED_MODE_UNSPECIFIED" },
          { key: "AC" },
          { key: "USB" },
          { key: "WIRELESS" },
        ],
      },
      {
        name: "Keyguard disabled features",
        keyName: "keyguardDisabledFeatures",
        keyType: "optionsMultiple",
        selectedOptions: ["KEYGUARD_DISABLED_FEATURE_UNSPECIFIED"],

        info: "Disabled keyguard (lock screen) customizations, such as widgets. (Android 7+)",
        options: [
          { key: "KEYGUARD_DISABLED_FEATURE_UNSPECIFIED" },
          { key: "CAMERA" },
          { key: "NOTIFICATIONS" },
          { key: "UNREDACTED_NOTIFICATIONS" },
          { key: "TRUST_AGENTS" },
          { key: "DISABLE_FINGERPRINT" },
          { key: "DISABLE_REMOTE_INPUT" },
          { key: "FACE" },
          { key: "IRIS" },
          { key: "BIOMETRICS" },
          { key: "SHORTCUTS" },
          { key: "ALL_FEATURES" },
        ],
      },
      {
        name: "System Update Type",
        keyName: "systemUpdateType",
        keyType: "optionsUnique",
        selectedOption: "WINDOWED",
        info: "The system update policy, which controls how OS updates are applied. When using &quot;Windowed&quot; this also configures Play apps to be updated within the window.",
        options: [
          { key: "WINDOWED" },
          { key: "SYSTEM_UPDATE_TYPE_UNSPECIFIED" },
          { key: "AUTOMATIC" },
          { key: "POSTPONE" },
        ],
      },
    ],
    display: [
      {
        keyName: "maximumTimeToLock",
        keyType: "text",

        name: "Maximum Time to Lock Screen (minutes)",
        info: "Maximum time in minutes for user activity until the device locks. A value of 0 means there is no restriction.",
        selectedOption: 1,
        options: "input_time",
      },
    ],
    message: [
      {
        keyName: "deviceOwnerLockScreenInfo", // ENVIAR UM OBJETO, a key da mensagem  é "defaultMessage"
        keyType: "text",
        name: "Owner Lock Screen Info",
        info: "The device owner information to be shown on the lock screen. (Android 7+)",
        selectedOption: "",
        options: "input_text",
      },
      {
        keyName: "shortSupportMessage", // ENVIAR UM OBJETO, a key da mensagem  é "defaultMessage"
        keyType: "text",
        name: "Short Support Message",
        info: "A message displayed to the user in the settings screen wherever functionality has been disabled by the admin. If the message is longer than 200 characters it may be truncated. (Android 7+)",
        options: "input_text",
      },
      {
        keyName: "longSupportMessage", // ENVIAR UM OBJETO, a key da mensagem  é "defaultMessage"
        keyType: "text",
        name: "Long Support Message",
        info: "A message displayed to the user in the device administrators settings screen. (Android 7+)",
        options: "input_text",
      },
    ],
    applications: {
      General: [
        {
          name: "Ensure verify apps enabled",
          selectedOption: "ENFORCED", // Corrigido para valor aceito
          keyType: "optionsUnique",
          keyName: "ensureVerifyAppsEnabled",
          options: [
            { key: "DISABLED", sendKey: false },
            { key: "ENFORCED", sendKey: true }, // Valor aceito
          ],
          info: "Whether app verification (Google Play Protect) is force-enabled.",
        },
        {
          name: "Untrusted apps policy",
          keyName: "untrustedAppsPolicy",
          keyType: "optionsUnique",
          selectedOption: "DISALLOW_INSTALL", // Corrigido para valor aceito
          options: [
            { key: "DISALLOW_INSTALL" },
            { key: "ALLOW_INSTALL_DEVICE_WIDE" },
            { key: "ALLOW_INSTALL_IN_PERSONAL_PROFILE_ONLY" },
          ],
          info: "Whether the device is allowed to run apps from untrusted sources.",
        },
      ],
      "Play Store": [
        {
          name: "App auto update policy",
          keyName: "appAutoUpdatePolicy",
          keyType: "optionsUnique",
          selectedOption: "WIFI_ONLY", // Corrigido para valor aceito
          options: [
            { key: "CHOICE_TO_THE_USER" },
            { key: "NEVER" },
            { key: "ALWAYS" },
            { key: "WIFI_ONLY" },
          ],
        },
      ],
      "Play Store": [
        {
          name: "Play Store mode",
          keyName: "playStoreMode",
          keyType: "optionsUnique",

          selectedOption: "PLAY_STORE_MODE_UNSPECIFIED",
          options: [
            { key: "PLAY_STORE_MODE_UNSPECIFIED" },
            { key: "ALLOWLIST" },
            { key: "DENYLIST" },
          ],

          info: "This mode controls which apps are available to the user in the Play Store and the behavior on the device when apps are removed from the policy.",
        },

        {
          name: "App auto update policy",
          keyName: "appAutoUpdatePolicy",
          keyType: "optionsUnique",

          selectedOption: "CHOICE_TO_THE_USER", // Escolha um valor válido
          options: [
            { key: "CHOICE_TO_THE_USER" },
            { key: "NEVER" },
            { key: "ALWAYS" },
            { key: "WIFI_ONLY" },
          ],
        },
      ],
      Applications: [
        {
          name: "Application",
          info: "Package name of the app to be added.",
          options: "input_text",
          keyName: "packageName",
          keyType: "text",
        },
        {
          name: "Install Type",
          keyName: "installType",
          keyType: "optionsUnique",
          info: "The install type of the app to be added.",
          options: [
            { key: "FORCE_INSTALLED" },
            { key: "PRE_INSTALLED" },
            { key: "BLOCKED" },
            { key: "AVAILABLE" },
            { key: "REQUIRED_FOR_SETUP" },
          ],
        },
        {
          name: "Permission Policy",
          keyName: "permissionPolicy",
          keyType: "optionsUnique",
          info: "The permission policy of the app to be added.",
          options: [{ key: "PROMPT" }, { key: "GRANT" }, { key: "DENY" }],
        },
        {
          name: "Ensure verify apps enabled",
          keyName: "googlePlayProtectVerifyApps",
          keyType: "optionsUnique",
          info: "Whether app verification (Google Play Protect) is force-enabled.",
          options: [
            {
              key: "GOOGLE_PLAY_PROTECT_VERIFY_APPS_UNSPECIFIED		",
              label: "Unspecified",
            },
            {
              key: "VERIFY_APPS_ENFORCED",
              label: "Enforced",
            },
            {
              key: "VERIFY_APPS_USER_CHOICE	",
              label: "User choice",
            },
          ],
        },
        {
          name: "Untrusted Apps Policy",
          keyName: "untrustedAppsPolicy",
          keyType: "optionsUnique",
          info: "Whether the user is allowed to enable the &quot;Unknown Sources&quot; setting, which allows installation of apps from unknown sources.",
          options: [
            {
              key: "UNTRUSTED_APPS_POLICY_UNSPECIFIED",
              label: "Not Specified",
            },
            {
              key: "DISALLOW_INSTALL",
              label: "Disallow Install",
            },
            {
              key: "ALLOW_INSTALL_IN_PERSONAL_PROFILE_ONLY",
              label: "Allow Install in Personal Profile Only",
            },
            {
              key: "ALLOW_INSTALL_DEVICE_WIDE",
              label: "Allow Install Device-wide",
            },
          ],
        },
      ],

      applicationsOptions: [],
    },
    playStore: [],
    addedApps: [], // Novo array para armazenar os aplicativos adicionados
  });

  return { policyData, setPolicyData };
};

export default usePolicyData;
