import React, { useContext, useState, useEffect } from "react";
import { SysadminContext } from "../../Context/SysadminContext";
import useSearch from "../../hooks/useSearch";
import { useGet } from "../../hooks/get";
import { Card, Col, Row } from "react-bootstrap";

import { VscLoading } from "react-icons/vsc";
import { MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Departments = () => {
  const { selectedDevice } = useContext(SysadminContext);
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");

  const [depsState, setDepsState] = useState(1);
  const [onDevState, setOnDevState] = useState(1);
  const [allDepartmentsData, setAllDepartmentsData] = useState([]);

  const { data: allDepartments, loading: depsLoading } = useGet(
    `/api/v1/department/view-departments`,
    depsState
  );

  const { data: deviceDepartments, loading: deviceDepartmentsLoading } = useGet(
    `/api/v1/sysadmin/device-departments/${selectedDevice?.deviceId}`,
    onDevState
  );
  useEffect(() => {
    // Verifica se allDepartments está disponível, se não estiver, seta uma lista vazia

    if (allDepartments && allDepartments.length > 0) {
      const deviceDepartmentIds =
        deviceDepartments && deviceDepartments.length > 0
          ? deviceDepartments.map((dep) => dep.departmentId)
          : [];

      // Filtra os departamentos para encontrar aqueles que não estão associados ao dispositivo
      const availableDepartments = allDepartments?.filter(
        (dep) => !deviceDepartmentIds.includes(dep.departmentId)
      );

      const sortedAvailable = availableDepartments.sort((a, b) =>
        a.departmentName.localeCompare(b.departmentName)
      ); // Ordena os departamentos por nome

      setAllDepartmentsData(sortedAvailable);
    } else {
      setAllDepartmentsData([]);
    }
  }, [allDepartments, deviceDepartments]);

  const addDepToDevice = async (depId) => {
    if (deviceDepartments && deviceDepartments.length > 0) {
      const depIds = deviceDepartments.map((dep) => dep.departmentId);
      if (depIds.includes(depId)) {
        console.log("Department already added to device");
        return;
      }
    }
    const body = {
      departmentId: depId,
      devices: [
        {
          deviceId: selectedDevice.deviceId,
          deviceName: selectedDevice.deviceName,
        },
      ],
    };
    try {
      const response = await fetch(`/api/v1/manage-device/add-in-department`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        setTimeout(() => {
          setOnDevState(onDevState + 1);
        }, 500);
      }
    } catch (error) {
      console.error("Error adding department to device:", error);
    }
  };

  const handleDelete = (departmentId) => {
    fetch(`/api/v1/manage-device/remove-from-department`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        devices: [
          {
            deviceId: selectedDevice.deviceId,
            deviceName: selectedDevice.deviceName,
          },
        ],
        departmentId: departmentId,
      }),
    })
      .then((response) => {
        response.json();
        if (response.ok) {
          setTimeout(() => {
            setOnDevState(onDevState + 1);
            setDepsState(depsState + "");
          }, 500);
        }
      })
      .then((data) => console.log("Success:", data))
      .catch((error) => console.error("Error:", error));
  };

  const {
    filteredData: filteredAvailable,
    searchTerm: searchAvailable,
    setSearchTerm: setSearchAvailable,
  } = useSearch(allDepartmentsData, ["departmentName"]);

  if (depsLoading || deviceDepartmentsLoading) {
    return (
      <Card style={{ height: "740px" }} className="">
        <Card.Header>
          <h4 className="mb-0">{t("Departments")}</h4>
        </Card.Header>

        <Card.Body className="jc ac">
          <h4 className="loading-icon">
            <VscLoading />
          </h4>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card style={{ height: "740px" }} className="">
      <Card.Header>
        <h4 className="mb-0">{t("Departments")}</h4>
      </Card.Header>

      <Card.Body>
        <Row>
          <Col className="mb-1" xl={6}>
            <Card
              style={{
                height: "620px",
              }}
              className="sysadmin-cards"
            >
              <Card.Header>
                <h6 className="mb-0">{t("Available departments")}</h6>

                {allDepartmentsData && allDepartmentsData.length > 0 && (
                  <input
                    className="form-control w-30"
                    type="text"
                    placeholder={`${t("Search...")}`}
                    value={searchAvailable}
                    onChange={(e) => setSearchAvailable(e.target.value)}
                  />
                )}
              </Card.Header>
              <Card.Body className="mh-100 overflow-auto">
                {allDepartmentsData && allDepartmentsData.length > 0 ? (
                  filteredAvailable.map((dep) => (
                    <div
                      className={`${
                        deviceDepartments && deviceDepartments.length > 0
                          ? "tw-bg-gray-100 tw-rounded-md p-2 mb-1 tw-opacity-40"
                          : "tw-bg-gray-100 tw-rounded-md p-2 mb-1"
                      }`}
                      key={dep.departmentId}
                    >
                      {deviceDepartments && deviceDepartments.length > 0 ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{t("The device is already associated with a department")}</Tooltip>}
                          disabled={
                            !(deviceDepartments && deviceDepartments.length > 0)
                          }
                        >
                          <span className="d-inline-block">
                            <button
                              disabled={
                                deviceDepartments &&
                                deviceDepartments.length > 0
                              }
                              onClick={() => {
                                if (
                                  deviceDepartments &&
                                  deviceDepartments.length > 0
                                ) {
                                  return;
                                } else {
                                  addDepToDevice(dep.departmentId);
                                }
                              }}
                              className="btn btn-gray me-2"
                              style={{
                                pointerEvents:
                                  deviceDepartments &&
                                  deviceDepartments.length > 0
                                    ? "none"
                                    : "auto",
                              }}
                            >
                              +
                            </button>
                          </span>
                        </OverlayTrigger>
                      ) : (
                        <button
                          disabled={
                            deviceDepartments && deviceDepartments.length > 0
                          }
                          onClick={() => {
                            if (
                              deviceDepartments &&
                              deviceDepartments.length > 0
                            ) {
                              return;
                            } else {
                              addDepToDevice(dep.departmentId);
                            }
                          }}
                          className="btn btn-gray me-2"
                        >
                          +
                        </button>
                      )}

                      {dep.departmentName}
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                    No departments available for:
                    <span className="mx-1 text-primary">
                      {selectedDevice?.deviceName}
                    </span>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>

          <Col className="mb-1" xl={6}>
            <Card
              style={{
                height: "620px",
              }}
              className="sysadmin-cards"
            >
              <Card.Header>
                {lang == "en" ? (
                  <h6 className="mb-0">
                    <span className="font-semibold me-1">
                      {selectedDevice?.deviceName}
                    </span>

                    {t("Departments")}
                  </h6>
                ) : (
                  <h6 className="mb-0">
                    {t("Departments")} em
                    <span className="font-semibold ms-1">
                      {selectedDevice?.deviceName}
                    </span>
                  </h6>
                )}

                <div //Pra ajustar o alinhamento da borda dos dois cards
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                    padding: "0.6rem",
                  }}
                ></div>
              </Card.Header>
              <Card.Body className="mh-100 overflow-auto">
                {deviceDepartmentsLoading && <div> {t("Loading...")}</div>}
                {deviceDepartments && deviceDepartments.length > 0 ? (
                  <div>
                    {deviceDepartments.map((dep) => (
                      <div
                        className="tw-bg-gray-100 tw-rounded-md p-2 mb-1"
                        key={dep.departmentId}
                      >
                        <button
                          onClick={() => handleDelete(dep.departmentId)}
                          className="btn btn-gray me-2"
                        >
                          <MdDelete />
                        </button>
                        {dep.departmentName}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center">
                    {t("No departments added to")}

                    <span className="mx-1 text-primary">
                      {selectedDevice?.deviceName}
                    </span>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Departments;
