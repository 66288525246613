import React from "react";

const ProgressBar = ({ progress, color }) => {
  return (
    <div
      style={{
        height: "7.2px",
        borderRadius: "6px",
      }}
      className="tw-bg-gray-200 w-100"
    >
      <div
        style={{
          width: `${progress}%`,
          height: "7px",
          borderRadius: "6px",
          transition: "width 1s ease-in-out",
          backgroundColor: color || "#506fd9",
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;

/* import React from "react";

const ProgressBar = ({ label, progress, color }) => {
  // Função para escurecer a cor em 25%
  const darkenColor = (hexColor) => {
    // Remove o "#" se existir
    hexColor = hexColor.replace("#", "");

    // Se for uma cor de 3 dígitos, converte para 6 dígitos
    if (hexColor.length === 3) {
      hexColor =
        hexColor[0] +
        hexColor[0] +
        hexColor[1] +
        hexColor[1] +
        hexColor[2] +
        hexColor[2];
    }

    // Converte a cor de hexadecimal para decimal e escurece
    const r = Math.round(parseInt(hexColor.substring(0, 2), 16) * 0.8);
    const g = Math.round(parseInt(hexColor.substring(2, 4), 16) * 0.8);
    const b = Math.round(parseInt(hexColor.substring(4, 6), 16) * 0.8);

    // Converte de volta para hexadecimal
    const darkenedColor =
      "#" +
      ((1 << 24) + (r << 16) + (g << 8) + b)
        .toString(16)
        .slice(1)
        .toUpperCase();

    return darkenedColor;
  };

  return (
    <div
      style={{
        height: "10.1px",
        paddingTop: "7px",
        paddingBottom: "7px",
        paddingLeft: 0,
        paddingRight: "5px",
        borderRadius: "6px",
      }}
      className="tw-bg-gray-200 w-100 d-flex tw-justify-between align-items-center"
    >
      <div
        style={{
          width: `${progress}%`,
          height: "10px",
          borderRadius: "10px",
          transition: "width 1s",
          backgroundColor: color || "#506fd9",
          padding: "3.5px 0",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      ></div>
      <span
        style={{
          fontSize: "9px",
          color: darkenColor(color || "#506fd9"), // Escurece a cor fornecida ou a cor padrão
          fontWeight: "bold",
        }}
      >
        {label || null}
      </span>
    </div>
  );
};

export default ProgressBar;
*/
