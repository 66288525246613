import React, { useState, useContext } from "react";
import {
  Form,
  Button,
  Col,
  Row,
  InputGroup,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa"; // Biblioteca de ícones como FontAwesome
import ListApps from "./ListApps.js";
import { PoliciesContext } from "../../Context/PolicyContext";

const AppForm = ({ add, handleAppOptions }) => {
  const [packageName, setPackageName] = useState("");
  const [installType, setInstallType] = useState("FORCE_INSTALLED");
  const [defaultPermissionPolicy, setDefaultPermissionPolicy] =
    useState("GRANT");

  const [appAutoUpdatePolicy, setAppAutoUpdatePolicy] = useState(
    "AUTO_UPDATE_DEFAULT"
  );
  const [playStoreMode, setPlayStoreMode] = useState("WHITELIST");
  const [googlePlayProtectVerifyApps, setGooglePlayProtectVerifyApps] =
    useState("GOOGLE_PLAY_PROTECT_VERIFY_APPS_UNSPECIFIED");
  const [untrustedAppsPolicy, setUntrustedAppsPolicy] = useState(
    "UNTRUSTED_APPS_POLICY_UNSPECIFIED"
  );

  const { selectedApps: apps, setSelectedApps } = useContext(PoliciesContext);

  const handleAddApplication = () => {
    const newApp = {
      packageName,
      installType,
      defaultPermissionPolicy,
    };

    setSelectedApps((prevApps) => [...prevApps, newApp]);

    // Enviar a nova aplicação para o PolicyForm
    add(newApp);

    // Limpar os inputs
    setPackageName("");
    setInstallType("FORCE_INSTALLED");
    setDefaultPermissionPolicy("GRANT");
  };

  const handleChangeAppOptions = (e) => {
    const { name, value } = e.target;
    if (name === "appAutoUpdatePolicy") setAppAutoUpdatePolicy(value);
    if (name === "playStoreMode") setPlayStoreMode(value);
    if (name === "untrustedAppsPolicy") setUntrustedAppsPolicy(value);

    if (name === "googlePlayProtectVerifyApps") {
      setGooglePlayProtectVerifyApps(value);
    }

    // Enviar a opção atualizada para o PolicyForm
    handleAppOptions({ [name]: value });
  };

  const renderTooltip = (message) => <Tooltip>{message}</Tooltip>;

  return (
    <div>
      <Form>
        {/* App Auto Update Policy */}
        <Row className="mb-3">
          <Col xl={6} className="mb-3">
            <h6 className="jsb mb-3 ac pe-1">
              <span className="tw-break-words pe-2">
                App Auto Update Policy
              </span>

              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    The app auto update policy, which controls when automatic
                    app updates can be applied.
                  </Tooltip>
                }
              >
                <span className="tw-text-blue-400">
                  <FaInfoCircle />
                </span>
              </OverlayTrigger>
            </h6>
            <Form.Group controlId="appAutoUpdatePolicy">
              <Form.Control
                as="select"
                name="appAutoUpdatePolicy"
                value={appAutoUpdatePolicy}
                onChange={handleChangeAppOptions}
              >
                <option value="APP_AUTO_UPDATE_POLICY_UNSPECIFIED">
                  Unspecified
                </option>
                <option value="CHOICE_TO_THE_USER">Choice to the User</option>
                <option value="NEVER">Never</option>
                <option value="WIFI_ONLY">Wi-Fi Only</option>
                <option value="ALWAYS">Always</option>
              </Form.Control>
            </Form.Group>
          </Col>

          {/* Play Store Mode */}
          <Col xl={6} className="mb-3">
            <h6 className="jsb mb-3 ac pe-1">
              <span className="tw-break-words pe-2">Play Store Mode</span>

              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    This mode controls which apps are available to the user in
                    the Play Store and the behavior on the device when apps are
                    removed from the policy.
                  </Tooltip>
                }
              >
                <span className="tw-text-blue-400">
                  <FaInfoCircle />
                </span>
              </OverlayTrigger>
            </h6>
            <Form.Group controlId="playStoreMode">
              <Form.Control
                as="select"
                name="playStoreMode"
                value={playStoreMode}
                onChange={handleChangeAppOptions}
              >
                <option value="PLAY_STORE_MODE_UNSPECIFIED">Unspecified</option>
                <option value="WHITELIST">Whitelist</option>
                <option value="BLACKLIST">Blacklist</option>
              </Form.Control>
            </Form.Group>
          </Col>

          {/* Untrusted Apps Policy */}
          <Col xl={6} className="mb-3">
            <h6 className="jsb mb-3 ac pe-1">
              <span className="tw-break-words pe-2">Untrusted Apps Policy</span>

              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Whether the user is allowed to enable the 'Unknown Sources'
                    setting, which allows installation of apps from unknown
                    sources.
                  </Tooltip>
                }
              >
                <span className="tw-text-blue-400">
                  <FaInfoCircle />
                </span>
              </OverlayTrigger>
            </h6>
            <Form.Group controlId="untrustedAppsPolicy">
              <Form.Control
                as="select"
                name="untrustedAppsPolicy"
                value={untrustedAppsPolicy}
                onChange={handleChangeAppOptions}
              >
                <option value="UNTRUSTED_APPS_POLICY_UNSPECIFIED">
                  Not Specified
                </option>
                <option value="DISALLOW_INSTALL">Disallow Install</option>
                <option value="ALLOW_INSTALL_IN_PERSONAL_PROFILE_ONLY">
                  Allow Install in Personal Profile Only
                </option>
                <option value="ALLOW_INSTALL_DEVICE_WIDE">
                  Allow Install Device-wide
                </option>
              </Form.Control>
            </Form.Group>
          </Col>

          {/* Ensure Verify Apps Enabled */}
          <Col xl={6} className="mb-3">
            <h6 className="jsb mb-3 ac pe-1">
              <span className="tw-break-words pe-2">
                Ensure verify apps enabled
              </span>

              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Whether app verification (Google Play Protect) is
                    force-enabled.
                  </Tooltip>
                }
              >
                <span className="tw-text-blue-400">
                  <FaInfoCircle />
                </span>
              </OverlayTrigger>
            </h6>
            <Form.Group controlId="googlePlayProtectVerifyApps">
              <Form.Control
                as="select"
                name="googlePlayProtectVerifyApps"
                value={googlePlayProtectVerifyApps}
                onChange={handleChangeAppOptions}
              >
                <option value="GOOGLE_PLAY_PROTECT_VERIFY_APPS_UNSPECIFIED">
                  Not Specified
                </option>
                <option value="VERIFY_APPS_ENFORCED">Enforced</option>
                <option value="VERIFY_APPS_USER_CHOICE">Users Choice</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </Form>

      <Row
        className="mb-3 py-3 "
        style={{
          borderStyle: "dotted",
          borderWidth: "1.7px",
          borderRadius: "4px",
          borderSpacing: "0.1px",
          borderColor: "#506fd9",
        }}
      >
        {/* Package Name */}
        <Col xl={4} className="mb-3">
          <h6 className="jsb mb-3 ac pe-1">
            <span className="tw-break-words pe-2">Package Name</span>

            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Package name of the app to be added.</Tooltip>}
            >
              <span className="tw-text-blue-400">
                <FaInfoCircle />
              </span>
            </OverlayTrigger>
          </h6>
          <InputGroup>
            <input
              className="form-control"
              type="text"
              value={packageName}
              onChange={(e) => setPackageName(e.target.value)}
              placeholder="Enter package name"
            />
            <Button variant="primary" onClick={handleAddApplication}>
              Add
            </Button>
          </InputGroup>
        </Col>

        {/* Install Type */}
        <Col xl={4} className="mb-3">
          <h6 className="jsb mb-3 ac pe-1">
            <span className="tw-break-words pe-2">Install Type</span>

            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Install type for this app.</Tooltip>}
            >
              <span className="tw-text-blue-400">
                <FaInfoCircle />
              </span>
            </OverlayTrigger>
          </h6>
          <Form.Group controlId="installType">
            <Form.Control
              as="select"
              value={installType}
              onChange={(e) => setInstallType(e.target.value)}
            >
              <option value="FORCE_INSTALLED">Force Installed</option>
              <option value="PRE_INSTALLED">Pre Installed</option>
              <option value="BLOCKED">Blocked</option>
              <option value="AVAILABLE">Available</option>
              <option value="REQUIRED_FOR_SETUP">Required for Setup</option>
            </Form.Control>
          </Form.Group>
        </Col>

        {/* Default Permission Policy */}
        <Col xl={4} className="mb-3">
          <h6 className="jsb mb-3 ac pe-1">
            <span className="tw-break-words pe-2">
              Default Permission Policy
            </span>

            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Permission policy for this app.</Tooltip>}
            >
              <span className="tw-text-blue-400">
                <FaInfoCircle />
              </span>
            </OverlayTrigger>
          </h6>
          <Form.Group controlId="defaultPermissionPolicy">
            <Form.Control
              as="select"
              value={defaultPermissionPolicy}
              onChange={(e) => setDefaultPermissionPolicy(e.target.value)}
            >
              <option value="GRANT">Grant</option>
              <option value="PROMPT">Prompt</option>
              <option value="DENY">Deny</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <div className="mt-2">
        <ListApps />
      </div>
    </div>
  );
};

export default AppForm;
