import { useState, useEffect } from "react";
import { LogOut } from "../extraFunctions/utils";
export const useGet = (url, state, anotherState) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(url, {
          credentials: "include", // Adicionado credentials como 'include' para enviar cookies em requisições cross-origin
          headers: {
            "Content-Type": "application/json",
          },
          method: "GET",
        });
        const data = await response.json();

        //  console.log("Veio:", data);
        setData(data);

        if (response.status === 401 || response.status === 403) {
          LogOut(); // Descomentar ou lidar com logout se necessário
        }
      } catch (error) {
        setError(error);
        console.error(`${url} Failed`, error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, state, anotherState]);

  // console.log("SetadoData: ", data); // Este log mostra o estado do 'data' após cada renderização

  return { data, loading, error, setLoading };
};
