import React, { useState } from "react";
import { Modal, Form, InputGroup } from "react-bootstrap";
import { BsEye } from "react-icons/bs";
import { t } from "i18next";
import { LogOut } from "../../extraFunctions/utils";

const ChangePasswordModal = ({ showModal, setShowModal }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [feedback, setFeedback] = useState(null);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const validatePassword = (password) => {
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[^A-Za-z0-9]/.test(password);
    const isAtLeastSixChars = password.length >= 6;

    return (
      hasLowerCase &&
      hasUpperCase &&
      hasNumber &&
      hasSpecialChar &&
      isAtLeastSixChars
    );
  };

  const submit = async () => {
    if (password !== newPassword) {
      setFeedback({
        ok: false,
        message: t("Passwords do not match"),
      });

      return;
    }

    if (!validatePassword(password)) {
      setFeedback({
        ok: false,
        message: t(
          "Password must contain at least 6 characters, one lowercase letter, one uppercase letter, one number and one special character"
        ),
      });

      return;
    }

    const response = await fetch("/api/v1/change-password", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        oldPassword: oldPassword,
        newPassword: newPassword,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setFeedback({
            ok: true,
            message: t(
              "Password changed successfully. You will be logged out on 5 seconds"
            ),
          });

          setTimeout(() => {
            setShowModal(false);
            LogOut();
          }, 5000);
        }
        return response.json;
      })
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Modal
      centered
      className="fixed-modal"
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <Modal.Body className="d-flex align-items-center justify-content-center">
        <div>
          <Form>
            <div className="form-group mb-3">
              <label className="mb-2">{t("Old Password")}</label>
              <InputGroup>
                <Form.Control
                  type={showPassword1 ? "text" : "password"}
                  aria-autocomplete="none"
                  autoComplete="off"
                  onChange={(e) => {
                    e.preventDefault();
                    setOldPassword(e.target.value);
                  }}
                  className="form-control"
                />
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => setShowPassword1(!showPassword1)}
                >
                  <span className="text-dark">
                    <BsEye />
                  </span>
                </button>
              </InputGroup>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="mb-2">{t("New Password")}</label>
                  <InputGroup>
                    <Form.Control
                      type={showPassword2 ? "text" : "password"}
                      aria-autocomplete="none"
                      autoComplete="off"
                      onChange={(e) => {
                        e.preventDefault();
                        setPassword(e.target.value);
                      }}
                      className="form-control"
                    />
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={() => setShowPassword2(!showPassword2)}
                    >
                      <span className="text-dark">
                        <BsEye />
                      </span>
                    </button>
                  </InputGroup>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="mb-2">{t("Confirm Password")}</label>
                  <InputGroup>
                    <Form.Control
                      type={showPassword3 ? "text" : "password"}
                      aria-autocomplete="none"
                      autoComplete="off"
                      className="form-control"
                      onChange={(e) => {
                        e.preventDefault();
                        setNewPassword(e.target.value);
                      }}
                    />
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={() => setShowPassword3(!showPassword3)}
                    >
                      <span className="text-dark">
                        <BsEye />
                      </span>
                    </button>
                  </InputGroup>
                </div>
              </div>
            </div>

            {feedback && (
              <div className="mt-3 px-2">
                {feedback.ok ? (
                  <div className="p-2 tw-bg-green-400 text-white w-100 tw-animate-pulse tw-rounded-md">
                    {feedback.message}
                  </div>
                ) : (
                  <div className="p-2 tw-bg-yellow-400 text-white w-100 tw-rounded-md">
                    {feedback.message}
                  </div>
                )}
              </div>
            )}
          </Form>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          onClick={() => setShowModal(false)}
          className="btn btn-secondary"
        >
          {t("Close")}
        </button>
        <button onClick={() => submit()} className="btn btn-primary">
          {t("Submit")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordModal;
