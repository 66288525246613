import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";

import { useGet } from "../../hooks/get";
import { SysadminContext } from "../../Context/SysadminContext";
import { useContext } from "react";
import useSearch from "../../hooks/useSearch";
import { VscLoading } from "react-icons/vsc";
import { tagBadges } from "../../extraFunctions/badgesTw";
import { useTranslation } from "react-i18next";
import { FaTrash } from "react-icons/fa";

const Tags = () => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");

  const { selectedDevice } = useContext(SysadminContext);
  const [refreshState, setRefreshState] = useState(false);
  const [loadingFiltering, setLoadingFiltering] = useState(false);

  const [data, setData] = useState([]);
  const { data: allTagsData, loading } = useGet(
    `/api/v1/tags/view-tags`,
    refreshState
  );
  const { data: deviceTags, loading: deviceLoading } = useGet(
    `/api/v1/sysadmin/device-tags/${selectedDevice?.deviceId}`,
    refreshState
  );

  const addTag = async (id) => {
    const bodyData = {
      tagId: id,
      devices: [
        {
          deviceName: selectedDevice?.deviceName,
          deviceId: selectedDevice?.deviceId,
        },
      ],
    };
    const response = await fetch("/api/v1/manage-device/add-tag", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        Accept: "application/json",
      },
      body: JSON.stringify(bodyData),
    });

    if (response.ok) {
      setTimeout(() => setRefreshState(!refreshState), 200);
    }
  };

  useEffect(() => {
    setLoadingFiltering(true);
    if (allTagsData && allTagsData.length > 0) {
      if (deviceTags && deviceTags.length > 0) {
        const filteredTags = allTagsData.filter(
          (tag) =>
            !deviceTags.some((deviceTag) => deviceTag.tagId === tag.tagId)
        );
        setData(filteredTags);
        setTimeout(() => setLoadingFiltering(false), 300); // ta piscando tag
      } else {
        setData(allTagsData);
        setTimeout(() => setLoadingFiltering(false), 300); // ta piscando tag
      }
    }
  }, [allTagsData, deviceTags]);

  const {
    filteredData: filteredTags,
    searchTerm: searchTermTags,
    setSearchTerm: setSearchTags,
  } = useSearch(data, ["name", "color", "createdBy"]);

  const {
    filteredData: filteredDevice,
    searchTerm: searchDevice,
    setSearchTerm: setSearchDevice,
  } = useSearch(deviceTags, ["tagName", "color", "createdBy"]);

  const colorTag = (cor) => {
    return tagBadges[cor] || tagBadges.default;
  };

  const handleDeleteSelected = async (tag) => {
    const body = {
      tagId: tag.tagId,
      devices: [
        {
          deviceName: selectedDevice?.deviceName,
          deviceId: selectedDevice?.deviceId,
        },
      ],
    };

    const response = await fetch(`/api/v1/manage-device/remove-tag`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    const data = await response.json();
    if (response.ok) {
      setTimeout(() => setRefreshState(!refreshState), 200);
    }
  };

  if (loading || loadingFiltering) {
    return (
      <Card style={{ height: "740px" }} className="">
        <Card.Header>
          <h5 className="mb-0 text-dark">{t("Tags")}</h5>
        </Card.Header>

        <Card.Body className="jc ac">
          <h4 className="loading-icon">
            <VscLoading />
          </h4>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card style={{ height: "740px" }} className="">
      <Card.Header>
        <h4 className="mb-0"> {t("Tags")}</h4>
      </Card.Header>

      <Card.Body>
        <Row>
          <Col xl={6}>
            <Card
              style={{
                height: "550px",
              }}
            >
              <Card.Header>
                <h4 className="mb-0">{t("Available Tags")}</h4>
                <input
                  placeholder={`${t("Search...")}`}
                  className="form-control w-40"
                  onChange={(e) => setSearchTags(e.target.value)}
                  value={searchTermTags}
                />
              </Card.Header>

              <Card.Body className="mh-100 overflow-auto">
                {filteredTags && filteredTags.length > 0
                  ? filteredTags.map((tag, index) => (
                      <div
                        style={{
                          fontSize: "13.5px !important",
                        }}
                        className={`p-3 ${colorTag(tag.color)} mb-2`}
                        key={tag.tagId}
                      >
                        <div className="justify-content-between ac">
                          {tag.name}

                          <div
                            onClick={() => addTag(tag.tagId)}
                            className={`tw-bg-gray-500 cursor-pointer tw-bg-opacity-10 p-2 tw-rounded-md`}
                          >
                            +
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </Card.Body>
            </Card>
          </Col>

          <Col xl={6}>
            <Card
              style={{
                height: "550px",
              }}
            >
              <Card.Header>
                {lang == "en" ? (
                  <h5 className="mb-0">
                    <span className="font-semibold me-1">
                      {selectedDevice?.deviceName}
                    </span>

                    {t("Tags")}
                  </h5>
                ) : (
                  <h5 className="mb-0">
                    {t("Tags")} em
                    <span className="font-semibold ms-1">
                      {selectedDevice?.deviceName}
                    </span>
                  </h5>
                )}

                <input
                  placeholder={`${t("Search...")}`}
                  className="form-control w-40"
                  onChange={(e) => setSearchDevice(e.target.value)}
                  value={searchDevice}
                />
              </Card.Header>

              <Card.Body className="mh-100 overflow-auto">
                {deviceTags && deviceTags.length > 0 ? (
                  filteredDevice.map((tag, index) => (
                    <div
                      style={{
                        fontSize: "14px !important",
                      }}
                      className={`p-3 ${colorTag(tag.color)} mb-2`}
                      key={tag.tagId}
                    >
                      <div className="justify-content-between ac">
                        {tag.tagName}

                        <span
                          onClick={() => handleDeleteSelected(tag)}
                          className="cursor-pointer tw-bg-transparent border tw-border-gray-800 p-2 tw-rounded-md"
                        >
                          <FaTrash />
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                    {t("No tag added to")}:
                    <span className="text-primary ms-1">
                      {selectedDevice?.deviceName}
                    </span>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <div className="text-secondary">
          Total:
          <span className="ms-1 text-semidark">
            {allTagsData && allTagsData.length}
          </span>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default Tags;
