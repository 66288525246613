import React, { useEffect, useState, useContext } from "react";
import useSearch from "../../hooks/useSearch";
import { useSort } from "../../hooks/useSort";
import { Card, Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { t } from "i18next";
import { useGet } from "../../hooks/get";
import { convertToLocalTime } from "../../hooks/convertDate";
import policepng from "../../assets/police/police.png";
import {
  FaUserTie,
  FaTrash,
  FaRegBuilding,
  FaCalendarAlt,
} from "react-icons/fa";
import { PoliciesContext } from "../../Context/PolicyContext";
import ConfirmModal from "./ConfirmModal";
import { VscLoading } from "react-icons/vsc";

const PoliciesGrid = () => {
  const [data, setData] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [showModal, setShowModal] = useState(false); // Estado para controlar o modal
  const [policyToDelete, setPolicyToDelete] = useState(null); // Estado para armazenar a política selecionada para exclusão
  const [feedback, setFeedback] = useState(null);
  const { data: policiesAvailable, loading } = useGet(
    "api/v1/policies/view-policies"
  );

  const {
    setSelectedPolicy,
    selectedPolicy,
    setDetails,
    setHome,
    setCreating,
    setPrimeiraVez,
    refreshState,
    setRefreshState,
  } = useContext(PoliciesContext);

  const clearName = (name) => {
    const regex = /[^\/]+$/;
    const cleanName = name.match(regex)[0];
    return cleanName;
  };

  useEffect(() => {
    if (policiesAvailable && policiesAvailable.length > 0) {
      const newData = policiesAvailable.map((item) => {
        return {
          ...item,
          fakeName: clearName(item.name),
          createdDate: convertToLocalTime(item.createdDate),
          lastChange: convertToLocalTime(item.lastChange),
        };
      });

      setData(newData);
    }
  }, [policiesAvailable]);

  const { sortedData, requestSort } = useSort(data);

  const { filteredData, searchTerm, setSearchTerm } = useSearch(sortedData, [
    "name",
    "createdBy",
    "policyVersion",
    "lastChange",
    "modifiedBy",
    "departmentName",
    "createdDate",
  ]);

  // Função para mostrar o modal de confirmação
  const wantDelete = (policy) => {
    setPolicyToDelete(policy); // Define qual política será deletada
    setShowModal(true); // Mostra o modal
  };

  // Função para deletar a política
  const deletePolicy = async () => {
    setFeedback({
      ok: "loading",
      message: t("Loading..."),
    });
    if (policyToDelete) {
      try {
        const response = await fetch(
          `/api/v1/google/delete-policy/${policyToDelete.policyId}`,
          {
            method: "DELETE",
          }
        );

        const data = await response.json();

        if (response.ok) {
          // Sucesso: remover a política do estado local

          setFeedback({
            ok: true,
            message: data?.message || "apinaodevolvermessage",
          });
          setData(
            data.filter((policy) => policy.policyId !== policyToDelete.policyId)
          );
        } else {
          // Lidar com erro
          console.error("Erro ao deletar a política");

          setFeedback({
            ok: false,
            message: data?.error || data?.message,
          });
        }
      } catch (error) {
        console.error("Erro:", error);
      } finally {
        setShowModal(false); // Fecha o modal
        setPolicyToDelete(null); // Limpa o estado da política a ser deletada
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (feedback) {
        setFeedback(null);
      }
    }, 8000);
  }, [feedback]);

  return (
    <div>
      <Card
        style={{ minHeight: "450px", maxHeight: "500px" }}
        className="custom-card-1"
      >
        <Card.Header>
          <h5>{t("Policies")}</h5>
          <div className="d-flex tw-justify-end">
            <input
              type="text"
              className="form-control w-50 me-1"
              placeholder={`${t("Search...")}`}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            {filteredData.map((item, index) => (
              <Col className="pb-3" xl={6} lg={6} md={6} sm={12} key={index}>
                <div
                  onClick={() => {
                    setSelectedPolicy(item);
                    setDetails(true);
                    setHome(false);
                    setCreating(false);
                    setPrimeiraVez((prevState) => ({
                      ...prevState,
                      details: true,
                    }));
                  }}
                  style={{ borderWidth: "2px" }}
                  className={`police-card tw-border-dotted tw-border-gray-200 tw-rounded-md ${
                    selectedPolicy && selectedPolicy.policyId === item.policyId
                      ? "police-card-active"
                      : ""
                  }`}
                >
                  <div className="ac jsb py-3 px-1 flex">
                    <div className="js ac mb-2">
                      <img src={policepng} alt="" style={{ width: "40px" }} />

                      <div className="ps-2">
                        <h6 className="mb-2 text-dark">{item.fakeName}</h6>
                        <div>
                          <small className="d-block mb-1">
                            <span className="police-icon tw-text-red-500">
                              <FaRegBuilding />
                            </span>
                            <span className=" text-secondary">
                              {t("Department:")}
                            </span>
                            <span className="ms-1 text-semidark">
                              {item.departmentName}
                            </span>
                          </small>
                          <small className="d-block mb-1 tw-text-blue-500">
                            <span className="police-icon">
                              <FaUserTie />
                            </span>
                            <span className=" text-secondary">
                              {t("Created By:")}
                            </span>
                            <span className="ms-1 text-semidark">
                              {item.createdBy}
                            </span>
                          </small>
                          <small className="d-block">
                            <span className="police-icon tw-text-green-500">
                              <FaCalendarAlt />
                            </span>
                            <span className="text-secondary">
                              {t("Created At:")}
                            </span>
                            <span className="ms-1">{item.createdDate}</span>
                          </small>
                        </div>
                      </div>
                    </div>

                    {item.hasDevice ? (
                      <div
                       
                        className={
                          "je cursor-pointer font-size-17px me-2 disabled-delete-policy-div"
                        }
                      >
                        <OverlayTrigger
                          placement="left"
                          overlay={
                            <Tooltip id={`tooltip-left`}>
                              {t("errorsDefault.hasDeviceOnPolicy")}
                            </Tooltip>
                          }
                        >
                          <span className="">
                            <FaTrash />
                          </span>
                        </OverlayTrigger>
                      </div>
                    ) : (
                      <div
                        onClick={(e) => {
                          if (item.hasDevice) {
                            e.stopPropagation(); // Impede o clique de propagar para o card
                            return;
                          }
                          e.stopPropagation(); // Impede o clique de propagar para o card
                          wantDelete(item); // Define a política a ser deletada
                        }}
                        className={
                          "je cursor-pointer font-size-17px me-2 delete-policy-div"
                        }
                      >
                        <span className="">
                          <FaTrash />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Card.Body>
        {feedback && (
          <Card.Footer>
            {feedback.ok == true ? (
              <div className="p-3 js ac tw-bg-green-500 tw-rounded-md tw-text-white">
                {feedback.message}
              </div>
            ) : feedback.ok == "loading" ? (
              <div className="p-3 js ac tw-bg-blue-500 tw-rounded-md tw-text-white">
                <h4 className="tw-animate-spin tw-text-white">
                  <VscLoading />
                </h4>
              </div>
            ) : (
              <div className="p-3 js ac tw-bg-red-500 tw-rounded-md tw-text-white">
                {feedback.message}
              </div>
            )}
          </Card.Footer>
        )}
      </Card>

      {/* Modal de confirmação */}
      <ConfirmModal
        show={showModal}
        handleClose={() => setShowModal(false)} // Função para fechar o modal
        handleConfirm={deletePolicy} // Função para confirmar a deleção
      />
    </div>
  );
};

export default PoliciesGrid;
