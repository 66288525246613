import React, { useState, useEffect } from "react";
import { useGet } from "../../hooks/get";
import useSearch from "../../hooks/useSearch";
import { useSort } from "../../hooks/useSort";
import { convertToLocalTime } from "../../hooks/convertDate";
import { Card, Table, Form } from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";
import { useTranslation } from "react-i18next";

const DevicesTable = ({ selectedTerm, refresh }) => {
  const { t } = useTranslation();

  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [refreshState, setRefreshState] = useState(0);

  const { data, loading: apiLoading } = useGet(
    `/api/v1/terms/devices-with-term/${selectedTerm._id}`,
    refreshState
  );

  useEffect(() => {
    if (data && data.length > 0) {
      setLoading(true);
      console.log(selectedTerm);
      const convertedData = data.map((device) => {
        return {
          deviceId: device.deviceId,
          deviceName: device.deviceName,
          addedBy: device.addedBy,
          addedAt: device.addedAt ? convertToLocalTime(device.addedAt) : "",
        };
      });
      setDevices(convertedData);
      setLoading(false);
    } else {
      setDevices([]);
      setLoading(false);
    }
  }, [data]);

  const { sortedData, requestSort } = useSort(devices);
  const { filteredData, searchTerm, setSearchTerm } = useSearch(sortedData, [
    "deviceName",
    "addedBy",
    "addedAt",
  ]);

  const handleDelete = async () => {
    if (selectedDevices.length > 0) {
      console.log(selectedDevices);
      const response = await fetch("/api/v1/manage-device/remove-term", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          termId: selectedTerm._id,
          devices: selectedDevices.map((device) => ({
            deviceId: device.deviceId,
            deviceName: device.deviceName,
          })),
        }),
      });

      if (response.ok) {
        // Atualiza a lista de dispositivos após a remoção
        setDevices(
          devices.filter(
            (device) =>
              !selectedDevices.some(
                (selectedDevice) => selectedDevice.deviceId === device.deviceId
              )
          )
        );
        setSelectedDevices([]); // Limpa a seleção após a operação
        setTimeout(() => {
          setRefreshState(refreshState + 1); // Atualiza a lista de dispositivos
          refresh();
        }, 300);
      } else {
        console.error("Failed to delete devices", await response.text());
      }
    }
  };

  const handleSelect = (device) => {
    if (
      selectedDevices.some(
        (selectedDevice) => selectedDevice.deviceId === device.deviceId
      )
    ) {
      setSelectedDevices(
        selectedDevices.filter(
          (selectedDevice) => selectedDevice.deviceId !== device.deviceId
        )
      );
    } else {
      setSelectedDevices([...selectedDevices, device]);
    }
  };

  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleSelectAll = () => {
    if (!isAllSelected) {
      setSelectedDevices(filteredData.map((device) => device));
      setIsAllSelected(true);
    } else {
      setSelectedDevices([]);
      setIsAllSelected(false);
    }
  };

  if (loading || apiLoading) {
    return (
      <Card
        style={{
          minHeight: "300px",
        }}
        className="custom-card-1"
      >
        <Card.Header>
          <h5>
            {t("Devices with")}
            <span className="ms-1">{selectedTerm?.description}</span>
          </h5>
        </Card.Header>
        <Card.Body>
          <h4 className="loading-icon">
            <VscLoading />
          </h4>
        </Card.Body>
      </Card>
    );
  }

  if (!selectedTerm || !selectedTerm._id) {
    return <div></div>;
  }

  return (
    <Card
      style={{
        minHeight: "300px",
      }}
      className="custom-card-1"
    >
      <Card.Header>
        <h5>
          {t("Devices with")}
          <span className="ms-1">{selectedTerm?.description}</span>
        </h5>

        <input
          className="form-control w-20"
          placeholder={`${t("Search...")}`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Card.Header>

      <Card.Body>
        <Table className="table-users" hover>
          <thead>
            <tr>
              <th onClick={() => handleSelectAll()} className="center-text-th">
                #
              </th>
              <th onClick={() => requestSort("deviceName")}>
                {t("Device Name")}
              </th>
              <th onClick={() => requestSort("addedBy")}>{t("Added By")}</th>
              <th onClick={() => requestSort("addedAt")}>{t("Added At")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((device) => (
              <tr key={device.deviceId}>
                <td className="center-text-td">
                  <Form.Check
                    checked={
                      selectedDevices &&
                      selectedDevices.some(
                        (selectedDevice) =>
                          selectedDevice.deviceId === device.deviceId
                      )
                    }
                    onChange={() => handleSelect(device)}
                  />
                </td>
                <td>{device.deviceName}</td>
                <td>{device.addedBy}</td>
                <td>{device.addedAt}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>

      {selectedDevices && selectedDevices.length > 0 && (
        <Card.Footer>
          <button onClick={handleDelete} className="btn btn-danger">
            Delete selected devices
          </button>
        </Card.Footer>
      )}
    </Card>
  );
};

export default DevicesTable;
