import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { tagBadges } from "../../extraFunctions/badgesTw";
import { useTranslation } from "react-i18next";

const EditTag = ({ show, close, refresh, tag }) => {
  const { t } = useTranslation();
  const [tagName, setTagName] = useState(tag.name);
  const [selectedColor, setSelectedColor] = useState(tag.color);
  const [feedback, setFeedback] = useState(null);
  const [colorOptions, setColorOptions] = useState([]);

  useEffect(() => {
    const newColorOptions = Object.keys(tagBadges).map((color) => ({
      value: color,
      label: <span className={tagBadges[color]}>{color.toUpperCase()}</span>,
    }));

    setColorOptions(newColorOptions);
    setSelectedColor(tag.color);
    setTagName(tag.name);
  }, [tag]);

  const submit = async () => {
    const body = {};

    if (tagName !== tag.name) {
      body.oldTagName = tag.name;
      body.newTagName = tagName;
    }

    if (selectedColor !== tag.color) {
      body.oldTagColor = tag.color;
      body.newTagColor = selectedColor;
    }

    if (Object.keys(body).length === 0) {
      setFeedback({ ok: false, message: t("No changes detected") });
      return;
    }

    try {
      const response = await fetch(`/api/v1/tags/edit-tag/${tag.tagId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const result = await response.json();

      if (response.ok) {
        setFeedback({
          ok: true,
          message: result.message || t("Tag updated successfully"),
        });
        refresh();
        close();
      } else {
        setFeedback({
          ok: false,
          message: result.message || t("Failed to update tag"),
        });
      }
    } catch (error) {
      setFeedback({ ok: false, message: t("Error: ") + error.message });
    }
  };

  return (
    <Modal centered show={show} onHide={close}>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row className="p-0">
            <Col className="" xl={6}>
              <Form.Group className="" controlId="formTagName">
                <Form.Label>{t("Tag Name")}</Form.Label>
                <Form.Control
                  type="text"
                  value={tagName}
                  onChange={(e) => setTagName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col className="" xl={6}>
              <Form.Group className="" controlId="formColorSelect">
                <Form.Label>{t("Select Color")}</Form.Label>
                <Select
                  isSearchable={false}
                  options={colorOptions}
                  value={colorOptions.find(
                    (option) => option.value === selectedColor
                  )}
                  onChange={(selectedOption) =>
                    setSelectedColor(selectedOption.value)
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          {feedback && (
            <div
              className={`tw-bg-${
                feedback.ok ? "green" : "red"
              }-500 tw-text-white p-4 tw-rounded-md mt-4`}
            >
              {feedback.message}
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
        <Button variant="primary" onClick={submit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditTag;
