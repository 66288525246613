import React from "react";

const WelcomeMessage = ({ next, prev }) => {
  return (
    <div className="p-xxl-3 p-lg-2 pb-0">
      <div className="p-2 text-center">
        <h4 className="welcome mb-2 ">
          Welcome to <span className="font-semibold ">DataDike MDM</span>
        </h4>
        <h5 className="text-secondary">
          Let's start by creating a new enterprise.
        </h5>
      </div>

      <div className="d-flex justify-content-center mb-3">
        <button onClick={next} className="btn btn-primary mx-1 mb-3 w-100">
          Start
        </button>

        <div className="mt-3">
          <span className="text-secondary">
            Have an enterprise?
            <span
              className="text-primary cursor-pointer ms-1"
              onClick={() => {
                window.location.replace("/signin");
              }}
            >
              Login
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default WelcomeMessage;
