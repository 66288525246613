import React, { createContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userUsername, setUserUserName] = useState(null);
  const [hasMfa, setHasMfa] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [googleEnterprise, setGoogleEnterprise] = useState(null);

  
  return (
    <AuthContext.Provider
      value={{
        userUsername,
        setGoogleEnterprise,
        googleEnterprise,
        setUserUserName,
        hasMfa,
        setHasMfa,
        userEmail,
        setUserEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
