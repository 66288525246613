import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InvoiceTable from "../components/Invoices/InvoiceTable";
import { Modal, Form, InputGroup, Button } from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";

import DevicesTable from "../components/Invoices/DevicesTable.js";

import { useTranslation } from "react-i18next";
const InvoicesManagement = () => {
  const { t } = useTranslation();
  document.title = `${t("DataDike MDM | Invoices")}`;

  const [showModal, setShowModal] = useState(false);
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [refreshState, setRefreshState] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({});

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : "");
  };

  const handleClick = () => {
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("invoice", file);
    formData.append("description", description);

    try {
      const response = await fetch("/api/v1/invoices/upload-invoice", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (response.ok) {
        setRefreshState((prevState) => prevState + 1);
        setTimeout(() => {
          setLoading(false);
          setShowModal(false);
        }, 500);
      }
    } catch (error) {
      console.error("Error uploading invoice:", error);
    }
  };

  return (
    <div>
      {showModal && (
        <Modal centered show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Body>
            {loading ? (
              <div className="text-center">
                <h5 className="mb-2">{t("Sending...")}</h5>
                <VscLoading className="loading-icon" />
              </div>
            ) : (
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formDescription" className="mb-3">
                  <Form.Label>{t("Description")}</Form.Label>
                  <Form.Control
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />
                </Form.Group>
                <InputGroup className="mb-3">
                  <Button variant="outline-primary" onClick={handleClick}>
                    {t("Choose File")}
                  </Button>
                  <Form.Control
                    aria-label="File name"
                    value={fileName || `${t("Upload a file")}`}
                    readOnly
                  />
                  <Form.Control
                    id="fileInput"
                    type="file"
                    accept=".pdf, .xml"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    required
                  />
                </InputGroup>

                <div className="text-end">
                  <Button
                    variant="secondary"
                    onClick={() => setShowModal(false)}
                  >
                    {t("Close")}
                  </Button>
                  <Button className="ms-2" type="submit" variant="primary">
                    {t("Send")}
                  </Button>
                </div>
              </Form>
            )}
          </Modal.Body>
        </Modal>
      )}
      <div className="flex align-items-center justify-content-between mb-3 ms-1">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="#">{t("Functionalities")}</Link>
            </li>
            <li className="d-none breadcrumb-item active" aria-current="page">
              {t("Invoices Management")}
            </li>
          </ol>
          <h4 className="main-title mb-0">{t("Invoices Management")}</h4>
        </div>

        <button onClick={() => setShowModal(true)} className="btn btn-primary">
          {t("Add Invoice")}
        </button>
      </div>

      <div className="mt-3">
        <InvoiceTable
          selectedInvoice={selectedInvoice}
          setSelectedInvoice={setSelectedInvoice}
          refreshState={refreshState}
        />
      </div>

      <div className="mt-3">
        {selectedInvoice && (
          <DevicesTable
            selectedInvoice={selectedInvoice}
            refreshState={refreshState}
            setRefreshState={setRefreshState}
          />
        )}
      </div>
    </div>
  );
};

export default InvoicesManagement;
