import React, { useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { useGet } from "../../hooks/get";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const EmpModal = ({ onHide, cadNew }) => {
  const { t } = useTranslation();
  const [employeeName, setEmployeeName] = useState("");
  const [employeeRegistration, setEmployeeRegistration] = useState("");
  const [employeeTel, setEmployeeTel] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [employeeJobTitle, setEmployeeJobTitle] = useState("");
  const [employeeDepartment, setEmployeeDepartment] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const { data: allDepartmentsData, loading: loadingDepartments } = useGet(
    "/api/v1/lines/departments"
  );

  const departmentOptions = allDepartmentsData?.map((device) => ({
    value: device.departmentId,
    label: device.departmentName || t("unnamed"),
  }));

  const handleSubmit = () => {
    const employeeData = {
      employeeName,
      employeeRegistration,
      employeeTel,
      employeeEmail,
      employeeJobTitle,
      employeeDepartment: selectedDepartment.label,
      employeeDepartmentId: selectedDepartment.value,
    };

    fetch("/api/v1/employees/cad-employee", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(employeeData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
        cadNew(); // Chama a função de atualização após o cadastro
        onHide(); // Fecha o modal após o cadastro
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  return (
    <Modal centered show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Add new Employee")}</Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Modal.Body>
          <Row className="mb-3">
            <Col xl={6}>
              <Form.Group>
                <Form.Label>{t("name")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("enterName")}
                  value={employeeName}
                  onChange={(e) => setEmployeeName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xl={6}>
              <Form.Group>
                <Form.Label>{t("registrationNumber")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("enterRegistrationNumber")}
                  value={employeeRegistration}
                  onChange={(e) => setEmployeeRegistration(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={6}>
              <Form.Group>
                <Form.Label>{t("telephone")}</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder={t("enterTelephone")}
                  value={employeeTel}
                  onChange={(e) => setEmployeeTel(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xl={6}>
              <Form.Group>
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t("enterEmail")}
                  value={employeeEmail}
                  onChange={(e) => setEmployeeEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xl={6}>
              <Form.Group>
                <Form.Label>{t("jobTitle")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("enterJobTitle")}
                  value={employeeJobTitle}
                  onChange={(e) => setEmployeeJobTitle(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xl={6}>
              <Form.Group>
                <Form.Label>{t("department")}</Form.Label>
                <Select
                  options={departmentOptions}
                  value={selectedDepartment}
                  onChange={setSelectedDepartment}
                  isLoading={loadingDepartments}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            {t("close")}
          </Button>
          <Button variant="primary" type="submit">
            {t("submit")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EmpModal;
