import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { VscLoading } from "react-icons/vsc";
import { FaGoogle } from "react-icons/fa";
import { Kick } from "../../extraFunctions/utils";
import ConfirmDeleteEnterpriseModal from "./ConfirmDeleteEnterpriseModal.js";

const Logs = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  let ended = localStorage.getItem("egr");

  // State for the modal
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/v1/enterprise/enterprise-info");
        if (response.status === 403) {
          Kick();
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error("Failed to fetch enterprise info:", error);
        setError("Failed to fetch enterprise info.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const open = async () => {
    localStorage.setItem("sgr", true); // started google register ?

    localStorage.setItem("egr", false); // ended google register ?
    setLoading(true);
    try {
      const response = await fetch("api/v1/google/generate-signup-url", {
        method: "POST",
      });

      const data = await response.json();

      if (data && data.signupUrl) {
        window.open(data.signupUrl, "_blank");
      }
    } catch (error) {
      console.error("Error generating signup URL:", error);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await fetch("api/v1/google/cancel-enterprise-cad", {
        method: "DELETE",
      });

      if (response.ok) {
        // Handle successful deletion (e.g., refresh data or show a success message)
        console.log("Enterprise successfully deleted.");
        // Optionally, fetch the data again to reflect changes
        fetchData();
      } else {
        console.error("Failed to delete enterprise.");
      }
    } catch (error) {
      console.error("Error deleting enterprise:", error);
    }
    setLoading(false);
    setShowModal(false); // Close the modal after deletion
  };

  if (loading) {
    return (
      <Card className="custom-card-1" style={{ height: "740px" }}>
        <Card.Header>
          <h5 className="mb-0">{t("Enterprise Info")}</h5>
        </Card.Header>
        <Card.Body className="jc ac">
          <h5 className="loading-icon">
            <VscLoading />
          </h5>
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="custom-card-1" style={{ height: "740px" }}>
        <Card.Header>
          <h5 className="mb-0">{t("Enterprise Info")}</h5>
        </Card.Header>
        <Card.Body className="jc">
          <h6>{error || "Error fetching logs"}</h6>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card className="custom-card-1" style={{ height: "740px" }}>
        <Card.Header>
          <h5 className="mb-0">{t("Enterprise Info")}</h5>
        </Card.Header>
        <Card.Body>
          <div>
            <h4 className="text-dark text-center mb-4">
              {data.enterpriseName}
            </h4>
            <div className="text-secondary">
              {t("Enterprise ID")}:
              <span className="ms-1 text-dark">{data.enterpriseId}</span>
            </div>
            <div className="text-secondary">
              {t("Enterprise Admin")}:
              <span className="ms-1 text-dark">{data.enterpriseAdmin}</span>
            </div>
            <div className="text-secondary">
              {t("Enterprise CNPJ")}:
              <span className="ms-1 text-dark">{data.cnpj}</span>
            </div>
            <div className="text-secondary">
              {t("Enterprise Address")}:
              <span className="ms-1 text-dark">{data.address}</span>
            </div>

            {data.googleEnterpriseName && (
              <div className="text-secondary">
                {t("Google Enterprise Name")}:
                <span className="ms-1 text-dark">
                  {data.googleEnterpriseName}
                </span>
              </div>
            )}
          </div>

          <div className="mt-2">
            {data.registrationCompleted ? (
              <button
                onClick={() => setShowModal(true)} // Open modal for confirmation
                className="btn btn-danger ac tw-rounded-none"
              >
                <FaGoogle />
                <span className="ms-2 tw-tracking-wider ">
                  {t("DELETE GOOGLE ENTERPRISE")}
                </span>
              </button>
            ) : (
              <button
                onClick={open}
                className="btn btn-primary ac tw-rounded-none"
              >
                <FaGoogle />
                <span className="ms-2 tw-tracking-wider ">
                  {t("GOOGLE ENTERPRISE")}
                </span>
              </button>
            )}
          </div>
        </Card.Body>
      </Card>

      <ConfirmDeleteEnterpriseModal
        show={showModal}
        handleClose={() => setShowModal(false)} // Close modal
        handleDelete={handleDelete} // Handle deletion
      />
    </>
  );
};

export default Logs;
