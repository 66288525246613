import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useGet } from "../../hooks/get";
import Select from "react-select";

const EmpEdit = ({ editData, setEditData, refreshState, setRefreshState }) => {
  const [formData, setFormData] = useState({
    employeeName: editData.employeeName || "",
    employeeRegistration: editData.employeeRegistration || "",
    employeeTel: editData.employeeTel || "",
    // employeeEmail: editData.employeeEmail || "",
    employeeJobTitle: editData.employeeJobTitle || "",
    employeeDepartment: editData.employeeDepartment || "",
  });

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const { data: allDepartmentsData, loading: loadingDepartments } = useGet(
    "/api/v1/lines/departments"
  );

  useEffect(() => {
    if (allDepartmentsData) {
      const options = allDepartmentsData.map((department) => ({
        value: department.departmentId,
        label: department.departmentName,
      }));
      setDepartmentOptions(options);
    }
  }, [allDepartmentsData, editData.employeeDepartmentId]);

  useEffect(() => {
    // Set selected department
    if (departmentOptions) {
      const selected = departmentOptions.find(
        (department) => department.value === editData.employeeDepartmentId
      );
      console.log("selected", selected);
      console.log(editData.employeeDepartmentId);
      setSelectedDepartment(selected || null);
    }
  }, [departmentOptions]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const response = await fetch("/api/v1/employees/edit-employee-info", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        employeeId: editData.employeeId,
        employeeDepartment: selectedDepartment.value,
        ...formData,
      }),
    });
    if (response.ok) {
      setEditData({});
      setRefreshState((prev) => prev + 1);
    } else {
      console.error("Failed to update employee");
    }
  };

  return (
    <Modal show={true} onHide={() => setEditData({})}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Employee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="employeeName"
              value={formData.employeeName}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Registration</Form.Label>
            <Form.Control
              type="text"
              name="employeeRegistration"
              value={formData.employeeRegistration}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Telephone</Form.Label>
            <Form.Control
              type="text"
              name="employeeTel"
              value={formData.employeeTel}
              onChange={handleChange}
            />
          </Form.Group>
          {/* <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="employeeEmail"
              value={formData.employeeEmail}
              onChange={handleChange}
            />
          </Form.Group> */}
          <Form.Group className="mb-3">
            <Form.Label>Job Title</Form.Label>
            <Form.Control
              type="text"
              name="employeeJobTitle"
              value={formData.employeeJobTitle}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Department</Form.Label>
            <Select
              options={departmentOptions}
              value={selectedDepartment}
              onChange={setSelectedDepartment}
              isLoading={loadingDepartments}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setEditData({})}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmpEdit;
