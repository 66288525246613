import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Row, Dropdown } from "react-bootstrap";
import { useGet } from "../../hooks/get";
import { SysadminContext } from "../../Context/SysadminContext";
import { VscLoading } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import { getBrowserTimeZone } from "../../hooks/convertDate.js";
import { IoTimeOutline } from "react-icons/io5";
import { convertToLocalTimeReq } from "../../hooks/convertDate.js";
import DateRangePickerModal from "../Utils/DateRangePickerModal.js";
import { optionsDataUsage } from "../../data/optionsDataUsage.js";

const DataUsage = () => {
  const { selectedDevice } = useContext(SysadminContext);
  const { t } = useTranslation();
  const now = new Date();
  const thirtyMinutesAgo = new Date(now.getTime() - 30 * 60000);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);

  const timezone = getBrowserTimeZone();
  const [startTime, setStartTime] = useState(thirtyMinutesAgo);
  const [endTime, setEndTime] = useState(new Date());
  const [selectedTimeRange, setSelectedTimeRange] = useState("30 min");

  const updateDateRangeAndFetch = (minutes, label) => {
    const newEnd = new Date();
    const newStart = new Date(newEnd.getTime() - minutes * 60000); // Multiplica por 60000 para minutos corretos
    setStartTime(newStart);
    setEndTime(newEnd);
    setSelectedTimeRange(label);
  };

  let lang = localStorage.getItem("lang");

  const [chartSeries, setChartSeries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await useGet(
          `/api/v1/sysadmin/device-data-usage/${
            selectedDevice?.deviceId
          }?startTime=${convertToLocalTimeReq(
            startTime
          )}&endTime=${convertToLocalTimeReq(endTime)}&timezone=${timezone}`,
          selectedDevice?.deviceId
        );

        if (response && Array.isArray(response.data)) {
          setChartSeries([
            {
              name: t("Mobile Data Received (MB)"),
              data: response.data.map((entry) => entry.totalMobileRxMB),
            },
            {
              name: t("Mobile Data Sent (MB)"),
              data: response.data.map((entry) => entry.totalMobileTxMB),
            },
            {
              name: t("Wi-Fi Data Received (MB)"),
              data: response.data.map((entry) => entry.totalWifiRxMB),
            },
            {
              name: t("Wi-Fi Data Sent (MB)"),
              data: response.data.map((entry) => entry.totalWifiTxMB),
            },
          ]);
        } else {
          setChartSeries([]); // Handle case where data is not an array
        }
      } catch (err) {
        setError(err);
        setChartSeries([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedDevice, startTime, endTime, timezone]);

  if (loading) {
    return (
      <Card style={{ height: "740px" }}>
        <Card.Header>
          <h5 className="mb-0 text-dark"></h5>
        </Card.Header>
        <Card.Body className="jc ac">
          <h4 className="loading-icon">
            <VscLoading />
          </h4>
        </Card.Body>
      </Card>
    );
  }

  if (!data || data.length === 0 || error) {
    return (
      <Card style={{ height: "740px" }}>
        {showDateRangePicker && (
          <DateRangePickerModal
            show={showDateRangePicker}
            handleClose={() => setShowDateRangePicker(false)}
            startDate={startTime}
            endDate={setEndTime}
            onApply={(start, end) => {
              setStartTime(start);
              setEndTime(end);
              setShowDateRangePicker(false);
            }}
          />
        )}
        <Card.Header>
          <h5 className="mb-0 text-dark">{t("Data Usage")}</h5>

          <div className="je">
            <Dropdown className="mx-1 outline-secondary text-dark">
              <Dropdown.Toggle
                className="outline-secondary text-dark"
                variant="outline-secondary"
                id="dropdown-basic"
              >
                <span
                  className="me-1 text-dark"
                  style={{ position: "relative", top: "-0.5px" }}
                >
                  <IoTimeOutline />
                </span>
                {t(selectedTimeRange)}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {optionsDataUsage &&
                  optionsDataUsage.map((option) => (
                    <Dropdown.Item
                      key={option.value}
                      active={selectedTimeRange === option.text}
                      onClick={() =>
                        updateDateRangeAndFetch(option.value, option.text)
                      }
                    >
                      {t(option.text)}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>

            <button
              onClick={() => setShowDateRangePicker(true)}
              className="btn btn-primary ms-1"
            >
              <i className="ri-calendar-line" />
            </button>
          </div>
        </Card.Header>
        <Card.Body className="jc">
          <h6 className="">{t("No data found")}</h6>
        </Card.Body>
      </Card>
    );
  }

  const chartOptions = {
    chart: {
      type: "area",
      fontFamily: "Poppins, sans-serif",
    },
    xaxis: {
      categories:
        data && data.length > 0
          ? data.map((entry) => {
              if (lang && lang.toLowerCase() == "pt-br") {
                return dayjs(entry.timestamp).format("MM/DD HH:mm");
              } else {
                return dayjs(entry.timestamp).format("DD/MM HH:mm");
              }
            })
          : [],
      title: {
        text: t("Time"),
      },
    },
    yaxis: {
      title: {
        text: t("MB"),
      },
      labels: {
        formatter: function (value) {
          return parseFloat(value);
        },
      },
    },
    colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560"],
    stroke: {
      width: 2,
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <Card style={{ height: "740px" }}>
      {showDateRangePicker && (
        <DateRangePickerModal
          show={showDateRangePicker}
          handleClose={() => setShowDateRangePicker(false)}
          startDate={startTime}
          endDate={endTime}
          onApply={(start, end) => {
            setStartTime(start);
            setEndTime(end);
            setShowDateRangePicker(false);
          }}
        />
      )}
      <Card.Header>
        <h5 className="mb-0 text-dark">{t("Data Usage")}</h5>

        <div className="je">
          <Dropdown className="mx-1 outline-secondary text-dark">
            <Dropdown.Toggle
              className="outline-secondary text-dark"
              variant="outline-secondary"
              id="dropdown-basic"
            >
              <span
                className="me-1 text-dark"
                style={{ position: "relative", top: "-0.5px" }}
              >
                <IoTimeOutline />
              </span>
              {t(selectedTimeRange)}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {optionsDataUsage &&
                optionsDataUsage.map((option) => (
                  <Dropdown.Item
                    className="text-dark"
                    key={option.value}
                    active={selectedTimeRange === option.text}
                    onClick={() =>
                      updateDateRangeAndFetch(option.value, option.text)
                    }
                  >
                    {t(option.text)}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>

          <button
            onClick={() => setShowDateRangePicker(true)}
            className="btn btn-primary ms-1"
          >
            <i className="ri-calendar-line" />
          </button>
        </div>
      </Card.Header>
      <Card.Body className="">
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="area"
          height={350}
        />
      </Card.Body>
    </Card>
  );
};

export default DataUsage;
