import React, { useState, useEffect, useContext } from "react";
import androidicon from "../../assets/img/android.png";
import { PoliciesContext } from "../../Context/PolicyContext";
import { t } from "i18next";

const ListApps = () => {
  const [appImages, setAppImages] = useState({});
  const { selectedApps: apps, setSelectedApps } = useContext(PoliciesContext);
  const [selectedApps, setLocalSelectedApps] = useState([]); // Estado para armazenar apps selecionados

  const getAppsInfo = async (packageName) => {
    try {
      const response = await fetch("/api/v1/policies/view-policy-apps", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          packageNames: [packageName],
        }),
      });

      if (!response.ok) {
        throw new Error(`Erro: ${response.statusText}`);
      }

      const data = await response.json();
      return data[packageName]; // Retorna o link da imagem
    } catch (error) {
      console.error("Erro ao buscar informações do app:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchAppImages = async () => {
      const images = {};
      for (const app of apps) {
        const imageUrl = await getAppsInfo(app.packageName);
        images[app.packageName] = imageUrl;
      }
      setAppImages(images); // Atualiza o estado com as URLs das imagens
    };

    fetchAppImages();
  }, [apps]);

  const handleAppClick = (app) => {
    if (selectedApps.includes(app)) {
      // Se o app já estiver selecionado, remove-o
      setLocalSelectedApps(
        selectedApps.filter((item) => item.packageName !== app.packageName)
      );
    } else {
      // Se não estiver, adiciona-o
      setLocalSelectedApps([...selectedApps, app]);
    }
  };

  const handleRemoveSelected = () => {
    setSelectedApps((prev) =>
      prev.filter((app) => !selectedApps.includes(app))
    );
    setLocalSelectedApps([]); // Limpa os apps selecionados após remover
  };

  if (!apps || apps.length === 0) {
    return null;
  }

  return (
    <div>
      {apps && apps.length > 0 && (
        <h6 className="mb-2 text-dark">Selected Apps</h6>
      )}
      <div className="d-flex">
        {apps.map((app) => (
          <div
            className={`p-2 mx-1 jc ac tw-rounded-md  ${
              selectedApps.includes(app) ? "selected" : "tw-shadow-md"
            }`}
            key={app.packageName}
            onClick={() => handleAppClick(app)}
            style={{
              border: selectedApps.includes(app)
                ? "1.5px dotted rgb(255 109 112)"
                : "none",
              cursor: "pointer",
            }}
          >
            <div className="text-center d-block">
              <img
                style={{
                  width: "60px",
                }}
                src={appImages[app.packageName] || androidicon} // Usa a imagem carregada ou uma imagem padrão
                alt=""
              />
            </div>
          </div>
        ))}
      </div>
      {selectedApps.length > 0 && (
        <button className="btn btn-danger mt-3" onClick={handleRemoveSelected}>
          {t("createPolicy.removeSelectedApps")}
        </button>
      )}
    </div>
  );
};

export default ListApps;
