import React, { useContext } from "react";

import { DeviceContext } from "../../../Context/DeviceContext";
import { t } from "i18next";

const Step1 = () => {
  const { deviceData, setDeviceData } = useContext(DeviceContext);

  const handleChange = (e) => {
    setDeviceData({
      ...deviceData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="p-1">
      <h5 className="mb-4 text-center">{t("Tell us about your device")}</h5>
      <input
        type="text"
        name="deviceFakeName"
        className="form-control mb-3"
        placeholder={`${t("Device Name")}`}
        value={deviceData?.deviceFakeName}
        onChange={handleChange}
      />
      <input
        type="text"
        name="mainUser"
        className="form-control mb-3"
        placeholder={`${t("Main User")}`}
        value={deviceData?.mainUser}
        onChange={handleChange}
      />
    </div>
  );
};

export default Step1;
