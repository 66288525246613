import React from "react";
import { Modal } from "react-bootstrap";

const DeleteModal = ({ close, confirm, selectedDevice }) => {
  return (
    <Modal centered show={true} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>
          Remove Device {selectedDevice ? selectedDevice.deviceName : " "}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Are you sure you want to remove this device? This is irreversible</p>
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-danger" onClick={confirm}>
          Delete
        </button>
        <button className="btn btn-secondary" onClick={close}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
