import React, { useContext } from "react";
import { EnterpriseContext } from "../../Context/EnterpriseContext";

const Step1 = ({ next }) => {
  const { enterpriseData, setEnterpriseData } = useContext(EnterpriseContext);

  const handleChange = (e) => {
    setEnterpriseData({
      ...enterpriseData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <h5 className="mb-4">Tell us about your enterprise.</h5>
      <input
        type="text"
        name="enterpriseName"
        className="form-control mb-3"
        placeholder="Enterprise Name"
        value={enterpriseData.enterpriseName}
        onChange={handleChange}
      />
      <input
        type="text"
        name="cnpj"
        className="form-control mb-3"
        placeholder="CNPJ"
        value={enterpriseData.cnpj}
        onChange={handleChange}
      />
      <input
        type="text"
        name="address"
        className="form-control"
        placeholder="Address"
        value={enterpriseData.address}
        onChange={handleChange}
      />
    </div>
  );
};

export default Step1;
