// Updated login function without useState
export async function login(username, password, isAd) {
  const apiUrl = "/api/v1/login";

  const response = await fetch(apiUrl, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: username, password }),
  });

  // const isInDevelopmentMode = process.env.NODE_ENV === "development";

  // if (isInDevelopmentMode) {
  //   console.log(JSON.stringify({ email: username, password }));
  //   console.log(response);
  //   localStorage.setItem("auth", true);
  // }

  if (response.ok) {
    const data = await response.json();
    //  sessionStorage.setItem("lang", "pt-BR"); // Definindo o idioma para espanhol

    return { success: true, data };
  } else {
    return { success: false, data: await response.json() };
  }
}
