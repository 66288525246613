import React, { useContext, useEffect, useState } from "react";
import { EnterpriseContext } from "../../Context/EnterpriseContext";

import { FaRegBuilding } from "react-icons/fa"; // enterprise name
import { FaUserAlt } from "react-icons/fa"; // enterprise admin
import { MdOutlineAlternateEmail } from "react-icons/md"; //email
import { MdPhoneIphone } from "react-icons/md";
import { HiOutlineDocument } from "react-icons/hi2";
import { IoLocationSharp } from "react-icons/io5"; // adress
import CreatedMessage from "./CreatedMessage";

const Step3 = ({ next }) => {
  const { enterpriseData } = useContext(EnterpriseContext);
  const [message, setMessage] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [hasSent, setHasSent] = useState(false);

  const submit = () => {
    fetch("/api/v1/cad-enterprise", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(enterpriseData),
    })
      .then((res) => {
        if (res.ok) {
          setHasSent(true);
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setMessage(data.message);
      });
  };

  const allfields =
    enterpriseData.enterpriseName &&
    enterpriseData.enterpriseAdmin &&
    enterpriseData.enterpriseAdminEmail &&
    enterpriseData.enterpriseAdminPhone &&
    enterpriseData.cnpj &&
    enterpriseData.enterpriseAdminPassword &&
    enterpriseData.enterpriseAdminPassword.length >= 6 &&
    enterpriseData.address;

  useEffect(() => {
    if (enterpriseData.enterpriseAdminPassword.length < 6) {
      setErrorPassword(true);
    } else {
      setErrorPassword(false);
    }
  }, []);

  return (
    <React.Fragment>
      {hasSent && (
        <CreatedMessage
          message="Enterprise created successfully"
          enterprise={enterpriseData}
        />
      )}
      <div className="review">
        <div className="text-secondary mb-3 d-flex align-items-center">
          <span className="me-2">
            <FaRegBuilding className="register-icon text-dark" />
          </span>

          <span>Enterprise Name:</span>
          <span className="text-dark font-semibold  ms-1">
            {enterpriseData.enterpriseName || (
              <span className="text-danger ms-1 font-semibold ">Required</span>
            )}
          </span>
        </div>

        <div className="text-secondary mb-3 d-flex align-items-center">
          <span className="me-2">
            <FaUserAlt className="register-icon tw-text-blue-300" />
          </span>
          <span>Enterprise Admin:</span>
          <span className="text-dark font-semibold  ms-1">
            {enterpriseData.enterpriseAdmin || (
              <span className="text-danger ms-1 font-semibold ">Required</span>
            )}
          </span>
        </div>

        <div className="text-secondary mb-3 d-flex align-items-center">
          <span className="me-2">
            <MdOutlineAlternateEmail className="register-icon " />
          </span>
          <span>Enterprise Admin Email:</span>
          <span className="text-dark font-semibold  ms-1">
            {enterpriseData.enterpriseAdminEmail || (
              <span className="text-danger ms-1 font-semibold ">Required</span>
            )}
          </span>
        </div>

        <div className="text-secondary mb-3 d-flex align-items-center">
          <span className="me-2">
            <MdPhoneIphone className="register-icon tw-text-red-500" />
          </span>
          <span>Enterprise Admin Phone:</span>
          <span className="text-dark font-semibold  ms-1">
            {enterpriseData.enterpriseAdminPhone || (
              <span className="text-danger ms-1 font-semibold ">Required</span>
            )}
          </span>
        </div>

        <div className="text-secondary mb-3 d-flex align-items-center">
          <span className="me-2">
            <HiOutlineDocument className="register-icon tw-text-datadike-blue" />
          </span>
          <span>Enterprise CNPJ:</span>
          <span className="text-dark font-semibold  ms-1">
            {enterpriseData.cnpj || (
              <span className="text-danger ms-1 font-semibold ">Required</span>
            )}
          </span>
        </div>

        <div className="text-secondary mb-3 d-flex align-items-center">
          <span className="me-2">
            <IoLocationSharp className="register-icon tw-text-red-600" />
          </span>
          <span>Enterprise Address:</span>
          <span className="text-dark font-semibold  ms-1">
            {enterpriseData.address || (
              <span className="text-danger ms-1 font-semibold ">Required</span>
            )}
          </span>
        </div>

        <div className="mt-2">
          {allfields && (
            <button
              disabled={!allfields}
              onClick={submit}
              className="btn btn-primary w-100"
            >
              Confirm
            </button>
          )}

          {/* {message && <p className="text-center mt-1">{message}</p>} */}
          {errorPassword && (
            <p className="text-danger text-center mt-1">
              Admin password is required and must be at least 6 characters
            </p>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Step3;
