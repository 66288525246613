export const optionsDataUsage = [
  {
    label: "30 min",
    value: 30,
    text: "Last 30 Minutes",
  },
  {
    label: "1 hour",
    value: 60,
    text: "Last 1 Hour",
  },
  {
    label: "3 hours",
    value: 180,
    text: "Last 3 Hours",
  },
  {
    label: "6 hours",
    value: 360,
    text: "Last 6 Hours",
  },
  {
    label: "12 hours",
    value: 720,
    text: "Last 12 Hours",
  },
  {
    label: "1 day",
    value: 1440,
    text: "Last 1 Day",
  },
  {
    label: "2 days",
    value: 2880,
    text: "Last 2 Days",
  },
];
