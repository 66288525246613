import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { tagBadges } from "../../extraFunctions/badgesTw";
import { useTranslation } from "react-i18next";

const CreateTag = ({ show, onHide, onAdd }) => {
  const { t } = useTranslation();
  const [tagName, setTagName] = useState(null);
  const [selectedColor, setSelectedColor] = useState("default");
  const [feedback, setFeedback] = useState("");
  const [colorOptions, setColorOptions] = useState(null);

  const submit = async () => {
    if (!tagName) {
      setFeedback({
        ok: false,
        message: t("The field name can't be empty"),
      });
      return;
    }

    const response = await fetch("/api/v1/tags/create-tag", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tagName: tagName.toUpperCase(),
        color: selectedColor,
      }),
    });

    const data = await response.json();

    if (response.ok) {
      onAdd();
      setFeedback({
        ok: true,
        message: t("Tag created successfully"),
      });
      setTagName(null);
    } else {
      setFeedback({
        ok: false,
        message: `${t("Error:")} ${data.message}`,
      });
    }
  };

  useEffect(() => {
    const newColorOptions = Object.keys(tagBadges).map((color) => ({
      value: color,
      label: (
        <span className={tagBadges[color]}>
          {tagName ? tagName.toUpperCase() : color}
        </span>
      ),
    }));

    setColorOptions(newColorOptions);
  }, [tagName]);

  useEffect(() => {
    if (feedback) {
      setTimeout(() => setFeedback(null), 3500);
    }
  }, [feedback]);

  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row className="p-0">
            <Col className="" xl={6}>
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Label>{t("Tag Name")}</Form.Label>
                <Form.Control
                  value={tagName}
                  onChange={(e) => setTagName(e.target.value)}
                  type="text"
                  placeholder={t("Enter Tag Name")}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      submit();
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Col className="" xl={6}>
              <Form.Group className="" controlId="formColorSelect">
                <Form.Label>{t("Select Color")}</Form.Label>
                <Select
                  isSearchable={false}
                  options={colorOptions}
                  onChange={(selectedOption) =>
                    setSelectedColor(selectedOption.value)
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          {feedback && (
            <div
              className={
                feedback.ok
                  ? "tw-bg-green-500 tw-text-white p-4 tw-rounded-md mt-4"
                  : "tw-bg-red-500 tw-text-white p-4 tw-rounded-md mt-4"
              }
            >
              {feedback.message}
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t("Close")}
        </Button>
        <Button variant="primary" onClick={submit}>
          {t("Create Tag")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateTag;
