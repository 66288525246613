import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, Button } from "react-bootstrap";
import Select from "react-select";
import { useGet } from "../../hooks/get";
import { PoliciesContext } from "../../Context/PolicyContext";
import usePolicyData from "./PolicyData"; // Certifique-se de que o caminho esteja correto
import PolicyForm from "./PolicyForm";
import FormatValidator from "./FormatValidator.js";
import { VscLoading } from "react-icons/vsc";
import { validatePolicyData } from "../../hooks/validatePolicyData.js";

const CreatePolicy = () => {
  const { t } = useTranslation();
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [policyName, setPolicyName] = useState("");
  const [allDepartmentsData, setDepartmentData] = useState([]);
  const { refreshState } = useContext(PoliciesContext);

  const { policyData, setPolicyData } = usePolicyData(); // Ajuste aqui
  const [feedback, setFeedback] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  // Certifique-se de passar setPolicyData como dependência

  const { data: allDepartmentsDataApi, loading: loadingDepartments } = useGet(
    "/api/v1/department/view-departments",
    refreshState
  );

  useEffect(() => {
    if (allDepartmentsDataApi && allDepartmentsDataApi.length > 0) {
      const newData = allDepartmentsDataApi.filter(
        (item) => item.hasPolicy !== true
      );
      setDepartmentData(newData);
    } else {
      setDepartmentData([
        {
          departmentId: "error",
          departmentName: "error",
        },
        {
          departmentId: "error2",
          departmentName: "error2",
        },
      ]);
    }
  }, [allDepartmentsDataApi]);

  const departmentOptions = allDepartmentsData?.map((d) => ({
    value: d.departmentId,
    label: d.departmentName,
  }));
  console.log("departmentOptions:", departmentOptions);

  const handleSubmit = async () => {
    setLoadingSubmit(true);

    // Extrair opções específicas das aplicações a partir de applicationsOptions
    const {
      appAutoUpdatePolicy = "APP_AUTO_UPDATE_POLICY_UNSPECIFIED",
      playStoreMode = "WHITELIST",
      untrustedAppsPolicy = "UNTRUSTED_APPS_POLICY_UNSPECIFIED",
      googlePlayProtectVerifyApps = "GOOGLE_PLAY_PROTECT_VERIFY_APPS_UNSPECIFIED",
    } = policyData.applicationsOptions || {};

    // Mapear as aplicações adicionadas
    const formattedApplications =
      policyData?.addedApps
        ?.filter((app) => app.packageName)
        .map((app) => ({
          packageName: app.packageName,
          installType: app.installType ?? null,
          defaultPermissionPolicy: app.defaultPermissionPolicy ?? null,
        })) ?? [];

    // Construir o objeto formattedData conforme o exemplo fornecido
    const formattedData = {
      policyId: policyName || "",
      policyOptions: {
        // Geral
        encryptionPolicy:
          policyData?.general?.find(
            (item) => item.keyName === "encryptionPolicy"
          )?.selectedOption ?? null,
        defaultPermissionPolicy:
          policyData?.general?.find(
            (item) => item.keyName === "defaultPermissionPolicy"
          )?.selectedOption ?? null,
        locationMode:
          policyData?.general?.find((item) => item.keyName === "locationMode")
            ?.selectedOption ?? null,
        keyguardDisabledFeatures:
          policyData?.general?.find(
            (item) => item.keyName === "keyguardDisabledFeatures"
          )?.selectedOptions ?? [],
        stayOnPluggedModes:
          policyData?.general?.find(
            (item) => item.keyName === "stayOnPluggedModes"
          )?.selectedOptions ?? [],
        systemUpdate: {
          type:
            policyData?.general?.find(
              (item) => item.keyName === "systemUpdateType"
            )?.selectedOption ?? null,
        },
        // Display
        maximumTimeToLock:
          (policyData?.display?.find(
            (item) => item.keyName === "maximumTimeToLock"
          )?.selectedOption ?? 0) * 60000,
        // Mensagens
        deviceOwnerLockScreenInfo: {
          defaultMessage:
            policyData?.message?.find(
              (item) => item.keyName === "deviceOwnerLockScreenInfo"
            )?.selectedOption ?? "",
        },
        shortSupportMessage: {
          defaultMessage:
            policyData?.message?.find(
              (item) => item.keyName === "shortSupportMessage"
            )?.selectedOption ?? "",
        },
        longSupportMessage: {
          defaultMessage:
            policyData?.message?.find(
              (item) => item.keyName === "longSupportMessage"
            )?.selectedOption ?? "",
        },
        // Aplicações - Configurações Gerais
        installAppsDisabled:
          policyData?.applications?.General?.find(
            (item) => item.keyName === "installAppsDisabled"
          )?.selectedOption === "DISABLED",
        uninstallAppsDisabled:
          policyData?.applications?.General?.find(
            (item) => item.keyName === "uninstallAppsDisabled"
          )?.selectedOption === "DISABLED",
        advancedSecurityOverrides: {
          googlePlayProtectVerifyApps:
            policyData?.applications?.General?.find(
              (item) => item.keyName === "ensureVerifyAppsEnabled"
            )?.selectedOption === "DISABLED"
              ? "VERIFY_APPS_UNENFORCED"
              : "VERIFY_APPS_ENFORCED",
          untrustedAppsPolicy: untrustedAppsPolicy,
          googlePlayProtectVerifyApps: googlePlayProtectVerifyApps,
        },
        // Opções Específicas das Aplicações (Separadas)
        playStoreMode: playStoreMode,
        appAutoUpdatePolicy: appAutoUpdatePolicy,
        // Lista de Aplicações
        applications: formattedApplications,
      },
      departmentId: selectedDepartment?.value,
      departmentName: selectedDepartment?.label,
    };

    // Validar os dados formatados

    try {
      const response = await fetch("/api/v1/google/create-policy", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formattedData),
      });

      const data = await response.json();
      if (response.ok) {
        setFeedback({
          ok: true,
          message: data?.message || "Policy created successfully",
        });
      } else {
        setFeedback({
          ok: false,
          message: data?.message || "Error creating policy",
        });
      }
    } catch (error) {
      setFeedback({ ok: false, message: "Error creating policy" });
    }
    setLoadingSubmit(false);
  };

  useEffect(() => {
    if (feedback) {
      setTimeout(() => {
        setFeedback(null);
      }, 6000);
    }
  }, [feedback]);

  if (loadingSubmit) {
    return (
      <Card className="custom-card-1" style={{ height: "650px", border: "0" }}>
        <Card.Body className="jc ac">
          <h5 className="loading-icon">
            <VscLoading />
          </h5>
        </Card.Body>
      </Card>
    );
  }
  return (
    <React.Fragment>
      <Card className="custom-card-1" style={{ height: "650px", border: "0" }}>
        {feedback && (
          <Card.Header>
            <div
              className={`${
                feedback.ok ? "tw-bg-green-500" : "tw-bg-red-500"
              } p-2 tw-rounded-md w-100 text-white`}
            >
              {feedback.message}
            </div>
          </Card.Header>
        )}
        <Card.Body>
          <div className="jsb px-2">
            <div className="w-50 pe-3">
              <div className="mb-2 font-semibold ps-1 ">
                {t("Policy Name")}
                <span className="tw-text-red-600 ms-1">*</span>
              </div>
              <input
                className="form-control"
                placeholder={`${t("Policy Name")}`}
                onChange={(e) => setPolicyName(e.target.value)}
                value={policyName}
              />
            </div>
            <div className="w-50">
              <div className="mb-2 font-semibold ps-1 ">
                {t("Department Name")}
                <span className="tw-text-red-600 ms-1">*</span>
              </div>
              <Select
                options={departmentOptions}
                value={selectedDepartment}
                onChange={setSelectedDepartment}
                isLoading={loadingDepartments}
                placeholder={t("Select a department")}
              />
            </div>
          </div>
          <div className="px-2 mt-3">
            {policyData && (
              <PolicyForm
                policyData={policyData}
                setPolicyData={setPolicyData}
                submit={(data) => handleSubmit(data)}
              />
            )}
          </div>
          {/* <Button onClick={handleSubmit} className="mt-3">
            {t("Submit Policy")}
          </Button> */}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default CreatePolicy;
