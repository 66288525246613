import React from "react";
import { t } from "i18next";
import { LargeBadges } from "../../extraFunctions/badgesTw";
import { convertToLocalTime } from "../../hooks/convertDate";
import { LuSmartphone } from "react-icons/lu";
import { TbWorldLatitude } from "react-icons/tb";
import { TbWorldLongitude } from "react-icons/tb";
import { FaRegCalendar } from "react-icons/fa";

const AddressPopup = ({ selectedDevice, address }) => {
  console.log("AddressPopup");
  return (
    <div
      className="address-popup"
      style={{
        zIndex: "1000",
      }}
    >
      <div
        className="w-20 flex  mb-2  js ac"
        style={{
          flexWrap: "nowrap",
          whiteSpace: "nowrap",
        }}
      >
        <h4 className={`${LargeBadges.default}`}>
          <span className="me-1">
            <LuSmartphone />
          </span>
          {selectedDevice?.name}
        </h4>
      </div>
      <div className="flex ac">
        {/* {selectedDevice?.status === true ? (
          <span className="tw-text-green-600  my-animate-pulse15">
            <GoDotFill />
          </span>
        ) : (
          <span className="tw-text-red-600 my-animate-pulse15">
            <GoDotFill />
          </span>
        )} */}

        <h4 className="force-font-2 mb-2 -tw-tracking-wider">
          {address || (
            <span className="text-secondary tw-animate-pulse">
              {t("Loading...")}
            </span>
          )}
        </h4>
      </div>
      <h6 className="force-font mb-2 font-semibold">
        <span className="me-1 tw-text-blue-400">
          <TbWorldLatitude className="icone-torto" />
        </span>
        Latitude:
        <span className="ms-1 tw-font-normal text-secondary">
          {selectedDevice.latitude}
        </span>
      </h6>
      <h6 className="force-font mb-2 font-semibold">
        <span className="me-1 tw-text-green-400">
          <TbWorldLongitude className="icone-torto" />
        </span>
        Longitude:
        <span className="ms-1 tw-font-normal text-secondary">
          {selectedDevice.longitude}
        </span>
      </h6>

      <h6 className="force-font mb-2 font-semibold">
        <span className="me-1 tw-text-red-400">
          <FaRegCalendar className="icone-torto" />
        </span>
        {t("Last Report")}:
        <span className="ms-1 tw-font-normal text-secondary">
          {convertToLocalTime(selectedDevice.lastSeen)}
        </span>
      </h6>
    </div>
  );
};

export default AddressPopup;
