import React, { useEffect, useState } from "react";
import { Card, Row, Table, Button, Dropdown } from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";
import { useSort } from "../../hooks/useSort";
import useSearch from "../../hooks/useSearch";
import ProgressBar from "../Utils/ProgressBar";
import { FaCheck, FaDownload, FaFilter, FaPen } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { MdCancel } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { badgesDefault } from "../../extraFunctions/badgesTw";
import { useNavigate } from "react-router-dom";
import { PiFileCsvDuotone } from "react-icons/pi";
import { convertToLocalTime } from "../../hooks/convertDate";
import { FcAndroidOs } from "react-icons/fc";
import { tagBadges } from "../../extraFunctions/badgesTw";
import FilterModal from "./FilterModal.js";
import PaginationUi from "../Utils/Pagination";
import { FaSort } from "react-icons/fa6";

const DevicesList = ({ refreshState }) => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editDeviceId, setEditDeviceId] = useState(null);
  const [editedName, setEditedName] = useState("");
  const [error, setError] = useState(null);
  const [refreshButton, setRefreshButton] = useState(1);
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang") || "en";
  const preloadedItemsPerPage = localStorage.getItem("dipp") || 10;
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Define quantos itens serão exibidos por página
  const totalPages = Math.ceil(devices.length / itemsPerPage);
  const isInDevelopmentMode = process.env.NODE_ENV === "development";

  const availableColumns = [
    "deviceName",
    "deviceModel",
    "status",
    "totalStorage",
    "usedStorage",
    "totalRam",
    "batteryLevel",
    "uptime",
    "deviceSystemVersion",
    "employees",
    "phoneLines",
    "serialNumber",
    "imeis",
    "lastSeen",
    "tags",
    "registeredAt",
  ];

  const preLoadedColumns = localStorage.getItem("dtc")
    ? JSON.parse(localStorage.getItem("dtc"))
    : availableColumns; // default columns

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterColumns, setFilterColumns] = useState(preLoadedColumns || []);

  const handleFilter = (filter) => {
    setFilterColumns(filter);
    localStorage.setItem("dtc", JSON.stringify(filter));
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`/api/v1/dashboard/devices-info`, {
        credentials: "include",
      });
      const data = await response.json();

      if (response.ok) {
        const newData = data.map((device) => {
          return {
            deviceModel: device.deviceModel ? device.deviceModel : "null",
            deviceId: device.deviceId,
            deviceVendor: device.deviceVendor,
            deviceName: device.deviceName || "-",
            totalStorage: device.totalStorage
              ? Number(device.totalStorage)
              : "",
            usedStorage: device.usedStorage,
            usedStoragePercent: device.usedStoragePercent,
            totalRam: device.totalRam,
            batteryLevel: device.batteryLevel,
            employees: device.employees ? device.employees.join(", ") : "",
            uptime: device.uptime ? device.uptime : "00:00:00:00",
            status: device.status ? "Online" : "Offline",
            phoneLines: device.phoneLines ? device.phoneLines.join(", ") : "",
            imeis: device.imeis
              ? device.imeis.filter((imei) => imei).join(", ")
              : "",
            registeredAt: device.registeredAt
              ? convertToLocalTime(device.registeredAt)
              : "",
            tags: device.tags,
            serialNumber: device.serialNumber,
            lastSeen: device.lastSeen
              ? convertToLocalTime(device.lastSeen)
              : "-",
            deviceSystemVersion: device.deviceSystemVersion
              ? device.deviceSystemVersion
              : "null",
          };
        });

        const sortedByOnlineData = newData.sort((a, b) => {
          if (a.status === "Online" && b.status === "Offline") {
            return -1;
          }
          if (a.status === "Offline" && b.status === "Online") {
            return 1;
          }
          return 0;
        });

        setDevices(sortedByOnlineData);

        setLoading(false);
      } else {
        setError("Error fetching Devices");
        setLoading(false);
        setDevices([]);
      }
    };

    fetchData();
  }, [refreshState, refreshButton]); // Remova `loading` se desejar executar apenas na montagem

  const handleEditClick = async (device) => {
    setEditDeviceId(device.deviceId);
    setEditedName(device.deviceName);
  };

  const handleSaveClick = async (deviceId) => {
    const response = await fetch(`/api/v1/manage-device/device-general-info`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        deviceId: deviceId,
        deviceName: editedName,
      }),
    });

    if (response.ok) {
      const newDevices = devices.map((device) => {
        if (device.deviceId === deviceId) {
          // Criando um novo objeto para o dispositivo atualizado
          return { ...device, deviceName: editedName };
        }
        return device;
      });
      setDevices(newDevices); // Atualizando o estado com o novo array de dispositivos
      setEditDeviceId(null);
    }
  };
  const exportToCSV = (data) => {
    const newData = data.map((device) => {
      if (lang == "en") {
        return {
          Model: device.deviceModel || "-",
          "Device ID:": device.deviceId,
          Vendor: device.deviceVendor,
          Name: device.deviceName,
          "Total Storage (GB)": device.totalStorage
            ? `${device.totalStorage} GB`
            : "-",
          "Used Storage (GB)": device.usedStorage
            ? `${device.usedStorage} GB`
            : "-",
          "Used Storage (%)": device.usedStoragePercent
            ? `${device.usedStoragePercent}%`
            : "-",
          "Total RAM (GB)": device.totalRam ? `${device.totalRam} GB` : "-",
          "Battery Level (%)": device.batteryLevel
            ? `${device.batteryLevel}%`
            : "0%",
          "Phone Number": device.phoneLines || "",
          imeis: Array.isArray(device.imeis)
            ? device.imeis.filter((imei) => imei).join(", ")
            : "",
          Tags: device.tags
            ? device.tags.map((item) => item.tagName).join(", ")
            : null,

          "Registered At": device.registeredAt || "",
          Uptime: device.uptime || "00:00:00:00",
          Status: device.status ? "Online" : "Offline",
          PhoneLines: device.phoneLines,
        };
      } else if (lang == "pt-BR") {
        return {
          Modelo: device.deviceModel || "-",
          "ID Do Dispositivo": device.deviceId,
          Fabricante: device.deviceVendor,
          "Nome do Dispositivo": device.deviceName,
          "Armazenamento Total (GB)": device.totalStorage
            ? `${device.totalStorage} GB`
            : "-",
          "Armazenamento Usado (GB)": device.usedStorage
            ? `${device.usedStorage} GB`
            : "-",
          "Armazenamento Usado (%)": device.usedStoragePercent
            ? `${device.usedStoragePercent}%`
            : "-",
          "Memória RAM (GB)": device.totalRam ? `${device.totalRam} GB` : "-",
          "Nível de Bateria (%)": device.batteryLevel
            ? `${device.batteryLevel}%`
            : "0%",
          "Número de Telefone": device.phoneLines || "",
          imeis: Array.isArray(device.imeis)
            ? device.imeis.filter((imei) => imei).join(", ")
            : "",
          Tags: device.tags
            ? device.tags.map((item) => item.tagName).join(", ")
            : null,
          "Registrado em": device.registeredAt || "",

          "Tempo de Atividade": device.uptime || "00:00:00:00",
          Status: device.status ? "Online" : "Offline",
        };
      }
    });

    const csvRows = [];
    const headers = newData.length > 0 ? Object.keys(newData[0]) : [];
    csvRows.push(headers.join(","));

    for (const row of newData) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "devices-data.csv";
    link.click();
  };

  const handleNavigate = (dev) => {
    console.log(`enviando para deviceId=${dev.deviceId}`);
    if (dev) {
      navigate(`/sysadmin/?deviceId=${dev.deviceId}`);
    }
  };
  const { sortedData, requestSort, sortConfig } = useSort(devices);
  const searchFields = [
    "deviceModel",
    "deviceName",
    "status",
    "totalStorage",
    "usedStorage",
    "totalRam",
    "batteryLevel",
    "uptime",
    "deviceSystemVersion",
    "employees",
    "phoneLines",
    "serialNumber",
    "imeis",
    "lastSeen",
    "tags",
    "registeredAt",
  ];
  const { searchTerm, setSearchTerm, filteredData } = useSearch(
    sortedData,
    searchFields
  );

  const colorTag = (cor) => {
    return tagBadges[cor] || tagBadges.default;
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (searchTerm && searchTerm !== "" && page !== 1) {
      setPage(1);
    }
  }, [page, searchTerm]);

  if (loading) {
    return (
      <Card
        style={{
          height: "500px",
        }}
      >
        <Card.Body className="jc ac">
          <div className="mw-100 mh-100">
            <h4
              style={{ fontSize: "25px" }}
              className="tw-animate-spin text-primary"
            >
              <VscLoading />
            </h4>
          </div>
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card
        style={{
          height: "500px",
        }}
      >
        <Card.Body className="jc ac">
          <h5 className="text-dark  mb-0">{error}</h5>
        </Card.Body>
      </Card>
    );
  }

  let min150 = {
    minWidth: lang == "en" ? "150px" : "175px",
  };

  let min250 = {
    minWidth: lang == "en" ? "200px" : "225px",
  };

  const currentDevices = filteredData.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <Row>
      {showFilterModal && (
        <FilterModal
          show={showFilterModal}
          handleClose={() => setShowFilterModal(false)}
          filterColumns={filterColumns}
          setFilterColumns={(filter) => handleFilter(filter)}
          availableColumns={availableColumns}
        />
      )}
      <Card
        style={{
          minHeight: "550px",
          maxheight: "740px",
          overflow: "auto",
        }}
        className="custom-card-1"
      >
        <Card.Header className="card-table-header">
          <button
            onClick={() => setRefreshButton((prevState) => prevState + 1)}
            className="btn btn-outline-primary"
          >
            <i className="ri-refresh-line cursor-pointer"></i>
          </button>
          <div className="flex  tw-justify-end">
            <select
              className="me-1 form-select"
              style={{
                width: "25%",
              }}
              onChange={(e) => {
                if (Number(e.target.value) == 0) {
                  setItemsPerPage(10000); // 10 mil
                } else {
                  setItemsPerPage(Number(e.target.value));
                }
                localStorage.setItem("dipp", Number(e.target.value));
              }}
              value={itemsPerPage}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={devices ? parseInt(devices.length) : 99999}>
                {t("All")}
              </option>

              {isInDevelopmentMode && <option value={1}>1 (dev only)</option>}
            </select>
            <input
              type="text"
              className="form-control w-50 me-2"
              placeholder={`${t("Search...")}`}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            <button
              onClick={() => setShowFilterModal(true)}
              className="me-1 btn btn-outline-primary"
            >
              <FaFilter />
            </button>

            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <FaDownload />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => exportToCSV(devices)}>
                  <span className="text-success">
                    <PiFileCsvDuotone />
                    <span className="text-dark ms-1">CSV</span>
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Card.Header>

        <Card.Body>
          <Table className="table-users" hover>
            <thead className="tw-bg-gray-600">
              <tr>
                {filterColumns.includes("deviceName") && (
                  <th onClick={() => requestSort("deviceName")}>
                    {t("Name")} <FaSort className="sort-icon" />
                  </th>
                )}

                {filterColumns.includes("deviceVendor") && (
                  <th onClick={() => requestSort("deviceVendor")}>
                    {t("Vendor")} <FaSort className="sort-icon" />
                  </th>
                )}

                {filterColumns.includes("deviceModel") && (
                  <th onClick={() => requestSort("deviceModel")}>
                    {t("Device Model")} <FaSort className="sort-icon" />
                  </th>
                )}

                {filterColumns.includes("status") && (
                  <th
                    className="center-text-th"
                    onClick={() => requestSort("status")}
                  >
                    {t("Status")} <FaSort className="sort-icon" />
                  </th>
                )}

                {filterColumns.includes("totalStorage") && (
                  <th onClick={() => requestSort("totalStorage")}>
                    {t("dashboardTable.totalStorage")}
                    <FaSort className="sort-icon" />
                  </th>
                )}

                {filterColumns.includes("usedStorage") && (
                  <th onClick={() => requestSort("usedStorage")}>
                    {t("dashboardTable.usedStorage")}
                    <FaSort className="sort-icon" />
                  </th>
                )}

                {filterColumns.includes("totalRam") && (
                  <th onClick={() => requestSort("totalRam")}>
                    {t("Total Ram")} <FaSort className="sort-icon" />
                  </th>
                )}

                {filterColumns.includes("batteryLevel") && (
                  <th onClick={() => requestSort("batteryLevel")}>
                    {t("Battery Level")} <FaSort className="sort-icon" />
                  </th>
                )}

                {filterColumns.includes("deviceSystemVersion") && (
                  <th onClick={() => requestSort("deviceSystemVersion")}>
                    {t("OS Version")} <FaSort className="sort-icon" />
                  </th>
                )}

                {filterColumns.includes("employees") && (
                  <th onClick={() => requestSort("employees")}>
                    {t("Employees")} <FaSort className="sort-icon" />
                  </th>
                )}

                {filterColumns.includes("phoneLines") && (
                  <th onClick={() => requestSort("phoneLines")}>
                    {t("Phone Number")} <FaSort className="sort-icon" />
                  </th>
                )}

                {filterColumns.includes("serialNumber") && (
                  <th onClick={() => requestSort("serialNumber")}>
                    {t("Serial Number")} <FaSort className="sort-icon" />
                  </th>
                )}

                {filterColumns.includes("imeis") && (
                  <th onClick={() => requestSort("imeis")}>
                    {t("IMEIs")} <FaSort className="sort-icon" />
                  </th>
                )}

                {filterColumns.includes("tags") && (
                  <th onClick={() => requestSort("tags")}>
                    {t("Tags")} <FaSort className="sort-icon" />
                  </th>
                )}

                {filterColumns.includes("uptime") && (
                  <th onClick={() => requestSort("uptime")}>
                    {t("Uptime")} <FaSort className="sort-icon" />
                  </th>
                )}

                {filterColumns.includes("lastSeen") && (
                  <th onClick={() => requestSort("lastSeen")}>
                    {t("Last seen")} <FaSort className="sort-icon" />
                  </th>
                )}

                {filterColumns.includes("registeredAt") && (
                  <th onClick={() => requestSort("registeredAt")}>
                    {t("dashboardTable.registeredAt")}
                    <FaSort className="sort-icon" />
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {currentDevices.map((dev, index) => (
                <tr key={dev.deviceId}>
                  {filterColumns.includes("deviceName") && (
                    <td style={{ minWidth: "250px" }}>
                      {editDeviceId === dev.deviceId ? (
                        <div className="d-flex align-items-center">
                          <button
                            className="btn btn-danger me-2"
                            onClick={() => {
                              setEditDeviceId(null);
                            }}
                          >
                            <MdCancel />
                          </button>
                          <input
                            type="text"
                            className="form-control w-50 me-2"
                            value={editedName}
                            onChange={(e) => setEditedName(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                handleSaveClick(dev.deviceId);
                              }
                            }}
                          />
                          <button
                            className="ms-1 btn btn-success"
                            onClick={() => handleSaveClick(dev.deviceId)}
                          >
                            <FaCheck />
                          </button>
                        </div>
                      ) : (
                        <div className="flex align-items-center tw-justify-between">
                          <div>
                            <span
                              className={`me-2 ${
                                dev.status === "Online"
                                  ? "tw-text-green-500"
                                  : "tw-text-red-600"
                              }`}
                            >
                              <GoDotFill className="" />
                            </span>
                            <span
                              onClick={() => {
                                handleNavigate(dev);
                              }}
                              className="cursor-pointer text-primary"
                            >
                              {dev.deviceName}
                            </span>
                          </div>
                          <span
                            className="me-2 cursor-pointer tw-text-gray-400"
                            onClick={() => handleEditClick(dev)}
                          >
                            <FaPen />
                          </span>
                        </div>
                      )}
                    </td>
                  )}
                  {filterColumns.includes("deviceVendor") && (
                    <td style={min150}>{dev.deviceVendor}</td>
                  )}
                  {filterColumns.includes("deviceModel") && (
                    <td style={{ minWidth: "200px" }}>
                      {dev.deviceModel ? (
                        <span className="">{dev.deviceModel}</span>
                      ) : (
                        <span className="tw-text-gray-300">Insert name...</span>
                      )}
                    </td>
                  )}
                  {filterColumns.includes("status") && (
                    <td style={min150} className="center-text-td">
                      <span
                        className={`${
                          dev.status === "Online"
                            ? badgesDefault.green
                            : badgesDefault.red
                        }`}
                      >
                        {dev.status}
                      </span>
                    </td>
                  )}
                  {filterColumns.includes("totalStorage") && (
                    <td style={min150}>{dev.totalStorage}GB</td>
                  )}
                  {filterColumns.includes("usedStorage") && (
                    <td style={{ minWidth: "200px" }}>
                      <span
                        className={`${
                          dev.usedStoragePercent > 80
                            ? "tw-text-red-700"
                            : dev.usedStoragePercent > 50
                            ? "tw-text-yellow-500"
                            : "tw-text-green-500"
                        } font-semibold  me-2`}
                      >
                        {dev.usedStorage}GB
                      </span>
                      <ProgressBar
                        progress={dev.usedStoragePercent}
                        label={`${dev.usedStoragePercent}%`}
                        color={
                          dev.usedStoragePercent > 80
                            ? "#ff6b6b"
                            : dev.usedStoragePercent > 50
                            ? "#ffd93d"
                            : "#51cf66"
                        }
                      />
                    </td>
                  )}
                  {filterColumns.includes("totalRam") && (
                    <td style={min150}>{dev.totalRam}GB</td>
                  )}
                  {filterColumns.includes("batteryLevel") && (
                    <td style={{ minWidth: "200px" }}>
                      <span
                        className={`${
                          dev.batteryLevel < 20
                            ? "tw-text-red-700"
                            : dev.batteryLevel < 50
                            ? "tw-text-yellow-500"
                            : "tw-text-green-500"
                        } font-semibold  me-2`}
                      >
                        {dev.batteryLevel}%
                      </span>
                      <ProgressBar
                        progress={dev.batteryLevel}
                        label={`${dev.batteryLevel}%`}
                        color={
                          dev.batteryLevel < 20
                            ? "#ff6b6b"
                            : dev.batteryLevel < 50
                            ? "#ffd93d"
                            : "#51cf66"
                        }
                      />
                    </td>
                  )}
                  {filterColumns.includes("deviceSystemVersion") && (
                    <td style={min150}>
                      <span style={{ fontSize: "20px" }} className="">
                        <FcAndroidOs />
                      </span>
                      <span className="ms-2">{dev.deviceSystemVersion}</span>
                    </td>
                  )}
                  {filterColumns.includes("employees") && (
                    <td style={min250} className="">
                      {dev.employees || ""}
                    </td>
                  )}
                  {filterColumns.includes("phoneLines") && (
                    <td style={min150} className="">
                      {dev.phoneLines || ""}
                    </td>
                  )}
                  {filterColumns.includes("serialNumber") && (
                    <td style={min150} className="">
                      {dev.serialNumber || ""}
                    </td>
                  )}
                  {filterColumns.includes("imeis") && (
                    <td style={min150} className="">
                      {dev.imeis || ""}
                    </td>
                  )}
                  {filterColumns.includes("tags") && (
                    <td
                      style={min150}
                      className={dev.tavTags > 1 ? "d-block" : ""}
                    >
                      {dev.tags
                        ? dev.tags.map((item) => (
                            <span
                              key={item.tagId}
                              className={`${colorTag(
                                item.color
                              )} d-block me-2 mb-2`}
                            >
                              {item.tagName}
                            </span>
                          ))
                        : ""}
                    </td>
                  )}
                  {filterColumns.includes("uptime") && (
                    <td style={min150}>
                      {dev.uptime && dev.uptime !== "00:00:00:00"
                        ? dev.uptime
                        : ""}
                    </td>
                  )}
                  {filterColumns.includes("lastSeen") && (
                    <td style={min250}>{dev.lastSeen ? dev.lastSeen : ""}</td>
                  )}
                  {filterColumns.includes("registeredAt") && (
                    <td style={min250} className="">
                      {dev.registeredAt || ""}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="flex jsb">
          <div>
            <span className="text-secondary">
              Total:
              <span className="ms-1 text-dark">
                {/* {devices && devices.length > 0 ? devices.length : 0} */}
                {searchTerm && searchTerm !== ""
                  ? filteredData.length
                  : devices.length}
              </span>
            </span>
          </div>
          <PaginationUi
            currentPage={page}
            totalPages={totalPages}
            onPageChange={(page) => setPage(page)}
            searchTerm={searchTerm}
          />
        </Card.Footer>
      </Card>
    </Row>
  );
};

export default DevicesList;
