import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";
import { useJwt } from "react-jwt";
import { FaEye } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const EndRegisterByInvite = () => {
  const [token, setToken] = useState("");
  const [enterpriseName, setEnterpriseName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [hasExpired, setHasExpired] = useState(false);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({
    passwordMismatch: false,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = searchParams.get("token");
    setToken(tokenFromUrl);
  }, []);

  const { decodedToken } = useJwt(token);

  useEffect(() => {
    if (decodedToken) {
      setEnterpriseName(decodedToken.enterpriseName);

      // Converter o valor exp para milissegundos e comparar com a data atual
      const currentTime = Date.now() / 1000; // Em segundos
      const tokenHasExpired = decodedToken.exp < currentTime;

      setHasExpired(tokenHasExpired);
    }
  }, [decodedToken]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        passwordMismatch: true,
      }));
      return;
    }

    try {
      const response = await fetch(
        `/api/v1/finalize-registration?token=${token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: formData.name,
            phone: formData.phone,
            password: formData.password,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setFeedback({
          ok: true,
          message: data?.message || data.error,
        });
        setTimeout(() => {
          window.location.replace("/signin");
        }, 5000);
      } else {
        setFeedback({
          ok: false,
          message: data?.message || data.error,
        });
        console.error("Registration failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!token) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Card className="custom-card-1">
          <Card.Body className="jc ac mw-100 mh-100">
            <VscLoading className="loading-icon" />
          </Card.Body>
        </Card>
      </div>
    );
  }

  if (hasExpired) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Card
          style={{
            width: "530px",
          }}
          className="custom-card-1"
        >
          <Card.Body className="jc ac mw-100 mh-100">
            <div>
              <h4 className="text-dark mb-2 text-center">
                {t("errorsDefault.inviteTokenExp1")}
              </h4>
              <h6>{t("errorsDefault.inviteTokenExp2")}</h6>

              <div className="mt-4 jc">
                <button
                  onClick={() => {
                    window.location.replace("/signin");
                  }}
                  className="btn btn-primary tw-tracking-widest tw-uppercase"
                >
                  {t("errorsDefault.backToLogin")}
                </button>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card className="custom-card-1">
        <Card.Header>
          <span className="logo-mdm m-0">DataDike MDM</span>
        </Card.Header>
        <Card.Body>
          <div className="mb-4 text-center w-100">
            <h5 className="mb-0">
              You were invited to join
              <span className="font-semibold  ms-1">{enterpriseName}</span>
            </h5>
          </div>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col xl={6}>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>

              <Col xl={6}>
                <Form.Group controlId="formPhone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <FaEye />
                  </Button>
                </InputGroup>
              </Form.Group>
            </Row>

            <Form.Group controlId="formConfirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                  isInvalid={formErrors.passwordMismatch}
                />
                <Form.Control.Feedback type="invalid">
                  Passwords do not match.
                </Form.Control.Feedback>
                <Button
                  variant="outline-secondary"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FaEye />
                </Button>
              </InputGroup>
            </Form.Group>

            <Button type="submit" className="mt-3 w-100">
              Finish your register
            </Button>

            {feedback && (
              <div className="">
                {/* <p className="mb-1 tw-text-white font-semibold ">Success.</p>
                <p className="tw-text-white tw-animate-pulse mb-0">
                  You will be redirected to login page on 5 seconds
                </p> */}

                {feedback.ok == true ? (
                  <div className="mt-4 tw-bg-green-500 p-3 tw-rounded-md tw-text-white">
                    {feedback.message}
                  </div>
                ) : (
                  <div className="mt-4 tw-bg-red-500 p-3 tw-rounded-md tw-text-white">
                    {feedback.message}
                  </div>
                )}
              </div>
            )}
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default EndRegisterByInvite;
