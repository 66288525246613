import React, { useState, useEffect, useContext, useTransition } from "react";
import {
  Card,
  Dropdown,
  Table,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { IoIosMore } from "react-icons/io";
import { FaCheck, FaTrash, FaPen } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

import { useGet } from "../../hooks/get";
import { useSort } from "../../hooks/useSort";
import useSearch from "../../hooks/useSearch";
import { convertToLocalTime } from "../../hooks/convertDate";
import { DepartmentsContext } from "../../Context/DepartmentsContext";
import { useTranslation } from "react-i18next";
import { HiTrash } from "react-icons/hi";

const DepartamentsTable = ({ refreshState }) => {
  const { t } = useTranslation();
  const [departaments, setDepartaments] = useState([]);
  const [editing, setEditing] = useState(null);
  const [editValue, setEditValue] = useState(null);
  const { selectedDep, setSelectedDep } = useContext(DepartmentsContext);

  const { data } = useGet("/api/v1/department/view-departments", refreshState);

  useEffect(() => {
    if (data && data.length > 0) {
      const departaments = data.map((dep) => ({
        departmentId: dep.departmentId,
        departmentName: dep.departmentName,
        devicesInDepartment: dep.devicesInDepartment,
        createdBy: dep.createdBy,
        creationDate: dep.creationDate
          ? convertToLocalTime(dep.creationDate)
          : "",
      }));
      setDepartaments(departaments);
      if (!selectedDep) {
        setSelectedDep(departaments[0]);
      }
    }
  }, [data, selectedDep, setSelectedDep]);

  const handleDelete = async (departmentId, departmentName) => {
    const response = await fetch(
      `/api/v1/department/delete-department/${departmentId}?departmentName=${departmentName}`,
      {
        method: "DELETE",
      }
    );

    if (response.ok) {
      setDepartaments((prev) =>
        prev.filter((dep) => dep.departmentId !== departmentId)
      );
    }
  };

  const { sortedData, requestSort } = useSort(departaments);
  const { searchTerm, setSearchTerm, filteredData } = useSearch(sortedData, [
    "departmentName",
    "createdBy",
    "creationDate",
  ]);

  const submit = async (departmentId, departmentName) => {
    if (!editValue) return;

    const response = await fetch(
      `/api/v1/department/change-department-name/${departmentId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          newDepartmentName: editValue,
          oldDepartmentName: departmentName,
        }),
      }
    );

    if (response.ok) {
      setEditing(null);
      setEditValue(null);
      setDepartaments((prev) =>
        prev.map((dep) =>
          dep.departmentId === departmentId
            ? { ...dep, departmentName: editValue }
            : dep
        )
      );
    }
  };

  let style = {
    minWidth: "150px",
  };

  return (
    <Card
      style={{
        minHeight: "400px",
        maxHeight: "500px",
      }}
      className="custom-card-1"
    >
      <Card.Header>
        <h1></h1>
        <div className="d-flex tw-justify-end">
          <input
            type="text"
            className="form-control w-50 me-1"
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <Table className="table-users" hover>
          <thead>
            <tr>
              <th className="center-text">#</th>
              <th onClick={() => requestSort("departmentName")}>
                {t("Department Name")}
              </th>
              <th
                className="center-text"
                onClick={() => requestSort("devicesInDepartment")}
              >
                {t("Devices in Department")}
              </th>
              <th onClick={() => requestSort("createdBy")}>{t("Creted By")}</th>
              <th onClick={() => requestSort("creationDate")}>
                {t("Creation Date")}
              </th>
              <th className="center-text-th">{t("Action")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((dep, index) => (
              <tr
                className={
                  selectedDep && selectedDep.departmentId === dep.departmentId
                    ? "table-secondary cursor-pointer"
                    : "cursor-pointer"
                }
                key={dep.departmentId}
              >
                <td className="center-text-td index-td">
                  <small className="text-secondary">
                    <small>{index + 1}</small>
                  </small>
                </td>
                <td
                  onClick={() => setSelectedDep(dep)}
                  style={{ minWidth: "150px" }}
                >
                  {editing === dep.departmentId ? (
                    <div className="d-flex justify-content-between">
                      <button
                        onClick={() => {
                          setEditing(null);
                          setEditValue(null);
                        }}
                        className="btn btn-danger"
                      >
                        <MdCancel />
                      </button>
                      <input
                        type="text"
                        className="form-control w-70"
                        value={editValue}
                        onChange={(e) => setEditValue(e.target.value)}
                      />
                      <button
                        onClick={() =>
                          submit(dep.departmentId, dep.departmentName)
                        }
                        className="btn btn-success"
                      >
                        <FaCheck />
                      </button>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between">
                      <span>{dep.departmentName}</span>
                      <span
                        onClick={() => {
                          setEditing(dep.departmentId);
                          setEditValue(dep.departmentName);
                        }}
                        className="me-2 text-gray-300 cursor-pointer"
                      >
                        <FaPen />
                      </span>
                    </div>
                  )}
                </td>
                <td
                  className="center-text-td"
                  onClick={() => setSelectedDep(dep)}
                  style={{ minWidth: "200px" }}
                >
                  {dep.devicesInDepartment}
                </td>
                <td
                  onClick={() => setSelectedDep(dep)}
                  style={{ minWidth: "150px" }}
                >
                  {dep.createdBy}
                </td>
                <td
                  onClick={() => setSelectedDep(dep)}
                  style={{ minWidth: "150px" }}
                >
                  {dep.creationDate}
                </td>
                <td className="center-text-td" style={{ minWidth: "25px" }}>
                  {dep.devicesInDepartment > 0 ? (
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id={`tooltip-left`}>
                          {t(
                            "To delete a department, you must delete all devices from it first"
                          )}
                        </Tooltip>
                      }
                    >
                      <div>
                        <button
                          disabled
                          onClick={() =>
                            handleDelete(dep.departmentId, dep.departmentName)
                          }
                          className="btn btn-danger"
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </OverlayTrigger>
                  ) : (
                    <button
                      onClick={() =>
                        handleDelete(dep.departmentId, dep.departmentName)
                      }
                      className="btn btn-danger"
                    >
                      <FaTrash />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default DepartamentsTable;
