import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Nav, Card } from "react-bootstrap";
import { useGet } from "../hooks/get";

import About from "../components/Settings/About";
import Logs from "../components/Settings/Logs";
import ChangeLogs from "../components/Settings/ChangeLog";
import IntLogs from "../components/Settings/IntLogs.js";
import { IoMdSettings } from "react-icons/io";
import ModalSupport from "../components/Settings/ModalSupport.js";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const [activeNav, setActiveNav] = useState(0);
  const [showModal, setShowModal] = useState(false);
  let preferedScreen = localStorage.getItem("prefSetSc"); // prefered settings scrreen
  let googleEvent = localStorage.getItem("goEvLi"); // google event listener

  const { t } = useTranslation();

  document.title = `${t("DataDike MDM | Settings")}`;

  useEffect(() => {
    setTimeout(() => {
      if (googleEvent) {
        localStorage.setItem("googleEvent", null);
      }
    }, 4000);
  }, []);

  return (
    <div>
      {showModal && (
        <ModalSupport show={showModal} setShowModal={setShowModal} />
      )}

      <Row className="px-3">
        <Col className="p-0 mb-3 pb-1" md={3} xl={2}>
          <Card
            className="custom-card-1"
            style={{
              height: "740px",
            }}
          >
            <Card.Header className="">
              <h6 className="mb-0 font-size-17px">
                <span className="tw-text-datadike-blue">
                  <IoMdSettings
                    style={{ position: "relative", top: "-1px" }}
                    className="my-animate-spin  me-1 font-size-17px"
                  />
                </span>

                {t("General Settings")}
              </h6>
            </Card.Header>
            <Card.Body>
              <div className="mt-3">
                {[
                  {
                    id: 0,
                    label: t("Enterprise Info"),
                  },
                  {
                    id: 1,
                    label: t("Device Logs"),
                  },
                  {
                    id: 3,
                    label: t("Interaction Logs"),
                  },
                  {
                    id: 2,
                    label: t("Change Logs"),
                  },
                  {
                    id: 4,
                    label: t("Support"),
                  },
                ].map((nav) => (
                  <div
                    onClick={() => {
                      if (nav.id !== 4) {
                        setActiveNav(nav.id);
                      } else {
                        setShowModal(true);
                      }
                    }}
                    key={nav.id}
                    className={`mb-2 cursor-pointer d-flex justify-content-between p-2 tw-rounded-md ${
                      activeNav === nav.id
                        ? "tw-bg-datadike-blue text-white"
                        : "hover:tw-bg-gray-300 "
                    }`}
                  >
                    {nav.label}
                  </div>
                ))}
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col className="mt-xl-0 p-0 ps-xl-2" md={9} xl={10}>
          {activeNav === 0 && <About />}
          {activeNav === 1 && <Logs />}
          {activeNav === 2 && <ChangeLogs />}

          {activeNav === 3 && <IntLogs />}
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
