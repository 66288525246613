import React, { useEffect, useState, useContext } from "react";
import { ButtonGroup, Card, Button } from "react-bootstrap";
import { GoDotFill } from "react-icons/go";
import useSearch from "../../hooks/useSearch";
import { t } from "i18next";
import { LuSmartphone } from "react-icons/lu";
import { IoLocationOutline } from "react-icons/io5";
import { GeofenceContext } from "../../Context/GeofenceContext";

const RightCard = ({ handleGeofenceClick, handleDeviceClick }) => {
  const [sortedDevices, setSortedDevices] = useState([]);

  const {
    rightCardMode,
    setRightCardMode,
    selectedGeofence,
    selectedDevice,
    availableGeofences: geofences,
    devices,
  } = useContext(GeofenceContext);

  const data = rightCardMode === "devices" ? devices : geofences;

  const { filteredData, searchTerm, setSearchTerm } = useSearch(data, [
    "name",
    "geofenceName",
  ]);

  console.log("rightCard");

  return (
    <Card className="custom-card-1" style={{ height: "740px" }}>
      <Card.Header className="d-block">
        <div>
          <input
            className="form-control w-100"
            placeholder={`${t("Search...")}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="mt-3 jc ac">
          <ButtonGroup>
            <Button
              variant={
                rightCardMode && rightCardMode === "devices"
                  ? "primary"
                  : "outline-primary"
              }
              onClick={() => {
                setRightCardMode("devices");
                localStorage.setItem("prcm", "devices");
              }}
            >
              {t("Devices")}
            </Button>
            <Button
              variant={
                rightCardMode && rightCardMode == "geofence"
                  ? "primary"
                  : "outline-primary"
              }
              onClick={() => {
                localStorage.setItem("prcm", "geofence");
                setRightCardMode("geofence");
              }}
            >
              {t("Geofences")}
            </Button>
          </ButtonGroup>
        </div>
      </Card.Header>
      <Card.Body className="mh-100 overflow-auto">
        {rightCardMode && rightCardMode === "devices" ? (
          <div>
            {filteredData.map((device) => (
              <div
                key={device.deviceId}
                className={`mb-2 cursor-pointer jsb p-2 ac tw-rounded-md ${
                  selectedDevice?.deviceId === device.deviceId
                    ? "tw-bg-datadike-blue tw-text-white"
                    : "hover:tw-bg-gray-300"
                }`}
                onClick={() => handleDeviceClick(device)}
              >
                <h6 className="font-semibold mb-0 flex ac">
                  <span className="me-1 font-size-16px icone-torto">
                    <LuSmartphone className="icone-torto" />
                  </span>
                  <span>
                    <GoDotFill
                      className={`icone-torto ${
                        device.status ? "text-success" : "text-danger"
                      }`}
                    />
                  </span>
                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "95%",
                    }}
                  >
                    {device.name}
                  </span>
                </h6>
              </div>
            ))}
          </div>
        ) : (
          <div>
            {filteredData.map((g) => (
              <div
                key={g._id}
                className={`mb-2 cursor-pointer jsb p-2 ac tw-rounded-md ${
                  selectedGeofence?._id === g._id
                    ? "tw-bg-datadike-blue tw-text-white"
                    : "hover:tw-bg-gray-300"
                }`}
                onClick={() => handleGeofenceClick(g)}
              >
                <h6 className="font-semibold mb-0 flex ac">
                  <span className="me-1 font-size-16px icone-torto">
                    <IoLocationOutline className="icone-torto" />
                  </span>

                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "95%",
                    }}
                  >
                    {g.geofenceName}
                  </span>
                </h6>
              </div>
            ))}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default RightCard;
