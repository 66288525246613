import React, { useContext, useEffect, useState } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { SysadminContext } from "../../Context/SysadminContext";
import { useGet } from "../../hooks/get";
import { FaCheck } from "react-icons/fa6";
import { convertToLocalTime } from "../../hooks/convertDate";
import { FaUser } from "react-icons/fa";

import { FaCalendar } from "react-icons/fa";
import useSearch from "../../hooks/useSearch";
import { useTranslation } from "react-i18next";

const Apps = () => {
  const { selectedDevice } = useContext(SysadminContext);
  const { t } = useTranslation();
  const [title, setTitle] = useState(null);
  const [body, setBody] = useState(null);
  const [feedback, setFeedBack] = useState(null);
  const [refreshState, setRefreshState] = useState(false);
  const [sent, setSent] = useState(null);

  const submit = async () => {
    if (!body || !title) {
      setFeedBack({
        ok: false,
        message: t("The fields title and body can't be empty"),
      });
      return;
    }
    try {
      const response = await fetch("/api/v1/communication/send-notification", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: title,
          body: body,
          deviceId: selectedDevice?.deviceId || "",
        }),
      });

      if (response.ok) {
        setFeedBack({
          ok: true,
          message: t("Message sent successfully"),
        });
        setTitle("");
        setBody("");
        setTimeout(() => {
          setRefreshState(!refreshState);
        }, 500);
      }

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      console.log("Message sent successfully:", data);
    } catch (error) {
      console.error("Error sending message:", error);

      setFeedBack({
        ok: false,
        message: `${t("Error sending message")}: ${error}`,
      });
    }
  };

  const {
    data: sentMessages,
    loading: loadingSent,
    error,
  } = useGet(
    `api/v1/sysadmin/device-notifications/${selectedDevice?.deviceId}`,
    refreshState,
    selectedDevice
  );

  useEffect(() => {
    console.log("Sent:", sentMessages);
    if (sentMessages && sentMessages.length > 0) {
      const formattedData = sentMessages.map((item) => ({
        body: item.body,
        title: item.title,
        whoSent: item.whoSent,
        timestamp: convertToLocalTime(item.timestamp),
      }));
      setSent(formattedData);
    } else {
      setSent(null);
    }
  }, [sentMessages]);

  const { filteredData, searchTerm, setSearchTerm } = useSearch(sent, [
    "whoSent",
    "title",
    "body",
    "timestamp",
  ]);

  useEffect(() => {
    if (feedback && feedback.ok) {
      setTimeout(() => {
        setFeedBack(null);
      }, 10000);
    } else {
      setTimeout(() => {
        setFeedBack(null);
      }, 10000);
    }
  }, [feedback, selectedDevice]);

  return (
    <Card style={{ height: "740px" }} className="">
      <Card.Header>
        <h4 className="mb-0 w-40">{t("Notifications")}</h4>
        <div className="d-flex w-60 justify-content-end"></div>
      </Card.Header>

      <Card.Body>
        <Row>
          <Col xl={6}>
            <Card
              style={{
                height: "620px",
              }}
              className="sysadmin-cards"
            >
              <Card.Body>
                <input
                  className="form-control mb-3"
                  placeholder={t("Title")}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <textarea
                  className="form-control mb-3"
                  placeholder={t("Body")}
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                />

                {feedback && (
                  <div>
                    {feedback.ok ? (
                      <div className="w-100 tw-animate-pulse tw-rounded-md p-3 tw-text-white tw-bg-green-500">
                        {feedback.message}
                      </div>
                    ) : (
                      <div className="w-100  tw-rounded-md p-3 tw-text-white tw-bg-red-500">
                        {feedback.message}
                      </div>
                    )}
                  </div>
                )}
              </Card.Body>
              <Card.Footer>
                <Button onClick={submit}>{t("Send Message")}</Button>
              </Card.Footer>
            </Card>
          </Col>

          <Col xl={6}>
            <Card
              style={{
                height: "620px",
              }}
              className="sysadmin-cards"
            >
              <Card.Header className="bg-0 jsb">
                <h6>{t("Last Notifications")}</h6>
                <input
                  className="form-control w-30"
                  placeholder={t("Search...")}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </Card.Header>
              <Card.Body>
                {filteredData && filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <div
                      className="jsb ac border-bottom tw-border-gray-300 p-2"
                      key={index}
                    >
                      <div className="d-block w-60 pe-2">
                        <h5 className="mb-3 text-dark">{item.title}</h5>

                        <h6 className="">{item.body}</h6>
                      </div>

                      <div
                        style={{
                          flexDirection: "column",
                        }}
                        className=" w-40 je"
                      >
                        <h6 className="mb-3 text-primary ac w-100">
                          <FaUser />
                          <span className="ms-1 text-dark">{item.whoSent}</span>
                        </h6>

                        <h6 className="mb-0 font-semibold w-100">
                          <span className="tw-text-red-500 icone-torto me-1">
                            <FaCalendar />
                          </span>
                          {item.timestamp}
                        </h6>
                      </div>
                    </div>
                  ))
                ) : loadingSent ? (
                  <div></div>
                ) : (
                  <div className="text-center">
                    {t("No notifications sent to")}
                    <span className="mx-1 text-primary">
                      {selectedDevice?.deviceName}
                    </span>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Apps;
