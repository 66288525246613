import React, { useEffect, useState, useContext } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";
import { useGet } from "../../hooks/get";
import { SysadminContext } from "../../Context/SysadminContext";
import { FaPen } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import { FaCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  const { selectedDevice } = useContext(SysadminContext);
  const [refreshState, setRefreshState] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const {
    data: aboutData,
    loading,
    error,
  } = useGet(
    `/api/v1/sysadmin/device-general-info/${selectedDevice?.deviceId}`,
    refreshState
  );
  const [editMode, setEditMode] = useState(null);
  const [editedValues, setEditedValues] = useState({});

  const handleFieldClick = (field) => {
    setEditMode(field);
    setEditedValues((prevValues) => ({
      ...prevValues,
      [field]: aboutData[field] || "",
    }));
  };

  const handleExitEdit = (field) => {
    setEditMode(null);
    setEditedValues(null);
  };

  const handleInputChange = (e, field) => {
    setEditedValues({
      ...editedValues,
      [field]: e.target.value || "",
    });
  };

  const handleBlur = async (field) => {
    setEditMode(null);
    if (editedValues[field] !== undefined) {
      const updateData = {
        deviceId: selectedDevice.deviceId,
        [field]: editedValues[field] || "", // Se o valor for vazio, envia uma string vazia
      };
      try {
        const response = await fetch(
          "/api/v1/manage-device/device-general-info",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updateData),
          }
        );

        if (response.ok) {
          setTimeout(() => {
            setRefreshState(!refreshState);
          }, 600);
        } else {
          return;
        }
        setSubmitLoading(false);
      } catch (error) {
        console.error("Erro ao atualizar o campo:", error);
      }
    }
  };

  const handleKeyPress = (e, field) => {
    if (e.key === "Enter") {
      handleBlur(field);
    }
  };

  const handleConfirmClick = (field) => {
    setSubmitLoading(true);

    setTimeout(() => {
      handleBlur(field);
    }, 300);
  };

  if (loading) {
    return (
      <Card style={{ height: "740px" }}>
        <Card.Header>
          <h5 className="mb-0 text-dark">{t("About")}</h5>
        </Card.Header>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <h4 className="loading-icon">
            <VscLoading />
          </h4>
        </Card.Body>
      </Card>
    );
  }
  if (submitLoading) {
    return (
      <Card style={{ height: "740px" }}>
        <Card.Header>
          <h5 className="mb-0 text-dark"> {t("About")}</h5>
        </Card.Header>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <h4 className="loading-icon">
            <VscLoading />
          </h4>
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card style={{ height: "740px" }}>
        <Card.Header>
          <h5 className="mb-0 text-dark"> {t("About")}</h5>
        </Card.Header>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <h6 className="text-danger">{error}</h6>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card style={{ height: "740px" }}>
      <Card.Header>
        <h4 className="mb-0"> {t("About")}</h4>
      </Card.Header>
      <Card.Body>
        {aboutData && (
          <div className="p-2 border-0 d-flex tw-shadow-sm flex-column tw-rounded-md">
            {[
              { label: "Device Name", field: "deviceName" },
              { label: "IMEI1", field: "IMEI1" },
              { label: "IMEI2", field: "IMEI2" },
              { label: "Serial Number", field: "serialNumber" },
            ].map(({ label, field }) => (
              <div
                className=" ac js my-2 p-2 border-bottom tw-border-gray-300"
                key={field}
              >
                <label className="me-1">{t(label)}:</label>
                {editMode === field ? (
                  <div className="ac ms-2">
                    <Button
                      className="me-1"
                      variant="danger"
                      onClick={() => handleExitEdit(field)}
                    >
                      <TiDelete />
                    </Button>
                    <input
                      type="text"
                      className="form-control w-70 me-1"
                      value={editedValues[field]}
                      onChange={(e) => handleInputChange(e, field)}
                      onBlur={() => handleBlur(field)}
                      onKeyPress={(e) => handleKeyPress(e, field)}
                    />
                    <Button
                      variant="success"
                      onClick={() => handleConfirmClick(field)}
                    >
                      <FaCheckCircle />
                    </Button>
                  </div>
                ) : (
                  <div className="ms-1 ac">
                    <span className="me-2 text-dark">{aboutData[field]}</span>
                    <span
                      onClick={() => handleFieldClick(field)}
                      className="tw-text-gray-400 font-size-16px cursor-pointer"
                    >
                      <FaPen />
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default About;
