import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import Select from "react-select";
import { SysadminContext } from "../Context/SysadminContext";
import { useGet } from "../hooks/get";
import Status from "../components/Sysadmin/Status";
import Apps from "../components/Sysadmin/Apps";
import Location from "../components/Sysadmin/Location";
import Contacts from "../components/Sysadmin/Contacts";
import DeletModal from "../components/Sysadmin/DeleteModal";
import Departments from "../components/Sysadmin/Departments";
import Invoices from "../components/Sysadmin/Invoices";
import Employees from "../components/Sysadmin/Employess";
import { useSearchParams } from "react-router-dom";
import Terms from "../components/Sysadmin/Terms";
import Notifications from "../components/Sysadmin/Notification";
import Calls from "../components/Sysadmin/Calls";
import { GoDotFill } from "react-icons/go";
import About from "../components/Sysadmin/About";
import Notification from "../components/Utils/Notification";
import Tags from "../components/Sysadmin/Tags.js";
import { useTranslation } from "react-i18next";
import { badgesDefault } from "../extraFunctions/badgesTw.js";
import DataUsage from "../components/Sysadmin/DataUsage.js";
const Sysadmin = () => {
  const { t } = useTranslation();

  document.title = `${t("DataDike MDM | Sysadmin")}`;
  const {
    availableDevices,
    navs,
    selectedDevice,
    setSelectedDevice,
    setAvailableDevices,
    setActiveNav,
    activeNav,
  } = useContext(SysadminContext);

  const { data: allDevicesData, loading } = useGet("/api/v1/sysadmin/devices");

  const [searchParams] = useSearchParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const deviceId = searchParams.get("deviceId");

    if (deviceId && allDevicesData) {
      const normalizedDeviceId = deviceId.trim().toLowerCase();
      const device = allDevicesData.find(
        (device) => device.deviceId.toLowerCase() === normalizedDeviceId
      );
      setSelectedDevice(device);
    }
  }, [searchParams, allDevicesData, setSelectedDevice]);

  useEffect(() => {
    if (allDevicesData && allDevicesData.length > 0) {
      const sortedByStatus = allDevicesData.sort((a, b) => {
        return a.status === b.status ? 0 : a.status ? -1 : 1;
      });

      setAvailableDevices(sortedByStatus);
      if (!selectedDevice) {
        setSelectedDevice(sortedByStatus[0]);
      }
    }
  }, [allDevicesData, setAvailableDevices, setSelectedDevice, selectedDevice]);

  const handleDelete = async () => {
    const response = await fetch(
      `/api/v1/sysadmin/remove-device/${selectedDevice.deviceId}`,
      {
        method: "DELETE",
      }
    );

    if (response.ok) {
      const updatedDevices = availableDevices.filter(
        (device) => device.deviceId !== selectedDevice.deviceId
      );

      setFeedback({
        ok: true,
        message: `Device ${selectedDevice?.deviceName} removed successfully`,
      });
      setShowNotification(true);
      setAvailableDevices(updatedDevices);
      setSelectedDevice(updatedDevices[0]);
      setShowDeleteModal(false);
      setActiveNav(0);
    } else {
      setFeedback({
        ok: false,
        message: `Error to remove device ${selectedDevice?.deviceName}`,
      });
    }
  };

  if (loading || !allDevicesData || allDevicesData.length === 0) {
    return null;
  }

  return (
    <div>
      {showDeleteModal && (
        <DeletModal
          show={showDeleteModal}
          close={() => setShowDeleteModal(false)}
          confirm={(id) => handleDelete(id)}
          selectedDevice={selectedDevice || ""}
        />
      )}

      {showNotification && (
        <Notification
          feedback={feedback?.message}
          show={showNotification}
          setShow={setShowNotification}
          type={feedback?.ok ? "success" : "error"}
          // selectedDevice={selectedDevice ? selectedDevice?.deviceName : ""}
        />
      )}

      <div className="flex align-items-center justify-content-between mb-2 ms-1">
        <div>
          <ol className="breadcrumb fs-sm mb-0">
            <li className="breadcrumb-item">
              <span className="text-primary cursor-pointer">Cockpit</span>
            </li>
            <li className="d-none breadcrumb-item active" aria-current="page">
              Sysadmin
            </li>
          </ol>
          <h4 className="main-title mb-0">Sysadmin</h4>
        </div>
        <div className="flex ac">
          <h5
            style={{
              fontSize: "15px",
            }}
            className="font-semibold mb-0 me-1"
          >
            {selectedDevice?.deviceName}
          </h5>
          <span
            className={`${
              selectedDevice?.status == true
                ? badgesDefault.green
                : badgesDefault.red
            }`}
          >
            {selectedDevice?.status == true ? "Online" : "Offline"}
          </span>
        </div>
      </div>

      <Row className="px-2">
        <Col className="p-1 mb-2" md={12} xl={2}>
          <Card
            className=""
            style={{
              height: "740px",
            }}
          >
            <Card.Body>
              {availableDevices && (
                <Select
                  value={{
                    value: selectedDevice ? selectedDevice.deviceId : "",
                    label: selectedDevice ? selectedDevice.deviceName : "",
                    status: selectedDevice ? selectedDevice.status : null,
                  }}
                  onChange={(selectedOption) =>
                    setSelectedDevice(
                      availableDevices.find(
                        (device) => device.deviceId === selectedOption.value
                      )
                    )
                  }
                  className="w-100"
                  options={availableDevices.map((device) => ({
                    value: device.deviceId,
                    label: device.deviceName,
                    status: device.status, // Passa o status para usar no formatOptionLabel
                  }))}
                  formatOptionLabel={({ label, status }) => (
                    <div className="d-flex align-items-center">
                      <GoDotFill
                        className={`me-1 ${
                          status == true
                            ? "tw-text-green-500"
                            : status == false
                            ? "tw-text-red-500"
                            : "tw-text-gray-200"
                        }`}
                      />
                      <span
                        style={{
                          maxWidth: "80%",
                          overflow: "hidden",
                        }}
                      >
                        {label}
                      </span>
                    </div>
                  )}
                  isSearchable={true}
                />
              )}
              <div className="mt-3">
                {navs &&
                  navs.map((nav) => (
                    <div
                      onClick={() => {
                        if (nav.id !== 5) {
                          setActiveNav(nav.id);
                        } else {
                          setShowDeleteModal(true);
                        }
                      }}
                      key={nav.id}
                      className={`mb-2 cursor-pointer ac js p-2 tw-rounded-md
                        ${
                          nav.id === 5
                            ? "tw-bg-red-500 text-white hover:tw-bg-red-600 "
                            : "hover:tw-bg-gray-300 "
                        }
                        ${
                          activeNav === nav.id && nav.id !== 5
                            ? "tw-bg-datadike-blue text-white sysadmin-sidebar-active"
                            : "hover:tw-bg-gray-300 "
                        }`}
                    >
                      <span
                        className={`${
                          nav.id === 5 ? "sysadmin-sidebar-icon-remove" : ""
                        }
                          ${nav.torto ? "icon-torto" : ""} ${
                          activeNav === nav.id && nav.id !== 5
                            ? "sysadmin-sidebar-icon-active"
                            : "sysadmin-sidebar-icon"
                        }`}
                      >
                        {nav.icon}
                      </span>
                      {nav.label}
                    </div>
                  ))}
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col className="p-1" md={12} xl={10}>
          {selectedDevice ? (
            <>
              {activeNav === 0 && <Status />}
              {activeNav === 1 && <Apps />}
              {activeNav === 3 && <Location />}
              {activeNav === 4 && <Contacts />}
              {activeNav === 6 && <Departments />}
              {activeNav === 7 && <Invoices />}
              {activeNav === 10 && <Employees />}
              {activeNav === 8 && <Terms />}
              {activeNav === 11 && <Notifications />}
              {activeNav === 12 && <Calls />}
              {activeNav === 13 && <About />}
              {activeNav == 14 && <Tags />}
              {activeNav === 15 && <DataUsage />}
            </>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default Sysadmin;
