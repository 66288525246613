import { useState, useMemo } from "react";
// useSort hook definition
// useSort hook definition
export const useSort = (data) => {
  const [sortConfig, setSortConfig] = useState({
    key: "calls", // Ajuste para a chave correta caso seja necessário
    direction: "descending",
  });

  const sortedData = useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }
    let sortableItems = [...data];
    if (sortConfig?.key) {
      sortableItems.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Verifica se os valores são nulos ou indefinidos e fornece um valor padrão
        aValue = (aValue == null ? "" : aValue).toString();
        bValue = (bValue == null ? "" : bValue).toString();

        // Check if sorting by diskInfo or networkInfo fields
        if (sortConfig.key.includes("diskInfo")) {
          const key = sortConfig.key.split(".")[1];
          aValue =
            a.diskInfo && a.diskInfo.length > 0 ? a.diskInfo[0][key] : "";
          bValue =
            b.diskInfo && b.diskInfo.length > 0 ? b.diskInfo[0][key] : "";
        } else if (sortConfig.key.includes("networkInfo")) {
          const key = sortConfig.key.split(".")[1];
          aValue =
            a.networkInfo && a.networkInfo.length > 0
              ? a.networkInfo[0][key]
              : "";
          bValue =
            b.networkInfo && b.networkInfo.length > 0
              ? b.networkInfo[0][key]
              : "";
        } else {
          // For top-level fields
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }

        if (sortConfig.key.includes("date")) {
          // Parse date strings into Date objects
          aValue = new Date(
            aValue.split(", ")[0].split("/").reverse().join("-") +
              " " +
              aValue.split(", ")[1]
          ).getTime();
          bValue = new Date(
            bValue.split(", ")[0].split("/").reverse().join("-") +
              " " +
              bValue.split(", ")[1]
          ).getTime();
        }
        // Handle numeric values and convert them to float
        if (["usedGb", "freeGb"].includes(sortConfig.key.split(".")[1])) {
          aValue = parseFloat(aValue) || 0;
          bValue = parseFloat(bValue) || 0;
        }

        // Handle free percent, remove the '%' sign and parse to float
        if (sortConfig.key.split(".")[1] === "freePercent") {
          aValue = parseFloat(aValue.replace("%", "")) || 0;
          bValue = parseFloat(bValue.replace("%", "")) || 0;
        }

        if (sortConfig.key === "uptime") {
          // Converte uptime de "DD:HH:MM:SS" para segundos totais
          const timeToSeconds = (time) => {
            const [days, hours, minutes, seconds] = time.split(":").map(Number);
            return days * 86400 + hours * 3600 + minutes * 60 + seconds;
          };
          aValue = timeToSeconds(aValue);
          bValue = timeToSeconds(bValue);
        }

        // Handle IP address sorting
        if (sortConfig.key.includes("ipAddress")) {
          aValue = aValue
            .split(".")
            .map((num) => `000${num}`.slice(-3))
            .join("");
          bValue = bValue
            .split(".")
            .map((num) => `000${num}`.slice(-3))
            .join("");
        }

        // Handle date sorting
        if (sortConfig.key.includes("date")) {
          aValue = new Date(aValue).getTime();
          bValue = new Date(bValue).getTime();
        }

        // Perform the string comparison
        if (typeof aValue === "string" && typeof bValue === "string") {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }

        // Perform the comparison
        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    return sortableItems;
  }, [data, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    } else if (sortConfig.key !== key) {
      // Se for uma nova chave que não seja 'calls', inicia com 'ascending'
      direction = "ascending";
    }
    setSortConfig({ key, direction });
  };

  return { sortedData, requestSort, sortConfig };
};
