import React, { useEffect, useState } from "react";
import { Table, Card, Dropdown } from "react-bootstrap";
import { useGet } from "../../hooks/get.js";
import useSearch from "../../hooks/useSearch.js";
import { useSort } from "../../hooks/useSort.js";
import { convertToLocalTime } from "../../hooks/convertDate.js";
import { VscLoading } from "react-icons/vsc";
import { badgesDefault } from "../../extraFunctions/badgesTw.js";
import EditModal from "./EditModal.js";
import { IoIosMore } from "react-icons/io";
import { FaPen, FaTrash } from "react-icons/fa";
import axios from "axios";
import { useTranslation } from "react-i18next";

const TableLine = ({ refreshState, setRefreshState }) => {
  const { t } = useTranslation();
  const [formattedData, setFormattedData] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState(null);
  const [selectedLine, setSelectedLine] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const {
    data: sampleData,
    loading,
    error,
  } = useGet(`/api/v1/lines/view-lines`, refreshState);

  const handleDelete = async (line) => {
    try {
      await axios.delete(`/api/v1/lines/delete-line/${line._id}`);

      setTimeout(() => {
        setRefreshState(!refreshState);
      }, 400);
    } catch (error) {
      console.error(t("Failed to update line"), error);
    }
  };

  useEffect(() => {
    if (sampleData) {
      const newData = sampleData.map((item, index) => ({
        _id: item._id,
        lineName: item.lineName,
        lineDDD: item.lineDDD,
        lineDDI: item.lineDDI,
        lineNumber: item.lineNumber,
        lineOperator: item.lineOperator,
        linePlan: item.linePlan,
        status: item.status ? t("Active") : t("Inactive"),
        dataCap: item.dataCap ? parseInt(item.dataCap) : 0,
        dataCapUnit: item.dataCapUnit,
        cadBy: item.cadBy,
        cadDate: item.cadDate ? convertToLocalTime(item.cadDate) : "-",
        deviceName: item.deviceName,
        departmentName: item.departmentName,
        deviceId: item.deviceId,
        departmentId: item.departmentId,
      }));
      setFormattedData(newData);
    }
  }, [sampleData, refreshState, t]);

  const fields = [
    "lineName",
    "lineDDD",
    "lineDDI",
    "lineNumber",
    "lineOperator",
    "linePlan",
    "status",
    "dataCap",
    "dataCapUnit",
    "cadBy",
    "cadDate",
    "deviceName",
    "departmentName",
  ];

  const { sortedData, requestSort } = useSort(formattedData);
  const { filteredData, searchTerm, setSearchTerm } = useSearch(
    sortedData,
    fields
  );

  const handleSelectAll = () => {
    // Implement your logic for selecting all rows if needed
  };

  useEffect(() => {
    console.log("showModal", showModal);
    console.log("deviceIdOnLine:", selectedLine);
    console.log("selectedLine", selectedLine);
  }, [showModal]);

  if (loading || error) {
    return (
      <Card className="custom-card-1" style={{ height: "400px" }}>
        <Card.Body className="jc ac">
          {loading ? (
            <h5 className="loading-icon">
              <VscLoading />
            </h5>
          ) : (
            <h6 className="mb-0">{error}</h6>
          )}
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="custom-card-1" style={{ height: "740px" }}>
      {showModal && selectedLine && (
        <EditModal
          selectedLine={selectedLine}
          setRefreshState={setRefreshState}
          onHide={() => setShowModal(false)}
        />
      )}
      <Card.Header>
        <h4>{t("")}</h4>

        <div className="d-flex tw-justify-end">
          <input
            type="text"
            className="form-control w-50 me-1"
            placeholder={`${"Search..."}`}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <Table className="table-users min-150-table" hover>
          <thead>
            <tr>
              <th onClick={handleSelectAll} className="center-text-th">
                #
              </th>
              <th onClick={() => requestSort("lineName")}>{t("Line Name")}</th>
              <th onClick={() => requestSort("lineDDD")}>{t("Line DDD")}</th>
              <th onClick={() => requestSort("lineDDI")}>{t("Line DDI")}</th>
              <th onClick={() => requestSort("lineNumber")}>
                {t("Line Number")}
              </th>
              <th onClick={() => requestSort("lineOperator")}>
                {t("Line Operator")}
              </th>
              <th onClick={() => requestSort("linePlan")}>{t("Line Plan")}</th>
              <th
                className="center-text-th"
                onClick={() => requestSort("status")}
              >
                {t("Status")}
              </th>
              <th onClick={() => requestSort("dataCap")}>{t("Data Cap")}</th>
              <th onClick={() => requestSort("dataCapUnit")}>
                {t("Data Cap Unit")}
              </th>
              <th onClick={() => requestSort("cadBy")}>{t("Cad By")}</th>
              <th onClick={() => requestSort("cadDate")}>{t("Cad Date")}</th>
              <th onClick={() => requestSort("deviceName")}>
                {t("Device Name")}
              </th>
              <th onClick={() => requestSort("departmentName")}>
                {t("Department Name")}
              </th>
              <th className="center-text-th">{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((line, rowIndex) => (
              <tr key={rowIndex}>
                <td className="center-text-td">
                  <small className="text-secondary">{rowIndex + 1}</small>
                </td>
                <td>{line.lineName || "-"}</td>
                <td>{line.lineDDD || "-"}</td>
                <td>{line.lineDDI || "-"}</td>
                <td>{line.lineNumber || "-"}</td>
                <td>{line.lineOperator || "-"}</td>
                <td>{line.linePlan || "-"}</td>
                <td className="center-text-td">
                  {line.status == t("Active") ? (
                    <div className={badgesDefault.green}>{line.status}</div>
                  ) : (
                    <div className={badgesDefault.default}>{line.status}</div>
                  )}
                </td>
                <td>{line.dataCap || "-"}</td>
                <td>{line.dataCapUnit || "-"}</td>
                <td>{line.cadBy || "-"}</td>
                <td>{line.cadDate || "-"}</td>
                <td>{line.deviceName || "-"}</td>
                <td>{line.departmentName || "-"}</td>
                <td
                  className="jc"
                  style={{
                    minWidth: "80px",
                  }}
                >
                  <button
                    onClick={() => {
                      setShowModal(true);
                      setSelectedLine(line);
                    }}
                    className="btn btn-primary"
                  >
                    <FaPen />
                  </button>
                  <button
                    onClick={() => {
                      handleDelete(line);
                    }}
                    className="btn btn-danger ms-1"
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default TableLine;
