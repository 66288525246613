import React, { useState } from "react";
import { Col, Modal } from "react-bootstrap";
import { MdAlternateEmail, MdOutlineLocalPhone } from "react-icons/md";
import { SiJira } from "react-icons/si";
import { FaWhatsapp } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import JiraForm from "./JiraForm";
import { t } from "i18next";

const ModalSupport = ({ setShowModal }) => {
  const [showPhone, setShowPhone] = useState(false);
  const [showJiraForm, setJiraForm] = useState(false);

  const copyToClip = (phone) => {
    setShowPhone(true);
    setTimeout(() => setShowPhone(false), 5000); // Hide message after 2 seconds
  };

  return (
    <Modal
      show={true}
      centered
      //   style={{
      //     height: "230px !important",
      //   }}
      onHide={() => setShowModal(false)}
      className="fixed-modal-3"
    >
      <Modal.Header closeButton>
        <h5 className="mb-0">{t("Support")}</h5>
      </Modal.Header>
      <h5 className="jc mt-3 mb-0">{t("Need help? Contact us")}</h5>

      <Modal.Body className="ac jc pt-1">
        {showJiraForm ? (
          <JiraForm />
        ) : (
          <div style={{ display: "flex" }}>
            <Col className="px-1" xl={4}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-email`}>Email</Tooltip>}
              >
                <div
                  onClick={() => {
                    window.location.href = "mailto:help@datadike.com";
                  }}
                  className="tw-bg-gray-200 hover:tw-bg-gray-300 tw-rounded-md p-3 ac jc cursor-pointer"
                >
                  <span
                    className="tw-text-yellow-500"
                    style={{ fontSize: "30px" }}
                  >
                    <MdAlternateEmail />
                  </span>
                </div>
              </OverlayTrigger>
            </Col>

            {/* <Col className="px-1" xl={4}>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-jira`}>Create a ticket on Jira</Tooltip>
                }
              >
                <div
                  onClick={() => {
                    setJiraForm(true);
                  }}
                  className="tw-bg-gray-200 hover:tw-bg-gray-300 tw-rounded-md p-3 ac jc cursor-pointer"
                >
                  <span style={{ fontSize: "30px", color: "#0052CC" }}>
                    <SiJira />
                  </span>
                </div>
              </OverlayTrigger>
            </Col> */}

            <Col className="px-1" xl={4}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-whatsapp`}>Whatsapp</Tooltip>}
              >
                <a
                  href="https://wa.me/551151965772"
                  target="_blank"
                  className="tw-bg-gray-200 hover:tw-bg-gray-300 tw-rounded-md p-3 ac jc cursor-pointer"
                >
                  <span
                    className="tw-text-green-500"
                    style={{ fontSize: "30px" }}
                  >
                    <FaWhatsapp />
                  </span>
                </a>
              </OverlayTrigger>
            </Col>

            <Col className="px-1" xl={4}>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-phone`}>
                    {t("Copy our phone number to clipboard")}
                  </Tooltip>
                }
              >
                <CopyToClipboard text="+55 11 5196-5772" onCopy={copyToClip}>
                  <div className="tw-bg-gray-200 hover:tw-bg-gray-300 tw-rounded-md p-3 ac jc cursor-pointer">
                    <span
                      className="tw-text-red-500"
                      style={{ fontSize: "30px" }}
                    >
                      <MdOutlineLocalPhone />
                    </span>
                  </div>
                </CopyToClipboard>
              </OverlayTrigger>
            </Col>
          </div>
        )}
      </Modal.Body>
      {showPhone && (
        <div className="mt-2 w-100 jc">
          <h6 className="text-primary">
            <span className="font-semibold me-1">+55 11 5196-5772</span>
            {t("copied to your clipboard")}
          </h6>
        </div>
      )}
    </Modal>
  );
};

export default ModalSupport;
