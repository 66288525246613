// EnterpriseContext.js
import React, { createContext, useState } from "react";

export const EnterpriseContext = createContext();

export const EnterpriseProvider = ({ children }) => {
  const [enterpriseData, setEnterpriseData] = useState({
    enterpriseName: "",
    enterpriseAdmin: "",
    enterpriseAdminEmail: "",
    enterpriseAdminPhone: "",
    enterpriseAdminPassword: "",
    cnpj: "",
    address: "",
  });

  return (
    <EnterpriseContext.Provider value={{ enterpriseData, setEnterpriseData }}>
      {children}
    </EnterpriseContext.Provider>
  );
};
