import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../Context/AuthContext";

const AuthInterceptor = ({ showMfa }) => {
  const { hasMfa, setHasMfa } = useContext(AuthContext);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/api/v1/mfa/mfa-status", {
        method: "GET",
      });

      const data = await response.json();

      if (data.mfaActive) {
        setHasMfa(true);
      } else {
        setHasMfa(false);
      }
    };

    fetchData();
  }, [hasMfa, showMfa]);

  return <div></div>;
};

export default AuthInterceptor;
