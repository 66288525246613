import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { login } from "./routes/Auth";
import { Form, Button, Card, Row, Col, Dropdown } from "react-bootstrap";
import { buttonsTw } from "../extraFunctions/buttonsTw";
import { VscLoading } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import ForgorModal from "./ForgotModal";
import MfaModalVerify from "./MfaModalVerify";
import { useTranslation } from "react-i18next";
import brazilSvg from "./loginflags/brazil.svg";
import usaSvg from "./loginflags/usa.svg";
import { AuthContext } from "../Context/AuthContext";

function SignIn() {
  document.title = "DataDike | Signin";
  const [loginError, setLoginError] = useState("");
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [logando, setLogando] = useState(false);
  const [language, setLanguage] = useState("en");
  const { setUserEmail } = useContext(AuthContext);
  const [mfaModal, setMfaModal] = useState(false);

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setLanguage(lang);
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSuccess = () => {
    setLoading(true);
    navigate("/");

    // setTimeout(() => {
    //   window.location.replace("/dashboard");
    // }, 300);
  };

  const handleMfa = () => {
    setMfaModal(true);
  };

  const onSubmit = async (data) => {
    setLogando(true);
    try {
      const result = await login(data.username, data.password);
      setUserEmail(data.username);

      if (result.success) {
        if (result.data.mfaRequired == true) {
          handleMfa();
        } else if (
          result.data.mfaRequired == false ||
          !result.data.mfaRequired
        ) {
          handleSuccess();
        }
      } else {
        setLoginError(result.data.message || "Login failed. Network Error");
      }
    } catch (error) {
      setLoginError(error.message || "Network error");
    }

    setLogando(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevenir o comportamento padrão do Enter
      handleSubmit((data) => onSubmit(data, false))(); // Adapte conforme necessário para suportar diferentes tipos de login
    }
  };

  const handleLanguageSelect = (lang) => {
    setLanguage(lang);
    localStorage.setItem("lang", lang);
    window.location.reload();
  };

  let emailPlaceholder = t("Enter your email");
  let passwordPlaceholder = t("Enter your password");
  const isInDevelopment = process.env.NODE_ENV === "development";

  return loading ? (
    <div className="page-sign">
      <div className="mw-100 mh-100">
        <h4 className="loading icon">
          <VscLoading />
        </h4>
      </div>
    </div>
  ) : (
    <div className="page-sign">
      {showModal && <ForgorModal onHide={() => setShowModal(false)} />}

      <Card className="card-sign">
        <Card.Header>
          <div className="d-block">
            <Card.Title> {t("Sign In DataDike MDM")} </Card.Title>
            <Card.Text>{t("Welcome! Please sign in to continue.")}</Card.Text>
          </div>
        </Card.Header>
        {mfaModal ? (
          <MfaModalVerify onHide={() => setMfaModal(false)} />
        ) : (
          <Card.Body className="pb-2">
            <Form onKeyDown={handleKeyDown}>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={emailPlaceholder}
                  {...register("username", {
                    required: `${t("Email is required")}`,
                  })}
                />
                {errors.username && (
                  <p className="text-danger">{errors.username.message}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("Password")}</Form.Label>
                <div
                  className="password-input"
                  style={{ position: "relative" }}
                >
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder={passwordPlaceholder}
                    {...register("password", {
                      required: `${t("Password is required")}`,
                    })}
                  />
                  <i
                    className={showPassword ? "ri-eye-off-line" : "ri-eye-line"}
                    onClick={() => setShowPassword(!showPassword)}
                    style={{
                      top: "10px",
                      position: "absolute",
                      right: "10px",
                      cursor: "pointer",
                    }}
                  ></i>
                  {errors.password && (
                    <p className="text-danger">{errors.password.message}</p>
                  )}
                </div>
              </Form.Group>
              {loginError && <p className="text-danger">{loginError}</p>}
              <Row className="mt-4 mb-1 justify-content-center">
                <div className="flex">
                  {logando ? (
                    <button className="btn btn-primary" disabled>
                      {t("LOGGING IN...")}
                    </button>
                  ) : (
                    <>
                      <button
                        className="btn btn-primary"
                        onClick={handleSubmit((data) => onSubmit(data, false))}
                      >
                        {t("LOG IN")}
                      </button>
                      <Dropdown>
                        <Dropdown.Toggle
                          drop={"down"}
                          className="btn btn-outline-primary text-primary  hover:tw-text-white ms-2"
                          id="dropdown-basic"
                          variant="outline-primary"
                        >
                          <div className="flex">
                            <img
                              className="me-2"
                              style={{
                                width: "25px",
                              }}
                              src={`${
                                language == "pt-BR" ? brazilSvg : usaSvg
                              }`}
                            />

                            {language ? language.toUpperCase() : ""}
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => handleLanguageSelect("en")}
                          >
                            <img
                              className="me-2"
                              style={{
                                width: "25px",
                              }}
                              src={usaSvg}
                            />
                            English
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleLanguageSelect("pt-BR")}
                          >
                            <img
                              className="me-2"
                              style={{
                                width: "25px",
                              }}
                              src={brazilSvg}
                            />
                            Português (BR)
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}
                </div>
              </Row>
            </Form>
          </Card.Body>
        )}
        {!mfaModal && (
          <Card.Footer className="text-center tw-border-top tw-border-gray-400">
            <div className="mt-2">
              <span className="text-secondary">
                {t("Forgot your password?")}
                <span
                  className="text-primary cursor-pointer ms-1"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {t("Click Here")}
                </span>
              </span>
            </div>
          </Card.Footer>
        )}
      </Card>
    </div>
  );
}

export default SignIn;
