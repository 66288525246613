import React, { useState } from "react";
import TagsTable from "../components/Tags/TagsTable";
import CreateTag from "../components/Tags/CreateTag.js";
import { Row, Col } from "react-bootstrap";
import DeviceTags from "../components/Tags/DeviceTags.js";
import { useTranslation } from "react-i18next";

const Tags = () => {
  const { t } = useTranslation();

  document.title = `${t("DataDike MDM | Tags")}`;

  const [showModal, setShowModal] = useState(false);
  const [refreshState, setRefreshState] = useState(0);
  const [selectedTag, setSelectedTag] = useState(null);

  return (
    <div>
      {showModal && (
        <CreateTag
          show={showModal}
          onHide={() => setShowModal(false)}
          onAdd={() => setRefreshState(refreshState + 1)}
        />
      )}
      <div className="flex align-items-center justify-content-between mb-3 ms-1">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <span className="text-primary cursor-pointer">
                {t("Functionalities")}
              </span>
            </li>
            <li className="d-none breadcrumb-item active" aria-current="page">
              {t("Tags Management")}
            </li>
          </ol>
          <h4 className="main-title mb-0">{t("Tags Management")}</h4>
        </div>

        <button onClick={() => setShowModal(true)} className="btn btn-primary">
          {t("Create Tag")}
        </button>
      </div>
      <Row className="mb-2">
        <Col className="mb-2" xl={12} md={12}>
          <TagsTable
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
            refreshState={refreshState}
            setRefreshState={setRefreshState}
          />
        </Col>
      </Row>
      {selectedTag && selectedTag.devicesWithTag > 0 && (
        <Row>
          <Col xl={12} md={12}>
            <DeviceTags
              selectedTag={selectedTag}
              setSelectedTag={setSelectedTag}
              refreshState={refreshState}
              setRefreshState={setRefreshState}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Tags;
