import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import SignIn from "./pages/Signin";
import Dashboard from "./dashboard/Dashboard";
import MultiStepForm from "./components/RegisterEnterprise/Form";
import "./scss/style.scss";
import UsersManagement from "./dashboard/UsersManagement";
import InvoicesManagement from "./dashboard/InvoicesManagement";
import Sysadmin from "./dashboard/Sysadmin";
import EndRegisterByInvite from "./components/RegisterEnterprise/EndRegisterByInvite";
import Header from "./layouts/Header";
import Sidebar from "./layouts/Sidebar";
import Departaments from "./dashboard/Departaments";
import Terms from "./dashboard/Terms";
import Settings from "./dashboard/Settings";
import Employess from "./dashboard/Employees";
import Test from "./dashboard/TTest.js";
import Geofence from "./dashboard/Geofence.js";

import Lines from "./dashboard/Lines";
import ResetPass from "./components/ResetPassword/ResetPass.js";
import Tags from "./dashboard/Tags.js";
import EndGoogle from "./dashboard/EndGoogle.js";

// import Update from "./dashboard/Update.js";
import Policies from "./dashboard/Policies";

import { SysadminProvider } from "./Context/SysadminContext";

import { LogOut } from "./extraFunctions/utils";

import { LayoutContext } from "./Context/LayoutContext"; // Certifique-se que o caminho esteja correto

const isAuthenticated = async () => {
  try {
    const response = await fetch("/api/v1/auth-check", {
      method: "GET",
      credentials: "include",
    });
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Failed to check authentication:", error);
    return false;
  }
};

const AuthChecker = ({ children }) => {
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      if (
        location.pathname !== "/signin" &&
        location.pathname !== "/register" &&
        location.pathname !== "/test" &&
        !location.pathname.startsWith("/end-register") &&
        !location.pathname.startsWith("/reset-password")
      ) {
        const auth = await isAuthenticated();
        if (!auth) {
          LogOut();
        } else {
          setIsAuthChecked(true);
        }
      } else {
        setIsAuthChecked(true);
      }
    };

    checkAuth();
  }, [location.path]);

  if (!isAuthChecked) {
    return null;
  }

  return children;
};

const AppLayout = ({ children }) => {
  const location = useLocation();

  const { closeSidebar } = useContext(LayoutContext);

  //puxar pra cima toda vez que mudar rota

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        // Fecha a sidebar toda vez que a rota muda para '/sysadmin' ou '/settings'
        if (
          location.pathname === "/sysadmin" ||
          location.pathname === "/settings" ||
          location.pathname === "/geofence" ||
          location.pathname === "/policies"
        ) {
          closeSidebar();
        }
      }
    };

    // Fecha a sidebar se a tela for menor que 768px
    handleResize();

    // Adiciona um listener para o resize da janela
    window.addEventListener("resize", handleResize);

    return () => {
      // Remove o listener quando o componente desmonta
      window.removeEventListener("resize", handleResize);
    };
  }, [location.pathname, closeSidebar]);

  if (
    location.pathname === "/signin" ||
    location.pathname === "/register" ||
    location.pathname.startsWith("/end-register") ||
    location.pathname.startsWith("/reset-password") ||
    location.pathname.startsWith("/end-google-registration")
  ) {
    return <div>{children}</div>;
  }

  return (
    <div className="app-layout">
      <Sidebar />
      <div className="main-content">
        <Header />
        <div className="main main-app pt-4 pb-4 px-3">{children}</div>
      </div>
    </div>
  );
};

const App = () => {
  const isInDevelopment = process.env.NODE_ENV === "development";

  return (
    <React.Fragment>
      <BrowserRouter>
        <AuthChecker>
          <AppLayout>
            <SysadminProvider>
              <Routes>
                <Route path="/signin" element={<SignIn />} />
                <Route path="/register" element={<MultiStepForm />} />
                <Route path="/end-register" element={<EndRegisterByInvite />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/" element={<Dashboard />} />
                <Route path="/users" element={<UsersManagement />} />
                <Route path="/invoices" element={<InvoicesManagement />} />
                <Route path="/sysadmin" element={<Sysadmin />} />
                <Route path="/departments" element={<Departaments />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/employees" element={<Employess />} />
                <Route path="/test" element={<Test />} />
                <Route path="/geofence" element={<Geofence />} />

                <Route path="/lines" element={<Lines />} />
                <Route path="/tags" element={<Tags />} />
                <Route path="/reset-password" element={<ResetPass />} />
                <Route path="/policies" element={<Policies />} />
                <Route path="end-google-registration" element={<EndGoogle />} />

                {/* {isInDevelopment && (
                  <Route path="/update-mdm" element={<Update />} />
                )} */}
                {/* <Route path="*" exact={true} element={My404Component} /> */}
              </Routes>
            </SysadminProvider>
          </AppLayout>
        </AuthChecker>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
