import React, { useState, useEffect } from "react";
import { FaRegBell } from "react-icons/fa";
import { Dropdown, Button, Col, Row, Badge } from "react-bootstrap";
import { GoDotFill } from "react-icons/go";
import { convertToLocalTime } from "../../hooks/convertDate";
import { t } from "i18next";
import { IoClose } from "react-icons/io5";
import { IoIosRefresh } from "react-icons/io";

const NotificationsDropdown = () => {
  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timesClicked, setTimesClicked] = useState(0);
  const [blockButton, setBlockButton] = useState(
    sessionStorage.getItem("bnb") === "true"
  ); // Read from sessionStorage on initialization

  useEffect(() => {
    if (timesClicked >= 15) {
      sessionStorage.setItem("bnb", true); // Block notifications button
      setBlockButton(true); // Update state to reflect the blocked status
    }
  }, [timesClicked]);

  // Função para buscar as notificações não lidas
  const fetchNotifications = async () => {
    try {
      const response = await fetch("/api/v1/notifications/see", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setNotificationList(data);
    } catch (error) {
      console.error("Erro ao buscar notificações:", error);
    }
  };

  // Função para marcar todas as notificações como lidas
  const markAllAsRead = async () => {
    const ids = notificationList.map((n) => n.id); // Captura todos os IDs

    try {
      const response = await fetch("/api/v1/notifications/mark-as-viewed", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          notificationIds: ids,
        }),
      });

      if (response.ok) {
        fetchNotifications(); // Refaz o GET após marcar como lidas
      }
    } catch (error) {
      console.error("Erro ao fazer a requisição:", error);
    }
  };

  const markAsRead = async (id) => {
    try {
      const response = await fetch("/api/v1/notifications/mark-as-viewed", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          notificationIds: [id],
        }),
      });

      if (response.ok) {
        fetchNotifications(); // Refaz o GET após marcar como lidas
      }
    } catch (error) {
      console.error("Erro ao fazer a requisição:", error);
    }
  };

  useEffect(() => {
    fetchNotifications(); // Chama a função para buscar notificações a cada 5 segundos
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchNotifications(); // Chama a função para buscar notificações a cada 5 segundos
    }, 5000);

    return () => clearInterval(interval); // Limpa o intervalo quando o componente desmontar
  }, []);

  return (
    <Dropdown className="dropdown-profile ms-2" align="end">
      <Dropdown.Toggle
        style={{
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
          position: "relative",
        }}
      >
        <FaRegBell style={{ fontSize: "20px" }} className="text-dark m-1" />
        {notificationList && notificationList.length > 0 && (
          <Badge
            pill
            bg="danger"
            style={{
              position: "absolute",
              top: "-4px",
              right: "8px",
              fontSize: "10px",
              padding: "4px 6px",
              color: "white",
            }}
          >
            {notificationList.length}
          </Badge>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ width: "500px" }}>
        <div
          style={{
            maxHeight: "500px",
          }}
          className="dropdown-menu-body overflow-auto"
        >
          {notificationList && notificationList.length > 0 ? (
            <div className="p-1 mb-1 je border-bottom">
              <Button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  boxShadow: "none",
                }}
                onClick={markAllAsRead}
              >
                <span className="text-primary ms-4">{t("markAllAsRead")}</span>
              </Button>
            </div>
          ) : null}

          {notificationList && notificationList.length > 0 ? (
            <>
              {notificationList.map((n) => (
                <Dropdown.Item key={n.id} className="py-2 border-bottom">
                  <Row>
                    <Col xl={10}>
                      <div className="ac mb-2">
                        {!n.isRead && (
                          <span className="me-2 text-primary">
                            <GoDotFill />
                          </span>
                        )}
                        <span className="text-wrap text-dark">{n.message}</span>
                      </div>

                      <small className="ms-3 text-secondary">
                        {convertToLocalTime(n.timestamp)}
                      </small>
                    </Col>

                    <Col xl={2} className="je">
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation(); // Impede que o Dropdown feche
                          markAsRead(n.id); // Marca como lida ao clicar
                        }}
                        className="font-size-16px text-secondary"
                      >
                        <IoClose />
                      </span>
                    </Col>
                  </Row>
                </Dropdown.Item>
              ))}
            </>
          ) : (
            <div className="p-3 text-center w-100">
              <div className="jc ac w-100 mb-2">
                {t("notifications.noData")}
              </div>
              <Button onClick={fetchNotifications} size="sm" variant="primary">
                <IoIosRefresh />
              </Button>
            </div>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationsDropdown;
