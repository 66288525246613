import React, { createContext, useState } from "react";

export const DepartmentsContext = createContext();

export const DepartmentProvider = ({ children }) => {
  const [selectedDep, setSelectedDep] = useState(null); // [4
  const [devices, setDevices] = useState([]); // [5

  return (
    <DepartmentsContext.Provider
      value={{ selectedDep, setSelectedDep, devices, setDevices }}
    >
      {children}
    </DepartmentsContext.Provider>
  );
};
