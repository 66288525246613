import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";

const Notification = ({ show, setShow, feedback, type }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        //     setShow(false);
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [show, setShow]);

  return (
    show && (
      <div className={`notification ${type == "success" ? "" : ""}`}>
        <div className="border-bottom tw-border-gray-200 mb-3 je w-100">
          <div
            onClick={() => setShow(false)}
            className="rounded-btt-notification  text-dark mb-1"
          >
            <IoMdClose />
          </div>
        </div>
        <h6>{feedback}</h6>
      </div>
    )
  );
};

export default Notification;
