import React, { useState, useEffect } from "react";
import { Card, Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";

import { useGet } from "../../hooks/get";
import { SysadminContext } from "../../Context/SysadminContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { FaBluetooth } from "react-icons/fa";
import { FaWifi } from "react-icons/fa";
import ProgressBar from "../Utils/ProgressBar";

import { convertToLocalTime } from "../../hooks/convertDate";
import { VscLoading } from "react-icons/vsc";

import { badgesDefault } from "../../extraFunctions/badgesTw";

import { IoMdArrowDown, IoMdArrowUp } from "react-icons/io";
import { IoArrowDownOutline } from "react-icons/io5";
import { IoInformationCircleOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const Status = () => {
  const { selectedDevice, setActiveNav, setEvent } =
    useContext(SysadminContext);
  const [state, setState] = useState(1);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `/api/v1/sysadmin/device-status/${selectedDevice.deviceId}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            method: "GET",
          }
        );
        const data = await response.json();
        console.log(data);
        setData(data);
      } catch (error) {
        console.error("Failed to fetch status:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedDevice.deviceId) {
      fetchData();
    }
  }, [selectedDevice, state]);

  const { t } = useTranslation();

  if (loading) {
    return (
      <Card style={{ height: "740px" }} className="custom-card-1">
        <Card.Body>
          <div className="mw-100 mh-100">
            <h4
              style={{ fontSize: "25px" }}
              className="tw-animate-spin text-primary"
            >
              <VscLoading />
            </h4>
          </div>
        </Card.Body>
      </Card>
    );
  }
  return (
    <Card style={{ height: "740px" }} className="">
      <Card.Header>
        <div className="w-100 flex justify-content-between">
          <h4 className="mb-0 text-dark">Status</h4>
          {/* <div className="flex ac">
            <h5 className="font-semibold text-dark me-1">
              {selectedDevice?.deviceName}
            </h5>
            <span
              className={`${
                selectedDevice?.status == true
                  ? badgesDefault.green
                  : badgesDefault.red
              }`}
            >
              {selectedDevice?.status == true ? "Online" : "Offline"}
            </span>
          </div> */}
        </div>
      </Card.Header>

      <Card.Body>
        <Row>
          <Col xl={4} className="p-0">
            <Card className="status-card">
              <Card.Header>
                <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                  {t("Connection Status")}
                </h6>
                <h5 className="mb-0 cursor-pointer">
                  <i
                    className="ri-refresh-line"
                    onClick={() => setState(state + 1)}
                  />
                </h5>
              </Card.Header>
              <Card.Body>
                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary"> {t("WIFI Router")}</h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {t(`${data?.connectionStatus?.wifiRouterStatus || "-"}`)}
                  </h6>
                </div>
                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary">{t("Bluetooth")}</h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {t(`${data?.connectionStatus?.bluetoothStatus || "-"}`)}
                  </h6>
                </div>

                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary">{t("GPS")}</h6>

                  <h6
                    className={`mb-0 ${
                      data?.connectionStatus?.gpsStatus === "inactive"
                        ? "tw-text-red-600"
                        : "tw-text-green-500"
                    } font-semibold`}
                  >
                    {t(`${data?.connectionStatus?.gpsStatus || "-"}`)}
                  </h6>
                </div>

                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary">{t("Dados Móveis")}</h6>
                  {/* ${
                      data?.connectionStatus?.mobileDataStatus === "inactive"
                        ? "tw-text-red-600"
                        : "tw-text-green-500"
                    }  */}
                  <h6 className={`mb-0 font-semibold`}>
                    {t(`${data?.connectionStatus?.mobileDataStatus || "-"}`)}
                  </h6>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} className="p-0">
            <Card className="status-card">
              <Card.Header>
                <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                  {t("Ram Memory")}
                </h6>
                <h5 className="mb-0 cursor-pointer">
                  <i
                    className="ri-refresh-line"
                    onClick={() => setState(state + 1)}
                  />
                </h5>
              </Card.Header>
              <Card.Body>
                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary">Total</h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {data?.memoryInfo?.totalMemory || "0"}GB
                  </h6>
                </div>

                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary"> {t("Available")}</h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {data?.memoryInfo?.availableMemory || "0"}GB
                  </h6>
                </div>

                <div className="mb-2">
                  <div className="mb-2 d-flex tw-justify-between">
                    <h6 className="mb-0 text-secondary"> {t("Used")}</h6>
                    <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                      {data?.memoryInfo?.usedMemory || "0"}GB
                      <span className="ms-1 tw-font-bold">
                        ({data?.memoryInfo?.usedMemoryPercentage || 0}%)
                      </span>
                    </h6>
                  </div>
                  <div>
                    <ProgressBar
                      progress={data?.memoryInfo?.usedMemoryPercentage}
                      color={
                        data?.memoryInfo?.usedMemoryPercentage > 50
                          ? "#ff6b6b"
                          : "#51cf66"
                      }
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} className="p-0">
            <Card className="status-card">
              <Card.Header>
                <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                  {t("Battery")}
                </h6>
                <h5 className="mb-0 cursor-pointer">
                  <i
                    className="ri-refresh-line"
                    onClick={() => setState(state + 1)}
                  />
                </h5>
              </Card.Header>

              <Card.Body>
                <div className="mb-1 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary"> {t("Percent")}</h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {data?.bateryInfo?.percent || "0"}%
                  </h6>
                </div>

                <div className="mb-2">
                  <ProgressBar
                    progress={data?.bateryInfo?.percent}
                    color={
                      `${data?.batteryInfo?.percent}` < 20
                        ? "#ff6b6b" // Red for less than 20%
                        : `${data?.batteryInfo?.percent}` < 50
                        ? "#fcc419" // Yellow for less than 50%
                        : "#51cf66" // Green for less than 100%
                    }
                  />
                </div>

                <div className="mb-2 d-flex justify-content-between">
                  <div className="d-flex">
                    <h6 className="mb-0 text-secondary">{t("Health")}:</h6>
                    <h6
                      className={`mb-0 ms-1 ${
                        data?.bateryInfo?.health === "Good"
                          ? "text-success"
                          : "text-danger"
                      } font-semibold `}
                    >
                      {t(data?.bateryInfo?.health)}
                    </h6>
                  </div>
                </div>

                <div className="mb-2 d-flex justify-content-between">
                  <div className="d-flex">
                    <h6 className="mb-0 text-secondary me-1">
                      {t("Temperature")}:
                    </h6>
                    <h6
                      className={`mb-0 ${
                        data?.bateryInfo?.temperature < 45
                          ? "tw-text-green-500"
                          : data?.bateryInfo?.temperature < 80
                          ? "tw-text-yellow-500"
                          : "tw-text-red-500"
                      } font-semibold `}
                    >
                      {data?.bateryInfo?.temperature || "0"}°C
                    </h6>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} className="p-0">
            <Card className="status-card">
              <Card.Header>
                <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                  {t("Storage")}
                </h6>
                <h5 className="mb-0 cursor-pointer">
                  <i
                    className="ri-refresh-line"
                    onClick={() => setState(state + 1)}
                  />
                </h5>
              </Card.Header>
              <Card.Body>
                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary">{t("Total")}</h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {data?.storageInfo?.totalStorage || "0"}GB
                  </h6>
                </div>

                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary"> {t("Used")}</h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {data?.storageInfo?.usedStorage || "0"}GB
                    <span className="ms-1 tw-font-bold">
                      ({data?.storageInfo?.usedStoragePercent || 0}%)
                    </span>
                  </h6>
                </div>

                <div className="mb-2">
                  <ProgressBar
                    progress={data?.storageInfo?.usedStoragePercent}
                    color={
                      data?.storageInfo?.usedStoragePercent > 50
                        ? "#ff6b6b"
                        : "#51cf66"
                    }
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} className="p-0">
            <Card className="status-card-mdm">
              <Card.Header>
                <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                  {t("General Info")}
                </h6>
                <h5 className="mb-0 cursor-pointer">
                  <i
                    className="ri-refresh-line"
                    onClick={() => setState(state + 1)}
                  />
                </h5>
              </Card.Header>
              <Card.Body>
                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary"> {t("Device Model")}</h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {data?.mdmInfo?.deviceModel || "-"}
                  </h6>
                </div>

                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary"> {t("Device Vendor")}</h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {data?.mdmInfo?.deviceVendor || "-"}
                  </h6>
                </div>

                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary">IMEI1</h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {data?.mdmInfo?.IMEI1 || "-"}
                  </h6>
                </div>
                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary">IMEI2</h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {data?.mdmInfo?.IMEI2 || "-"}
                  </h6>
                </div>

                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary"> {t("Serial Number")}</h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {data?.mdmInfo?.serialnumber || "-"}
                  </h6>
                </div>

                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary"> {t("Uptime")}</h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {data?.mdmInfo?.uptime || "-"}
                  </h6>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} className="p-0">
            <Card className="status-card-mdm">
              <Card.Header>
                <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                  {t("WIFI Info")}
                </h6>
                <h5 className="mb-0 cursor-pointer">
                  <i
                    className="ri-refresh-line"
                    onClick={() => setState(state + 1)}
                  />
                </h5>
              </Card.Header>
              <Card.Body>
                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary">{t("WIFI Status")}</h6>
                  <h6
                    className={`mb-0 font-semibold ${
                      data?.wifiInfo?.wifiStatus == "active"
                        ? "tw-text-green-500"
                        : "tw-text-red-500"
                    }`}
                  >
                    {t(`${data?.wifiInfo?.wifiStatus || "-"}`)}
                  </h6>
                </div>
                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary"> {t("WIFI SSID")}</h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {data?.wifiInfo?.wifiSsid || "-"}
                  </h6>
                </div>

                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary">{t("WIFI Strength")}</h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {t(`${data?.wifiInfo?.wifiStrength || "-"}`)}
                  </h6>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} className="p-0">
            <Card className="status-card-mdm">
              <Card.Header className="ac">
                <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                  {t("Data Consumption")}
                </h6>
                <h5 className="mb-0">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-00012}`}>
                        {t("Data consumed in the last 5 minutes")}
                      </Tooltip>
                    }
                  >
                    <span className="ms-2 font-size-20px text-dark p-2">
                      <IoInformationCircleOutline />
                    </span>
                  </OverlayTrigger>
                </h5>
              </Card.Header>
              <Card.Body>
                <div className="mb-1 d-flex justify-content-between ac">
                  <h6 className="mb-0 text-secondary">{t("WIFI Data")}</h6>
                  <div className="d-flex justify-content-between ac">
                    <div>
                      <span className="tw-text-green-500 font-size-16px me-1">
                        <IoMdArrowUp />
                      </span>
                      {data?.connectionStatus?.wifiSentDataMb || 0}MB
                    </div>

                    <div className="ms-2">
                      <span className="tw-text-orange-500 font-size-16px me-1">
                        <IoMdArrowDown />
                      </span>
                      {data?.connectionStatus?.wifiReceivedDataMb || 0}MB
                    </div>
                  </div>
                </div>

                <div className="mb-2 d-flex justify-content-between ac">
                  <h6 className="mb-0 text-secondary">{t("Mobile Data")}</h6>
                  <div className="d-flex justify-content-between ac">
                    <div>
                      <span className="tw-text-green-500 font-size-16px me-1">
                        <IoMdArrowUp />
                      </span>
                      {data?.connectionStatus?.mobileDataSentMb || 0}MB
                    </div>

                    <div className="ms-2">
                      <span className="tw-text-orange-500 font-size-16px me-1">
                        <IoMdArrowDown />
                      </span>
                      {data?.connectionStatus?.mobileDataReceiveMb || 0}MB
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} className="p-0">
            <Card className="status-card-mdm">
              <Card.Header>
                <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                  {t("MDM Info")}
                </h6>
                <h5 className="mb-0 cursor-pointer">
                  <i
                    className="ri-refresh-line"
                    onClick={() => setState(state + 1)}
                  />
                </h5>
              </Card.Header>
              <Card.Body>
                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary">
                    {t("MDM App Version")}
                  </h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {data?.mdmInfo?.mdmAppVersion || "-"}
                  </h6>
                </div>

                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary">{t("Registered At")}</h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {convertToLocalTime(data?.mdmInfo?.registeredAt) || "-"}
                  </h6>
                </div>

                <div className="mb-2 d-flex justify-content-between">
                  <h6 className="mb-0 text-secondary">{t("Last Comm.")}</h6>
                  <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                    {convertToLocalTime(data?.mdmInfo?.lastCommunication)}
                  </h6>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} className="p-0">
            <Card className="status-card-mdm">
              <Card.Header>
                <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                  {t("Device Lines")}
                </h6>
                <h5 className="mb-0 cursor-pointer">
                  <i
                    onClick={() => setState(state + 1)}
                    className={`ri-refresh-line ${
                      data?.deviceLines && data?.deviceLines.length > 0
                        ? ""
                        : "d-none"
                    }`}
                  />
                </h5>
              </Card.Header>
              <Card.Body
                className={`${
                  data?.deviceLines && data?.deviceLines.length > 0
                    ? ""
                    : "jc ac text-center"
                }`}
              >
                {data?.deviceLines && data?.deviceLines.length > 0 ? (
                  data.deviceLines.map((item, index) => (
                    <div key={index}>
                      <div className="mb-2 d-flex justify-content-between">
                        <h6 className="mb-0 text-secondary">
                          {t("Line Name")}
                        </h6>
                        <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                          {item.lineName || "-"}
                        </h6>
                      </div>
                      <div className="mb-2 d-flex justify-content-between">
                        <h6 className="mb-0 text-secondary">
                          {t("Line Number")}
                        </h6>
                        <h6 className="mb-0 tw-text-gray-700 font-semibold ">
                          {item.lineNumber || "-"}
                        </h6>
                      </div>
                      {index !== data?.deviceLines.length && <hr />}
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                    <h5 className="mb-2 mb-0 font-semibold">
                      {t("No lines added")}
                    </h5>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setEvent({
                          key: "add line",
                          device: selectedDevice?.devideId,
                        });

                        navigate("/lines");
                      }}
                    >
                      {t("Add a line to device")}
                    </button>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Status;
