import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CreateDepartament = ({ show, onHide, onAdd }) => {
  const [departamentName, setDepartamentName] = useState("");
  const [feedback, setFeedback] = useState("");
  const { t } = useTranslation();

  const submit = () => {
    fetch("/api/v1/department/create-department", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ departmentName: departamentName }),
    })
      .then((res) => {
        res.json();

        if (res.ok) {
          onAdd();
          setFeedback({
            ok: true,
            message: `${t("Department created successfully")}`,
          });
        } else {
          setFeedback({
            ok: false,
            message: `Error: ${res.status}}`,
          });
        }
      })
      .then((data) => {
        console.log(data);
      });
  };
  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Group className="p-1" controlId="formBasicEmail">
            <Form.Label>{t("Department Name")}</Form.Label>
            <Form.Control
              value={departamentName}
              onChange={(e) => setDepartamentName(e.target.value)}
              type="text"
              placeholder={`${t("Enter Department Name")}`}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  submit();
                }
              }}
            />
          </Form.Group>

          {feedback &&
            (feedback.ok ? (
              <div className="tw-bg-green-500 tw-text-white p-4 tw-rounded-md mt-4">
                {feedback.message}
              </div>
            ) : (
              <div className="tw-bg-red-500   tw-text-white p-4 tw-rounded-md mt-4">
                {feedback.message}
              </div>
            ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t("Close")}
        </Button>
        <Button variant="primary" onClick={submit}>
          {t("Create")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateDepartament;
