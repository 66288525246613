import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap"; // Corrected import

import { LuUser2 } from "react-icons/lu";
import { FaCheck } from "react-icons/fa";
import { IoIosNotificationsOutline } from "react-icons/io";

import logoutPng from "../assets/headerIcons/logout.png";
import { LogOut } from "../extraFunctions/utils";
import { useGet } from "../hooks/get";
import { BsKey } from "react-icons/bs";
import { t } from "i18next";
import { RiLockPasswordLine } from "react-icons/ri";
import ChangePasswordModal from "./HeaderUtils/ChangePasswordModal.js";
import MFAModal from "./HeaderUtils/MfaModal.js";
import AuthInterceptor from "../components/Auth/AuthInterceptor.js";
import { IoLanguage } from "react-icons/io5";

import brazilSvg from "../pages/loginflags/brazil.svg";
import usaSvg from "../pages/loginflags/usa.svg";
import { badgesDefault } from "../extraFunctions/badgesTw.js";
import { AuthContext } from "../Context/AuthContext.js";
import { useContext } from "react";
import { GoDotFill } from "react-icons/go";

import NotificationsDropdown from "../components/Utils/NotificationDrodown.js";

export default function Header({}) {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  };

  const handleSignOut = () => {
    LogOut();
  };

  // const { data: userData, loading, error } = useGet("/api/v1/user-data");
  const [userData, setUserData] = useState([]);
  const { googleEnterprise, setGoogleEnterprise } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [showMfa, setShowMfa] = useState(false);
  let lang = localStorage.getItem("lang") || "en";
  let preSelectedLang = localStorage.getItem("lang") || "en";
  const [language, setLanguage] = useState(preSelectedLang);
  const handleLanguageSelect = (lang) => {
    console.log("lang:", lang);
    setLanguage(lang);
    localStorage.setItem("lang", lang);
    window.location.reload();
  };

  const fetchData = async () => {
    const response = await fetch("/api/v1/user-data", {
      method: "GET",
    });

    const data = await response.json();
    setUserData(data || []);

    setGoogleEnterprise(data?.googleEnterprise == true ? "tem" : "naotem");
  };

  const isInDevelopment = process.env.NODE_ENV === "development";

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (isInDevelopment) {
      console.log("apiDevolveu: ", userData.googleEnterprise);
      console.log("Eu setei:", googleEnterprise);
    }
  }, [googleEnterprise, userData]);

  return (
    <div className="header-main px-3  px-lg-4">
      <ChangePasswordModal showModal={showModal} setShowModal={setShowModal} />
      {showMfa && (
        <MFAModal
          showMfa={showMfa}
          setShowMfa={setShowMfa}
          close={() => setShowMfa(false)}
        />
      )}
      <div className="d-none">
        <AuthInterceptor showMfa={showMfa} />
      </div>
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4">
        <i className="ri-menu-2-fill"></i>
      </Link>

      <div
        style={{ marginLeft: "auto", marginTop: "-5px", marginRigth: "-35px" }}
      >
        <NotificationsDropdown />
      </div>

      <Dropdown
        style={{ marginLeft: "auto" }}
        className="dropdown-profile ms-2"
        align="end"
      >
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar">
            <LuUser2 style={{ fontSize: "20px" }} className="text-dark m-1" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body mh-100 mw-100 overflow-auto">
            <div className="avatar avatar-xl mb-3 flex tw-justify-between">
              <LuUser2
                style={{ fontSize: "35px" }}
                className="text-dark text-center m-1"
              />
            </div>

            <h6
              style={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
              className="mb-1 text-dark fw-semibold text-uppercase"
            >
              {userData ? userData.name : "Guest"}
            </h6>
            <p className="fs-sm text-secondary">
              {userData ? userData.permissionLevel : "-"}
            </p>

            <nav className="nav">
              <hr />

              <Link to="#">
                <Dropdown>
                  <Dropdown.Toggle
                    drop={"down"}
                    className="p-0"
                    id="dropdown-basic-button"
                    variant="n"
                    style={{
                      border: "none",
                      backgroundColor: "none !imporant",
                    }}
                  >
                    <div className="flex">
                      <i className="align-middle me-1 text-dark">
                        <IoLanguage />
                      </i>
                      Choose Language
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        if (lang && lang.toLowerCase() == "pt-br") {
                          handleLanguageSelect("pt-BR");
                        } else {
                          return;
                        }
                      }}
                    >
                      <img
                        className="me-2"
                        style={{
                          width: "25px",
                        }}
                        src={usaSvg}
                      />
                      English
                      {lang.toLowerCase() === "en" && (
                        <span className={`${badgesDefault.green} ms-1`}>
                          <FaCheck />
                        </span>
                      )}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        if (lang && lang.toLowerCase() == "en") {
                          handleLanguageSelect("pt-BR");
                        } else {
                          return;
                        }
                      }}
                    >
                      <img
                        className="me-2"
                        style={{
                          width: "25px",
                        }}
                        src={brazilSvg}
                      />
                      Português (BR)
                      {lang.toLowerCase() === "pt-br" && (
                        <span className={`${badgesDefault.green} ms-1`}>
                          <FaCheck />
                        </span>
                      )}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Link>
              <Link
                onClick={() => setShowModal(true)}
                to="#"
                className="logout-link"
              >
                <i className="align-middle me-1 text-dark">
                  <BsKey />
                </i>
                {t("Change Password")}
              </Link>
              <Link
                onClick={() => {
                  setShowMfa(true);
                }}
                to="#"
              >
                <i className="align-middle me-1 text-dark">
                  <RiLockPasswordLine className="icone-torto" />
                </i>
                {t("MFA")}
              </Link>

              <Link onClick={handleSignOut} to="#" className="logout-link">
                <i className="align-middle me-1 text-danger">
                  <img src={logoutPng} alt="" />
                </i>

                {t("Log Out")}
              </Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
