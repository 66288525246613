// src/components/Utils/FilterModal.js
import { t } from "i18next";
import React, { useState } from "react";
import { Modal, Button, FormCheck, Col, Row } from "react-bootstrap";

const FilterModal = ({
  show,
  handleClose,
  filterColumns = [],
  setFilterColumns,
  availableColumns,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const handleColumnToggle = (column) => {
    if (filterColumns && filterColumns.includes(column)) {
      setFilterColumns(filterColumns.filter((col) => col !== column));
    } else {
      setFilterColumns([...filterColumns, column]);
    }
  };

  const handleReset = () => {
    setSelectAll((prev) => !prev);
    setFilterColumns(availableColumns);
  };

  return (
    <Modal size="lg" centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Filter Columns")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mh-100 overflow-auto">
        <div className="d-flex w-100">
          {availableColumns.map((column) => (
            <Col
              xl={4}
              onClick={() => handleColumnToggle(column)}
              key={column}
              className="pe-2 mb-2"
            >
              <h5
                className={`${
                  filterColumns && filterColumns.includes(column)
                    ? "tw-bg-datadike-blue text-white hover:tw-bg-datadike-dark"
                    : "text-dark  tw-bg-gray-100 hover:tw-bg-gray-200"
                }  w-100 cursor-pointer text-center py-2`}
              >
                {t(
                  column
                    .replace(/([A-Z])/g, " $1")
                    // uppercase the first character
                    .replace(/^./, function (str) {
                      return str.toUpperCase();
                    })
                )}
              </h5>
            </Col>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={handleReset}>
          {t("Select All")}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModal;
