import React from "react";
import QRCode from "qrcode.react";
import { t } from "i18next";

const Step2 = () => {
  return (
    <div>
      <h5 className="mb-3 text-center">
        {t("Scan this QR Code using the device.")}
      </h5>
      <div className="mb-4 d-flex justify-content-center">
        <QRCode value="https://mdmdev.datadike.online/api/v1/download-app" />
      </div>
      <span className="mb-4 text-secondary">
        {t("Than, open the link. Download and install the")}

        <span className="text-dark ms-1">DataDikeAgent.APK</span>
      </span>
    </div>
  );
};

export default Step2;
