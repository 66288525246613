import React, { useState, useEffect, useCallback } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { useGet } from "../../hooks/get";

const EditModal = ({ selectedLine, onHide, setRefreshState }) => {
  const [lineName, setLineName] = useState("");
  const [lineDDI, setLineDDI] = useState("");
  const [lineDDD, setLineDDD] = useState("");
  const [lineOperator, setLineOperator] = useState("");
  const [lineNumber, setLineNumber] = useState("");
  const [linePlan, setLinePlan] = useState("");
  const [dataCap, setDataCap] = useState("");
  const [dataCapUnit, setDataCapUnit] = useState("");
  const [status, setStatus] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const { data: allDevicesData, loading: loadingDevices } = useGet(
    "/api/v1/lines/devices"
  );
  const { data: allDepartmentsData, loading: loadingDepartments } = useGet(
    "/api/v1/lines/departments"
  );

  useEffect(() => {
    if (allDevicesData) {
      setDeviceOptions(
        allDevicesData.map((device) => ({
          value: device.deviceId,
          label: device.deviceName || "Unnamed Device",
        }))
      );
    }
  }, [allDevicesData]);

  useEffect(() => {
    if (allDepartmentsData) {
      setDepartmentOptions(
        allDepartmentsData.map((department) => ({
          value: department.departmentId,
          label: department.departmentName,
        }))
      );
    }
  }, [allDepartmentsData]);

  useEffect(() => {
    if (selectedLine) {
      setLineName(selectedLine.lineName || "");
      setLineDDI(selectedLine.lineDDI || "");
      setLineDDD(selectedLine.lineDDD || "");
      setLineOperator(selectedLine.lineOperator || "");
      setLineNumber(selectedLine.lineNumber || "");
      setLinePlan(selectedLine.linePlan || "");
      setDataCap(selectedLine.dataCap || "");
      setDataCapUnit(selectedLine.dataCapUnit || "");
      setStatus(selectedLine.status || false);
      setSelectedDevice(
        deviceOptions.find(
          (device) => device.value === selectedLine.deviceId
        ) || null
      );
      setSelectedDepartment(
        departmentOptions.find(
          (department) => department.value === selectedLine.departmentId
        ) || null
      );
    }
  }, [selectedLine, deviceOptions, departmentOptions]);

  const handleSave = async () => {
    const updatedLine = {
      lineName,
      lineDDI,
      lineDDD,
      lineOperator,
      lineNumber,
      linePlan,
      dataCap,
      dataCapUnit,
      status,
      deviceId: selectedDevice ? selectedDevice.value : null,
      departmentId: selectedDepartment ? selectedDepartment.value : null,
      departmentName: selectedDepartment ? selectedDepartment.label : null,

      deviceName: selectedDevice ? selectedDevice.label : null,
    };

    try {
      await fetch(`/api/v1/lines/update-line/${selectedLine._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedLine),
      });
      setFeedback({
        ok: true,
        message: "Edited successfully",
      });

      setTimeout(() => {
        setRefreshState((prevState) => !prevState);
        onHide();
        setFeedback(null);
      }, 1000);
    } catch (error) {
      console.error("Failed to update line", error);
      setFeedback({
        ok: false,
        message: `${error}`,
      });
    }
  };

  if (!selectedLine) {
    return <div></div>;
  }

  return (
    <Modal show={true} onHide={onHide} centered>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            <Col xl={12} md={12}>
              <Form.Group controlId="formLineName">
                <Form.Label>Line Name</Form.Label>
                <Form.Control
                  type="text"
                  value={lineName}
                  onChange={(e) => setLineName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="deviceId">
                <Form.Label>Device</Form.Label>
                <Select
                  options={deviceOptions}
                  value={selectedDevice}
                  onChange={setSelectedDevice}
                  isLoading={loadingDevices}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="departmentId">
                <Form.Label>Department</Form.Label>
                <Select
                  options={departmentOptions}
                  value={selectedDepartment}
                  onChange={setSelectedDepartment}
                  isLoading={loadingDepartments}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xl={6} md={12}>
              <Form.Group controlId="formLineDDI">
                <Form.Label>Line DDI</Form.Label>
                <Form.Control
                  type="text"
                  value={lineDDI}
                  onChange={(e) => setLineDDI(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={12} xl={6}>
              <Form.Group controlId="formLineDDD">
                <Form.Label>Line DDD</Form.Label>
                <Form.Control
                  type="text"
                  value={lineDDD}
                  onChange={(e) => setLineDDD(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xl={6} md={12}>
              <Form.Group controlId="formLineOperator">
                <Form.Label>Line Operator</Form.Label>
                <Form.Control
                  type="text"
                  value={lineOperator}
                  onChange={(e) => setLineOperator(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={12} xl={6}>
              <Form.Group controlId="formLineNumber">
                <Form.Label>Line Number</Form.Label>
                <Form.Control
                  type="text"
                  value={lineNumber}
                  onChange={(e) => setLineNumber(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12} xl={6}>
              <Form.Group controlId="formLinePlan">
                <Form.Label>Line Plan</Form.Label>
                <Form.Control
                  type="text"
                  value={linePlan}
                  onChange={(e) => setLinePlan(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={12} xl={6}>
              <Form.Group controlId="formDataCap">
                <Form.Label>Data Cap</Form.Label>
                <Form.Control
                  type="text"
                  value={dataCap}
                  onChange={(e) => setDataCap(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xl={6} md={12}>
              <Form.Group controlId="formDataCapUnit">
                <Form.Label>Data Cap Unit</Form.Label>
                <Form.Control
                  type="text"
                  value={dataCapUnit}
                  onChange={(e) => setDataCapUnit(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xl={6} md={12}>
              <Form.Group controlId="formStatus">
                <Form.Label>Active Line?</Form.Label>
                <Form.Control
                  as="select"
                  value={status ? "Active" : "Inactive"}
                  onChange={(e) => setStatus(e.target.value === "Active")}
                >
                  <option value="Active">Yes</option>
                  <option value="Inactive">No</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        {feedback &&
          (feedback.ok ? (
            <div className="tw-bg-green-500 tw-text-white p-4 tw-rounded-md mt-4">
              {feedback.message}
            </div>
          ) : (
            <div className="tw-bg-red-500 tw-text-white p-4 tw-rounded-md mt-4">
              {feedback.message}
            </div>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
