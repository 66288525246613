import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import TutorialMessage from "./TutorialMessage";
import EndMessage from "./EndMessage.js";

import { DeviceProvider } from "../../../Context/DeviceContext";
import { t } from "i18next";

const NewDevice = ({ close }) => {
  const [step, setStep] = useState(0);
  return (
    <DeviceProvider>
      <Modal className="fixed-modal" show={true} centered>
        {/* {step == 1 || step == 2 ? (
        <Modal.Header>
          <h4 className="mb-0">Add a new Device</h4>
        </Modal.Header>
      ) : null} */}

        <Modal.Body className="d-flex align-items-center tw-justify-center">
          {step === 0 && <TutorialMessage next={() => setStep(1)} />}
          {/* {step === 1 && <Step1 next={() => setStep(1)} />} */}
          {step === 1 && <Step2 close={close} />}
          {step === 2 && <Step3 />}
          {step === 3 && <EndMessage close={close} />}
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-between align-items-center tw-border-top tw-border-gray-300 tw-bg-white">
          <div className="d-flex tw-justify-start">
            {step !== 3 && (
              <button className="btn btn-secondary" onClick={close}>
                {t("Close")}
              </button>
            )}
          </div>

          <div className="d-flex tw-justify-end">
            <button
              className="me-1 tw-rounded-full d-flex justify-content-center align-items-center tw-bg-gray-400 tw-text-white tw-border-none tw-cursor-pointer"
              disabled={step === 0}
              style={{
                width: "35px",
                height: "35px",
              }}
              onClick={() => {
                if (step > 0) {
                  setStep(step - 1);
                } else {
                  return;
                }
              }}
            >
              {"<"}
            </button>

            {step === 3 ? (
              <button
                className="btn btn-primary"
                onClick={() => {
                  close();
                }}
              >
                Finish
              </button>
            ) : (
              <button
                style={{
                  width: "35px",
                  height: "35px",
                }}
                className="ms-1 tw-rounded-full d-flex justify-content-center align-items-center tw-bg-datadike-blue tw-text-white tw-border-none tw-cursor-pointer"
                disabled={step === 3}
                onClick={() => {
                  if (step < 3) {
                    setStep(step + 1);
                  } else {
                    return;
                  }
                }}
              >
                {">"}
              </button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </DeviceProvider>
  );
};

export default NewDevice;
