import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useGet } from "../../hooks/get";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const CreateLineModal = ({ show, close, submit, preSelected }) => {
  const { t } = useTranslation();

  const [lineName, setLineName] = useState("");
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [lineDDI, setLineDDI] = useState("+55");
  const [lineDDD, setLineDDD] = useState("");
  const [lineNumber, setLineNumber] = useState("");
  const [lineOperator, setLineOperator] = useState("");
  const [linePlan, setLinePlan] = useState("");
  const [status, setStatus] = useState(true);
  const [dataCap, setDataCap] = useState("");
  const [dataCapUnit, setDataCapUnit] = useState("GB");

  const { data: allDevicesData, loading: loadingDevices } = useGet(
    "/api/v1/lines/devices"
  );
  const { data: allDepartmentsData, loading: loadingDepartments } = useGet(
    "/api/v1/lines/departments"
  );

  useEffect(() => {
    if (preSelected) {
      const preSelectedDevice = allDevicesData?.find(
        (device) => device.deviceId === preSelected.deviceId
      );
      setSelectedDevice(
        preSelectedDevice
          ? {
              value: preSelectedDevice.deviceId,
              label: preSelectedDevice.deviceName || "Unnamed Device",
            }
          : null
      );
    }
  }, [preSelected, allDevicesData]);

  const deviceOptions = allDevicesData?.map((device) => ({
    value: device.deviceId,
    label: device.deviceName || "Unnamed Device",
  }));

  const departmentOptions = allDepartmentsData?.map((department) => ({
    value: department.departmentId,
    label: department.departmentName,
  }));

  const onSubmit = async () => {
    const body = {
      ...(lineName && { lineName }),
      ...(selectedDevice && {
        deviceId: selectedDevice.value,
        deviceName: selectedDevice.label,
      }),
      ...(selectedDepartment && {
        departmentId: selectedDepartment.value,
        departmentName: selectedDepartment.label,
      }),
      lineDDI,
      ...(lineDDD && { lineDDD }),
      ...(lineNumber && { lineNumber }),
      ...(lineOperator && { lineOperator }),
      ...(linePlan && { linePlan }),
      status,
      ...(dataCap && { dataCap }),
      ...(dataCapUnit && { dataCapUnit }),
    };

    const response = await fetch("/api/v1/lines/cad-line", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      setTimeout(() => {
        submit();
        close();
      }, 1000);
    } else {
      console.error("Failed to register the line");
      alert("Failed to register the line");
    }
  };

  return (
    <Modal centered show={show} onHide={close}>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="lineName">
                <Form.Label>{t("Line Name")}</Form.Label>
                <Form.Control
                  type="text"
                  value={lineName}
                  placeholder={t("Enter line name")}
                  onChange={(e) => setLineName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="deviceId">
                <Form.Label>{t("Device")}</Form.Label>
                <Select
                  options={deviceOptions}
                  value={selectedDevice}
                  onChange={(selectedOption) =>
                    setSelectedDevice(selectedOption)
                  }
                  isLoading={loadingDevices}
                  placeholder={t("Select a device")}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="departmentId">
                <Form.Label>{t("Department")}</Form.Label>
                <Select
                  options={departmentOptions}
                  value={selectedDepartment}
                  onChange={setSelectedDepartment}
                  isLoading={loadingDepartments}
                  placeholder={t("Select a department")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="lineDDD">
                <Form.Label>{t("DDD")}</Form.Label>
                <Form.Control
                  type="text"
                  value={lineDDD}
                  placeholder={t("Enter DDD")}
                  onChange={(e) => setLineDDD(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="lineDDD">
                <Form.Label>{t("DDI")}</Form.Label>
                <Form.Control
                  type="text"
                  value={lineDDI}
                  placeholder={t("Enter DDI")}
                  onChange={(e) => setLineDDI(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="lineNumber">
                <Form.Label>{t("Line Number")}</Form.Label>
                <Form.Control
                  type="text"
                  value={lineNumber}
                  placeholder={t("Enter line number")}
                  onChange={(e) => setLineNumber(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="lineOperator">
                <Form.Label>{t("Line Operator")}</Form.Label>
                <Form.Control
                  type="text"
                  value={lineOperator}
                  placeholder={t("Enter line operator")}
                  onChange={(e) => setLineOperator(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="linePlan">
                <Form.Label>{t("Line Plan")}</Form.Label>
                <Form.Control
                  type="text"
                  value={linePlan}
                  placeholder={t("Enter line plan")}
                  onChange={(e) => setLinePlan(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="dataCap">
                <Form.Label>{t("Data Cap")}</Form.Label>
                <Form.Control
                  type="text"
                  value={dataCap}
                  placeholder={t("Enter data cap")}
                  onChange={(e) => setDataCap(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            
            <Col xl={6}>
              <Form.Group controlId="dataCapUnit">
                <Form.Label>{t("Data Cap Unit")}</Form.Label>
                <Form.Control
                  as="select"
                  value={dataCapUnit}
                  onChange={(e) => setDataCapUnit(e.target.value)}
                >
                  <option value="MB">{t("MB")}</option>
                  <option value="GB">{t("GB")}</option>
                  <option value="TB">{t("TB")}</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="status" className="mb-3">
            <Form.Check
              type="checkbox"
              label={t("Active")}
              checked={status}
              onChange={(e) => setStatus(e.target.checked)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          {t("Cancel")}
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          {t("Save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateLineModal;
