import React from "react";
import { useNavigate } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";
import { t } from "i18next";

const EndMessage = ({ close }) => {
  const navigate = useNavigate();
  return (
    <div>
      <h3 className="mb-2 text-center">{t("Finish")}</h3>
      <h5 className="mb-4 text-secondary">
        {t("In some minutes, you will can see your device on devices list.")}
      </h5>

      <div className="d-flex justify-content-center align-items-center mt-3">
        <button
          className="btn btn-primary"
          onClick={() => {
            navigate("/");
            close();
          }}
        >
          {t("Go to devices list")}
          <span className="icone-torto ms-2">
            <MdArrowOutward />
          </span>
        </button>
      </div>
    </div>
  );
};

export default EndMessage;
