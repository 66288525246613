import React from "react";
import ReactApexChart from "react-apexcharts";

const Chart1 = ({ index }) => {
  var dataApexChart = [
    [0, 9],
    [1, 7],
    [2, 4],
    [3, 8],
    [4, 4],
    [5, 12],
    [6, 4],
    [7, 6],
    [8, 5],
    [9, 10],
    [10, 4],
    [11, 5],
    [12, 10],
  ];

  var dataApexChart2 = [
    [0, 8],
    [1, 4],
    [2, 3],
    [3, 8],
    [4, 4],
    [5, 5],
    [6, 4],
    [7, 6],
    [8, 5],
    [9, 10],
    [10, 4],
    [11, 12],
    [12, 10],
  ];

  const chart = {
    parentHeightOffset: 0,
    stacked: true,
    sparkline: {
      enabled: true,
    },
  };

  const states = {
    hover: {
      filter: {
        type: "none",
      },
    },
    active: {
      filter: {
        type: "none",
      },
    },
  };

  const plotOptions = {
    bar: {
      columnWidth: "60%",
    },
  };

  const stroke = {
    curve: "straight",
    lineCap: "square",
  };

  const seriesOne = [
    {
      type: "column",
      data: [
        [0, 0],
        [1, 0],
        [2, 5],
        [3, 10],
        [4, 6],
        [5, 10],
        [6, 15],
        [7, 18],
        [8, 7],
        [9, 11],
        [10, 13],
        [11, 15],
        [12, 13],
        [13, 7],
        [14, 5],
      ],
    },
    {
      type: "column",
      data: index == 2 ? dataApexChart : dataApexChart2,
    },
  ];

  const optionOne = {
    chart: chart,
    states: states,
    colors: ["#506fd9", "#e5e9f2"],
    plotOptions: plotOptions,
    stroke: stroke,
    fill: { opacity: 1 },
    tooltip: { enabled: false },
  };
  return (
    <ReactApexChart
      series={seriesOne}
      options={optionOne}
      type="bar"
      height={40}
    />
  );
};

export default Chart1;
