import React, { useContext, useState, useEffect } from "react";
import { Card, Dropdown, Button } from "react-bootstrap";
import { SysadminContext } from "../../Context/SysadminContext";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { VscLoading } from "react-icons/vsc";
import { IoTimeOutline } from "react-icons/io5";
import {
  convertToLocalTime,
  getBrowserTimeZone,
  convertToLocalTimeReq,
} from "../../hooks/convertDate";
import { badgesDefault } from "../../extraFunctions/badgesTw";

import DateRangePickerModal from "../Utils/DateRangePickerModal";
import { GoDotFill } from "react-icons/go";
import { optionsLocation } from "../../data/optionsLocation";
import { useTranslation } from "react-i18next";
// Fix for missing marker icons in Leaflet
const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const Apps = () => {
  const { t } = useTranslation();
  const { selectedDevice } = useContext(SysadminContext);
  const now = new Date();
  const thirtyMinutesAgo = new Date(now.getTime() - 30 * 60000);

  const [start, setStart] = useState(thirtyMinutesAgo);
  const [end, setEnd] = useState(new Date());
  const [selectedTimeRange, setSelectedTimeRange] = useState(t("Last Report"));
  const timezone = getBrowserTimeZone();
  const [address, setAddress] = useState(null);
  const [deviceLocation, setDeviceLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isHistoricalData, setIsHistoricalData] = useState(false);
  const [show, setShow] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [lastreportValue, setLastReportValue] = useState(null);

  const fetchLatestLocation = async (lastreport) => {
    console.log("fetchLatestLocation", lastreport);
    setLoading(true);
    setError(null);
    setIsHistoricalData(false);
    try {
      const apiUrl = `/api/v1/sysadmin/device-location/${selectedDevice?.deviceId}`;
      const response = await fetch(apiUrl);
      if (response.status == 403) {
        window.location.replace("/signin");
      }
      const data = await response.json();
      if (data && data.length > 0) {
        setDeviceLocation(data);
        setLastReportValue(data[0].timestamp);

        if (lastreport) {
          setInputValue(
            `Last Report: ${convertToLocalTime(data[0].timestamp)}`
          );
          console.log("inputValue", inputValue);
        } else {
          setInputValue(null);
        }
      } else {
        setError("No location data available");
      }
    } catch (error) {
      setError("Error fetching location data");
    } finally {
      setLoading(false);
    }
  };

  if (inputValue) {
    console.log("comp", inputValue);
  }

  const fetchHistoricalLocation = async (start, end, timezone) => {
    setLoading(true);
    setError(null);
    setInputValue(null);
    setIsHistoricalData(true);
    const startDate = convertToLocalTimeReq(start);
    const endDate = convertToLocalTimeReq(end);
    try {
      const apiUrl = `/api/v1/sysadmin/device-location/${selectedDevice?.deviceId}?startTime=${startDate}&endTime=${endDate}&timezone=${timezone}`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      if (data && data.length > 0) {
        setDeviceLocation(data);
      } else {
        setError("No location data available");
      }
    } catch (error) {
      setError("Error fetching location data");
    } finally {
      setLoading(false);
    }
  };

  const updateDateRangeAndFetch = (minutes, label) => {
    const newEnd = new Date();
    const newStart = new Date(newEnd.getTime() - minutes * 60000);
    setStart(newStart);
    setEnd(newEnd);
    setSelectedTimeRange(label);
    fetchHistoricalLocation(newStart, newEnd, timezone);
  };

  useEffect(() => {
    if (selectedDevice) {
      fetchLatestLocation(true);
    }
  }, [selectedDevice]);

  useEffect(() => {
    if (deviceLocation && deviceLocation.length > 0) {
      const { latitude, longitude } = deviceLocation[0];
      fetchAddress(latitude, longitude);
    }
  }, [deviceLocation]);

  const fetchAddress = async (lat, lng) => {
    if (!lat || !lng) {
      return;
    }
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&addressdetails=1`
      );
      const data = await response.json();
      if (data && data.address) {
        const addressComponents = [
          data.address.road,
          data.address.suburb,
          data.address.city,
          data.address.state,
          data.address.postcode,
          data.address.country,
        ];
        // Filter out undefined or "undefined" components
        const validComponents = addressComponents.filter(
          (component) => component && component !== "undefined"
        );
        // Join the valid components into a single address string
        setAddress(validComponents.join(", "));
      } else {
        setAddress("Address not found");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      setAddress("Error fetching address");
    }
  };

  if (loading) {
    return (
      <Card style={{ height: "740px" }} className="">
        <DateRangePickerModal
          show={show}
          handleClose={() => setShow(false)}
          startDate={start}
          endDate={end}
          onApply={(start, end) => {
            setStart(start);
            setEnd(end);
            setShow(false);
          }}
        />
        <Card.Header>
          <h4 className="mb-0 w-40">{t("Location")}</h4>

          <div className="d-flex w-60 justify-content-end">
            <input
              className={"form-control w-50"}
              disabled
              value={
                inputValue
                  ? inputValue
                  : `${convertToLocalTime(start)} - ${convertToLocalTime(end)}`
              }
            />
          </div>
        </Card.Header>

        <Card.Body className="jc ac">
          <h4 className="loading-icon">
            <VscLoading />
          </h4>
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card style={{ height: "740px" }} className="">
        <DateRangePickerModal
          show={show}
          handleClose={() => setShow(false)}
          startDate={start}
          endDate={end}
          onApply={(start, end) => {
            setStart(start);
            setEnd(end);
            setShow(false);
          }}
        />
        <Card.Header>
          <h4 className="mb-0 w-40"> {t("Location")}</h4>

          <div className="d-flex w-60 justify-content-end">
            <input
              className={"form-control w-50"}
              disabled
              value={
                inputValue
                  ? inputValue
                  : `${convertToLocalTime(start)} - ${convertToLocalTime(end)}`
              }
            />

            <Dropdown className="mx-1 outline-secondary">
              <Dropdown.Toggle
                className="outline-secondary"
                variant="outline-secondary"
                id="dropdown-basic"
              >
                <span
                  className="me-1"
                  style={{ position: "relative", top: "-0.5px" }}
                >
                  <IoTimeOutline />
                </span>
                {t(selectedTimeRange)}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {optionsLocation.map((option) => (
                  <Dropdown.Item
                    key={option.value}
                    active={selectedTimeRange === option.text}
                    onClick={() =>
                      updateDateRangeAndFetch(option.value, option.text)
                    }
                  >
                    {t(option.text)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Card.Header>

        <Card.Body>
          <div className="text-center text-muted">{t(error)}</div>
        </Card.Body>
      </Card>
    );
  }

  const position = [
    deviceLocation?.[0]?.latitude || 0,
    deviceLocation?.[0]?.longitude || 0,
  ];
  const polylinePositions =
    deviceLocation?.map((location) => [
      location.latitude,
      location.longitude,
    ]) || [];

  return (
    <Card style={{ height: "740px" }} className="">
      <DateRangePickerModal
        show={show}
        handleClose={() => setShow(false)}
        startDate={start}
        endDate={end}
        onApply={(start, end) => {
          setStart(start);
          setEnd(end);
          setShow(false);
        }}
      />
      <Card.Header>
        <h4 className="mb-0 w-40"> {t("Location")}</h4>

        <div className="d-flex w-60 justify-content-end">
          <input
            className={"form-control w-50"}
            disabled
            value={
              inputValue
                ? inputValue
                : `${convertToLocalTime(start)} - ${convertToLocalTime(end)}`
            }
          />

          <Dropdown className="mx-1 outline-secondary">
            <Dropdown.Toggle
              className="outline-secondary"
              variant="outline-secondary"
              id="dropdown-basic"
            >
              <span
                className="me-1"
                style={{ position: "relative", top: "-0.5px" }}
              >
                <IoTimeOutline />
              </span>
              {t(selectedTimeRange)}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {optionsLocation.map((option) => (
                <Dropdown.Item
                  key={option.value}
                  active={selectedTimeRange === option.text}
                  onClick={() =>
                    updateDateRangeAndFetch(option.value, option.text)
                  }
                >
                  {t(option.text)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Header>

      <Card.Body
        className="pb-4"
        style={{ overflowY: "hidden", overflowX: "hidden" }}
      >
        {selectedDevice?.status ? (
          <span
            style={{
              position: "relative",
              zIndex: "1000",
              top: "23px",
              left: "2px",
            }}
            className={`${badgesDefault.green} w-100 floating-location`}
          >
            <span className="tw-text-green-600 me-1  my-animate-pulse15">
              <GoDotFill />
            </span>
            {address || (
              <span className="text-secondary tw-animate-pulse">
                {t("Loading...")}
              </span>
            )}
          </span>
        ) : (
          <span
            style={{
              position: "relative",
              zIndex: "1000",
              top: "23px",
              left: "2px",
            }}
            className={`${badgesDefault.red} w-100 floating-location`}
          >
            <span className="tw-text-red-600 me-1 font-semibold">
              <GoDotFill />

              {t("Last Communication:")}
            </span>
            {address || (
              <span className="text-secondary tw-animate-pulse">
                {t("Loading...")}
              </span>
            )}
          </span>
        )}
        <MapContainer
          center={position}
          zoom={12}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {deviceLocation &&
            (deviceLocation.length < 50
              ? deviceLocation.map((location, index) => (
                  <Marker
                    key={index}
                    position={[location.latitude, location.longitude]}
                  >
                    <Popup>
                      {/* Display "LAST REPORT" if this is the last location */}
                      {index === deviceLocation.length && (
                        <div className={`${badgesDefault.green} font-semibold`}>
                          <span className="tw-text-green-600 me-1  my-animate-pulse15">
                            <GoDotFill />
                          </span>
                          {/* LAST REPORT */}
                        </div>
                      )}
                      <h4 className="force-font-2 mb-2">
                        {address || (
                          <h4 className="text-secondary tw-animate-pulse">
                            Loading...
                          </h4>
                        )}
                      </h4>

                      <h6 className="force-font mb-2 font-semibold">
                        {t("Accuracy (m)")}:
                        <span className="ms-1 tw-font-normal text-secondary">
                          {location.accuracy}
                        </span>
                      </h6>

                      <div className="d-flex justify-content-between">
                        <h6 className="force-font mb-2 font-semibold">
                          Latitude:
                          <span className="ms-1 tw-font-normal text-secondary">
                            {location.latitude}
                          </span>
                        </h6>
                        <h6 className="force-font mb-2 font-semibold">
                          Longitude:
                          <span className="ms-1 tw-font-normal text-secondary">
                            {location.longitude}
                          </span>
                        </h6>

                        <h6 className="force-font mb-2 font-semibold">
                          Timestamp:
                          <span className="ms-1 tw-font-normal text-secondary">
                            {convertToLocalTime(location.timestamp)}
                          </span>
                        </h6>
                      </div>
                    </Popup>
                  </Marker>
                ))
              : [
                  // Show the first location
                  <Marker
                    key={0}
                    position={[
                      deviceLocation[0].latitude,
                      deviceLocation[0].longitude,
                    ]}
                  >
                    <Popup>
                      {/* Your popup content for the first location */}
                    </Popup>
                  </Marker>,

                  // Show the last location
                  <Marker
                    key={deviceLocation.length - 1}
                    position={[
                      deviceLocation[deviceLocation.length - 1].latitude,
                      deviceLocation[deviceLocation.length - 1].longitude,
                    ]}
                  >
                    <Popup>
                      <h4 className="force-font-2 mb-2 tw-tracking-widest">
                        {address || (
                          <h4 className="text-secondary tw-animate-pulse">
                            Loading...
                          </h4>
                        )}
                      </h4>

                      <h6 className="force-font mb-2 font-semibold">
                        {t("Accuracy (m)")}:
                        <span className="ms-1 tw-font-normal text-secondary">
                          {deviceLocation[deviceLocation.length - 1].accuracy}
                        </span>
                      </h6>

                      <div className="d-flex justify-content-between">
                        <h6 className="force-font mb-2 font-semibold">
                          Latitude:
                          <span className="ms-1 tw-font-normal text-secondary">
                            {deviceLocation[deviceLocation.length - 1].latitude}
                          </span>
                        </h6>
                        <h6 className="force-font mb-2 font-semibold">
                          Longitude:
                          <span className="ms-1 tw-font-normal text-secondary">
                            {
                              deviceLocation[deviceLocation.length - 1]
                                .longitude
                            }
                          </span>
                        </h6>

                        <h6 className="force-font mb-2 font-semibold">
                          Timestamp:
                          <span className="ms-1 tw-font-normal text-secondary">
                            {convertToLocalTime(
                              deviceLocation[deviceLocation.length - 1]
                                .timestamp
                            )}
                          </span>
                        </h6>
                      </div>
                    </Popup>
                  </Marker>,
                ])}

          {isHistoricalData && (
            <Polyline
              positions={polylinePositions}
              color="#506FD9"
              weight={3}
            />
          )}
        </MapContainer>
      </Card.Body>
    </Card>
  );
};

export default Apps;
