import React, { useState } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";

import TermsTable from "../components/Terms/TermsTable";
import SubmitModal from "../components/Terms/SubmitModal";

import DevicesTable from "../components/Terms/DevicesTable.js";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [refreshState, setRefreshState] = useState(0);
  const [selectedTerm, setSelectedTerm] = useState(null);

  document.title = `${t("DataDike MDM | Termos")}`;
  return (
    <React.Fragment>
      {showModal && (
        <SubmitModal
          show={showModal}
          submit={() => {
            setRefreshState(refreshState + 1);
          }}
          close={() => setShowModal(false)}
        />
      )}
      <div className="">
        <div className="flex align-items-center justify-content-between mb-3 ms-1">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">{t("Functionalities")}</Link>
              </li>
              <li className="d-none breadcrumb-item active" aria-current="page">
                {t("Terms Management")}
              </li>
            </ol>
            <h4 className="main-title mb-0">
              {t("Management of terms of responsibility")}
            </h4>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <button
              onClick={() => setShowModal(true)}
              className="btn btn-primary"
            >
              {t("Add term")}
            </button>
          </div>
        </div>

        <Row className="mt-3 mb-1">
          <TermsTable
            onSelect={(term) => setSelectedTerm(term)}
            refreshState={refreshState}
            setRefreshState={setRefreshState}
          />
        </Row>

        <Row className="mt-2">
          {selectedTerm && selectedTerm.deviceWithTerms > 0 ? (
            <DevicesTable
              refreshState={refreshState}
              setRefreshState={setRefreshState}
              selectedTerm={selectedTerm}
              refresh={() => setRefreshState(refreshState + 1)}
            />
          ) : null}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Terms;
