import React, { useEffect, useState } from "react";
import { Card, Col, Button } from "react-bootstrap";

import { useGet } from "../../hooks/get";
import { SysadminContext } from "../../Context/SysadminContext";
import { useContext } from "react";
import useSearch from "../../hooks/useSearch";
import { useSort } from "../../hooks/useSort";
import { FaSort } from "react-icons/fa";

import { VscLoading } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import androidpng from "../../assets/img/android.png";

const Apps = () => {
  const { t } = useTranslation();
  const { selectedDevice } = useContext(SysadminContext);
  const [data, setData] = useState([]);
  const { data: allAppsData, loading } = useGet(
    `/api/v1/sysadmin/device-apps/${selectedDevice?.deviceId}`
  );

  useEffect(() => {
    if (allAppsData && allAppsData.length > 0) {
      const sortedByNameData = allAppsData.sort((a, b) => {
        if (a.appName < b.appName) {
          return -1;
        }
        if (a.appName > b.appName) {
          return 1;
        }
        return 0;
      });

      setData(sortedByNameData);
    }
  }, [allAppsData]);

  const { sortedData, requestSort } = useSort(data);

  const { filteredData, searchTerm, setSearchTerm } = useSearch(sortedData, [
    "appName",
    "packageName",
    //  "versionName",
  ]);

  if (loading) {
    return (
      <Card style={{ height: "740px" }} className="">
        <Card.Header>
          <h5 className="mb-0 text-dark">{t("Apps")}</h5>
        </Card.Header>

        <Card.Body className="jc ac">
          <h4 className="loading-icon">
            <VscLoading />
          </h4>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card style={{ height: "740px" }} className="">
      <Card.Header>
        <h4 className="mb-0">{t("Apps")}</h4>

        <input
          type="text"
          className="form-control w-30"
          placeholder={`${t("Search...")}`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Card.Header>

      <div className="mt-2 mb-2 ">
        <div className="w-100 je px-3">
          <Button size="sm" onClick={() => requestSort("appName")}>
            <FaSort />
          </Button>
        </div>
      </div>

      <Card.Body className="pt-1">
        <div
          className="js ac"
          style={{
            flexWrap: "wrap",
          }}
        >
          {allAppsData &&
            filteredData.map((item, index) => (
              <div
                className="p-1 pb-2 app-card"
                style={{
                  width: "150px",
                  height: "180px",
                }}
                // style={{
                //   borderBottom:
                //     index === allAppsData.length - 1
                //       ? "none"
                //       : "1px solid #e4e9f0",
                //   marginBottom: "10px",
                // }}
                key={item.packageName}
              >
                <Card
                  style={{
                    height: "178px",
                  }}
                  className="tw-rounded-md tw-shadow-md no-footer app-card
                "
                >
                  <Card.Body
                    className="text-center"
                    style={{ overflow: "hidden" }}
                  >
                    <div className="w-100 jc">
                      <img
                        style={{
                          width: "95px",
                        }}
                        src={item.icon || androidpng}
                        alt="icon"
                      />
                    </div>
                  </Card.Body>
                  <Card.Footer
                    className="jc"
                    style={{
                      backgroundColor: "transparent !important",
                      border: "none !important",
                    }}
                  >
                    <h6
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        //    whiteSpace: "nowrap",
                      }}
                      className="text-dark"
                    >
                      {item.appName || ""}
                    </h6>
                  </Card.Footer>
                </Card>
                {/* 
              <div className="mb-2 text-secondary">
                {t("Package Name:")}

                <span className="text-semidark ms-1">
                  {item.packageName || ""}
                </span>
              </div>

              <div className="mb-2 text-secondary">
                {t("Version")}:
                <span className="text-semidark ms-1">
                  {item.versionName || ""}
                </span>
              </div> */}
              </div>
            ))}
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="text-secondary">
          Total:
          <span className="ms-1 text-semidark">
            {allAppsData && allAppsData.length}
          </span>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default Apps;
