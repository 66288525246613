import React, { useEffect, useState } from "react";
import { Button, Card, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { t } from "i18next";
import { useGet } from "../../hooks/get";
import useSearch from "../../hooks/useSearch";
import { useSort } from "../../hooks/useSort";
import { IoMdSend } from "react-icons/io";
import { VscLoading } from "react-icons/vsc";
import { convertToLocalTime } from "../../hooks/convertDate";
import { validateEmail } from "../../extraFunctions/validate";
import { badgesDefault } from "../../extraFunctions/badgesTw";
import { FaTrash } from "react-icons/fa";

const PendingInvites = ({ refreshState, setRefreshState }) => {
  const {
    data: invites,
    loading: loadingApiData,
    error,
  } = useGet("api/v1/users/pending-invites", refreshState);

  const [data, setData] = useState([]);
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  //  let lang = localStorage.getItem("lang") || "en";

  useEffect(() => {
    if (invites && invites.length > 0) {
      const newData = invites.map((item) => {
        const today = new Date(); // Data e hora atual
        const expiresAt = new Date(item.expiresAt); // Data de expiração

        // Conversão para o horário local
        const todayLocal = new Date(today.toLocaleString());
        const expiresAtLocal = new Date(expiresAt.toLocaleString());

        return {
          ...item,
          invitedAtFormatted: convertToLocalTime(item.invitedAt),
          expiresAtFormatted: convertToLocalTime(item.expiresAt),
          isValid: expiresAtLocal > todayLocal ? "valid" : "invalid", // Verificação ajustada para o formato de hora
          isValidPt: expiresAtLocal > todayLocal ? "válido" : "inválido",
        };
      });
      setData(newData);
    } else {
      setData([]);
    }
  }, [invites]);

  const { sortedData, requestSort } = useSort(data || []);
  const { filteredData, searchTerm, setSearchTerm } = useSearch(sortedData, [
    "email",
    "invitedBy",
    "invitedAt",
    "invitedAtFormatted",
    "expiresAtFormatted",
    "expiresAt",
    "isValid",
    "isVaidPt",
  ]);

  const handleRemove = async (id) => {
    setLoading(true);

    const response = await fetch("api/v1/invite/cancel-invite", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ inviteId: id }),
    });

    if (response.ok) {
      setTimeout(() => {
        setRefreshState(!refreshState);
      }, 3000);
    }
    setLoading(false);
  };

  const handleSubmit = async (emailInvite) => {
    setLoadingResend(true);
    if (!validateEmail(emailInvite)) {
      setFeedback({
        ok: false,
        message: t("errorsDefault.invalidEmail"),
      });
      return;
    }

    try {
      const response = await fetch("/api/v1/users/send-invite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: emailInvite }),
      });

      if (response.ok) {
        setFeedback({
          ok: true,
          message: t("usersManagement.pendingInvites.feedbackOk"),
        });
      } else {
        setFeedback({
          ok: false,
          message: t("usersManagement.pendingInvites.feedbackError"),
        });
      }
    } catch (error) {
      setFeedback({
        ok: false,
        message: t("usersManagement.pendingInvites.feedbackError"),
      });
    }
    setLoadingResend(false);
    setTimeout(() => {
      setRefreshState(!refreshState);
    }, 5000);
  };

  useEffect(() => {
    if (feedback) {
      setTimeout(() => {
        setFeedback(null);
      }, 6000);
    }
  }, [feedback]);

  if (loading || loadingApiData) {
    return (
      <Card
        style={{
          height: "500px",
        }}
        className="custom-card-1"
      >
        <Card.Body className="ac jc">
          <h4 className="loading-icon">
            <VscLoading />
          </h4>
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="custom-card-1" style={{ height: "500px" }}>
        <Card.Body>{t("errorsDefault.noData")}</Card.Body>
      </Card>
    );
  }

  return (
    <Card
      style={{
        height: "740px",
      }}
      className="custom-card-1"
    >
      <Card.Header>
        <h5 className="mb-0">{t("")}</h5>
        <div className="d-flex tw-justify-end">
          <input
            type="text"
            className="form-control"
            placeholder={t("Search...")}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </Card.Header>

      <Card.Body>
        <Table className="table-users" hover>
          <thead>
            <tr>
              <th onClick={() => requestSort("email")}>{t("Email")}</th>
              <th onClick={() => requestSort("invitedBy")}>
                {t("usersManagement.pendingInvites.invitedBy")}
              </th>
              <th onClick={() => requestSort("invitedAt")}>
                {t("usersManagement.pendingInvites.invitedAt")}
              </th>

              <th onClick={() => requestSort("expiresAt")}>
                {t("usersManagement.pendingInvites.expiresAt")}
              </th>

              <th
                className="center-text"
                onClick={() => requestSort("isValid")}
              >
                {t("usersManagement.pendingInvites.isValid")}
              </th>
              <th className="center-text">{t("Action")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((i) => (
              <tr key={i.email}>
                <td>{i.email}</td>
                <td>{i.invitedBy}</td>
                <td>{i.invitedAtFormatted}</td>
                <td>{i.expiresAtFormatted}</td>
                <td className="center-text-td">
                  {i.isValid == "valid" ? (
                    <div className={badgesDefault.green}>
                      {t("usersManagement.pendingInvites.Yes")}
                    </div>
                  ) : (
                    <div className={badgesDefault.red}>
                      {t("usersManagement.pendingInvites.No")}
                    </div>
                  )}
                </td>

                {loadingResend ? (
                  <td className="center-text-td">
                    <h4 className="my-animate-spin text-primary">
                      <VscLoading />
                    </h4>
                  </td>
                ) : (
                  <td className="center-text-td">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          {t("usersManagement.buttons.resend")}
                        </Tooltip>
                      }
                    >
                      <Button
                        onClick={() => handleSubmit(i.email)}
                        size="sm"
                        className="btn btn-outline-primary tw-tracking-wider text-uppercase"
                      >
                        <IoMdSend />
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          {t("usersManagement.buttons.delete")}
                        </Tooltip>
                      }
                    >
                      <Button
                        onClick={() => handleRemove(i.inviteId)}
                        size="sm"
                        variant="danger"
                        className="ms-1"
                      >
                        <FaTrash />
                      </Button>
                    </OverlayTrigger>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
      {feedback && (
        <Card.Footer className="je">
          {feedback && (
            <div>
              {feedback.ok ? (
                <div className="tw-bg-green-500 tw-text-white p-4 tw-rounded-md">
                  {feedback.message}
                </div>
              ) : feedback.sending ? (
                <div className="tw-bg-blue-500 tw-text-white p-4 tw-rounded-md">
                  {feedback.message}
                </div>
              ) : (
                <div className="tw-bg-red-500   tw-text-white p-4 tw-rounded-md">
                  {feedback.message}
                </div>
              )}
            </div>
          )}
        </Card.Footer>
      )}
    </Card>
  );
};

export default PendingInvites;
