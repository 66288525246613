import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import { VscLoading } from "react-icons/vsc";

const FullyModal = ({ close }) => {
  const [step, setStep] = useState(0); // Etapas do modal
  const [departments, setDepartments] = useState([]); // Lista de departamentos
  const [selectedDepartment, setSelectedDepartment] = useState(null); // Departamento selecionado
  const [qrCode, setQrCode] = useState(""); // QR code retornado
  const [enrollmentToken, setEnrollmentToken] = useState(""); // Token de inscrição

  const [loadingQrCode, setLoadingQrCode] = useState(false);
  const [loadingDepartments, setLoadingDepartments] = useState(false);

  useEffect(() => {
    // Requisição para obter departamentos ao montar o componente
    const fetchDepartments = async () => {
      setLoadingDepartments(true);
      try {
        const response = await axios.get(
          "/api/v1/dashboard/fully-managed-departments"
        );
        const options = response.data.map((department) => ({
          value: department.departmentId,
          label: department.departmentName,
        }));
        setDepartments(options);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }

      setLoadingDepartments(false);
    };

    fetchDepartments();
  }, []);

  const handleGenerateQRCode = async () => {
    setLoadingQrCode(true);
    // Enviar o departamento selecionado e gerar QRCode
    try {
      const response = await axios.post("/api/v1/google/cad-device", {
        departmentId: selectedDepartment.value,
      });
      setQrCode(response.data.qrCode);
      setEnrollmentToken(response.data.enrollmentToken);
      setStep(1); // Avançar para o próximo passo (mostrar QRCode)
    } catch (error) {
      console.error("Error generating QR Code:", error);
    }

    setLoadingQrCode(false);
  };

  if (loadingQrCode) {
    return (
      <Modal className="fixed-modal" show={true} centered>
        <Modal.Body className="ac jc">
          <h5 className="loading-icon">
            <VscLoading />
          </h5>
        </Modal.Body>
        <Modal.Footer className="je">
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal className="fixed-modal" show={true} centered>
      {step === 0 && (
        <>
          <Modal.Body className="jc ac">
            <div className="text-center">
              <h6 className="mb-2">
                Select the department you want to add the fully managed devices
                to.
              </h6>
              <Select
                options={departments}
                value={selectedDepartment}
                onChange={setSelectedDepartment}
                className="w-100"
                placeholder="Select a department"
              />
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-between align-items-center">
            <Button variant="secondary" onClick={close}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={handleGenerateQRCode}
              disabled={!selectedDepartment}
            >
              Generate QRCode
            </Button>
          </Modal.Footer>
        </>
      )}

      {step === 1 && (
        <>
          <Modal.Body className="d-flex flex-column align-items-center">
            <h6 className="mb-1 text-dark">
              Scan the QR code to provision the device
            </h6>
            {qrCode && (
              <img
                src={qrCode}
                alt="QR Code"
                style={{ width: "200px", height: "190px" }}
              />
            )}
            {enrollmentToken && (
              <p
                className="text-secondary mb-0
              "
              >
                Enrollment Token:
                <span className="text-dark ms-1">{enrollmentToken}</span>
              </p>
            )}
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-between align-items-center">
            <Button variant="secondary" onClick={close}>
              Close
            </Button>
            <div className="je ac">
              <button
                style={{
                  width: "35px",
                  height: "35px",
                }}
                className="btn-pagination me-1 btn-primary"
                onClick={() => setStep(0)}
              >
                {"<"}
              </button>
              <Button variant="primary" onClick={() => setStep(0)}>
                Finish
              </Button>
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default FullyModal;
