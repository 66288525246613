import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Button, Form, Row, Alert, Spinner } from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";
import Select from "react-select";
import { PoliciesContext } from "../../Context/PolicyContext";

const EditPolicies = ({ sayDelete }) => {
  const { t } = useTranslation();
  const { selectedPolicy, refreshState, setRefreshState } =
    useContext(PoliciesContext);

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [policyData, setPolicyData] = useState({
    general: {
      encryptionPolicy: "",
      defaultPermissionPolicy: "PROMPT",
      locationMode: "",
      stayOnPluggedModes: [],
      keyguardDisabledFeatures: [],
    },
    display: {
      maximumTimeToLock: "",
    },
    message: {
      deviceOwnerLockScreenInfo: "",
      shortSupportMessage: "",
      longSupportMessage: "",
    },
    systemUpdate: {
      type: "AUTOMATIC",
    },
    departmentId: selectedPolicy?.departmentId || "",
    departmentName: selectedPolicy?.departmentName || "",
    policyId: selectedPolicy?.policyId || "",
    policyName: selectedPolicy?.fakeName || "",
  });

  // Definição das opções para os campos de seleção
  const encryptionPolicyOptions = [
    { value: "ENCRYPTION_POLICY_UNSPECIFIED", label: t("Unspecified") },
    { value: "ENCRYPTION_POLICY_REQUIRED", label: t("Required") },
    { value: "ENCRYPTION_POLICY_UNSUPPORTED", label: t("Unsupported") },
  ];

  const defaultPermissionPolicyOptions = [
    { value: "PROMPT", label: t("Prompt") },
    { value: "GRANT", label: t("Grant") },
    { value: "DENY", label: t("Deny") },
  ];

  const locationModeOptions = [
    { value: "LOCATION_UNSPECIFIED", label: t("Unspecified") },
    { value: "LOCATION_ALWAYS_ON", label: t("Always On") },
    { value: "LOCATION_NEVER_ON", label: t("Never On") },
    { value: "LOCATION_USER_CHOICE", label: t("User Choice") },
  ];

  const stayOnPluggedModesOptions = [
    { value: "USB", label: t("USB") },
    { value: "AC", label: t("AC") },
    { value: "WIRELESS", label: t("Wi-Fi") },
    // Adicione mais opções conforme necessário
  ];

  const keyguardDisabledFeaturesOptions = [
    { value: "CAMERA", label: t("Camera") },
    { value: "NOTIFICATIONS", label: t("Notifications") },
    { value: "INSTANT_APPS", label: t("Instant Apps") },
    // Adicione mais opções conforme necessário
  ];

  const systemUpdateOptions = [
    { value: "AUTOMATIC", label: t("Automatic") },
    { value: "WINDOWED", label: t("Windowed") },
    { value: "POSTPONED", label: t("Postponed") },
    // Adicione mais opções conforme necessário
  ];

  useEffect(() => {
    let isInDev = process.env.NODE_ENV === "development";
    if (isInDev) {
      console.log("Selected Policy:", selectedPolicy);
      console.log("Policy Data:", policyData);
    }
  }, [selectedPolicy, policyData]);

  // Função para buscar os dados da política
  useEffect(() => {
    const fetchPolicy = async () => {
      try {
        const response = await fetch(
          `/api/v1/policies/view-policy/${selectedPolicy?.policyId}`
        );
        const result = await response.json();
        console.log(
          `/api/v1/policies/view-policy/${selectedPolicy?.policyId}`,
          result
        );
        if (response.ok) {
          setPolicyData({
            general: {
              encryptionPolicy: result.policyOptions.encryptionPolicy || "",
              defaultPermissionPolicy:
                result.policyOptions.defaultPermissionPolicy || "PROMPT",
              locationMode: result.policyOptions.locationMode || "",
              stayOnPluggedModes: result.policyOptions.stayOnPluggedModes || [],
              keyguardDisabledFeatures:
                result.policyOptions.keyguardDisabledFeatures || [],
            },
            display: {
              maximumTimeToLock: result.policyOptions.maximumTimeToLock || "",
            },
            message: {
              deviceOwnerLockScreenInfo:
                result.policyOptions.deviceOwnerLockScreenInfo
                  ?.defaultMessage || "",
              shortSupportMessage:
                result.policyOptions.shortSupportMessage?.defaultMessage || "",
              longSupportMessage:
                result.policyOptions.longSupportMessage?.defaultMessage || "",
            },
            systemUpdate: {
              type: result.policyOptions.systemUpdate?.type || "AUTOMATIC",
            },
            departmentId: result.departmentId || "",
            departmentName: result.departmentName || "",
            policyId: result.name || selectedPolicy?.fakeName || "",
          });
          setLoading(false);
        } else {
          console.error("Error fetching policy:", result.message);
          setError(result.message || t("Failed to fetch policy."));
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching policy:", error);
        setError(error.message || t("An unexpected error occurred."));
        setLoading(false);
      }
    };

    if (selectedPolicy?.policyId) {
      fetchPolicy();
    } else {
      setLoading(false);
      setError(t("No policy selected."));
    }
    //debug
    //  console.log("policyData:", policyData);
  }, [selectedPolicy?.policyId, t]);

  // Função para lidar com mudanças nos campos de formulário
  const handleInputChange = (category, key, value) => {
    setPolicyData((prevData) => ({
      ...prevData,
      [category]: {
        ...prevData[category],
        [key]: value,
      },
    }));
  };

  // Função para lidar com mudanças nos campos de seleção múltipla
  const handleMultiSelectChange = (category, key, selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setPolicyData((prevData) => ({
      ...prevData,
      [category]: {
        ...prevData[category],
        [key]: values,
      },
    }));
  };

  // Função para salvar os dados editados
  const handleSave = async () => {
    setSaving(true);
    setError(null);
    setSuccess(null);

    // Construir o payload conforme o exemplo fornecido
    const payload = {
      policyId: policyData.policyId,
      policyOptions: {
        encryptionPolicy: policyData.general.encryptionPolicy,
        defaultPermissionPolicy: policyData.general.defaultPermissionPolicy,
        locationMode: policyData.general.locationMode,
        stayOnPluggedModes: policyData.general.stayOnPluggedModes,
        keyguardDisabledFeatures: policyData.general.keyguardDisabledFeatures,
        maximumTimeToLock: Number(policyData.display.maximumTimeToLock),
        deviceOwnerLockScreenInfo: {
          defaultMessage: policyData.message.deviceOwnerLockScreenInfo,
        },
        shortSupportMessage: {
          defaultMessage: policyData.message.shortSupportMessage,
        },
        longSupportMessage: {
          defaultMessage: policyData.message.longSupportMessage,
        },
        systemUpdate: {
          type: policyData.systemUpdate.type,
        },
      },
      departmentId: policyData.departmentId,
      departmentName: policyData.departmentName,
    };

    try {
      const response = await fetch("/api/v1/google/edit-policy", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // Adicione outros headers se necessário, como autenticação
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      if (response.ok) {
        console.log("Política atualizada com sucesso:", result);
        setSuccess(t("Policy updated successfully."));
        setRefreshState(!refreshState); // Atualiza o estado global, se necessário
      } else {
        console.error("Erro ao atualizar a política:", result.message);
        setError(result.message || t("Failed to update policy."));
      }
    } catch (error) {
      console.error("Erro na requisição:", error);
      setError(error.message || t("An unexpected error occurred."));
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="w-100 p-3 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">{t("Loading...")}</span>
        </Spinner>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Card className="custom-card-1" style={{ height: "auto" }}>
        <Form>
          <Card.Body>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}

            {/* Seção Geral */}
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="encryptionPolicy">
                  <Form.Label>{t("Encryption Policy")}</Form.Label>
                  <Form.Control
                    as="select"
                    value={policyData.general.encryptionPolicy}
                    onChange={(e) =>
                      handleInputChange(
                        "general",
                        "encryptionPolicy",
                        e.target.value
                      )
                    }
                  >
                    {encryptionPolicyOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group
                  className="mb-3"
                  controlId="defaultPermissionPolicy"
                >
                  <Form.Label>{t("Default Permission Policy")}</Form.Label>
                  <Form.Control
                    as="select"
                    value={policyData.general.defaultPermissionPolicy}
                    onChange={(e) =>
                      handleInputChange(
                        "general",
                        "defaultPermissionPolicy",
                        e.target.value
                      )
                    }
                  >
                    {defaultPermissionPolicyOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="locationMode">
                  <Form.Label>{t("Location Mode")}</Form.Label>
                  <Form.Control
                    as="select"
                    value={policyData.general.locationMode}
                    onChange={(e) =>
                      handleInputChange(
                        "general",
                        "locationMode",
                        e.target.value
                      )
                    }
                  >
                    {locationModeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group className="mb-3" controlId="stayOnPluggedModes">
                  <Form.Label>{t("Stay On Plugged Modes")}</Form.Label>
                  <Select
                    isMulti
                    options={stayOnPluggedModesOptions}
                    value={stayOnPluggedModesOptions.filter((option) =>
                      policyData.general.stayOnPluggedModes.includes(
                        option.value
                      )
                    )}
                    onChange={(selectedOptions) =>
                      handleMultiSelectChange(
                        "general",
                        "stayOnPluggedModes",
                        selectedOptions
                      )
                    }
                    placeholder={t("Select modes...")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group
                  className="mb-3"
                  controlId="keyguardDisabledFeatures"
                >
                  <Form.Label>{t("Keyguard Disabled Features")}</Form.Label>
                  <Select
                    isMulti
                    options={keyguardDisabledFeaturesOptions}
                    value={keyguardDisabledFeaturesOptions.filter((option) =>
                      policyData.general.keyguardDisabledFeatures.includes(
                        option.value
                      )
                    )}
                    onChange={(selectedOptions) =>
                      handleMultiSelectChange(
                        "general",
                        "keyguardDisabledFeatures",
                        selectedOptions
                      )
                    }
                    placeholder={t("Select features...")}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Seção Display */}
            <h5 className="mb-3">{t("Display Settings")}</h5>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="maximumTimeToLock">
                  <Form.Label>{t("Maximum Time To Lock (ms)")}</Form.Label>
                  <Form.Control
                    type="number"
                    value={policyData.display.maximumTimeToLock}
                    onChange={(e) =>
                      handleInputChange(
                        "display",
                        "maximumTimeToLock",
                        e.target.value
                      )
                    }
                    placeholder={t(
                      "Enter maximum time to lock in milliseconds"
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Seção Message */}
            <h5 className="mb-3">{t("Message Settings")}</h5>
            <Row>
              <Col sm={6}>
                <Form.Group
                  className="mb-3"
                  controlId="deviceOwnerLockScreenInfo"
                >
                  <Form.Label>{t("Device Owner Lock Screen Info")}</Form.Label>
                  <Form.Control
                    type="text"
                    value={policyData.message.deviceOwnerLockScreenInfo}
                    onChange={(e) =>
                      handleInputChange(
                        "message",
                        "deviceOwnerLockScreenInfo",
                        e.target.value
                      )
                    }
                    placeholder={t("Enter lock screen info")}
                  />
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group className="mb-3" controlId="shortSupportMessage">
                  <Form.Label>{t("Short Support Message")}</Form.Label>
                  <Form.Control
                    type="text"
                    value={policyData.message.shortSupportMessage}
                    onChange={(e) =>
                      handleInputChange(
                        "message",
                        "shortSupportMessage",
                        e.target.value
                      )
                    }
                    placeholder={t("Enter short support message")}
                  />
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group className="mb-3" controlId="longSupportMessage">
                  <Form.Label>{t("Long Support Message")}</Form.Label>
                  <Form.Control
                    type="text"
                    value={policyData.message.longSupportMessage}
                    onChange={(e) =>
                      handleInputChange(
                        "message",
                        "longSupportMessage",
                        e.target.value
                      )
                    }
                    placeholder={t("Enter long support message")}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Seção System Update */}
            <h5 className="mb-3">{t("System Update Settings")}</h5>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="systemUpdateType">
                  <Form.Label>{t("System Update Type")}</Form.Label>
                  <Form.Control
                    as="select"
                    value={policyData.systemUpdate.type}
                    onChange={(e) =>
                      handleInputChange("systemUpdate", "type", e.target.value)
                    }
                  >
                    {systemUpdateOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>

          <Card.Footer className="d-flex justify-content-end">
            <Button onClick={handleSave} variant="primary" disabled={saving}>
              {saving ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {t("Saving...")}
                </>
              ) : (
                t("Save")
              )}
            </Button>
          </Card.Footer>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default EditPolicies;
