import React, { createContext, useState } from "react";

export const PoliciesContext = createContext();

export const PoliciesProvider = ({ children }) => {
  const [choose, setChoose] = useState(0);
  const [refreshState, setRefreshState] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [creating, setCreating] = useState(false);
  const [details, setDetails] = useState(false);
  const [primeiraVez, setPrimeiraVez] = useState({
    home: true,
    details: false,
    create: false,
  });
  const [home, setHome] = useState(true);
  const [selectedApps, setSelectedApps] = useState([]);

  return (
    <PoliciesContext.Provider
      value={{
        choose,
        setChoose,
        refreshState,
        setRefreshState,
        selectedPolicy,
        setSelectedPolicy,
        creating,
        setCreating,
        details,
        setDetails,
        primeiraVez,
        setPrimeiraVez,
        home,
        setHome,
        selectedApps,
        setSelectedApps,
      }}
    >
      {children}
    </PoliciesContext.Provider>
  );
};
