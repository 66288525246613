import { useTranslation } from "react-i18next";
import { AuthContext } from "../Context/AuthContext";
import { useContext } from "react";

const useMenus = () => {
  const { t } = useTranslation();
  const isInDevelopment = process.env.NODE_ENV === "development";
  const { googleEnterprise } = useContext(AuthContext);

  const dashboardMenu = [
    {
      label: `${t("Devices")}`,
      key: "dashboard",
      imgSrc: "../assets/sidebarIcons/dashboard.png",
      link: "/",
      icon: "ri-bar-chart-2-line",
    },
    { label: "Sysadmin", key: "sysadmin", link: "/sysadmin" },
    googleEnterprise == "tem"
      ? { label: "Policies", key: "policies", link: "/policies" }
      : { label: "Policies", key: "info", link: "/" },
    {
      label: "Geofence",
      key: "geofence",
      link: "/geofence",
    },
  ];

  const applicationsMenu = [
    {
      label: t("Users"),
      key: "users",
      link: "/users",
    },
    {
      label: t("Settings"),
      key: "settings",
      link: "/settings",
    },
  ];

  const pagesMenu = [
    { label: t("Departments"), key: "departaments", link: "/departments" },
    {
      label: t("Invoices"),
      key: "invoices",
      link: "/invoices",
    },
    {
      label: t("Terms"),
      key: "terms",
      link: "/terms",
    },
    {
      label: t("Employees"),
      key: "funcionario",
      link: "/employees",
    },
    {
      label: t("Lines"),
      key: "lines",
      link: "/lines",
    },
    {
      label: t("Tags"),
      key: "tags",
      link: "/tags",
    },
  ];

  const uiElementsMenu = [];

  const panelElementsMenu = [
    isInDevelopment
      ? {
          label: t("Update MDM (DEV)"),
          key: "update",
          link: "/update-mdm",
        }
      : null,
  ];

  return {
    dashboardMenu,
    applicationsMenu,
    pagesMenu,
    uiElementsMenu,
    panelElementsMenu,
  };
};

export default useMenus;
