import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

const PdfModal = ({ show, onHide, id }) => {
  const [pdfUrl, setPdfUrl] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await fetch(
          `/api/v1/invoices/download-invoice/${id}`,
          {
            credentials: "include",
          }
        );
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
      } catch (error) {
        console.error("Error fetching PDF:", error);
      }
    };

    // Certificar que o PDF é carregado somente quando o modal é mostrado e o id é válido
    if (show && id) {
      fetchPdf();
    }

    // Função para limpar o URL do PDF
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
        setPdfUrl(""); // Limpa a URL quando o modal é fechado ou o ID muda
      }
    };
  }, [show, id]);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("PDF Viewer")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {pdfUrl ? (
          <iframe
            src={pdfUrl}
            width="100%"
            height="500px"
            style={{ border: "none" }}
          ></iframe>
        ) : (
          <p>{t("Loading PDF...")}</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PdfModal;
