import React, { useEffect, useState } from "react";

import { useGet } from "../../../hooks/get";
import { VscLoading } from "react-icons/vsc";
import { t } from "i18next";

const Step3 = () => {
  const pin = "346505";

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/v1/enterprise-id", {
          credentials: "include",
        });
        const data = await response.json();

        if (data && data.enterpriseId) {
          setData(data);
          console.log(data);
        }

        if (response.status === 401 || response.status === 403) {
          // LogOut(); // Uncomment or handle logout if necessary
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  console.log(data);
  if (loading) {
    return (
      <div className="">
        <div className="mw-100 mh-100">
          <h4 className="loading-icon">
            <VscLoading />
          </h4>
        </div>
      </div>
    );
  }
  if (data) {
    return (
      <div className="w-100">
        <h6 className="mb-1 text-secondary text-center">
          {t("The app will ask for a Enterprise ID.")}
        </h6>

        <h5 className="mb-3 text-center text-dark">
          {t("You need to input it")}:
        </h5>

        <div className="p-3 d-flex justify-content-center align-items-center tw-bg-gray-200 tw-rounded-md">
          <h3
            style={{
              letterSpacing: "1rem",
            }}
            className="mb-0 text-center font-semibold "
          >
            {data ? data.enterpriseId : ""}
          </h3>
        </div>
      </div>
    );
  }
};

export default Step3;
