import { t } from "i18next";
import React, { useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
// import "./datepicker.css";

const DateRangePickerModal = ({
  show,
  handleClose,
  startDate: initialStartDate,
  endDate: initialEndDate,
  onApply,
}) => {
  // Estados individuais para as datas de início e término
  const [startDate, setStartDate] = useState(initialStartDate || new Date());
  const [endDate, setEndDate] = useState(initialEndDate || new Date());

  const handleApply = () => {
    onApply(startDate, endDate); // Envia as datas selecionadas
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("Select Date Range")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3 p-2">
          <Col xs={12} md={6} className="mb-3 mb-md-0">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              startDate={startDate}
              endDate={endDate}
              dateFormat="MMMM d, yyyy h:mm aa"
              showTimeSelect
              timeFormat="HH:mm"
              className="form-control"
            />
          </Col>
          <Col xs={12} md={6}>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="MMMM d, yyyy h:mm aa"
              showTimeSelect
              timeFormat="HH:mm"
              className="form-control"
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("Close")}
        </Button>
        <Button variant="primary" onClick={handleApply}>
          {t("Apply")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DateRangePickerModal;
