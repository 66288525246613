import React, { useState } from "react";

const JiraIssueForm = () => {
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState(null);
  const submitIssue = async () => {
    try {
      const response = await fetch("http://localhost:4444/api/submitIssue", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",

        body: JSON.stringify({ description, title }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage("Issue created successfully!");
        setDescription("");
        setTitle("");

        setTimeout(() => {
          setMessage(null);
        }, 5000);
      } else {
        setMessage("Error to create issue");
        setDescription("");
        setTimeout(() => {
          setMessage(null);
        }, 5000);
      }
    } catch (error) {
      setMessage("Erro ao conectar com o servidor.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitIssue();
  };

  return (
    <div className="w-70">
      <form onSubmit={handleSubmit}>
        <div className="mb-2">
          <input
            className="form-control"
            onChange={(e) => setTitle(e.target.value)}
            required
            value={title}
            placeholder="Summary"
          />
        </div>
        <div className="mb-2">
          <textarea
            value={description}
            className="form-control"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Describe your issue"
            required
          />
        </div>
        <div className="w-100 jc">
          <button className="btn btn-primary" type="submit">
            Enviar
          </button>
        </div>
      </form>
      {message && <p className="w-100 jc mt-2">{message}</p>}
    </div>
  );
};

export default JiraIssueForm;
