import React, { useState } from "react";
import { FaDownload, FaEye } from "react-icons/fa";
import { Dropdown, Modal, Button } from "react-bootstrap";
import { IoIosMore } from "react-icons/io";
import { useTranslation } from "react-i18next";

const DownloadButton = ({ url, fileName }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const handleDownload = async () => {
    const response = await fetch(`${url}`, {
      method: "GET",
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName || "datadike";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } else {
      console.error("Failed to download");
    }
  };

  const handleSee = async () => {
    const response = await fetch(`${url}`, {
      method: "GET",
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      setPdfUrl(url);
      setShow(true);
    } else {
      console.error("Failed to load PDF");
    }
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          variant="light"
          className="m-0 border tw-border-gray-500"
        >
          <IoIosMore />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={handleSee}>
            <span className="text-primary me-2">
              <FaEye />
            </span>
            {t("See")}
          </Dropdown.Item>
          <Dropdown.Item onClick={handleDownload}>
            <span className="text-warning me-2">
              <FaDownload />
            </span>
            {t("Download")}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Modal centered show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t("PDF Viewer")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {pdfUrl && (
            <iframe
              src={pdfUrl}
              width="100%"
              height="500px"
              title={t("PDF Viewer")}
            ></iframe>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            {t("Close")}
          </Button>
          <Button variant="primary" onClick={() => handleDownload()}>
            {t("Download")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DownloadButton;
