import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { HiPlus } from "react-icons/hi";
import PoliciesTable from "../components/Policies/PoliciesTable.js";
import CreatePolicie from "../components/Policies/Create.js";
import Edit from "../components/Policies/Edit.js";
import { PoliciesContext } from "../Context/PolicyContext";
import { AuthContext } from "../Context/AuthContext.js"; // Import the context
import { VscLoading } from "react-icons/vsc";

const Policies = () => {
  const { t } = useTranslation();
  const { googleEnterprise } = useContext(AuthContext);
  const {
    choose,
    setChoose,
    refreshState,
    setRefreshState,
    selectedPolicy,
    setSelectedPolicy,
    creating,
    setCreating,
    details,
    setDetails,
    primeiraVez,
    setPrimeiraVez,
    home,
    setHome,
  } = useContext(PoliciesContext); // Use the context

  document.title = `${t("DataDike MDM | Policies")}`;

  const handleCreatePolicy = () => {
    setCreating(true);
    setHome(false);
    setDetails(false);
    setPrimeiraVez((prevState) => ({
      ...prevState,
      create: true,
    }));
  };
  console.log("googleEnterprise", googleEnterprise);

  const isInDevelopment = process.env.NODE_ENV === "development";

  if (!googleEnterprise || googleEnterprise == null) {
    return (
      <div className="mw-100 mh-100">
        <h5 className="loading-icon">
          <VscLoading />
        </h5>
      </div>
    );
  }

  if (googleEnterprise == "naotem") {
    return (
      <div className="jc ac">
        <h4 className="text-dark mb-1">{t("errorsDefault.noGoogle")}</h4>

        <div>
          <h6
            onClick={() => {
              localStorage.setItem("goEvLi", true);
            }}
            className="text-dark tw-cursor-pointer"
          >
            {t("errorsDefault.noGoogle2")}
          </h6>
        </div>
      </div>
    );
  }

  if (googleEnterprise == "tem") {
    return (
      <React.Fragment>
        <div className="">
          <div className="flex align-items-center justify-content-between mb-3 ms-1">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="#">{t("Cockpit")}</Link>
                </li>
                <li
                  className="d-none breadcrumb-item active"
                  aria-current="page"
                >
                  {t("Policies")}
                </li>
              </ol>
              <h4 className="main-title mb-0">{t("Policies")}</h4>
            </div>
            <button
              onClick={handleCreatePolicy}
              className="btn btn-primary tw-tracking-wider"
            >
              <span className=" me-1 icone-torto">
                <HiPlus />
              </span>
              {t("CREATE POLICY")}
            </button>
          </div>

          <div>
            <Nav className="nav-line mb-4 text-dark tw-font-normal">
              <Nav.Link
                href="#"
                className={home ? "activeNav tw-font-semibold" : "inactiveNav"}
                onClick={(e) => {
                  e.preventDefault();
                  setHome(true);
                  setDetails(false);
                  setCreating(false);
                }}
              >
                {t("Policies")}
              </Nav.Link>
              {primeiraVez.details && (
                <Nav.Link
                  href="#"
                  className={
                    details ? "activeNav tw-font-semibold" : "inactiveNav"
                  }
                  onClick={() => {
                    setHome(false);
                    setDetails(true);
                    setCreating(false);
                  }}
                >
                  {selectedPolicy?.fakeName}
                </Nav.Link>
              )}
              {primeiraVez.create && (
                <Nav.Link
                  href="#"
                  className={
                    creating ? "activeNav tw-font-semibold" : "inactiveNav"
                  }
                  onClick={() => {
                    setCreating(true);
                    setHome(false);
                    setDetails(false);
                  }}
                >
                  {t("Create Policy")}
                </Nav.Link>
              )}
            </Nav>
          </div>

          <div className={`${home ? "" : "d-none"}`}>
            <PoliciesTable
              selectedPolicy={selectedPolicy}
              setSelectedPolicy={setSelectedPolicy}
              setChoose={setChoose}
              setHome={setHome}
              setDetails={setDetails}
              setCreating={setCreating}
              setPrimeiraVez={setPrimeiraVez}
              setRefreshState={setRefreshState}
              refreshState={refreshState}
            />
          </div>

          <div className={`${details && !home && !creating ? "" : "d-none"}`}>
            {selectedPolicy && (
              <Edit
                sayDelete={() => {
                  setHome(true);
                  setCreating(false);
                  setDetails(false);
                  setPrimeiraVez((prevState) => ({
                    ...prevState,
                    details: false,
                  }));
                }}
              />
            )}
          </div>

          <div className={`${!details && !home && creating ? "" : "d-none"}`}>
            <CreatePolicie />
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default Policies;
