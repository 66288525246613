import React, { useState, useEffect } from "react";
import { useGet } from "../../hooks/get";
import { Table, Card, Dropdown } from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";

import { FaCheck, FaDownload, FaPen, FaEye } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

import { BsFiletypeXml } from "react-icons/bs";
import { FaRegFilePdf, FaTrash } from "react-icons/fa6";
import { IoDocument } from "react-icons/io5";
import { IoIosMore } from "react-icons/io";
import { HiTrash } from "react-icons/hi";
import PdfModal from "./PdfModal";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const InvoiceTable = ({
  refreshState,
  selectedInvoice,
  setSelectedInvoice,
}) => {
  const { t } = useTranslation();
  const { data, loading, error } = useGet(
    "/api/v1/invoices/view-invoices",
    refreshState
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [editDescription, setEditDescription] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [selectedPdfId, setSelectedPdfId] = useState(null);

  useEffect(() => {
    if (data && data.length > 0) {
      let filtered = data;

      if (searchTerm) {
        filtered = data.filter((invoice) => {
          return (
            (invoice.filename &&
              invoice.filename
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            (invoice.description &&
              invoice.description
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            (invoice.contentType &&
              invoice.contentType
                .toLowerCase()
                .includes(searchTerm.toLowerCase()))
          );
        });
      }

      if (sortConfig.key) {
        filtered.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }

      setFilteredData(filtered);
      setSelectedInvoice(filtered[0]);
    } else {
      setFilteredData([]);
    }
  }, [data, searchTerm, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleDownload = async (id, type, name) => {
    const fileType = type === "xml" ? "text/xml" : "application/pdf";
    const fileName =
      type === "xml" ? `invoice-${id}.xml` : `invoice-${name}.pdf`;

    const response = await fetch(`/api/v1/invoices/download-invoice/${id}`, {
      method: "GET",
      headers: {
        Accept: fileType,
      },
    });

    if (response.ok) {
      const blob = await response.blob(); // Convert the response to a blob
      const url = window.URL.createObjectURL(blob); // Create a URL for the blob
      const a = document.createElement("a"); // Create an <a> element
      a.href = url; // Set the href of the <a> element to the blob URL
      a.download = fileName; // Set the download attribute to the desired file name
      document.body.appendChild(a); // Append the <a> element to the body
      a.click(); // Programmatically click the <a> element to trigger the download
      window.URL.revokeObjectURL(url); // Clean up the blob URL
      a.remove(); // Remove the <a> element from the body
    } else {
      console.error("Failed to download invoice");
    }
  };

  const handleDelete = async (id) => {
    const response = await fetch(`/api/v1/invoices/delete-invoice/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        invoiceId: id,
      }),
    });

    if (response.ok) {
      const updatedInvoices = filteredData.filter(
        (invoice) => invoice._id !== id
      );
      setFilteredData(updatedInvoices);
    }
  };

  const editValueSubmit = async (id, name) => {
    setEditDescription(false);

    const response = await fetch("/api/v1/invoices/update-invoice", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newDescription: editValue ? editValue : "",
        invoiceId: id,
        oldDescription: name,
      }),
    });

    if (response.ok) {
      const updatedData = await response.json();

      const updatedInvoices = filteredData.map((invoice) => {
        if (invoice._id === id) {
          return {
            ...invoice,
            description: editValue,
          };
        }
        return invoice;
      });

      setFilteredData(updatedInvoices);
    }
  };

  const handleShowPdf = (id) => {
    setSelectedPdfId(id);
    setShowPdfModal(true);
  };

  if (loading) {
    return (
      <Card className="custom-card-1">
        <Card.Header></Card.Header>
        <Card.Body className="mh-100 mw-100 d-flex tw-justify-center">
          <h1 className="loading-icon">
            <VscLoading />
          </h1>
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="custom-card-1">
        <Card.Body className="mh-100 mw-100 d-flex tw-justify-center">
          <h4 className="mb-0 text-center text-dark">
            {t("Error fetching invoices")}
          </h4>
        </Card.Body>
      </Card>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Card className="custom-card-1">
        <Card.Body>
          <h6 className="mb-0 text-center text-dark">
            {t("No invoices found")}
          </h6>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card
      style={{
        minHeight: "300px",
        maxHeight: "500px",
      }}
      className="custom-card-1"
    >
      <Card.Header>
        <h4 className="mb-0"></h4>

        <div className="d-flex tw-justify-end">
          <input
            type="text"
            className="form-control w-50 me-1"
            placeholder={`${"Search..."}`}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </Card.Header>

      <Card.Body>
        <Table hover className="table-users">
          <thead>
            <tr>
              <th className="center-text">#</th>
              <th onClick={() => requestSort("filename")}>
                {t("Invoice Name")}
              </th>
              <th onClick={() => requestSort("description")}>
                {t("Description")}
              </th>
              <th
                className="center-text"
                onClick={() => requestSort("deviceWithInvoice")}
              >
                {t("Attached To")}
              </th>
              <th
                className="center-text"
                onClick={() => requestSort("contentType")}
              >
                {t("Invoice Format")}
              </th>
              <th onClick={() => requestSort("uploadedBy")}>
                {t("Uploaded By")}
              </th>
              <th onClick={() => requestSort("uploadDate")}>
                {t("Upload Date")}
              </th>
              <th className="center-text">{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((invoice, index) => (
              <tr
                className={`${
                  selectedInvoice && selectedInvoice._id === invoice._id
                    ? "table-light"
                    : ""
                }`}
                onClick={() => setSelectedInvoice(invoice)}
                key={invoice._id}
              >
                <td className="center-text-td">
                  <small className="text-secondary">
                    <small>{index + 1}</small>
                  </small>
                </td>
                <td>
                  <span className="me-2 tw-text-blue-400">
                    <IoDocument
                      style={{
                        position: "relative",
                        top: "-1.5px",
                      }}
                    />
                  </span>

                  {invoice.filename}
                </td>
                <td>
                  {editDescription === invoice._id ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-danger me-2"
                        onClick={() => {
                          setEditDescription(false);
                          setEditValue("");
                        }}
                      >
                        <MdCancel />
                      </button>
                      <input
                        type="text"
                        value={editValue}
                        className="form-control me-2 w-50"
                        onChange={(e) => setEditValue(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            editValueSubmit(invoice._id, invoice.description);
                          }
                        }}
                      />

                      <button
                        className="ms-1 btn btn-success"
                        onClick={() => editValueSubmit(invoice._id)}
                      >
                        <FaCheck />
                      </button>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between">
                      <span>{invoice.description}</span>

                      <span
                        className="ms-2 cursor-pointer tw-text-gray-600"
                        onClick={() => {
                          setEditDescription(invoice._id);
                          setEditValue(invoice.description);
                        }}
                      >
                        <FaPen />
                      </span>
                    </div>
                  )}
                </td>

                <td
                  className="center-text-td"
                  style={{
                    minWidth: "150px",
                  }}
                >
                  {invoice.deviceWithInvoice}
                </td>
                <td className="center-text-td">
                  <div className="d-flex tw-justify-center">
                    {invoice.contentType === "xml" ? (
                      <span className="tw-text-green-400 me-2">
                        <BsFiletypeXml
                          style={{
                            position: "relative",
                            top: "-2px",
                            fontSize: "16px",
                          }}
                        />
                      </span>
                    ) : (
                      <span className="tw-text-red-400 me-2">
                        <FaRegFilePdf
                          style={{
                            position: "relative",
                            top: "-2px",
                            fontSize: "16px",
                          }}
                        />
                      </span>
                    )}
                    {/* {invoice.contentType} */}
                  </div>
                </td>
                <td>{invoice.uploadedBy}</td>
                <td>{new Date(invoice.uploadDate).toLocaleDateString()}</td>
                <td className="d-flex ac jc">
                  <button
                    onClick={() => handleShowPdf(invoice._id)}
                    className="btn btn-info me-1"
                  >
                    <FaEye
                      style={{
                        position: "relative",
                        top: "-2px",
                      }}
                    />
                  </button>
                  <button
                    onClick={() =>
                      handleDownload(
                        invoice._id,
                        invoice.contentType,
                        invoice.filename
                      )
                    }
                    className="btn btn-primary me-1"
                  >
                    <FaDownload
                      style={{
                        position: "relative",
                        top: "-2px",
                      }}
                    />
                  </button>

                  {invoice.deviceWithInvoice > 0 ? (
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id={`tooltip-left`}>
                          {t(
                            "To delete a invoice, you must delete all devices from it first."
                          )}
                        </Tooltip>
                      }
                    >
                      <div>
                        <button disabled className="btn btn-danger">
                          <FaTrash />
                        </button>
                      </div>
                    </OverlayTrigger>
                  ) : (
                    <button
                      onClick={() => {
                        if (invoice.deviceWithInvoice > 0) {
                          return;
                        }
                        handleDelete(invoice._id);
                      }}
                      className="btn btn-danger"
                    >
                      <FaTrash />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
      <PdfModal
        show={showPdfModal}
        onHide={() => setShowPdfModal(false)}
        id={selectedPdfId}
      />
    </Card>
  );
};

export default InvoiceTable;
