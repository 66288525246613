import React, { useState, useEffect } from "react";
import { EnterpriseProvider } from "../../Context/EnterpriseContext.js";
import WelcomeMessage from "./WelcomeMessage.js";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

import { Row, Col, Card } from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";

const Form = () => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  let times = [155, 150, 300, 250, 100];

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [step]);

  return (
    <EnterpriseProvider>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Card
          className="card-one"
          style={{
            height: "435px",
            width: "420px",
          }}
        >
          {step === 3 ? (
            <Card.Header className="tw-bg-white tw-border-bottom tw-border-gray-400">
              <h4 className="">Review your info</h4>
            </Card.Header>
          ) : step == 1 || step == 2 ? (
            <Card.Header className="tw-bg-white tw-border-bottom tw-border-gray-400">
              <h4 className="mb-0">Create a enterprise</h4>
            </Card.Header>
          ) : (
            <Card.Header className="tw-bg-white tw-border-bottom tw-border-gray-400">
              <h4 className="logo-mdm mb-0">DataDike</h4>
            </Card.Header>
          )}
          <Card.Body className="d-flex align-items-center justify-content-center">
            {step === 0 && <WelcomeMessage next={() => setStep(1)} />}
            {step === 1 && (
              <Step1 next={() => setStep(2)} prev={() => setStep(0)} />
            )}
            {step === 2 && (
              <Step2 next={() => setStep(3)} prev={() => setStep(1)} />
            )}
            {step === 3 && <Step3 next={() => setStep(4)} />}
          </Card.Body>
          {step !== 0 && (
            <Card.Footer className="d-flex tw-justify-center tw-border-top tw-border-gray-300 tw-bg-white">
              <button
                className="me-1 tw-rounded-full d-flex justify-content-center align-items-center tw-bg-gray-400 tw-text-white tw-border-none tw-cursor-pointer"
                disabled={step === 0}
                style={{
                  width: "35px",
                  height: "35px",
                }}
                onClick={() => {
                  if (step > 0) {
                    setStep(step - 1);
                  } else {
                    return;
                  }
                }}
              >
                {"<"}
              </button>
              <button
                style={{
                  width: "35px",
                  height: "35px",
                }}
                className="ms-1 rounded-btt"
                disabled={step === 3}
                onClick={() => {
                  if (step < 3) {
                    setStep(step + 1);
                  } else {
                    return;
                  }
                }}
              >
                {">"}
              </button>
            </Card.Footer>
          )}
        </Card>
      </div>
    </EnterpriseProvider>
  );
};

export default Form;
