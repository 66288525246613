import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { VscLoading } from "react-icons/vsc";

const CreatedMessage = ({ enterprise }) => {
  const [seconds, setSeconds] = useState(5); // start from 10 seconds

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
      return () => clearTimeout(timerId); // cleanup on component unmount
    } else {
      // redirect to signin page
      window.location.href = "/signin";
    }
  }, [seconds]);

  return (
    <Modal show={true} centered>
      <Modal.Body className="text-center">
        <h4>
          Your enterprise
          <span className="ms-1 font-semibold  me-1">
            {enterprise.enterpriseName}
          </span>
          was created successfully.
        </h4>
        <h6 className="mt-3 mb-0 text-secondary tw-animate-pulse">
          You will be redirected to signin page in {seconds} seconds.
        </h6>

        <div className="mw-100 mh-100">
          <h4 className="tw-animate-spin text-primary">
            <VscLoading />
          </h4>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreatedMessage;
