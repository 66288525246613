import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form, InputGroup, Row, Col } from "react-bootstrap";
import {
  GoogleMap,
  Marker,
  Circle,
  useLoadScript,
} from "@react-google-maps/api";
import { t } from "i18next";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useGet } from "../../hooks/get";
import { GeofenceContext } from "../../Context/GeofenceContext";

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const initialCenter = {
  lat: -23.50157,
  lng: -46.14938,
};

const libraries = ["places"];

const CreateGeofence = ({ close, apiKey, show }) => {
  const { devices } = useContext(GeofenceContext);
  const preferedRadius = localStorage.getItem("preRadGeo");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      geofenceName: "Geofence Mogi",
    },
  });

  const [selectedPosition, setSelectedPosition] = useState(initialCenter);
  const [radius, setRadius] = useState(preferedRadius || 1000);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [radiusInputType, setRadiusInputType] = useState("range");

  const onMapClick = (event) => {
    const newPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setSelectedPosition(newPosition);
  };

  const onSubmit = async (data) => {
    const payload = {
      deviceIds: data.deviceIds.map((device) => device.value),
      latitude: selectedPosition.lat,
      longitude: selectedPosition.lng,
      radius: radius,
      geofenceName: data.geofenceName,
      departmentId: selectedDepartment?.value,
      users: selectedUsers.map((user) => user.value),
    };
    console.log("Geofence Data:", payload);
    localStorage.setItem("preRadGeo", radius);
    close();
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const { data: users } = useGet("api/v1/notifications/users");

  const deviceOptions =
    devices?.map((device) => ({
      value: device.deviceId,
      label: device.name,
    })) || [];

  const usersOptions =
    users?.map((user) => ({
      value: user._id,
      label: user.name,
    })) || [];

  const departmentOptions = [];
  devices?.forEach((device) => {
    if (device.deviceDepartments) {
      device.deviceDepartments.forEach((department) => {
        if (
          !departmentOptions.some(
            (opt) => opt.value === department.departmentId
          )
        ) {
          departmentOptions.push({
            value: department.departmentId,
            label: department.departmentName,
          });
        }
      });
    }
  });


  if (loadError) return <div>Erro ao carregar o mapa</div>;
  if (!isLoaded && show === true) {
    return (
      <Modal centered show={true} onHide={close}>
        <Modal.Body className="jc ac">
          <h4>{t("createGeofence.Loading...")}</h4>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal size="lg" show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{t("createGeofence.createGeofence")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="ac">
            <Col className="mb-2" xl={6}>
              <Form.Group controlId="geofenceName">
                <Form.Label>{t("createGeofence.geofenceName")}</Form.Label>
                <Form.Control
                  type="text"
                  {...register("geofenceName", { required: true })}
                  placeholder={t("createGeofence.enterGeofenceName")}
                  isInvalid={errors.geofenceName}
                />
                {errors.geofenceName && (
                  <Form.Control.Feedback type="invalid">
                    {t("createGeofence.geofenceNameRequired")}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col className="mb-2" xl={6}>
              <Form.Group controlId="deviceIds">
                <Form.Label>{t("createGeofence.selectDevices")}</Form.Label>
                <Select
                  isMulti
                  options={deviceOptions}
                  onChange={(selected) => setValue("deviceIds", selected)}
                  placeholder={t("createGeofence.selectDevices")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className="mb-2" xl={6}>
              <Form.Group controlId="selectUsers">
                <Form.Label>{t("createGeofence.selectUsers")}</Form.Label>
                <Select
                  isMulti
                  options={usersOptions}
                  value={selectedUsers}
                  onChange={(selected) => setSelectedUsers(selected)}
                  placeholder={t("createGeofence.selectUsers")}
                />
              </Form.Group>
            </Col>

            <Col className="mb-2" xl={6}>
              <Form.Group controlId="departmentId">
                <Form.Label>{t("createGeofence.selectDepartment")}</Form.Label>
                <Select
                  options={departmentOptions}
                  value={selectedDepartment}
                  onChange={(selected) => setSelectedDepartment(selected)}
                  placeholder={t("createGeofence.selectDepartment")}
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="p-1 mt-2 mb-1">
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={14}
              center={selectedPosition}
              onClick={onMapClick}
              options={{
                disableDefaultUI: true,
                zoomControl: true,
                mapId: "datadike_map123",
              }}
            >
              <Marker position={selectedPosition} />
              <Circle
                center={selectedPosition}
                radius={radius}
                options={{
                  strokeColor: "#87CEEB",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#87CEEB",
                  fillOpacity: 0.25,
                }}
              />
            </GoogleMap>
          </div>

          <Form.Group className="mt-3" controlId="radius">
            <Form.Label>{t("createGeofence.radius")}</Form.Label>
            <div className="ac">
              {radiusInputType === "range" ? (
                <Form.Range
                  min="5"
                  max="9999"
                  value={radius}
                  onChange={(e) => setRadius(Number(e.target.value))}
                />
              ) : (
                <InputGroup>
                  <Form.Control
                    min="5"
                    max="9999"
                    type="number"
                    value={radius}
                    onChange={(e) => setRadius(Number(e.target.value))}
                  />
                </InputGroup>
              )}
              <Button
                type="button"
                onClick={() =>
                  setRadiusInputType((prev) =>
                    prev === "range" ? "number" : "range"
                  )
                }
                className="ms-2"
              >
                {radiusInputType === "range"
                  ? t("createGeofence.manualRadius")
                  : t("createGeofence.rangeRadius")}
              </Button>
            </div>
          </Form.Group>

          <Modal.Footer>
            <Button variant="secondary" onClick={close}>
              {t("cancel")}
            </Button>
            <Button type="submit" variant="success">
              {t("createGeofence.submit")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateGeofence;
