import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { SysadminContext } from "../../Context/SysadminContext";
import useSearch from "../../hooks/useSearch";
import { useGet } from "../../hooks/get";
import { VscLoading } from "react-icons/vsc";
import DownloadButton from "./DownloadButton.js";
import { MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Invoices = () => {
  const { t } = useTranslation();
  const { selectedDevice } = useContext(SysadminContext);
  const [refreshState, setRefreshState] = useState(1);
  const [invoiceState, setInvoicesState] = useState(0);
  const [loading, setLoading] = useState(false);
  const lang = localStorage.getItem("lang");

  const { data: apiAvailableInvoices } = useGet(
    `/api/v1/invoices/view-invoices`,
    invoiceState
  );
  const { data: apiDeviceInvoices } = useGet(
    `/api/v1/sysadmin/device-invoices/${selectedDevice?.deviceId}`,
    refreshState
  );

  const [availableInvoices, setAvailableInvoices] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (apiAvailableInvoices) {
      setTimeout(() => {
        setLoading(false);
      }, 500);

      if (apiDeviceInvoices && apiDeviceInvoices.length > 0) {
        // Cria uma lista de IDs das faturas vinculadas ao dispositivo
        const deviceInvoiceIds = apiDeviceInvoices.map(
          (invoice) => invoice.invoiceId
        );

        // Filtra para excluir faturas já vinculadas ao dispositivo
        const filteredInvoices = apiAvailableInvoices.filter(
          (invoice) => !deviceInvoiceIds.includes(invoice._id)
        );
        setAvailableInvoices(filteredInvoices);
      } else {
        // Se não há faturas vinculadas ao dispositivo, exibe todas as faturas disponíveis
        setAvailableInvoices(apiAvailableInvoices);
      }
    }
  }, [apiAvailableInvoices, apiDeviceInvoices]);

  const addInvToDevice = async (invoice) => {
    const response = await fetch(`/api/v1/manage-device/attach-invoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        devices: [
          {
            deviceId: selectedDevice.deviceId,
            deviceName: selectedDevice.deviceName,
          },
        ],
        invoiceId: invoice._id,
      }),
    });

    if (response.ok) {
      setLoading(true);
      setTimeout(() => {
        setRefreshState((prevState) => prevState + 1);
      }, 500);
    }
  };

  const handleDelete = async (invoice) => {
    const response = await fetch(`/api/v1/manage-device/remove-invoice`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        devices: [
          {
            deviceId: selectedDevice.deviceId,
            deviceName: selectedDevice.deviceName,
          },
        ],
        invoiceId: invoice,
      }),
    });
    const data = await response.json();
    if (response.ok) {
      setLoading(true);
      setTimeout(() => {
        setRefreshState((prevState) => prevState + 1);
        setInvoicesState((prevState) => prevState + 1);
      }, 500);
    }
  };

  const { filteredData, searchTerm, setSearchTerm } = useSearch(
    availableInvoices,
    ["description"]
  );

  if (loading) {
    return (
      <Card
        style={{
          height: "620px",
        }}
      >
        <Card.Header>
          <h6 className="mb-0 text-dark">{t("Invoices")}</h6>
        </Card.Header>
        <Card.Body className="jc ac">
          <h4 className="loading-icon">
            <VscLoading />
          </h4>
        </Card.Body>
      </Card>
    );
  }

  return (
    !loading && (
      <Card
        style={{
          height: "620px",
        }}
      >
        <Card.Header>
          <h4 className="mb-0 text-dark">{t("Invoices")}</h4>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col
              style={{
                height: "620px",
              }}
              xl={6}
              className="mb-1"
            >
              <Card
                style={{
                  height: "620px",
                }}
                className="sysadmin-cards"
              >
                <Card.Header>
                  <h6 className="mb-0">{t("Available Invoices")}</h6>
                  <input
                    className="form-control w-20"
                    type="text"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search"
                    value={searchTerm}
                  />
                </Card.Header>
                <Card.Body className="mh-100 overflow-auto">
                  {filteredData && filteredData.length > 0 ? (
                    filteredData.map((invoice) => (
                      <div
                        className="tw-bg-gray-100 tw-rounded-md p-2 mb-1"
                        key={invoice._id}
                      >
                        <button
                          onClick={() => {
                            addInvToDevice(invoice);
                          }}
                          className="btn btn-gray me-2"
                        >
                          +
                        </button>
                        {invoice.description}
                      </div>
                    ))
                  ) : (
                    <div className="text-center">
                      {t("No invoices available to")}
                      <span className="mx-1 text-primary">
                        {selectedDevice?.deviceName}
                      </span>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>

            <Col className="mb-1" xl={6}>
              <Card
                style={{
                  height: "620px",
                }}
                className="sysadmin-cards"
              >
                <Card.Header>
                  {lang == "en" ? (
                    <h6 className="mb-0">
                      <span className="font-semibold me-1">
                        {selectedDevice?.deviceName}
                      </span>

                      {t("Invoices")}
                    </h6>
                  ) : (
                    <h6 className="mb-0">
                      {t("Invoices")} em
                      <span className="font-semibold ms-1">
                        {selectedDevice?.deviceName}
                      </span>
                    </h6>
                  )}

                  <div //Pra ajustar o alinhamento da borda dos dois cards
                    style={{
                      marginTop: "0.5rem",
                      marginBottom: "0.5rem",
                      padding: "0.6rem",
                    }}
                  ></div>
                </Card.Header>
                <Card.Body className="mh-100 overflow-auto">
                  {apiDeviceInvoices && apiDeviceInvoices.length > 0 ? (
                    apiDeviceInvoices.map((invoice) => (
                      <div
                        className="tw-bg-gray-100 ac tw-rounded-md p-2 mb-1 d-flex justify-content-between"
                        key={invoice.invoiceId}
                      >
                        <div>
                          <button
                            onClick={() => handleDelete(invoice.invoiceId)}
                            className="btn btn-gray me-2"
                          >
                            <MdDelete />
                          </button>
                          {invoice.invoceDescription}
                        </div>

                        <span className="text-secondary">
                          <DownloadButton
                            see
                            download
                            fileName={`${selectedDevice.deviceName} invoice`}
                            url={`/api/v1/invoices/download-invoice/${invoice.invoiceId}`}
                          />
                        </span>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">
                      {t("No invoices added to")}
                      <span className="mx-1 text-primary">
                        {selectedDevice?.deviceName}
                      </span>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  );
};

export default Invoices;
