import React, { useState, useEffect } from "react";
import { Card, Table, Form, Button } from "react-bootstrap";
import { convertToLocalTime } from "../../hooks/convertDate";
import { useGet } from "../../hooks/get";
import { useSort } from "../../hooks/useSort";
import useSearch from "../../hooks/useSearch";
import { useTranslation } from "react-i18next";

const EmpDevices = ({ refreshState, setRefreshState, propData }) => {
  const { t } = useTranslation();
  console.log(propData);
  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const { data: devicesApi } = useGet(
    `/api/v1/employees/view-employee-devices/${propData.employeeId}`,
    refreshState
  );

  useEffect(() => {
    if (devicesApi && devicesApi.length > 0) {
      const convertedData = devicesApi.map((item) => {
        return {
          deviceId: item.deviceId,
          deviceName: item.deviceName,
          addedBy: item.addedBy,
          addedAt: item.addedAt ? convertToLocalTime(item.addedAt) : "",
        };
      });

      setDevices(convertedData);
    } else {
      setDevices([]);
    }
  }, [devicesApi]);

  const { sortedData, requestSort } = useSort(devices);
  const { filteredData, setSearchTerm, searchTerm } = useSearch(sortedData, [
    "deviceName",
    "addedBy",
    "addedAt",
  ]);

  const deleteDevice = () => {
    const body = {
      employeeId: propData.employeeId,
      deviceIds: selectedDevices,
    };
    fetch(`/api/v1/manage-device/remove-employee`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setSelectedDevices([]);

        const updatedDevices = devices.filter(
          (device) => !selectedDevices.includes(device.deviceId)
        );
        setDevices(updatedDevices);

        setTimeout(() => {
          setRefreshState(refreshState + 1);
        }, 600);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const handleSelect = (deviceId) => {
    setSelectedDevices((prevSelected) =>
      prevSelected.includes(deviceId)
        ? prevSelected.filter((id) => id !== deviceId)
        : [...prevSelected, deviceId]
    );
  };

  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleSelectAll = () => {
    if (!isAllSelected) {
      setSelectedDevices(filteredData.map((device) => device.deviceId));
      setIsAllSelected(true);
    } else {
      setSelectedDevices([]);
      setIsAllSelected(false);
    }
  };

  return (
    <Card className="custom-card-1" style={{ height: "500px" }}>
      <Card.Header>
        <h4>{t("Employee Devices")}</h4>
        <input
          type="text"
          placeholder={t("Search...")}
          className="form-control w-20"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Card.Header>
      <Card.Body>
        <Table className="table-users" hover>
          <thead>
            <tr>
              <th onClick={handleSelectAll} className="center-text-th">
                #
              </th>
              <th onClick={() => requestSort("deviceName")}>
                {t("Device Name")}
              </th>
              <th onClick={() => requestSort("addedBy")}>{t("Added By")}</th>
              <th onClick={() => requestSort("addedAt")}>{t("Added At")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((dev) => (
              <tr key={dev.deviceId}>
                <td className="center-text-td">
                  <Form.Check
                    checked={selectedDevices.includes(dev.deviceId)}
                    onChange={() => handleSelect(dev.deviceId)}
                  />
                </td>
                <td>{dev.deviceName || "-"}</td>
                <td>{dev.addedBy || "-"}</td>
                <td>{dev.addedAt || "-"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
      {selectedDevices.length > 0 && (
        <Card.Footer>
          <Button variant="danger" onClick={deleteDevice}>
            {t("Delete Selected Devices")}
          </Button>
        </Card.Footer>
      )}
    </Card>
  );
};

export default EmpDevices;
