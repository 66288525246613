import React, { useContext, useState, useEffect } from "react";
import { SysadminContext } from "../../Context/SysadminContext";
import useSearch from "../../hooks/useSearch";
import { useGet } from "../../hooks/get";
import { Card, Col, Row } from "react-bootstrap";

import { VscLoading } from "react-icons/vsc";
import { MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Employees = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { selectedDevice } = useContext(SysadminContext);
  const [refreshState, setRefreshState] = useState(false);
  const { data: apiEmployees, loading: apiLoading } = useGet(
    `/api/v1/employees/view-employees`,
    refreshState
  );
  const { data: deviceEmployees, loading: deviceEmployeesLoading } = useGet(
    `/api/v1/sysadmin/device-employees/${selectedDevice?.deviceId}`,
    refreshState
  );

  const [availableEmployees, setAvailableEmployees] = useState([]);

  useEffect(() => {
    setLoading(true);

    if (apiEmployees && deviceEmployees && deviceEmployees.length > 0) {
      const deviceEmployeeIds = deviceEmployees.map(
        (employee) => employee.employeeId
      );
      const filteredEmployees = apiEmployees.filter(
        (employee) => !deviceEmployeeIds.includes(employee.employeeId)
      );
      setAvailableEmployees(filteredEmployees);
      setLoading(false);
    } else if (apiEmployees) {
      setAvailableEmployees(apiEmployees);
      setLoading(false);
    }
  }, [apiEmployees, deviceEmployees]);

  const {
    filteredData: filteredEmployees,
    searchTerm: employeeSearch,
    setSearchTerm: setEmployeeSearch,
  } = useSearch(availableEmployees, ["employeeName"]);

  const addEmployeeToDevice = async (employeeId) => {
    const response = await fetch("/api/v1/manage-device/attach-employee", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        employeeId: employeeId,
        deviceIds: [selectedDevice.deviceId],
      }),
    });

    if (response.ok) {
      setLoading(true);

      setTimeout(() => {
        setRefreshState(!refreshState);
      }, 600);
    }
  };

  const deleteDevice = (id) => {
    const body = {
      employeeId: id,
      deviceIds: [selectedDevice?.deviceId],
    };
    fetch(`/api/v1/manage-device/remove-employee`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          setLoading(true);
          setTimeout(() => {
            setRefreshState(!refreshState);
          }, 500);
        }
        return response.json();
      })
      .then((data) => {})
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  if (loading) {
    return (
      <Card style={{ height: "740px" }} className="">
        <Card.Header>
          <h4 className="mb-0">{t("Employees")}</h4>
        </Card.Header>

        <Card.Body className="jc ac">
          <h4 className="loading-icon">
            <VscLoading className="" />
          </h4>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card style={{ height: "740px" }} className="">
      <Card.Header>
        <h4 className="mb-0">{t("Employees")}</h4>
      </Card.Header>

      <Card.Body>
        <Row>
          <Col
            style={{
              height: "620px",
            }}
            className="mb-1"
            xl={6}
          >
            <Card
              style={{
                height: "620px",
              }}
              className="sysadmin-cards"
            >
              <Card.Header>
                <h6 className="mb-0">{t("Available Employees")}</h6>
                <input
                  type="text"
                  className="form-control w-50"
                  placeholder={t("Search")}
                  value={employeeSearch}
                  onChange={(e) => setEmployeeSearch(e.target.value)}
                />
              </Card.Header>
              <Card.Body className="mh-100 overflow-auto">
                {availableEmployees && availableEmployees.length > 0 ? (
                  filteredEmployees.map((employee) => (
                    <div
                      className="tw-bg-gray-100 tw-rounded-md p-2 mb-1"
                      key={employee.employeeId}
                    >
                      <button
                        onClick={() => addEmployeeToDevice(employee.employeeId)}
                        className="btn btn-gray me-2"
                      >
                        +
                      </button>
                      {employee.employeeName}
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                    {t("No employees available for")}:
                    <span className="mx-1 text-primary">
                      {selectedDevice?.deviceName}
                    </span>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>

          <Col
            style={{
              height: "620px",
            }}
            xl={6}
            className="mb-1"
          >
            <Card
              style={{
                height: "620px",
              }}
              className="sysadmin-cards"
            >
              <Card.Header>
                <h6 className="mb-0">
                  <span className="font-semibold me-1">
                    {selectedDevice?.deviceName}
                  </span>
                  {t("Employees")}
                </h6>

                <div
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                    padding: "0.6rem",
                  }}
                ></div>
              </Card.Header>
              <Card.Body className="mh-100 overflow-auto">
                {deviceEmployees && deviceEmployees.length > 0 ? (
                  <div>
                    {deviceEmployees.map((employee) => (
                      <div
                        className="tw-bg-gray-100 tw-rounded-md p-3 mb-1"
                        key={employee.employeeId}
                      >
                        <div>
                          <button
                            onClick={() => deleteDevice(employee.employeeId)}
                            className="btn btn-gray me-2"
                          >
                            <MdDelete />
                          </button>
                          {employee.employeeName}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center">
                    {t("No employees added to")}
                    <span className="mx-1 text-primary">
                      {selectedDevice?.deviceName}
                    </span>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Employees;
