import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Dropdown,
  Table,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { IoIosMore } from "react-icons/io";
import { FaCheck, FaTrash, FaPen } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { useTranslation } from "react-i18next";

import { useGet } from "../../hooks/get";
import { useSort } from "../../hooks/useSort";
import useSearch from "../../hooks/useSearch";
import { convertToLocalTime } from "../../hooks/convertDate";
import { tagBadges } from "../../extraFunctions/badgesTw";
import EditTagModal from "./EditTag";

const TagsTable = ({
  setRefreshState,
  refreshState,
  selectedTag,
  setSelectedTag,
}) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState([]);
  const [editing, setEditing] = useState(null);
  const [editValue, setEditValue] = useState(null);

  const { data } = useGet("/api/v1/tags/view-tags", refreshState);

  useEffect(() => {
    if (data && data.length > 0) {
      const tags = data.map((tag) => ({
        tagId: tag.tagId,
        name: tag.name,
        color: tag.color,
        devicesWithTag: tag.devicesWithTag,
        createdBy: tag.createdBy,
        creationDate: convertToLocalTime(tag.creationDate),
      }));
      setTags(tags);
      if (!selectedTag) {
        setSelectedTag(tags[0]);
      }
    }
  }, [data, selectedTag, setSelectedTag]);

  const handleDelete = async (tagId, tagName) => {
    const response = await fetch(
      `/api/v1/tags/delete-tag/${tagId}?tagName=${tagName}`,
      {
        method: "DELETE",
      }
    );

    if (response.ok) {
      setTags((prev) => prev.filter((tag) => tag.tagId !== tagId));
      setTimeout(() => setRefreshState(refreshState + 1), 300);
    }
  };

  const { sortedData, requestSort } = useSort(tags);
  const { searchTerm, setSearchTerm, filteredData } = useSearch(sortedData, [
    "name",
    "createdBy",
    "creationDate",
  ]);

  const submit = async (tagId, tagName) => {
    if (!editValue) return;

    const response = await fetch(`/api/v1/tag/change-tag-name/${tagId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newTagName: editValue,
        oldTagName: tagName,
      }),
    });

    if (response.ok) {
      setEditing(null);
      setEditValue(null);
      setTags((prev) =>
        prev.map((tag) =>
          tag.tagId === tagId ? { ...tag, name: editValue } : tag
        )
      );
    }
  };

  let style = {
    minWidth: "150px",
  };

  const colorTag = (cor) => {
    return tagBadges[cor] || tagBadges.default;
  };
  const [tagToEdit, setTagToEdit] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleEditTag = (tag) => {
    setTagToEdit(tag);

    setTimeout(() => setShowModal(true), 100);
  };
  return (
    <>
      <Card
        style={{
          minHeight: "400px",
          maxHeight: "500px",
        }}
        className="custom-card-1"
      >
        <Card.Header>
          <h5>{t("Tags")}</h5>

          <div className="d-flex tw-justify-end">
            <input
              type="text"
              className="form-control w-50 me-1"
              placeholder={`${"Search..."}`}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </Card.Header>
        <Card.Body>
          <Table className="table-users" hover>
            <thead>
              <tr>
                <th className="center-text">#</th>
                <th onClick={() => requestSort("name")}>{t("Tag Name")}</th>
                <th
                  className="center-text"
                  onClick={() => requestSort("devicesWithTag")}
                >
                  {t("Devices with Tag")}
                </th>
                <th onClick={() => requestSort("createdBy")}>
                  {t("Created By")}
                </th>
                <th onClick={() => requestSort("creationDate")}>
                  {t("Creation Date")}
                </th>
                <th className="center-text-th">{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((tag, index) => (
                <tr
                  className={
                    selectedTag && selectedTag.tagId === tag.tagId
                      ? "table-secondary cursor-pointer"
                      : "cursor-pointer"
                  }
                  key={tag.tagId}
                >
                  <td className="center-text-td index-td">
                    <small className="text-secondary">
                      <small>{index + 1}</small>
                    </small>
                  </td>
                  <td
                    onClick={() => setSelectedTag(tag)}
                    style={{ minWidth: "150px" }}
                  >
                    <span className={colorTag(tag.color)}>{tag.name}</span>
                  </td>
                  <td
                    className="center-text-td"
                    onClick={() => setSelectedTag(tag)}
                    style={{ minWidth: "200px" }}
                  >
                    {tag.devicesWithTag}
                  </td>
                  <td
                    onClick={() => setSelectedTag(tag)}
                    style={{ minWidth: "150px" }}
                  >
                    {tag.createdBy}
                  </td>
                  <td
                    onClick={() => setSelectedTag(tag)}
                    style={{ minWidth: "150px" }}
                  >
                    {tag.creationDate}
                  </td>
                  <td className="jc" style={{ minWidth: "90px" }}>
                    <button
                      onClick={() => handleEditTag(tag)}
                      className="btn btn-primary me-1"
                    >
                      <FaPen />
                    </button>

                    {tag.devicesWithTag > 0 ? (
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id={`tooltip-left`}>
                            {t(
                              "To delete a tag, you must remove all devices with it first."
                            )}
                          </Tooltip>
                        }
                      >
                        <div>
                          <button disabled className="btn btn-danger">
                            <FaTrash />
                          </button>
                        </div>
                      </OverlayTrigger>
                    ) : (
                      <button
                        onClick={() => handleDelete(tag.tagId, tag.name)}
                        className="btn btn-danger"
                      >
                        <FaTrash />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      {tagToEdit && (
        <EditTagModal
          tag={tagToEdit}
          show={showModal}
          close={() => setShowModal(false)}
          refresh={() => setRefreshState(!refreshState)}
        />
      )}
    </>
  );
};

export default TagsTable;
