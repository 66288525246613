// options.o
export const optionsLocation = [
  {
    label: "Last Report",
    value: 0,
    text: "Last Report",
  },
  {
    label: "5 min",
    value: 5,
    text: "Last 5 Minutes",
  },
  {
    label: "15 min",
    value: 15,
    text: "Last 15 Minutes",
  },
  {
    label: "30 min",
    value: 30,
    text: "Last 30 Minutes",
  },
  {
    label: "1 hour",
    value: 60,
    text: "Last 1 Hour",
  },
  {
    label: "3 hours",
    value: 180,
    text: "Last 3 Hours",
  },
  {
    label: "6 hours",
    value: 360,
    text: "Last 6 Hours",
  },
  {
    label: "12 hours",
    value: 720,
    text: "Last 12 Hours",
  },
  {
    label: "1 day",
    value: 1440,
    text: "Last 1 Day",
  },
  {
    label: "2 days",
    value: 2880,
    text: "Last 2 Days",
  },
];
