import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";

import { useGet } from "../../hooks/get";
import Chart1 from "./chartinho";
import { useTranslation } from "react-i18next";

const FirstCards = ({ refreshState }) => {
  const { t } = useTranslation();

  const {
    data: apiData,
  } = useGet("/api/v1/dashboard/devices-status", refreshState);

  const data = {
    Cards: [
      {
        count: apiData ? apiData.totalDevices : 0,
        label: `${t("dashboard.firstCards.totalDevices")}`,
        icon: "fa fa-mobile",
        borderStyle: "tw-border-blue-400",
      },
      {
        count: apiData ? apiData.totalLines : "N/A",
        label: `${t("dashboard.firstCards.totalLines")}`,

        borderStyle: "tw-border-orange-400",
      },
      {
        count: apiData ? apiData.onlyApp : 0,
        // label: "Online Devices",
        label: `${t("dashboard.firstCards.onlyApp")}`,
        borderStyle: "tw-border-yellow-400",
      },
      {
        count: apiData ? apiData.fullyManaged : 0,
        label: `${t("dashboard.firstCards.fullyManaged")}`,
        borderStyle: "tw-border-green-400",
      },
    ],

    total: [
      {
        totalDevicecount: apiData ? apiData.online + apiData.offline : 0,
      },
    ],
  };

  return (
    <Row>
      {data.Cards.map((card, index) => (
        <Col key={index} md={6} xl={3} className="mb-2 pe-1">
          <Card
            style={{
              height: "110px",
              borderWidth: 0,
            }}
            //   className="custom-card-1"
          >
            <div
              style={{
                borderStyle: "dotted",
                borderWidth: "1.5px",
                borderRadius: "4px",
                borderSpacing: "0.1px",
              }}
              className={`${card.borderStyle}`}
            >
              <Card.Body
                style={{
                  overflow: "hidden",
                }}
                className={" ac jc"}
              >
                <Row className="">
                  <div className="text-center">
                    <h2 className={` mb-0 font-semibold`}>
                      {card.count || "0"}
                    </h2>
                    <p className={"mt-2"}>{card.label}</p>
                  </div>
                </Row>
              </Card.Body>
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default React.memo(FirstCards);
