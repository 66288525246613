import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import EmpModal from "../components/Employess/EmpModal";
import EmpTable from "../components/Employess/EmpTable";
import EmpDevices from "../components/Employess/EmpDevices";
import { useTranslation } from "react-i18next";

const Employess = () => {
  const { t } = useTranslation();

  document.title = `${t("DataDike MDM | Employees")}`;
  const [showModal, setShowModal] = useState(false);
  const [propData, setPropData] = useState(null);
  const [refreshState, setRefreshState] = useState(1);

  return (
    <div>
      {showModal && (
        <EmpModal
          cadNew={() => setRefreshState((prevState) => prevState + 1)}
          onHide={() => setShowModal(false)}
        />
      )}
      <div className="flex align-items-center justify-content-between mb-3 ms-1">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <span className="text-primary cursor-pointer">
                {t("Functionalities")}
              </span>
            </li>
            {/* <li className="d-none breadcrumb-item active" aria-current="page">
              Employess
            </li> */}
          </ol>
          <h4 className="main-title mb-0"> {t("Employees")}</h4>
        </div>

        <button
          onClick={() => {
            setShowModal(true);
          }}
          className="btn btn-primary"
        >
          {t("Add new Employee")}
        </button>
      </div>

      <EmpTable
        onEmp={setPropData}
        refreshState={refreshState}
        setRefreshState={setRefreshState}
      />

      {propData && propData.devicesInEmployee > 0 && (
        <Row className="mt-3">
          <EmpDevices propData={propData} />
        </Row>
      )}
    </div>
  );
};

export default Employess;
