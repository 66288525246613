import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { SysadminProvider } from "./Context/SysadminContext";
import { DepartmentProvider } from "./Context/DepartmentsContext";
import { AuthProvider } from "./Context/AuthContext";
import { PoliciesProvider } from "./Context/PolicyContext.js";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n.js";
import { LayoutProvider } from "./Context/LayoutContext.js";
import { GeofenceProvider } from "./Context/GeofenceContext.js";
// Importações de CSS
// import "tailwindcss/tailwind.css"; // Ou o caminho para seu arquivo CSS personalizado
import "./assets/css/remixicon.css";

// Desabilitar console.logs em produção
if (process.env.NODE_ENV === "production") {
  console.log = console.info = console.error = console.warn = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GeofenceProvider>
      <SysadminProvider>
        <DepartmentProvider>
          <AuthProvider>
            <PoliciesProvider>
              <LayoutProvider>
                <I18nextProvider i18n={i18n}>
                  <App />
                </I18nextProvider>
              </LayoutProvider>
            </PoliciesProvider>
          </AuthProvider>
        </DepartmentProvider>
      </SysadminProvider>
    </GeofenceProvider>
  </React.StrictMode>
);
