import React, { useState, useEffect } from "react";
import { Card, Form, Button, InputGroup } from "react-bootstrap";
import axios from "axios";
import { VscLoading } from "react-icons/vsc"; // Certifique-se de ter react-icons instalado
import { FaArrowLeft, FaEye, FaEyeSlash } from "react-icons/fa"; // Ícones de olho para exibir/ocultar senha

const ResetPass = () => {
  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [feedback, setFeedback] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = searchParams.get("token");
    setToken(tokenFromUrl);
  }, []);

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar
    );
  };

  const submit = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setFeedback({ ok: false, message: "Passwords do not match." });
      return;
    }
    if (!validatePassword(newPassword)) {
      setFeedback({
        ok: false,
        message:
          "Password must be at least 8 characters long and include uppercase, lowercase, number, and special character.",
      });
      return;
    }
    setFeedback({ ok: "loading", message: "Loading..." });
    try {
      await axios.post(`/api/v1/password-reset?token=${token}`, {
        newPassword,
      });
      setFeedback({
        ok: true,
        message:
          "Password reset successfully. You will be redirected to sign in page",
      });

      setTimeout(() => {
        window.location.replace("/signin");
      }, 2000);
    } catch (error) {
      setFeedback({ ok: false, message: "Failed to reset password." });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card
        className="card-one"
        style={{
          height: "485px",
          width: "420px",
        }}
      >
        <Card.Header>
          <h4
            onClick={() => {
              window.location.replace("/signin");
            }}
            className="mb-0 text-dark cursor-pointer"
          >
            <FaArrowLeft />
          </h4>
        </Card.Header>
        <Card.Body className="jc ac">
          <Form onSubmit={submit}>
            <Form.Group controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <InputGroup.Text
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ cursor: "pointer" }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="confirmPassword" className="mt-3">
              <Form.Label>Confirm Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <InputGroup.Text
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ cursor: "pointer" }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            {feedback && (
              <div
                className={
                  feedback.ok === true
                    ? "tw-bg-green-500 tw-text-white p-3 tw-rounded-md w-100 mt-3"
                    : feedback.ok === false
                    ? "tw-bg-red-500 tw-text-white p-3 tw-rounded-md w-100 mt-3"
                    : feedback.ok === "loading"
                    ? "tw-bg-datadike-blue tw-text-white p-3 tw-rounded-md w-100 mt-3 text-center tw-animate-pulse d-block"
                    : ""
                }
              >
                {feedback.message}
                {feedback.ok === "loading" && (
                  <h3 className="loading-icon-white tw-text-white mt-1">
                    <VscLoading />
                  </h3>
                )}
              </div>
            )}
            <Button variant="primary" type="submit" className="mt-3 w-100">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ResetPass;
