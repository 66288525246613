// DeviceContext.js
import React, { createContext, useState } from "react";

export const DeviceContext = createContext();

export const DeviceProvider = ({ children }) => {
  const [deviceData, setDeviceData] = useState({
    deviceFakeName: "",
    mainUser: "",
    group: "",
    dateCreate: new Date(),
  });

  return (
    <DeviceContext.Provider value={{ deviceData, setDeviceData }}>
      {children}
    </DeviceContext.Provider>
  );
};
