import React, { useEffect, useState } from "react";
import { Card, Dropdown, Nav, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { VscLoading } from "react-icons/vsc";
import { FaPen, FaPlus, FaCheck, FaLock } from "react-icons/fa";
import CreateUserModal from "../components/UsersManagement/CreateUserModal";
import PendingInvites from "../components/UsersManagement/PendingInvites.js";
import { useGet } from "../hooks/get";
import { MdCancel } from "react-icons/md";
import { IoIosMore } from "react-icons/io";
import { FaTrash } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { badgesDefault } from "../extraFunctions/badgesTw";
import { convertToLocalTime } from "../hooks/convertDate";
import { GoDotFill } from "react-icons/go";

const UsersManagement = () => {
  const { t } = useTranslation();

  document.title = `${t("DataDike MDM | Users")}`;

  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshState, setRefreshState] = useState(false);
  const [view, setView] = useState(0);

  const { data: usersApiApi, loading: usersLoading } = useGet(
    "/api/v1/enterprise-users",
    refreshState
  );

  const [usersApi, setUsersApi] = useState([]);

  useEffect(() => {
    if (usersApiApi && usersApiApi.length > 0) {
      const formattedData = usersApiApi.map((u) => ({
        ...u,
        lastLogin: u.lastLogin ? convertToLocalTime(u.lastLogin) : "-",
        mfaEnabled: u.mfaEnabled === true ? "Active" : "Inactive",
        userStatus: u.isOnline === true ? "online" : "offline",
      }));
      setUsersApi(formattedData);
    } else {
      setUsersApi(null);
    }
  }, [usersApiApi]);

  const [users, setUsers] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });

  const [editingUser, setEditingUser] = useState(null);
  const [editedPermissionLevel, setEditedPermissionLevel] = useState("");

  useEffect(() => {
    if (usersApi) {
      setUsers(usersApi);
      setLoading(false);
    }
  }, [usersApi]);

  const addUser = (info) => {
    setLoading(true);
    const newUser = {
      _id: (users.length + 1).toString(),
      ...info,
    };
    setUsers((prevUsers) => [...prevUsers, newUser]);
    setLoading(false);
  };

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedAndFilteredData = () => {
    let sortedUsers = [...users];
    if (sortConfig !== null) {
      sortedUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    if (searchTerm && searchTerm !== "") {
      sortedUsers = sortedUsers.filter((user) =>
        [
          "name",
          "email",
          "phone",
          "permissionLevel",
          "lastLogin",
          "mfaEnabled",
          "userStatus",
        ].some(
          (key) =>
            user[key] &&
            user[key].toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    return sortedUsers;
  };

  const handleEdit = (user) => {
    setEditingUser(user._id);
    setEditedPermissionLevel(user.permissionLevel);
  };

  const handleSave = async (userId, username) => {
    const response = await fetch(`/api/v1/update-user-permission`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id: userId,
        newPermissionLevel: editedPermissionLevel,
        userName: username,
      }),
    });

    if (response.ok) {
      const updatedUsers = users.map((user) => {
        if (user._id === userId) {
          return {
            ...user,
            permissionLevel: editedPermissionLevel,
          };
        }
        return user;
      });

      setUsers(updatedUsers);
      setEditingUser(null);
    }
  };

  const handleDelete = async (userId, username) => {
    const response = await fetch(
      `/api/v1/delete-user/?_id=${userId}&userName=${username}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: userId,
        }),
      }
    );

    if (response.ok) {
      const updatedUsers = users.filter((user) => user._id !== userId);
      setUsers(updatedUsers);
    }
  };
  const handleResetMfa = async (userId, username) => {
    setLoading(true);

    const response = await fetch(
      `/api/v1/disable-user-mfa/?_id=${userId}&userName=${username}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: userId,
          username: username,
        }),
      }
    );

    if (response.ok) {
      setTimeout(() => {
        setRefreshState(!refreshState);
        setLoading(false);
      }, 300);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  };

  if (loading || usersLoading) {
    return (
      <div className="text-center">
        <h1 className="loading-icon">
          <VscLoading />
        </h1>
      </div>
    );
  }

  return (
    <div>
      {showModal && (
        <CreateUserModal
          close={() => setShowModal(false)}
          addUser={(info) => {
            addUser(info);
            setShowModal(false);
          }}
        />
      )}
      <div className="flex align-items-center justify-content-between mb-3 ms-1">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="#">{t("Plataform Admin")}</Link>
            </li>
            <li className="d-none breadcrumb-item active" aria-current="page">
              {t("usersManagement.mainTitle")}
            </li>
          </ol>
          <h4 className="main-title mb-0"> {t("usersManagement.mainTitle")}</h4>
        </div>

        <div className="d-flex justify-content-end">
          <button
            onClick={() => setShowModal(true)}
            className="rounded-btt"
            style={{
              width: "45px",
              height: "45px",
            }}
          >
            <FaPlus />
          </button>
        </div>
      </div>

      <div className="mt-1 mb-1 w-100">
        <Nav className="nav-line mb-4 tw-font-normal">
          <Nav.Link
            onClick={() => setView(0)}
            to="#"
            className={view == 0 ? "activeNav" : "inactiveNav"}
          >
            {t("usersManagement.navItems.users")}
          </Nav.Link>
          <Nav.Link
            onClick={() => setView(1)}
            to="#"
            className={view == 1 ? "activeNav " : "inactiveNav"}
          >
            {t("usersManagement.navItems.pendingInvites")}
          </Nav.Link>
        </Nav>
      </div>

      <div className={view == 0 ? "" : "d-none"}>
        <Card
          style={{
            height: "700px",
          }}
          className="custom-card-1"
        >
          <Card.Header className="card-table-header">
            <h5 className="mb-0"></h5>

            <div className="d-flex tw-justify-end">
              <input
                type="text"
                className="form-control"
                placeholder={`${t("Search...")}`}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </Card.Header>

          <Card.Body className="mh-100 tw-overflow-auto">
            <Table className="table-users" hover>
              <thead className="tw-bg-gray-600">
                <tr>
                  <th className="center-text">#</th>
                  <th onClick={() => sortData("name")}>{t("Name")}</th>
                  {/* <th onClick={() => sortData("name")}>{t("Status")}</th> */}
                  <th onClick={() => sortData("email")}>Email</th>
                  <th onClick={() => sortData("phone")}>{t("Phone")}</th>

                  <th
                    className="center-text"
                    onClick={() => sortData("permissionLevel")}
                  >
                    {t("Permission Level")}
                  </th>
                  <th
                    className="center-text"
                    onClick={() => sortData("mfaEnabled")}
                  >
                    {t("MFA Status")}
                  </th>
                  <th
                    className="center-text"
                    onClick={() => sortData("lastLogin")}
                  >
                    {t("Last Login")}
                  </th>
                  <th className="center-text">{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {sortedAndFilteredData().map((user, index) => (
                  <tr key={user._id}>
                    <td className="center-text-td">
                      <small className="text-secondary">
                        <small>{index + 1}</small>
                      </small>
                    </td>
                    <td className="">
                      <div className="flex">
                        {user.isOnline == true ? (
                          <span className={"tw-text-green-400 me-2 mb-0"}>
                            <GoDotFill />
                          </span>
                        ) : (
                          <span className={"tw-text-red-500 me-2 mb-0"}>
                            <GoDotFill />
                          </span>
                        )}
                        <span className="">{user.name}</span>
                      </div>
                    </td>

                    <td>{user.email}</td>
                    <td>{user.phone}</td>

                    <td className="flex justify-content-between">
                      {editingUser === user._id ? (
                        <>
                          <button
                            onClick={() => setEditingUser(null)}
                            className="btn btn-danger"
                          >
                            <MdCancel />
                          </button>
                          <select
                            value={editedPermissionLevel}
                            onChange={(e) =>
                              setEditedPermissionLevel(e.target.value)
                            }
                            className="form-select w-60 mx-2"
                          >
                            <option value="admin">Admin</option>
                            <option value="operator">Operator</option>
                          </select>
                        </>
                      ) : (
                        <span className="me-1">{user.permissionLevel}</span>
                      )}

                      {editingUser === user._id ? (
                        <button
                          onClick={() => handleSave(user._id, user.name)}
                          className="btn btn-success"
                        >
                          <FaCheck />
                        </button>
                      ) : (
                        <button
                          onClick={() => handleEdit(user)}
                          className="btn tw-bg-gray-100 tw-text-gray-300 hover:tw-bg-400 hover:tw-text-gray-800"
                        >
                          <FaPen />
                        </button>
                      )}
                    </td>

                    <td className="center-text-td">
                      {user.mfaEnabled == "Active" ? (
                        <div className={badgesDefault.green}>Active</div>
                      ) : (
                        <div className={badgesDefault.red}>Inactive</div>
                      )}
                    </td>

                    <td className="center-text-td">{user.lastLogin} </td>

                    <td className="center-text-td">
                      <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                          <span className="text-dark">
                            <IoIosMore />
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              handleDelete(user._id, user.name);
                            }}
                          >
                            <span
                              style={{
                                postion: "relative",
                                top: "-2px",
                              }}
                              className="tw-text-red-500 me-2"
                            >
                              <FaTrash />
                            </span>
                            {t("Delete")}
                          </Dropdown.Item>

                          {user.mfaEnabled && (
                            <Dropdown.Item
                              onClick={() => {
                                handleResetMfa(user._id, user.name);
                              }}
                            >
                              <span
                                style={{
                                  postion: "relative",
                                  top: "-2px",
                                }}
                                className="tw-text-blue-400 me-2"
                              >
                                <FaLock />
                              </span>
                              {t("Reset MFA")}
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>

      <div className={view == 1 ? "" : "d-none"}>
        <PendingInvites
          refreshState={refreshState}
          setRefreshState={setRefreshState}
        />
      </div>
    </div>
  );
};

export default UsersManagement;
