import React, { useState, useEffect } from "react";
import { Card, Dropdown, Tooltip, OverlayTrigger } from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";
import { IoTimeOutline, IoInformationCircleOutline } from "react-icons/io5";
import DateRangePickerModal from "../Utils/DateRangePickerModal";
import { useContext } from "react";
import { SysadminContext } from "../../Context/SysadminContext.js";
import {
  getBrowserTimeZone,
  convertToLocalTime,
  convertToLocalTimeReq,
} from "../../hooks/convertDate";
import { options } from "../../data/dropdown.js";
import PaginationUi from "../Utils/Pagination";
import { useNavigate } from "react-router-dom";
import { CiMobile1 } from "react-icons/ci";
import { useTranslation } from "react-i18next";

const Logs = () => {
  const { t } = useTranslation();
  const { selectedDevice, setSelectedDevice } = useContext(SysadminContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const now = new Date();
  const thirtyMinutesAgo = new Date(now.getTime() - 30 * 60000);
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang") || "en";

  const [start, setStart] = useState(thirtyMinutesAgo);
  const [end, setEnd] = useState(new Date());
  const [selectedTimeRange, setSelectedTimeRange] = useState("30 min");
  const timezone = getBrowserTimeZone();
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState(null);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [maxItems, setMaxItems] = useState(10);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `/api/v1/logs/device-logs?page=${page}&limit=${maxItems}&startTime=${convertToLocalTimeReq(
          start
        )}&endTime=${convertToLocalTimeReq(
          end
        )}&timezone=${timezone}&lang=${lang}`
      );
      const jsonData = await response.json();
      if (jsonData && jsonData.totalLogs > 0) {
        setData(jsonData);
        setPage(jsonData.currentPage);
      } else {
        setData(null);
      }
      setTotalPages(jsonData.totalPages); // Assuming your API provides total pages
    } catch (error) {
      console.error("Failed to fetch enterprise info:", error);
      setError("Failed to fetch enterprise info:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [start, end, timezone, page, maxItems]);

  const updateDateRangeAndFetch = (minutes, label) => {
    const newEnd = new Date();
    const newStart = new Date(newEnd.getTime() - minutes * 60000);
    setStart(newStart);
    setEnd(newEnd);
    setSelectedTimeRange(label);
  };

  if (loading) {
    return (
      <Card className="custom-card-1" style={{ height: "740px" }}>
        <Card.Header>
          <h5 className="mb-0"> {t("Device Logs")}</h5>
        </Card.Header>
        <Card.Body className="jc ac">
          <h5 className="loading-icon">
            <VscLoading />
          </h5>
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="custom-card-1" style={{ height: "740px" }}>
        <Card.Header>
          <h5 className="mb-0">{t("Device Logs")}</h5>
        </Card.Header>
        <Card.Body className="jc">
          <h6>{t("Error to fetching logs")}</h6>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="custom-card-1" style={{ height: "740px" }}>
      <DateRangePickerModal
        show={show}
        handleClose={() => setShow(false)}
        startDate={start}
        endDate={end}
        onApply={(start, end) => {
          setStart(start);
          setEnd(end);
          setShow(false);
        }}
      />
      <Card.Header>
        <h5 className="mb-0 w-30">{t("Device Logs")}</h5>

        <div className="d-flex justify-content-end w-100">
          <input
            className="form-control w-30"
            disabled
            value={
              inputValue
                ? inputValue
                : `${convertToLocalTime(start)} - ${convertToLocalTime(end)}`
            }
          />
          <select
            className="ms-1 form-select w-10"
            onChange={(e) => setMaxItems(Number(e.target.value))}
            value={maxItems}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={0}> {t("All")}</option>
          </select>

          <Dropdown className="mx-1 outline-secondary">
            <Dropdown.Toggle
              className="outline-secondary"
              variant="outline-secondary"
              id="dropdown-basic"
            >
              <span
                className="me-1"
                style={{ position: "relative", top: "-0.5px" }}
              >
                <IoTimeOutline />
              </span>
              {t(selectedTimeRange)}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {options.map((option) => (
                <Dropdown.Item
                  key={option.value}
                  active={selectedTimeRange === option.text}
                  onClick={() =>
                    updateDateRangeAndFetch(option.value, option.text)
                  }
                >
                  {t(option.text)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <button onClick={() => setShow(true)} className="btn btn-primary">
            <i className="ri-calendar-line" />
          </button>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="log-list">
          {data && data.logs
            ? data.logs.map((log) => (
                <div
                  key={log._id}
                  className="log-item d-flex align-items-center"
                >
                  <div className="d-flex">
                    <div className="log-date">
                      {new Date(log.timestamp).toLocaleString()} |
                    </div>
                    <div className="log-message ms-1">{log.message}</div>
                  </div>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-${log._id}`}>
                        <span
                          onClick={() => {
                            navigate(`/sysadmin?deviceId=${log.deviceId}`);
                          }}
                          className=""
                        >
                          {log.deviceName}
                        </span>
                      </Tooltip>
                    }
                  >
                    <span
                      onClick={() => {
                        if (log.deviceId) {
                          // setSelectedDevice({
                          //   deviceId: log.deviceId,
                          //   deviceName: log.deviceName,
                          // });
                          setTimeout(() => {
                            navigate(`/sysadmin/?deviceId=${log.deviceId}`);
                          }, 500);
                        }
                      }}
                      className="ms-2 font-size-16px tw-text-blue-300 p-2 cursor-pointer"
                    >
                      <CiMobile1 />
                    </span>
                  </OverlayTrigger>
                </div>
              ))
            : null}
        </div>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end">
        <PaginationUi
          currentPage={page}
          totalPages={totalPages}
          onPageChange={(page) => setPage(page)}
        />
      </Card.Footer>
    </Card>
  );
};

export default Logs;
