import React, { createContext, useState, useEffect } from "react";

export const GeofenceContext = createContext();

export const GeofenceProvider = ({ children }) => {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [enterpriseLocation, setEnterpriseLocation] = useState(null);
  const isInDev = process.env.NODE_ENV === "development";
  const preferedRightCardMode = localStorage.getItem("prcm") || "devices"; // preferedrightcardmode
  const [rightCardMode, setRightCardMode] = useState(preferedRightCardMode);
  const [selectedGeofence, setSelectedGeofence] = useState(null);
  const [creatingGeofenceModal, setCreatingGeofenceModal] = useState(false);
  const [devices, setDevices] = useState(null);
  const [availableGeofences, setAvailableGeofences] = useState([]);
  const [devicesLoading, setDevicesLoading] = useState(true);
  const [devicesError, setDevicesError] = useState(null);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        setDevicesLoading(true);
        const response = await fetch("/api/v1/maps/all-devices");
        if (!response.ok) {
          throw new Error("Failed to fetch devices");
        }
        const devicesApi = await response.json();
        if (devicesApi && devicesApi.length > 0) {
          const sortedDevices = devicesApi.slice().sort((a, b) => {
            // Prioriza dispositivos online (status: true) e depois ordena alfabeticamente
            if (a.status !== b.status) {
              return a.status ? -1 : 1; // Status true (online) primeiro
            }
            return a.name.localeCompare(b.name); // Ordena por nome depois
          });

          setDevices(sortedDevices);
        }
      } catch (error) {
        setDevicesError(error.message);
      } finally {
        setDevicesLoading(false);
      }
    };

    fetchDevices();
  }, []);

  return (
    <GeofenceContext.Provider
      value={{
        selectedDevice,
        setSelectedDevice,
        enterpriseLocation,
        setEnterpriseLocation,
        isInDev,
        preferedRightCardMode,
        rightCardMode,
        setRightCardMode,
        selectedGeofence,
        setSelectedGeofence,
        creatingGeofenceModal,
        setCreatingGeofenceModal,
        devices,
        setDevices,
        availableGeofences,
        setAvailableGeofences,
        devicesLoading,
        devicesError,
      }}
    >
      {children}
    </GeofenceContext.Provider>
  );
};
