import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Dropdown,
  Tooltip,
  OverlayTrigger,
  Button,
} from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";
import { IoTimeOutline } from "react-icons/io5";
import DateRangePickerModal from "../Utils/DateRangePickerModal";
import FilterModal from "../Utils/FilterModal";
import { SysadminContext } from "../../Context/SysadminContext.js";
import {
  getBrowserTimeZone,
  convertToLocalTime,
  convertToLocalTimeReq,
} from "../../hooks/convertDate";
import { options } from "../../data/dropdown.js";
import PaginationUi from "../Utils/Pagination";
import { useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import useSearch from "../../hooks/useSearch.js";
import { useTranslation } from "react-i18next";

const IntLogs = () => {
  const { selectedDevice, setSelectedDevice } = useContext(SysadminContext);
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const now = new Date();
  const thirtyMinutesAgo = new Date(now.getTime() - 30 * 60000);
  const navigate = useNavigate();

  const [start, setStart] = useState(thirtyMinutesAgo);
  const [end, setEnd] = useState(new Date());
  const [selectedTimeRange, setSelectedTimeRange] = useState("30 min");
  const timezone = getBrowserTimeZone();
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [inputValue, setInputValue] = useState(null);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [maxItems, setMaxItems] = useState(10);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState("ALL");
  const lang = localStorage.getItem("lang") || "en";
  const [totalLogs, setTotalLogs] = useState(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `/api/v1/logs/interaction-logs?page=${page}&limit=${maxItems}&startTime=${convertToLocalTimeReq(
          start
        )}&endTime=${convertToLocalTimeReq(
          end
        )}&timezone=${timezone}&type=${filter}&lang=${lang}`
      );

      const jsonData = await response.json();
      if (jsonData && jsonData.totalLogs > 0) {
        const formattedData = jsonData.logs.map((i) => ({
          user: i.user,
          timestamp: i.timestamp,
          description: i.description,
          type: i.type,
        }));

        setData(formattedData);
        setPage(jsonData.currentPage);
        setTotalPages(jsonData.totalPages);
        setTotalLogs(jsonData.totalLogs);
      } else {
        setData(null);
      }
    } catch (error) {
      setError(`Failed to fetch interaction logs : ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const validTypes = [
    "PASSWORD_CHANGE",
    "PERMISSION_CHANGE",
    "DELETE_USER",
    "USER_REGISTER",
    "PASSWORD_RESET",
    "SEND_INVITE",
    "LOGIN",
    "CREATE_DEPARTMENT",
    "DELETE_DEPARTMENT",
    "EDIT_DEPARTMENT",
    "ATTACH_DEVICE_IN_DEPARTMENT",
    "REMOVE_DEVICE_FROM_DEPARTMENT",
    "DELETE_INVOICE",
    "UPLOAD_INVOICE",
    "EDIT_INVOICE",
    "DOWNLOAD_INVOICE",
    "ATTACH_INVOICE_TO_DEVICE",
    "REMOVE_INVOICE_FROM_DEVICE",
    "CREATE_TAG",
    "DELETE_TAG",
    "EDIT_TAG",
    "ATTACH_TAG_IN_DEVICE",
    "REMOVE_TAG_FROM_DEVICE",
    "DELETE_TERM",
    "DOWNLOAD_TERM",
    "EDIT_TERM",
    "UPLOAD_TERM",
    "ATTACH_TERM_TO_DEVICE",
    "REMOVE_TERM_FROM_DEVICE",
    "ACTIVATE_MFA",
    "CONFIRM_MFA",
    "RESET_MFA",
    "DISABLE_MFA",
    "CREATE_POLICY",
    "DELETE_POLICY",
    "EDIT_POLICY",
  ];

  const { filteredData, searchTerm, setSearchTerm } = useSearch(data, [
    "user",
    "timestamp",
    "description",
    "type",
  ]);

  useEffect(() => {
    fetchData();
  }, [start, end, timezone, page, maxItems, filter]);

  useEffect(() => {
    if (searchTerm && searchTerm !== "" && page !== 1) {
      setPage(1);
      //  setTotalPages(1);
    } else {
      // fetchData()
    }
  }, [searchTerm]);

  const updateDateRangeAndFetch = (minutes, label) => {
    const newEnd = new Date();
    const newStart = new Date(newEnd.getTime() - minutes * 60000);
    setStart(newStart);
    setEnd(newEnd);
    setSelectedTimeRange(label);
  };

  if (loading) {
    return (
      <Card className="custom-card-1" style={{ height: "740px" }}>
        <Card.Header>
          <h5 className="mb-0">{t("Interaction Logs")}</h5>
        </Card.Header>
        <Card.Body className="jc ac">
          <h5 className="loading-icon">
            <VscLoading />
          </h5>
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="custom-card-1" style={{ height: "740px" }}>
        <Card.Header>
          <h5 className="mb-0">{t("Interaction Logs")}</h5>
        </Card.Header>
        <Card.Body className="jc">
          <h6>{error || "Error fetching logs"}</h6>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="custom-card-1" style={{ height: "740px" }}>
      <DateRangePickerModal
        show={showDateRangePicker}
        handleClose={() => setShowDateRangePicker(false)}
        startDate={start}
        endDate={end}
        onApply={(start, end) => {
          setStart(start);
          setEnd(end);
          setShowDateRangePicker(false);
        }}
      />
      <FilterModal
        show={showFilterModal}
        handleClose={() => setShowFilterModal(false)}
        validTypes={validTypes}
        filter={filter}
        setFilter={setFilter}
      />
      <Card.Header>
        <h5 className="mb-0 w-50">{t("Interaction Logs")}</h5>

        <div className="d-flex justify-content-end w-100">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="form-control w-20"
          />
          <select
            className="ms-1 form-select w-10"
            onChange={(e) => setMaxItems(Number(e.target.value))}
            value={maxItems}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={0}>{t("All")}</option>
          </select>

          <Dropdown className="mx-1 outline-secondary">
            <Dropdown.Toggle
              className="outline-secondary"
              variant="outline-secondary"
              id="dropdown-basic"
            >
              <span
                className="me-1"
                style={{ position: "relative", top: "-0.5px" }}
              >
                <IoTimeOutline />
              </span>
              {t(selectedTimeRange)}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {options.map((option) => (
                <Dropdown.Item
                  key={option.value}
                  active={selectedTimeRange === option.text}
                  onClick={() =>
                    updateDateRangeAndFetch(option.value, option.text)
                  }
                >
                  {t(option.text)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Button variant="primary" onClick={() => setShowFilterModal(true)}>
            {t("Filter")}
          </Button>

          <button
            onClick={() => setShowDateRangePicker(true)}
            className="btn btn-primary ms-1"
          >
            <i className="ri-calendar-line" />
          </button>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="log-list">
          {data ? (
            filteredData.map((log) => (
              <div
                key={log.timestamp}
                className="log-item"
                style={{
                  display: "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                  className=""
                >
                  <div
                    style={{
                      maxWidth: "99%",
                      //   overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    className="log-date"
                  >
                    {new Date(log.timestamp).toLocaleString()} |
                  </div>
                  <div
                    style={{
                      maxWidth: "99%",
                      overflow: "hidden",
                    }}
                    className="log-message ms-1"
                  >
                    {log.description}
                  </div>
                </div>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-${log.timestamp}`}>
                      <span className="">{log.user}</span>
                    </Tooltip>
                  }
                >
                  <span className=" font-size-15px tw-text-blue-300 p-0 m-0">
                    <FaUser />
                  </span>
                </OverlayTrigger>
              </div>
            ))
          ) : (
            <div className="text-center">
              <h6 className="mb-0">{t("No logs available")}</h6>
            </div>
          )}
        </div>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-between">
        <div className="d-block">
          <div className="w-100">
            <small className="text-dark me-1">
              {convertToLocalTime(start)}
            </small>
            -<small className="text-dark ms-1">{convertToLocalTime(end)}</small>
          </div>
          <div className="w-100">
            <small className="text-secondary">
              Total:
              <small className="ms-1 text-dark">{totalLogs || ""}</small>
            </small>
          </div>
        </div>
        <PaginationUi
          currentPage={page}
          totalPages={totalPages}
          searchTerm={searchTerm}
          onPageChange={(page) => setPage(page)}
        />
      </Card.Footer>
    </Card>
  );
};

export default IntLogs;
