import React, { useState, useEffect, useContext } from "react";
import {
  GoogleMap,
  useLoadScript,
  InfoWindow,
  Circle,
} from "@react-google-maps/api";
import { useGet } from "../hooks/get";
import { Card, Row, Col } from "react-bootstrap";
import RightCard from "../components/bigMap/RightCard.js";
import { t } from "i18next";
import ReactDOMServer from "react-dom/server";
import { LuSmartphone } from "react-icons/lu";
import { fetchAddress, fetchCoordinates } from "../extraFunctions/utils";
import AddressPopup from "../components/Geofence/AddressPopup.js";
import CreatePopup from "../components/Geofence/CreatePopup.js";
import { GeofenceContext } from "../Context/GeofenceContext.js";

const mapContainerStyle = {
  width: "100%",
  height: "100vh",
};

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";
const libraries = ["marker"];

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  mapId: "datadike_map", // Substitua pelo seu Map ID
};

const MapWithDeviceList = () => {
  document.title = "DataDike MDM | Geofence";
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const [renderPopup, setRenderPopup] = useState(false);
  const [mapRef, setMapRef] = useState(null);
  const [center, setCenter] = useState({ lat: -23.525, lng: -46.1884 }); // Ponto padrão
  const [address, setAddress] = useState(null);

  const {
    rightCardMode,
    setRightCardMode,
    selectedGeofence,
    setSelectedGeofence,
    creatingGeofenceModal,
    setCreatingGeofenceModal,
    isInDev,
    enterpriseLocation,
    setEnterpriseLocation,
    refreshState,
    setRefreshState,
    selectedDevice,
    setSelectedDevice,
    devices,
    setDevices,
    availableGeofences,
    setAvailableGeofences,
  } = useContext(GeofenceContext);

  // Hooks para buscar dados

  const {
    data: enterpriseAddress,
    loading: enterpriseLoading,
    error: enterpriseError,
  } = useGet("api/v1/maps/base-location");

  console.log("devices", devices);

  useEffect(() => {
    const fetchGeofences = async () => {
      const response = await fetch("api/v1/maps/geofences", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (data && data.length > 0) {
        setAvailableGeofences(data);
      }
    };

    fetchGeofences();
  }, []);
  useEffect(() => {
    const getEnterpriseCoordinates = async () => {
      if (enterpriseAddress) {
        const coords = await fetchCoordinates(enterpriseAddress.address);
        if (coords) {
          setEnterpriseLocation(coords);
          //        console.log("enterpriseAddress", enterpriseAddress);
          //        console.log("coords", coords);
          setCenter(coords);
          if (mapRef) {
            mapRef.panTo(coords);
            mapRef.setZoom(14);
          }
        }
      }
    };

    if (isInDev) {
      getEnterpriseCoordinates();
    }
  }, [enterpriseAddress, mapRef, isInDev]);

  // Reverter para o endereço da empresa

  // Lidar com o clique no dispositivo
  const handleDeviceClick = (device) => {
    setSelectedDevice(device);
    setRenderPopup(true);
    if (device) {
      setCenter({ lat: device.latitude, lng: device.longitude });
      if (mapRef) {
        mapRef.panTo({ lat: device.latitude, lng: device.longitude });
        mapRef.setZoom(18);
      }
    }
  };

  const handleGeofenceClick = (g) => {
    let loc = g.location;
    setSelectedGeofence(g);
    setCenter({ lat: loc.latitude, lng: loc.longitude });

    if (mapRef) {
      mapRef.panTo({ lat: loc.latitude, lng: loc.longitude });
      mapRef.setZoom(14);
    }
  };

  // Obter o endereço do dispositivo selecionado
  useEffect(() => {
    const getAddress = async () => {
      if (selectedDevice) {
        const end = await fetchAddress(
          selectedDevice.latitude,
          selectedDevice.longitude
        );
        setAddress(end ? end : t("Address not found"));
      }
    };

    getAddress();
  }, [selectedDevice]);

  const renderGeofences = () => {
    return availableGeofences.map((geofence, index) => {
      const lat = parseFloat(geofence.location?.latitude);
      const lng = parseFloat(geofence.location?.longitude);

      if (!isNaN(lat) && !isNaN(lng)) {
        return (
          <Circle
            key={geofence._id}
            center={{ lat, lng }}
            radius={geofence?.radius || 0}
            options={{
              strokeColor: "#87CEEB",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#87CEEB",
              fillOpacity: 0.25,
            }}
          />
        );
      }
      return null;
    });
  };

  // Then use it in your return statement

  // Tratamento de erros e carregamento
  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading map...</div>;
  if (enterpriseLoading || !devices) return <div>Loading data...</div>;
  if (enterpriseError) return <div>Error fetching enterprise location</div>;
  console.log("geofence");

  return (
    <>
      <CreatePopup
        apiKey={apiKey}
        show={creatingGeofenceModal}
        close={() => setCreatingGeofenceModal(false)}
      />

      <div className="jsb ac">
        <div className="px-2 mb-3">
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <span className="text-primary" to="#">
                {t("Cockpit")}
              </span>
            </li>
            <li className="d-none breadcrumb-item active" aria-current="page">
              {t("Geofence")}
            </li>
          </ol>
          <h4 className="main-title mb-0">{t("Geofence")}</h4>
        </div>

        <button
          onClick={() => setCreatingGeofenceModal(true)}
          className="btn btn-primary"
        >
          {t("Create Geofence")}
        </button>
      </div>

      <Row>
        <Col lg={8} xl={9} md={12}>
          <Card className="custom-card-1" style={{ height: "740px" }}>
            <div
              className="p-4 tw-overflow-hidden"
              style={{
                overflowY: "hidden !important",
              }}
            >
              <div className="w-100">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={center}
                  zoom={14}
                  options={options}
                  onLoad={(map) => {
                    setMapRef(map);
                    if (
                      google.maps &&
                      google.maps.marker &&
                      google.maps.marker.AdvancedMarkerElement
                    ) {
                      devices?.forEach((device) => {
                        const contentDiv = document.createElement("div");
                        contentDiv.style.display = "flex";
                        contentDiv.style.justifyContent = "center";
                        contentDiv.style.alignItems = "center";
                        contentDiv.style.background = device.status
                          ? "#22bb33"
                          : "#bb2124";
                        contentDiv.style.width = "23px";
                        contentDiv.style.height = "23px";
                        contentDiv.style.borderRadius = "50%";

                        const iconHTML = ReactDOMServer.renderToString(
                          <LuSmartphone size={13} color="white" />
                        );
                        contentDiv.innerHTML = iconHTML;

                        const marker =
                          new google.maps.marker.AdvancedMarkerElement({
                            position: {
                              lat: device.latitude,
                              lng: device.longitude,
                            },
                            content: contentDiv,
                          });

                        marker.setMap(map);

                        marker.addListener("click", () => {
                          handleDeviceClick(device);
                        });
                      });
                    } else {
                      //  console.error("AdvancedMarkerElement is not available");
                    }
                  }}
                >
                  {/* Renderizar InfoWindow para dispositivos */}
                  {renderPopup && selectedDevice && (
                    <InfoWindow
                      position={{
                        lat: selectedDevice.latitude,
                        lng: selectedDevice.longitude,
                      }}
                      options={{
                        pixelOffset: new window.google.maps.Size(0, -15),
                      }}
                      onCloseClick={() => {
                        setRenderPopup(false);
                      }}
                    >
                      <AddressPopup
                        selectedDevice={selectedDevice}
                        address={address}
                      />
                    </InfoWindow>
                  )}

                  {rightCardMode === "geofence" && mapRef && renderGeofences()}
                </GoogleMap>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={4} xl={3} md={12}>
          <RightCard
            handleGeofenceClick={handleGeofenceClick}
            handleDeviceClick={handleDeviceClick}
          />
        </Col>
      </Row>
    </>
  );
};

export default MapWithDeviceList;
