import React, { useState, useEffect } from "react";
import DepartamentsTable from "../components/Departaments/DepartamentsTable";
import { Row, Col } from "react-bootstrap";
import CreateDepartament from "../components/Departaments/CreateDepartament";
import DepDevices from "../components/Departaments/DepDevices";
import { DepartmentsContext } from "../Context/DepartmentsContext";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
const Departaments = () => {
  const { t } = useTranslation();

  document.title = `${t("DataDike MDM | Departments")}`;

  const [showModal, setShowModal] = useState(false);
  const [refreshState, setRefreshState] = useState(0);
  const { selectedDep, setSelectedDep } = useContext(DepartmentsContext);
  return (
    <div>
      {showModal && (
        <CreateDepartament
          show={showModal}
          onHide={() => setShowModal(false)}
          onAdd={() => setRefreshState(refreshState + 1)}
        />
      )}
      <div className="flex align-items-center justify-content-between mb-3 ms-1">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <span className="text-primary cursor-pointer">
                {t("Functionalities")}
              </span>
            </li>
            <li className="d-none breadcrumb-item active" aria-current="page">
              {t("Departments Management")}
            </li>
          </ol>
          <h4 className="main-title mb-0"> {t("Departments Management")}</h4>
        </div>

        <button onClick={() => setShowModal(true)} className="btn btn-primary">
          {t("Create Department")}
        </button>
      </div>
      <Row className="mb-2">
        <Col className="mb-2" xl={12} md={12}>
          <DepartamentsTable refreshState={refreshState} />
        </Col>

        {selectedDep && selectedDep.devicesInDepartment > 0 && (
          <Col xl={12} md={12}>
            <DepDevices
              refreshState={refreshState}
              setRefreshState={setRefreshState}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Departaments;
