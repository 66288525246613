import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import { MdMessage } from "react-icons/md";
import { t } from "i18next";
import { validateEmail } from "../../extraFunctions/validate.js";

const CreateUserModal = ({ close, addUser }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");

  const [showForm, setShowForm] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [main, setMain] = useState(true);
  const [emailInvite, setEmailInvite] = useState("");

  const [feedback, setFeedback] = useState(null);
  const lang = localStorage.getItem("lang") || "en";

  const handleSubmit = async () => {
    if (!validateEmail(emailInvite)) {
      setFeedback({
        ok: false,
        message: t("errorsDefault.invalidEmail"),
      });
      return;
    }
    setFeedback({
      ok: "sending",
      message: "Sending invite...",
    });

    try {
      const response = await axios.post("/api/v1/users/send-invite", {
        email: emailInvite,
        lang: lang,
      });

      if (response.status === 200) {
        setFeedback({
          ok: true,
          message:
            "Invite sent successfully. Check the mail box and the spam folder",
        });
        setEmailInvite("");
        setTimeout(() => {
          setFeedback(null);
        }, 5000);
      }
    } catch (error) {
      setFeedback({
        ok: false,
        message: error.response?.data?.message || "Error sending invite",
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (feedback) {
        setFeedback(null);
      }
    }, 2000);
  }, [feedback]);

  return (
    <Modal centered className="fixed-modal-2" show={true} onHide={close}>
      <Modal.Header closeButton>
        <h5 className="mb-2">{t("usersManagement.modal.title")}</h5>
      </Modal.Header>
      <Modal.Body className="mh-100 tw-overflow-auto d-flex align-items-center tw-justify-center">
        <div className="text-center">
          <h5 className="mb-2">{t("usersManagement.modal.message")}</h5>
          <input
            type="email"
            placeholder="Email"
            className="form-control"
            value={emailInvite}
            onChange={(e) => setEmailInvite(e.target.value)}
          />
          <div className="mt-4">
            {feedback && feedback.ok == true ? (
              <div className="p-2 tw-bg-green-500 tw-text-white font-semibold tw-rounded-md">
                {feedback.message}
              </div>
            ) : feedback && feedback.ok == false ? (
              <div className="p-2 tw-bg-red-500 tw-text-white font-semibold tw-rounded-md tw-animate-pulse">
                {feedback.message}
              </div>
            ) : feedback && feedback.ok == "sending" ? (
              <div className="p-2 tw-bg-datadike-blue tw-text-white font-semibold tw-rounded-md tw-animate-pulse">
                {feedback.message}
              </div>
            ) : null}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex tw-justify-end">
        <Button className="mx-1" variant="secondary" onClick={close}>
          {t("usersManagement.buttons.close")}
        </Button>
        <Button onClick={handleSubmit} variant="primary">
          {t("usersManagement.buttons.invite")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateUserModal;
