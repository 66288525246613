import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { Link } from "react-router-dom";
import { Row, Dropdown } from "react-bootstrap";
import { IoShieldCheckmarkSharp } from "react-icons/io5";

import FirstCards from "../components/dashboard/FirstCards";
import DevicesTable from "../components/dashboard/DevicesTable";
import NewDeviceModal from "../components/dashboard/NewDevice/index";
import FullyModal from "../components/dashboard/NewDevice/FullyModal";
import { FaPlus } from "react-icons/fa";

const Dashboard = () => {
  const [showModal, setShowModal] = useState(false);
  const [showFullyModal, setShowFullyModal] = useState(false);
  const [refreshState, setRefreshState] = useState(0);
  const { t } = useTranslation();

  document.title = `${t("DataDike MDM | Devices")}`;

  setTimeout(() => {
    setRefreshState((prevState) => prevState + 1);
  }, 30000);

  return (
    <React.Fragment>
      {showModal && <NewDeviceModal close={() => setShowModal(false)} />}

      {showFullyModal && <FullyModal close={() => setShowFullyModal(false)} />}
      <div className="">
        <div className="flex align-items-center justify-content-between mb-3 ms-1">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">{t("Cockpit")}</Link>
              </li>
              <li className="d-none breadcrumb-item active" aria-current="page">
                {t("Devices")}
              </li>
            </ol>
            <h4 className="main-title mb-0">{t("Devices")}</h4>
          </div>

          <div className="d-flex justify-content-end align-items-center">
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                {t("Add a new device")}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setShowFullyModal(true)}>
                  <span className="me-2 tw-text-green-600 font-size-15px">
                    <IoShieldCheckmarkSharp />
                  </span>
                  {t("dashboard.buttons.fullyManaged")}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setShowModal(true)}>
                  <span className="me-2 tw-text-datadike-blue font-size-15px">
                    <FaPlus />
                  </span>
                  {t("dashboard.buttons.onlyApp")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <Row className="mt-5 mb-3">
          <FirstCards refreshState={refreshState} />
        </Row>

        <div className="mt-0 pe-2">
          <DevicesTable refreshState={refreshState} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
