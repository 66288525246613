// Step2.js
import React, { useContext, useState } from "react";
import { EnterpriseContext } from "../../Context/EnterpriseContext";
import { InputGroup } from "react-bootstrap";
import { FaEye } from "react-icons/fa";

const Step2 = ({ next, prev }) => {
  const { enterpriseData, setEnterpriseData } = useContext(EnterpriseContext);
  const [passwordShown, setPasswordShown] = useState(false);

  const handleChange = (e) => {
    setEnterpriseData({
      ...enterpriseData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <h5 className="mb-4">Admin information</h5>
      <input
        type="text"
        name="enterpriseAdmin"
        placeholder="Admin Name"
        className="form-control w-100 mb-2"
        value={enterpriseData.enterpriseAdmin}
        onChange={handleChange}
      />
      <input
        type="email"
        name="enterpriseAdminEmail"
        placeholder="Admin Email"
        className="form-control w-100 mb-2"
        value={enterpriseData.enterpriseAdminEmail}
        onChange={handleChange}
      />
      <input
        type="text"
        name="enterpriseAdminPhone"
        placeholder="Admin Phone"
        className="form-control w-100 mb-2"
        value={enterpriseData.enterpriseAdminPhone}
        onChange={handleChange}
      />
      <InputGroup className="d-flex">
        <input
          type={passwordShown ? "text" : "password"}
          name="enterpriseAdminPassword"
          placeholder="Admin Password"
          value={enterpriseData.enterpriseAdminPassword}
          className="form-control w-90"
          onChange={handleChange}
        />

        <button
          onClick={() => {
            setPasswordShown(!passwordShown);
          }}
          className="btn btn-primary"
        >
          <FaEye />
        </button>
      </InputGroup>
    </div>
  );
};

export default Step2;
