import React from "react";
import androidGif from "../../../assets/GIF/scanqrcode.gif";
import { t } from "i18next";

const TutorialMessage = () => {
  return (
    <div className="p-1">
      <h5 className="welcome text-center mb-4 ">
        {t("With the device in hand, let's get started")}
      </h5>

      <div className="d-flex justify-content-center mb-4">
        <img
          src={androidGif}
          alt="android"
          style={{
            width: "125px",
          }}
        />
      </div>

      <div className="d-flex justify-content-center">
        <span className="text-center text-secondary">
          {t("Are you not with the device now?")}

          <span
            onClick={() => {
              //    const host = window.location.host; // Get the current host
              window.open(`/api/v1/download-app`);
            }}
            className="text-primary cursor-pointer ms-1"
          >
            {t("Download directly here")}
          </span>
        </span>
      </div>
    </div>
  );
};

export default TutorialMessage;
