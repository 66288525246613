import React, { useContext, useState, useRef } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";
import { AuthContext } from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { MdDiversity3 } from "react-icons/md";

const MfaModalVerify = ({ onHide }) => {
  const { userEmail } = useContext(AuthContext);
  const [tokens, setTokens] = useState(["", "", "", "", "", ""]);
  const [feedback, setFeedback] = useState(null);
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  const handleChange = (value, index) => {
    if (/^\d?$/.test(value)) {
      const newTokens = [...tokens];
      newTokens[index] = value;
      setTokens(newTokens);

      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !tokens[index]) {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = tokens.join("");
    setFeedback({ ok: "loading", message: "Verifying..." });

    try {
      const response = await fetch("/api/v1/login-with-mfa", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: userEmail, token }),
      });

      const result = await response.json();

      if (response.ok) {
        setFeedback({ ok: true, message: "Verification successful!" });
        setTimeout(() => {
          navigate("/");
        }, 1500); // Redirect after 1.5 seconds
      } else {
        setFeedback({
          ok: false,
          message: result.message || "Verification failed.",
        });
      }
    } catch (error) {
      setFeedback({
        ok: false,
        message: "An error occurred. Please try again.",
      });
    }
  };

  return (
    <div className="p-4">
      <Form onSubmit={handleSubmit}>
        <div>
          <h6 className="mt-1">{t("Enter your MFA token to continue")}</h6>

          <div className="d-flex justify-content-between">
            {tokens.map((token, index) => (
              <Form.Control
                key={index}
                type="text"
                value={token}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onFocus={handleFocus}
                maxLength="1"
                className="text-center"
                style={{ width: "2.5rem", marginRight: "0.5rem" }}
                ref={(el) => (inputRefs.current[index] = el)}
                required
              />
            ))}
          </div>
          {feedback && (
            <div
              className={
                feedback.ok === true
                  ? "tw-bg-green-500 tw-text-white p-3 tw-rounded-md w-100 mt-3"
                  : feedback.ok === false
                  ? "tw-bg-red-500 tw-text-white p-3 tw-rounded-md w-100 mt-3"
                  : feedback.ok === "loading"
                  ? "tw-bg-datadike-blue tw-text-white p-3 tw-rounded-md w-100 mt-3 text-center tw-animate-pulse d-block"
                  : ""
              }
            >
              {feedback.message}
              {feedback.ok === "loading" && (
                <h3 className="loading-icon-white tw-text-white mt-1">
                  <VscLoading />
                </h3>
              )}
            </div>
          )}
        </div>
        <div
          className="tw-border-0 mt-3"
          style={{
            border: "none !important",
          }}
        >
          <Button variant="primary" type="submit">
            {t("Submit")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default MfaModalVerify;
