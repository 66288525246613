import React from "react";
import { Link, NavLink } from "react-router-dom";
import useMenus from "../data/Menu";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FaPlus, FaBroadcastTower } from "react-icons/fa";
import { TbBinaryTree2 } from "react-icons/tb";
import { IoIosDocument, IoMdSettings } from "react-icons/io";
import { LiaPeopleCarrySolid } from "react-icons/lia";
import { PiTagSimple } from "react-icons/pi";

import dashboardGif from "../assets/sidebarIcons/dashboard.gif";
import dashboardPng from "../assets/sidebarIcons/dashboard.png";
import usersGif from "../assets/sidebarIcons/user.gif";
import usersPng from "../assets/sidebarIcons/user.png";
import sysadminPng from "../assets/sidebarIcons/sysadmin.png";
import sysadminGif from "../assets/sidebarIcons/sysadmin.gif";
import termsPng from "../assets/sidebarIcons/terms.png";
import termsGif from "../assets/sidebarIcons/terms.gif";
import invoicePng from "../assets/sidebarIcons/invoice.png";
import invoiceGif from "../assets/sidebarIcons/invoice.gif";
import { BsUpload } from "react-icons/bs";
import { IoDocumentTextOutline } from "react-icons/io5";
import { OverlayTrigger, Tooltip } from "react-bootstrap"; // Importação de OverlayTrigger e Tooltip
import { t } from "i18next";

import devicesPng from "../assets/sidebarIcons/devices.png";
import devicesGif from "../assets/sidebarIcons/devices.gif";
import { useTranslation } from "react-i18next";
import { FaLocationDot } from "react-icons/fa6";
import { IoLocationOutline } from "react-icons/io5";

const Sidebar = () => {
  const toggleFooterMenu = (e) => {
    e.preventDefault();
    let parent = e.target.closest(".sidebar");
    parent.classList.toggle("footer-menu-show");
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <Link to="/" style={{ fontWeight: "1000" }} className="sidebar-logo">
          DataDike
        </Link>
        <span
          onClick={() => {
            window.location.replace("/");
          }}
          className="sidebar-logo-mdm logo-mdm tw-cursor-pointer"
        >
          MDM
        </span>
      </div>
      <div className="sidebar-body">
        <SidebarMenu />
      </div>
    </div>
  );
};

const SidebarMenu = () => {
  const {
    dashboardMenu,
    applicationsMenu,
    pagesMenu,
    uiElementsMenu,
    panelElementsMenu,
  } = useMenus();

  const { t } = useTranslation();

  const [hoveredIconKey, setHoveredIconKey] = React.useState(null);

  const populateMenu = (m) => {
    const menu = m.map((item, key) => {
      if (process.env.NODE_ENV === "production" && item.key === "forense") {
        return null;
      }
      let iconSrc;
      switch (item.key) {
        case "dashboard":
          iconSrc =
            item.key === hoveredIconKey ? (
              <img
                className="sidebar-icons"
                src={dashboardGif}
                alt="dashboard"
              />
            ) : (
              <img
                className="sidebar-icons"
                src={dashboardPng}
                alt="dashboard"
              />
            );
          break;

        case "registerDevice":
          iconSrc = <FaPlus />;
          break;

        case "users":
          iconSrc =
            item.key === hoveredIconKey ? (
              <img className="sidebar-icons" src={usersGif} alt="users" />
            ) : (
              <img className="sidebar-icons" src={usersPng} alt="userss" />
            );
          break;
        case "invoices":
          iconSrc =
            item.key === hoveredIconKey ? (
              <img className="sidebar-icons" src={invoiceGif} alt="users" />
            ) : (
              <img className="sidebar-icons" src={invoicePng} alt="userss" />
            );
          break;

        case "sysadmin":
          iconSrc =
            item.key === hoveredIconKey ? (
              <img className="sidebar-icons" src={sysadminGif} alt="users" />
            ) : (
              <img className="sidebar-icons" src={sysadminPng} alt="userss" />
            );
          break;
        case "departaments":
          iconSrc = <TbBinaryTree2 />;
          break;

        case "terms":
          iconSrc =
            item.key === hoveredIconKey ? (
              <img className="sidebar-icons" src={termsGif} alt="users" />
            ) : (
              <img className="sidebar-icons" src={termsPng} alt="userss" />
            );
          break;

        case "settings":
          iconSrc = <IoMdSettings />;
          break;

        case "funcionario":
          iconSrc = <LiaPeopleCarrySolid />;
          break;

        case "lines":
          iconSrc = <FaBroadcastTower />;
          break;

        case "tags":
          iconSrc = <PiTagSimple />;
          break;

        case "update":
          iconSrc = <BsUpload />;
          break;

        case "policies":
          iconSrc = <IoDocumentTextOutline />;
          break;
        case "info":
          iconSrc = <IoDocumentTextOutline />;
          break;

        case "geofence":
          iconSrc = <IoLocationOutline />;
          break;

        default:
      }

      const isInfoItem = item.key === "info";
      const itemContent = (
        <NavLink
          to={isInfoItem ? null : item.link}
          className={`nav-link ${isInfoItem ? "sidebar-disable-item" : ""}`}
        >
          <i>{iconSrc}</i> <span>{item.label}</span>
        </NavLink>
      );

      const iconElement = <i>{iconSrc}</i>;

      return (
        <li
          key={key}
          className="nav-item"
          onClick={toggleSubMenu}
          onMouseEnter={() => setHoveredIconKey(item.key)}
          onMouseLeave={() => setHoveredIconKey(null)}
        >
          {isInfoItem ? (
            // Envolvemos o item com OverlayTrigger se for o item "info"
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id={`tooltip-${key}`}>
                  {t("sidebar.disabledItem.message")}
                </Tooltip>
              }
            >
              {itemContent}
            </OverlayTrigger>
          ) : (
            itemContent
          )}
        </li>
      );
    });

    return <ul className="nav nav-sidebar">{menu}</ul>;
  };

  const isInDevelopment = process.env.NODE_ENV === "development";

  const toggleMenu = (e) => {
    e.preventDefault();
    let parent = e.target.closest(".nav-group");
    parent.classList.toggle("show");
  };

  const toggleSubMenu = (e) => {
    e.preventDefault();
    let parent = e.target.closest(".nav-item");
    if (!parent.classList.contains("show")) {
      const allSubMenus = document.querySelectorAll(".nav-item.show");
      allSubMenus.forEach((submenu) => submenu.classList.remove("show"));
      parent.classList.add("show");
    } else {
      parent.classList.remove("show");
    }
  };

  return (
    <React.Fragment>
      <div className="nav-group show">
        <div className="nav-label" onClick={toggleMenu}>
          {t("Cockpit")}
        </div>
        {populateMenu(dashboardMenu)}
      </div>

      <div className="nav-group show">
        <div className="nav-label text-uppercase" onClick={toggleMenu}>
          {t("Functionalities")}
        </div>
        {populateMenu(pagesMenu)}
      </div>

      <div className="nav-group show">
        <div className="nav-label" onClick={toggleMenu}>
          {t("Plataform Admin")}
        </div>
        {populateMenu(applicationsMenu)}
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
