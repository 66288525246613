import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";
import useSearch from "../../hooks/useSearch";
import { badgesDefault } from "../../extraFunctions/badgesTw";
import { GoDotFill } from "react-icons/go";
import { t } from "i18next";

const ChangeLog = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [piscando, setPiscando] = useState(true);
  const lang = localStorage.getItem("lang") || "en";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `/api/v1/datadike/change-logs?lang=${lang}`
        );

        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error("Failed to fetch change logs:", error);
        setError("Failed to fetch change logs");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const { filteredData, searchTerm, setSearchTerm } = useSearch(data, [
    "version",
    "date",
  ]);

  useEffect(() => {
    setTimeout(() => {
      setPiscando(false);
    }, 3700);
  }, []);

  if (loading) {
    return (
      <Card className="custom-card-1" style={{ height: "740px" }}>
        <Card.Header>
          <h5 className="mb-0">{t("Change Logs")}</h5>
        </Card.Header>
        <Card.Body className="jc ac">
          <h5 className="loading-icon">
            <VscLoading />
          </h5>
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="custom-card-1" style={{ height: "740px" }}>
        <Card.Header>
          <h5 className="mb-0">{t("Change Logs")}</h5>
        </Card.Header>
        <Card.Body className="jc">
          <h6>{error}</h6>
        </Card.Body>
      </Card>
    );
  }

  const sectionColors = {
    improvements: "tw-text-blue-400",
    fixes: "tw-text-yellow-400",
    removals: "tw-text-red-400",
    new: "tw-text-green-400",
  };

  return (
    <Card
      style={{
        height: "740px",
      }}
      className="custom-card-1"
    >
      <Card.Header>
        <h5 className="mb-0">{t("Change Logs")}</h5>
      </Card.Header>
      <Card.Body className="mh-100 overflow-auto">
        {data && data.length > 0 ? (
          data.map((log, index) => (
            <div
              key={index}
              className="tw-p-3 tw-mb-4 tw-border tw-rounded-lg tw-shadow-lg"
            >
              <h4 className="tw-font-bold text-dark ac">
                {t("Version")}: {log.version}
                {index === 0 && (
                  <div className={`${piscando ? "tw-animate-pulse" : ""}`}>
                    <div className={`${badgesDefault.defaultBold} ms-2 `}>
                      {t("LATEST")}
                    </div>
                  </div>
                )}
              </h4>
              <p className="tw-text-gray-700">
                {t("Release Date")}: {log.date}
              </p>

              {Object.entries(log.sections).map(([sectionName, items]) => (
                <div key={sectionName} className="tw-mb-3">
                  <h6 className={`font-semibold tw-mb-2 text-dark`}>
                    <span
                      className={` ${sectionColors[sectionName]} icone-torto me-1`}
                    >
                      <GoDotFill />
                    </span>

                    {t(
                      sectionName.charAt(0).toUpperCase() + sectionName.slice(1)
                    )}
                  </h6>
                  <div className="tw-list-disc tw-pl-5">
                    {items.map((item, idx) => (
                      <div className="ms-1 mb-1" key={idx}>
                        <div className="mb-1">
                          <span className="tw-font-bold">
                            <span className="tw-font-normal me-1">-</span>
                            {t(item.title)}
                          </span>
                        </div>
                        <div className="ms-3"> {t(item.description)}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))
        ) : (
          <pre>data:{JSON.stringify(data)}</pre>
        )}
      </Card.Body>
    </Card>
  );
};

export default ChangeLog;
