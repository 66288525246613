import React, { useEffect, useContext, useState } from "react";
import { Card, Table, Button, Form } from "react-bootstrap";
import { DepartmentsContext } from "../../Context/DepartmentsContext";
import { useGet } from "../../hooks/get";
import { useSort } from "../../hooks/useSort";
import useSearch from "../../hooks/useSearch";
import { convertToLocalTime } from "../../hooks/convertDate";
import { useTranslation } from "react-i18next";

const DepDevices = ({ refreshState, setRefreshState }) => {
  const { selectedDep, setDevices, devices } = useContext(DepartmentsContext);
  const { data, loading } = useGet(
    `/api/v1/department/view-devices/${selectedDep.departmentId}`,
    refreshState
  );
  const { t } = useTranslation();
  const [selectedDevices, setSelectedDevices] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const formattedData = data.map((device) => ({
        deviceId: device.deviceId,
        deviceName: device.deviceName,
        addedBy: device.addedBy,
        addedAt: device.addedAt ? convertToLocalTime(device.addedAt) : "",
      }));
      setDevices(formattedData);
    }
  }, [data, loading]);

  const { sortedData, requestSort } = useSort(devices);
  const { filteredData, searchTerm, setSearchTerm } = useSearch(sortedData, [
    "deviceId",
    "deviceName",
    "addedBy",
    "addedAt",
  ]);

  const handleSelectDevice = (deviceId) => {
    setSelectedDevices((prevSelectedDevices) =>
      prevSelectedDevices.includes(deviceId)
        ? prevSelectedDevices.filter((id) => id !== deviceId)
        : [...prevSelectedDevices, deviceId]
    );
  };

  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleSelectAll = () => {
    if (!isAllSelected) {
      setSelectedDevices(filteredData.map((device) => device));
      setIsAllSelected(true);
    } else {
      setSelectedDevices([]);
      setIsAllSelected(false);
    }
  };

  const submit = () => {
    fetch(`/api/v1/manage-device/remove-from-department`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        devices: selectedDevices.map((device) => ({
          deviceId: device.deviceId,
          deviceName: device.deviceName,
        })),
        departmentId: selectedDep.departmentId,
      }),
    })
      .then((response) => {
        response.json();
        if (response.ok) {
          const updatedDevices = devices.filter(
            (device) => !selectedDevices.includes(device.deviceId)
          );
          setDevices(updatedDevices);
          setSelectedDevices([]);
          setTimeout(() => {
            setRefreshState(refreshState + 1);
          }, 500);
        }
      })
      .then((data) => console.log("Success:", data))
      .catch((error) => console.error("Error:", error));
  };

  return (
    <React.Fragment>
      <Card
        style={{
          minHeight: "400px",
        }}
        className="custom-card-1"
      >
        <Card.Header>
          <div>
            <h4 className="mb-0">
              {t("Devices in")} {selectedDep.departmentName}
            </h4>
          </div>
          <div>
            <input
              type="text"
              placeholder={`${t("Search...")}`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control w-80"
            />
          </div>
        </Card.Header>
        <Card.Body>
          <Table className="table-users table-hover">
            <thead>
              <tr>
                <th
                  onClick={() => handleSelectAll()}
                  className="center-text-th"
                >
                  #
                </th>
                <th onClick={() => requestSort("deviceName")}>
                  {t("Device Name")}
                </th>
                <th onClick={() => requestSort("addedBy")}>{t("Added By")}</th>
                <th onClick={() => requestSort("addedAt")}>{t("Added At")}</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((device, index) => (
                <tr key={device.deviceId}>
                  <td className="center-text-td cursor-pointer">
                    <Form.Check
                      type="checkbox"
                      checked={selectedDevices.includes(device)}
                      onChange={() => handleSelectDevice(device)}
                    />
                  </td>

                  <td>{device.deviceName}</td>
                  <td>{device.addedBy}</td>
                  <td>{device.addedAt}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>

        {selectedDevices.length > 0 && (
          <Card.Footer>
            <button className="btn btn-danger" onClick={submit}>
              Delete selected devices
            </button>
          </Card.Footer>
        )}
      </Card>
    </React.Fragment>
  );
};

export default DepDevices;
