import React, { useState, useRef, useContext } from "react";
import { InputGroup, Modal } from "react-bootstrap";
import { RiLockPasswordLine } from "react-icons/ri";
import { MdSyncLock } from "react-icons/md";
import { t } from "i18next";
import { AuthContext } from "../../Context/AuthContext";
import { VscLoading } from "react-icons/vsc";
import { LuShieldAlert } from "react-icons/lu";
import { LuShieldCheck } from "react-icons/lu";

import { badgesDefault } from "../../extraFunctions/badgesTw";
import { FaRemoveFormat } from "react-icons/fa";
const MFAModal = ({ showMfa, setShowMfa, close }) => {
  const { hasMfa, setHasMfa } = useContext(AuthContext);
  const [resetingMfa, setResetingMfa] = useState(false);
  const [resetTokens, setResetTokens] = useState(["", "", "", "", "", ""]);
  const [feedback, setFeedback] = useState(null);
  const [qrCode, setQrCode] = useState(null);
  const [activating, setActivating] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  const resetInputRefs = useRef([]);

  const handleChange = (value, index, setFunc, array, refs) => {
    if (/^\d?$/.test(value)) {
      const newArray = [...array];
      newArray[index] = value;
      setFunc(newArray);

      if (value && index < 5) {
        setTimeout(() => {
          if (refs.current[index + 1]) {
            refs.current[index + 1].focus();
          }
        }, 0);
      }
    }
  };

  const handleKeyDown = (e, index, array, refs) => {
    if (e.key === "Backspace" && !array[index]) {
      if (index > 0) {
        refs.current[index - 1].focus();
      }
    }
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const cancelMfa = async () => {
    const token = resetTokens.join("");
    const response = await fetch("/api/v1/mfa/reset-mfa", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
      }),
    });

    const data = await response.json();

    if (response.ok) {
      setResetTokens(["", "", "", "", "", ""]);
      setHasMfa(false);
      setResetingMfa(false);

      setFeedback({
        ok: true,
        message: data.message || "success",
      });

      setTimeout(() => {
        close();
      }, 5000);
    } else {
      setFeedback({
        ok: false,
        message: data.message || "erro",
      });
    }
  };

  const activateMfa = async () => {
    const response = await fetch("/api/v1/mfa/activate-mfa", {
      method: "POST",
    });

    const data = await response.json();

    if (response.ok) {
      setQrCode(data.qrCodeUrl);
    }
  };

  const confirmMfa = async () => {
    const token = tokens.join("");
    if (!token) {
      setFeedback({
        ok: false,
        message: t("Field can't be empty"),
      });
      return;
    }

    let body = {
      MFAToken: token,
    };
    const response = await fetch("/api/v1/mfa/comfirm-mfa", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const data = await response.json();

    if (response.ok) {
      setHasMfa(true);

      setFeedback({
        ok: true,
        message: data.message || "Sucesso e sem mensagem",
      });

      setTimeout(() => {
        close();
      }, 5000);
    } else {
      setFeedback({
        ok: false,
        message: data.message,
      });
    }
  };

  if (hasMfa) {
    return (
      <Modal
        centered
        className="fixed-modal"
        show={showMfa}
        onHide={() => setShowMfa(false)}
      >
        <Modal.Body className="d-flex align-items-center justify-content-center">
          <div className="w-100 p-2 text-center">
            {!resetingMfa ? (
              <div className="mb-2 d-block text-center">
                <h1 className="tw-text-blue-800">
                  <LuShieldCheck
                    style={{
                      fontSize: "80px",
                    }}
                  />
                </h1>

                <h5 className="mb-0">
                  {t("Your Multi-Factor Authentication is activated!")}
                </h5>
              </div>
            ) : (
              <h5 className="mb-3">{t("To remove MFA, enter the token")}</h5>
            )}

            <div className="w-100">
              {!resetingMfa && (
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    setResetingMfa(true);
                  }}
                  className="btn btn-danger tw-tracking-widest"
                >
                  {t("Disable MFA")}
                </span>
              )}
              {resetingMfa && (
                <>
                  <div className="d-flex justify-content-between">
                    {resetTokens.map((token, index) => (
                      <input
                        key={index}
                        type="text"
                        value={token}
                        onChange={(e) =>
                          handleChange(
                            e.target.value,
                            index,
                            setResetTokens,
                            resetTokens,
                            resetInputRefs
                          )
                        }
                        onKeyDown={(e) =>
                          handleKeyDown(e, index, resetTokens, resetInputRefs)
                        }
                        onFocus={handleFocus}
                        maxLength="1"
                        className="text-center form-control"
                        style={{ width: "3rem", marginRight: "0.5rem" }}
                        ref={(el) => (resetInputRefs.current[index] = el)}
                        required
                      />
                    ))}
                  </div>
                  <div className="mt-2">
                    <button
                      onClick={cancelMfa}
                      className="btn btn-outline-danger"
                    >
                      {t("Disable")}
                    </button>
                  </div>
                </>
              )}
            </div>
            {/* Add your MFA content here */}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            onClick={() => setShowMfa(false)}
            className="btn btn-secondary"
          >
            {t("Close")}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal centered show={showMfa} onHide={() => setShowMfa(false)}>
      <Modal.Body className="d-flex align-items-center justify-content-center">
        <div>
          {!activating && (
            <>
              <div className={` mb-3 d-block text-center`}>
                <div className="mb-2">
                  <h1 className="tw-text-yellow-500">
                    <LuShieldAlert
                      style={{
                        fontSize: "80px",
                      }}
                    />
                  </h1>
                </div>
                {!hasMfa && (
                  <h5 className="">{t("You have not activated the MFA.")}</h5>
                )}
              </div>

              <div className="w-100 text-center">
                <button
                  onClick={() => {
                    setActivating(true);
                    activateMfa();
                  }}
                  className="mb-4 btn btn-primary w-100 tw-tracking-widest text-uppercase"
                >
                  {t("Activate")}
                </button>
                <span
                  onClick={() => setShowMfa(false)}
                  className=" tw-text-gray-500 cursor-pointer tw-tracking-wider"
                >
                  {t("DO THIS LATER")}
                </span>
              </div>
            </>
          )}

          {activating && !loading ? (
            <div className="mt-3 w-100">
              <div className="w-100 jc text-center ac">
                <img className="mb-3" src={qrCode} alt="qrcode" />
              </div>
              <div className="d-flex justify-content-between">
                {tokens.map((token, index) => (
                  <input
                    key={index}
                    type="text"
                    value={token}
                    onChange={(e) =>
                      handleChange(
                        e.target.value,
                        index,
                        setTokens,
                        tokens,
                        inputRefs
                      )
                    }
                    onKeyDown={(e) =>
                      handleKeyDown(e, index, tokens, inputRefs)
                    }
                    onFocus={handleFocus}
                    maxLength="1"
                    className="text-center form-control"
                    style={{ width: "3rem", marginRight: "0.5rem" }}
                    ref={(el) => (inputRefs.current[index] = el)}
                    required
                  />
                ))}
              </div>
              <button
                disabled={tokens.some((token) => !token)}
                onClick={confirmMfa}
                className="btn btn-primary w-100 mt-3"
              >
                {t("Confirm")}
              </button>
            </div>
          ) : loading ? (
            <div className="mw-100 mh-100">
              <h4 className="loading-icon">
                <VscLoading />
              </h4>
            </div>
          ) : null}

          {feedback ? (
            <div
              className={
                feedback.ok == true
                  ? "tw-bg-green-500 tw-text-white p-3 tw-rounded-md w-100 mt-3"
                  : feedback.ok == false
                  ? "tw-bg-red-500 tw-text-white p-3 tw-rounded-md w-100 mt-3"
                  : feedback.ok == "loading"
                  ? "tw-bg-datadike-blue tw-text-white p-3 tw-rounded-md w-100 mt-3 text-center tw-animate-pulse d-block"
                  : ""
              }
            >
              {feedback.message}
            </div>
          ) : null}
        </div>
      </Modal.Body>

      {activating && (
        <Modal.Footer>
          <button
            type="button"
            onClick={() => setShowMfa(false)}
            className="btn btn-secondary"
          >
            {t("Cancel")}
          </button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default MFAModal;
