import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { VscLoading } from "react-icons/vsc";
import { useLocation } from "react-router-dom";

import dd from "./DDMDM.png";
const EndGoogle = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  // Function to extract query parameters from the URL
  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };

  // Get the enterpriseToken from the query parameters
  const queryParams = getQueryParams(location.search);
  const enterpriseToken = queryParams.get("enterpriseToken");

  // Set the document title
  useEffect(() => {
    document.title = `${t("DataDike MDM | Google Enterprise")}`;
  }, [t]);

  useEffect(() => {
    const fetchEnterpriseToken = async () => {
      try {
        const response = await fetch(
          `/api/v1/google/enterprise-callback?enterpriseToken=${enterpriseToken}`,
          {
            method: "POST",
          }
        );

        if (response.ok) {
          // Handle success if needed
          setLoading(false);
          setTimeout(() => {
            window.location.replace("/");
          }, 3000);
        } else {
          // Handle errors if needed
        }
      } catch (error) {
        console.error("Error fetching enterprise token:", error);
      }
    };

    fetchEnterpriseToken();
  }, [enterpriseToken]);

  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
        }}
        className=" jc ac"
      >
        <div
          style={{
            display: "block",
          }}
        >
          <div className="mh-100 mw-100 jc ac">
            <h4 className="loading-icon-2">
              <VscLoading />
            </h4>
          </div>
          <h6 className="tw-animate-pulse text-dark mt-2 text-center">
            {t("Finalizing your registration")}...
          </h6>

          <div className="mt-2">
            <img style={{ width: "250px" }} alt="" src={dd} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100vh",
      }}
      className=" jc ac"
    >
      <div
        style={{
          display: "block",
        }}
      >
        <h6 className="text-dark mt-2 text-center">
          {t("Successfully registered with Google Enterprise")}...
        </h6>

        <div className="mt-2">
          <img style={{ width: "250px" }} alt="" src={dd} />
        </div>
      </div>
    </div>
  );
};

export default EndGoogle;
