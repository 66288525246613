// buttonsTw.js

const buttonsTw = {
  gray: "tw-bg-gray-500  tw-rounded tw-shadow-md hover:tw-shadow-lg   hover:tw-bg-gray-600   text-white py-2 px-3 border-0",

  default:
    "tw-bg-datadike-blue  tw-rounded tw-shadow-md hover:tw-shadow-lg     hover:tw-bg-datadike-dark   text-white py-2 px-3 border-0",

  alternative:
    "border-0 tw-py-2.5 tw-px-5 tw-me-2 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-focus:outline-none tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 tw-hover:bg-gray-100 tw-hover:text-blue-700 tw-focus:z-10 tw-focus:ring-4 tw-focus:ring-gray-200 tw-dark:focus:ring-gray-700 tw-dark:bg-gray-800 tw-dark:text-gray-400 tw-dark:border-gray-600 tw-dark:hover:text-white tw-dark:hover:bg-gray-700",

  white:
    "border-0 tw-text-gray-900 tw-bg-white tw-border tw-border-gray-300 tw-focus:outline-none tw-hover:bg-gray-100 tw-focus:ring-4 tw-focus:ring-gray-200 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2 tw-dark:bg-gray-800 tw-dark:text-white tw-dark:border-gray-600 tw-dark:hover:bg-gray-700 tw-dark:hover:border-gray-600 tw-dark:focus:ring-gray-700",

  green:
    "border-0 tw-text-white tw-bg-green-700 tw-hover:bg-green-800 tw-focus:outline-none tw-focus:ring-4 tw-focus:ring-green-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2 tw-dark:bg-green-600 tw-dark:hover:bg-green-700 tw-dark:focus:ring-green-800",

  red: "border-0 tw-focus:outline-none tw-text-white tw-bg-red-700 tw-hover:bg-red-800 tw-focus:ring-4 tw-focus:ring-red-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2 tw-dark:bg-red-600 tw-dark:hover:bg-red-700 tw-dark:focus:ring-red-900",
  yellow:
    "border-0 tw-text-white tw-bg-yellow-400 tw-hover:bg-yellow-500 tw-focus:outline-none tw-focus:ring-4 tw-focus:ring-yellow-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2 tw-dark:focus:ring-yellow-900",
  purple:
    "border-0 tw-text-white tw-bg-purple-700 tw-hover:bg-purple-800 tw-focus:outline-none tw-focus:ring-4 tw-focus:ring-purple-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-mb-2 tw-dark:bg-purple-600 tw-dark:hover:bg-purple-700 tw-dark:focus:ring-purple-900",

  disable:
    "bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed",
  light:
    "text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700",
};

const buttonsTwRounded = {
  default:
    "border-0 tw-text-white tw-bg-blue-700 tw-hover:bg-blue-800 tw-focus:outline-none tw-focus:ring-4 tw-focus:ring-blue-300 tw-font-medium tw-rounded-full tw-text-sm tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2 tw-dark:bg-blue-600 tw-dark:hover:bg-blue-700 tw-dark:focus:ring-blue-800",

  alternative:
    "border-0 tw-py-2.5 tw-px-5 tw-me-2 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-focus:outline-none tw-bg-white tw-rounded-full tw-border tw-border-gray-200 tw-hover:bg-gray-100 tw-hover:text-blue-700 tw-focus:z-10 tw-focus:ring-4 tw-focus:ring-gray-200 tw-dark:focus:ring-gray-700 tw-dark:bg-gray-800 tw-dark:text-gray-400 tw-dark:border-gray-600 tw-dark:hover:text-white tw-dark:hover:bg-gray-700",

  dark: "border-0 tw-text-white tw-bg-gray-800 tw-hover:bg-gray-900 tw-focus:outline-none tw-focus:ring-4 tw-focus:ring-gray-300 tw-font-medium tw-rounded-full tw-text-sm tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2 tw-dark:bg-gray-800 tw-dark:hover:bg-gray-700 tw-dark:focus:ring-gray-700 tw-dark:border-gray-700",

  light:
    "border-0 tw-text-gray-900 tw-bg-white tw-border tw-border-gray-300 tw-focus:outline-none tw-hover:bg-gray-100 tw-focus:ring-4 tw-focus:ring-gray-200 tw-font-medium tw-rounded-full tw-text-sm tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2 tw-dark:bg-gray-800 tw-dark:text-white tw-dark:border-gray-600 tw-dark:hover:bg-gray-700 tw-dark:hover:border-gray-600 tw-dark:focus:ring-gray-700",

  green:
    "border-0 tw-text-white tw-bg-green-700 tw-hover:bg-green-800 tw-focus:outline-none tw-focus:ring-4 tw-focus:ring-green-300 tw-font-medium tw-rounded-full tw-text-sm tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2 tw-dark:bg-green-600 tw-dark:hover:bg-green-700 tw-dark:focus:ring-green-800",

  red: "border-0 tw-text-white tw-bg-red-700 tw-hover:bg-red-800 tw-focus:outline-none tw-focus:ring-4 tw-focus:ring-red-300 tw-font-medium tw-rounded-full tw-text-sm tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2 tw-dark:bg-red-600 tw-dark:hover:bg-red-700 tw-dark:focus:ring-red-900",

  yellow:
    "border-0 tw-text-white tw-bg-yellow-400 tw-hover:bg-yellow-500 tw-focus:outline-none tw-focus:ring-4 tw-focus:ring-yellow-300 tw-font-medium tw-rounded-full tw-text-sm tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2 tw-dark:focus:ring-yellow-900",

  disable:
    "bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed",
  purple:
    "border-0 tw-text-white tw-bg-purple-700 tw-hover:bg-purple-800 tw-focus:outline-none tw-focus:ring-4 tw-focus:ring-purple-300 tw-font-medium tw-rounded-full tw-text-sm tw-px-5 tw-py-2.5 tw-mb-2 tw-dark:bg-purple-600 tw-dark:hover:bg-purple-700 tw-dark:focus:ring-purple-900",
};
const buttonsTwNb = {
  dark: "border-0 tw-text-gray-900 tw-hover:text-white tw-border tw-border-gray-800 tw-hover:bg-gray-900 tw-focus:ring-4 tw-focus:outline-none tw-focus:ring-gray-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-text-center tw-me-2 tw-mb-2 tw-dark:border-gray-600 tw-dark:text-gray-400 tw-dark:hover:text-white tw-dark:hover:bg-gray-600 tw-dark:focus:ring-gray-800",
  default:
    "border-0 tw-text-gray-900 tw-hover:text-white tw-border tw-border-gray-800 tw-hover:bg-gray-900 tw-focus:ring-4 tw-focus:outline-none tw-focus:ring-gray-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-text-center tw-me-2 tw-mb-2 tw-dark:border-gray-600 tw-dark:text-gray-400 tw-dark:hover:text-white tw-dark:hover:bg-gray-600 tw-dark:focus:ring-gray-800",
  green:
    "border-0 tw-text-green-700 tw-hover:text-white tw-border tw-border-green-700 tw-hover:bg-green-800 tw-focus:ring-4 tw-focus:outline-none tw-focus:ring-green-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-text-center tw-me-2 tw-mb-2 tw-dark:border-green-500 tw-dark:text-green-500 tw-dark:hover:text-white tw-dark:hover:bg-green-600 tw-dark:focus:ring-green-800",
  red: "border-0 tw-text-red-700 tw-hover:text-white tw-border tw-border-red-700 tw-hover:bg-red-800 tw-focus:ring-4 tw-focus:outline-none tw-focus:ring-red-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-text-center tw-me-2 tw-mb-2 tw-dark:border-red-500 tw-dark:text-red-500 tw-dark:hover:text-white tw-dark:hover:bg-red-600 tw-dark:focus:ring-red-900",
  yellow:
    "border-0 tw-text-yellow-400 tw-hover:text-white tw-border tw-border-yellow-400 tw-hover:bg-yellow-500 tw-focus:ring-4 tw-focus:outline-none tw-focus:ring-yellow-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-text-center tw-me-2 tw-mb-2 tw-dark:border-yellow-300 tw-dark:text-yellow-300 tw-dark:hover:text-white tw-dark:hover:bg-yellow-400 tw-dark:focus:ring-yellow-900",

  disable:
    "bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed",
  purple:
    "border-0 tw-text-purple-700 tw-hover:text-white tw-border tw-border-purple-700 tw-hover:bg-purple-800 tw-focus:ring-4 tw-focus:outline-none tw-focus:ring-purple-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-text-center tw-me-2 tw-mb-2 tw-dark:border-purple-400 tw-dark:text-purple-400 tw-dark:hover:text-white tw-dark:hover:bg-purple-500 tw-dark:focus:ring-purple-900",
};

export { buttonsTwRounded, buttonsTw, buttonsTwNb };
