import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { convertToLocalTime } from "../../hooks/convertDate";
import { useGet } from "../../hooks/get";
import { useSort } from "../../hooks/useSort";
import useSearch from "../../hooks/useSearch";
import { FaPen, FaTrash } from "react-icons/fa";
import { IoIosMore } from "react-icons/io";

import EmpEdit from "./EmployeEdit";
import { useTranslation } from "react-i18next";

const EmpTable = ({ refreshState, setRefreshState, onEmp }) => {
  const { t } = useTranslation();
  const [employess, setEmployess] = useState([]);
  const [editData, setEditData] = useState(null);
  const [selectedEmp, setSelectedEmp] = useState(null);
  const { data: employesApi } = useGet(
    "/api/v1/employees/view-employees",
    refreshState
  );

  useEffect(() => {
    if (employesApi && employesApi.length > 0) {
      const convertedData = employesApi.map((emp) => {
        return {
          employeeId: emp.employeeId,
          employeeName: emp.employeeName,
          employeeRegistration: emp.employeeRegistration
            ? emp.employeeRegistration
            : "",
          employeeTel: emp.employeeTel,
          employeeJobTitle: emp.employeeJobTitle,
          employeeDepartment: emp.employeeDepartment,
          devicesInEmployee: emp.devicesInEmployee,
          employeeDepartmentId: emp.employeeDepartmentId,
          registrationDate: convertToLocalTime(emp.registrationDate),
        };
      });

      if (!selectedEmp) {
        setSelectedEmp(convertedData[0]);
        onEmp(convertedData[0]);
      }

      setEmployess(convertedData);
    }
  }, [employesApi]);

  const { sortedData, requestSort } = useSort(employess);
  const { filteredData, setSearchTerm, searchTerm } = useSearch(sortedData, [
    "employeeName",
    "employeeRegistration",
    "employeeTel",
    "employeeJobTitle",
    "employeeDepartment",
    "devicesInEmployee",
    "registrationDate",
  ]);

  const deleteEmployee = (employeeId) => {
    fetch(`/api/v1/employees/delete-employee/${employeeId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const newEmploye = employess.filter(
          (emp) => emp.employeeId !== employeeId
        );
        setEmployess(newEmploye);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  return (
    <Card
      className="custom-card-1"
      style={{
        minHeight: "400px",
        maxHeight: "500px",
      }}
    >
      <Card.Header>
        <h4></h4>

        <div className="d-flex tw-justify-end">
          <input
            type="text"
            className="form-control w-50 me-1"
            placeholder={`${"Search..."}`}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </Card.Header>

      <Card.Body>
        <Table className="table-users" hover>
          <thead>
            <tr>
              <th className="center-text-th">#</th>
              <th onClick={() => requestSort("employeeName")}>{t("Name")}</th>
              <th onClick={() => requestSort("employeeRegistration")}>
                {t("Registration")}
              </th>
              <th onClick={() => requestSort("employeeTel")}>
                {t("Telephone")}
              </th>
              <th onClick={() => requestSort("employeeJobTitle")}>
                {t("Job Title")}
              </th>
              <th onClick={() => requestSort("employeeDepartment")}>
                {t("Department")}
              </th>
              <th
                className="center-text-th"
                onClick={() => requestSort("devicesInEmployee")}
              >
                {t("Devices")}
              </th>
              <th onClick={() => requestSort("registrationDate")}>
                {t("Registration Date")}
              </th>
              <th className="center-text-th">{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((emp, index) => (
              <tr
                onClick={() => {
                  if (selectedEmp.employeeId !== emp.employeeId) {
                    setSelectedEmp(emp);
                    onEmp(emp);
                  }
                }}
                className={
                  emp.employeeId == selectedEmp.employeeId
                    ? "table-secondary"
                    : ""
                }
                key={emp.employeeId}
              >
                <td className="center-text-td">
                  <small className="">
                    <small className="text-muted">{index + 1}</small>
                  </small>
                </td>

                <td>{emp.employeeName}</td>
                <td>{emp.employeeRegistration}</td>
                <td>{emp.employeeTel}</td>
                <td>{emp.employeeJobTitle}</td>
                <td>{emp.employeeDepartment}</td>
                <td className="center-text-td">{emp.devicesInEmployee}</td>
                <td>{emp.registrationDate}</td>
                <td
                  className="jc"
                  style={{
                    minWidth: "8px",
                  }}
                >
                  {emp.devicesInEmployee > 0 ? (
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id={`tooltip-left`}>
                          {t(
                            "To delete an employee, you must delete all devices from it first."
                          )}
                        </Tooltip>
                      }
                    >
                      <div>
                        <button disabled className="btn btn-danger">
                          <FaTrash />
                        </button>
                      </div>
                    </OverlayTrigger>
                  ) : (
                    <div>
                      <button
                        onClick={() => {
                          if (emp.devicesInEmployee > 0) {
                            return;
                          }
                          deleteEmployee(emp.employeeId);
                        }}
                        className="btn btn-danger"
                      >
                        <FaTrash />
                      </button>
                    </div>
                  )}

                  <button
                    className="btn btn-primary ms-1"
                    onClick={() => setEditData(emp)}
                  >
                    <FaPen />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>

      {editData && Object.keys(editData).length > 0 ? (
        <EmpEdit
          editData={editData}
          setEditData={setEditData}
          refreshState={refreshState}
          setRefreshState={setRefreshState}
        />
      ) : null}
    </Card>
  );
};

export default EmpTable;
