const badgesDefault = {
  default:
    "tw-bg-blue-100 tw-text-blue-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-blue-900 dark:text-blue-300",
  dark: "tw-bg-gray-100 tw-text-gray-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-gray-700 dark:text-gray-300",
  red: "tw-bg-red-100 tw-text-red-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-red-900 dark:text-red-300",
  green:
    "tw-bg-green-100 tw-text-green-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-green-900 dark:text-green-300",
  yellow:
    "tw-bg-yellow-100 tw-text-yellow-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-yellow-900 dark:text-yellow-300",
  indigo:
    "tw-bg-indigo-100 tw-text-indigo-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-indigo-900 dark:text-indigo-300",
  purple:
    "tw-bg-purple-100 tw-text-purple-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-purple-900 dark:text-purple-300",
  pink: "tw-bg-pink-100 tw-text-pink-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-pink-900 dark:text-pink-300",
  defaultBold:
    "tw-bg-blue-100 tw-text-blue-800 tw-text-xs tw-font-bold tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-blue-900 dark:text-blue-300",
};

const tagBadges = {
  default:
    "tw-bg-blue-100 tw-text-blue-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-blue-900 dark:text-blue-300",
  dark: "tw-bg-gray-100 tw-text-gray-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-gray-700 dark:text-gray-300",
  red: "tw-bg-red-100 tw-text-red-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-red-900 dark:text-red-300",
  green:
    "tw-bg-green-100 tw-text-green-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-green-900 dark:text-green-300",
  yellow:
    "tw-bg-yellow-100 tw-text-yellow-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-yellow-900 dark:text-yellow-300",
  indigo:
    "tw-bg-indigo-100 tw-text-indigo-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-indigo-900 dark:text-indigo-300",
  purple:
    "tw-bg-purple-100 tw-text-purple-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-purple-900 dark:text-purple-300",
  pink: "tw-bg-pink-100 tw-text-pink-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-pink-900 dark:text-pink-300",
  orange:
    "tw-bg-orange-200 tw-text-orange-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-orange-900 dark:text-orange-300",
  teal: "tw-bg-teal-100 tw-text-teal-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-teal-900 dark:text-teal-300",
  cyan: "tw-bg-cyan-100 tw-text-cyan-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-cyan-900 dark:text-cyan-300",
  amber:
    "tw-bg-amber-100 tw-text-amber-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-amber-900 dark:text-amber-300",
  lime: "tw-bg-lime-100 tw-text-lime-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-lime-900 dark:text-lime-300",
  brown:
    "tw-bg-amber-950 tw-text-white tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-brown-900 dark:text-brown-300",
};

const LargeBadges = {
  default:
    "tw-bg-blue-100 tw-text-blue-800 tw-text-sm tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-blue-900 dark:text-blue-300",
  dark: "tw-bg-gray-100 tw-text-gray-800 tw-text-sm tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-gray-700 dark:text-gray-300",
  red: "tw-bg-red-100 tw-text-red-800 tw-text-sm tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-red-900 dark:text-red-300",
  green:
    "tw-bg-green-100 tw-text-green-800 tw-text-sm tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-green-900 dark:text-green-300",
  yellow:
    "tw-bg-yellow-100 tw-text-yellow-800 tw-text-sm tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-yellow-900 dark:text-yellow-300",
  indigo:
    "tw-bg-indigo-100 tw-text-indigo-800 tw-text-sm tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-indigo-900 dark:text-indigo-300",
  purple:
    "tw-bg-purple-100 tw-text-purple-800 tw-text-sm tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-purple-900 dark:text-purple-300",
  pink: "tw-bg-pink-100 tw-text-pink-800 tw-text-sm tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-pink-900 dark:text-pink-300",
};
const BorderedBadges = {
  default:
    "tw-bg-blue-100 tw-text-blue-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-gray-700 dark:text-blue-400 tw-border-blue-400",
  dark: "tw-bg-gray-100 tw-text-gray-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-gray-700 dark:text-gray-400 tw-border-gray-500",
  red: "tw-bg-red-100 tw-text-red-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-gray-700 dark:text-red-400 tw-border-red-400",
  green:
    "tw-bg-green-100 tw-text-green-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-gray-700 dark:text-green-400 tw-border-green-400",
  yellow:
    "tw-bg-yellow-100 tw-text-yellow-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-gray-700 dark:text-yellow-300 tw-border-yellow-300",
  indigo:
    "tw-bg-indigo-100 tw-text-indigo-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-gray-700 dark:text-indigo-400 tw-border-indigo-400",
  purple:
    "tw-bg-purple-100 tw-text-purple-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-gray-700 dark:text-purple-400 tw-border-purple-400",
  pink: "tw-bg-pink-100 tw-text-pink-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:bg-gray-700 dark:text-pink-400 tw-border-pink-400",
};

const RoundedBadges = {
  default:
    "tw-bg-blue-100 tw-text-blue-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded-full dark:bg-blue-900 dark:text-blue-300",
  dark: "tw-bg-gray-100 tw-text-gray-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded-full dark:bg-gray-700 dark:text-gray-300",
  red: "tw-bg-red-100 tw-text-red-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded-full dark:bg-red-900 dark:text-red-300",
  green:
    "tw-bg-green-100 tw-text-green-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded-full dark:bg-green-900 dark:text-green-300",
  yellow:
    "tw-bg-yellow-100 tw-text-yellow-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded-full dark:bg-yellow-900 dark:text-yellow-300",
  indigo:
    "tw-bg-indigo-100 tw-text-indigo-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded-full dark:bg-indigo-900 dark:text-indigo-300",
  purple:
    "tw-bg-purple-100 tw-text-purple-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded-full dark:bg-purple-900 dark:text-purple-300",
  pink: "tw-bg-pink-100 tw-text-pink-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded-full dark:bg-pink-900 dark:text-pink-300",
};

const badgesNoMaring = {
  default:
    "tw-bg-blue-100 tw-text-blue-800 tw-text-xs tw-font-medium tw-rounded dark:bg-blue-900 dark:text-blue-300",
  dark: "tw-bg-gray-100 tw-text-gray-800 tw-text-xs tw-font-medium tw-rounded dark:bg-gray-700 dark:text-gray-300",
  red: "tw-bg-red-100 tw-text-red-800 tw-text-xs tw-font-medium tw-rounded dark:bg-red-900 dark:text-red-300",
  green:
    "tw-bg-green-100 tw-text-green-800 tw-text-xs tw-font-medium tw-rounded dark:bg-green-900 dark:text-green-300",
  yellow:
    "tw-bg-yellow-100 tw-text-yellow-800 tw-text-xs tw-font-medium tw-rounded dark:bg-yellow-900 dark:text-yellow-300",
  indigo:
    "tw-bg-indigo-100 tw-text-indigo-800 tw-text-xs tw-font-medium tw-rounded dark:bg-indigo-900 dark:text-indigo-300",
  purple:
    "tw-bg-purple-100 tw-text-purple-800 tw-text-xs tw-font-medium tw-rounded dark:bg-purple-900 dark:text-purple-300",
  pink: "tw-bg-pink-100 tw-text-pink-800 tw-text-xs tw-font-medium tw-rounded dark:bg-pink-900 dark:text-pink-300",
};

export {
  badgesDefault,
  LargeBadges,
  BorderedBadges,
  RoundedBadges,
  badgesNoMaring,
  tagBadges,
};
