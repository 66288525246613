import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import { VscLoading } from "react-icons/vsc";

const ForgotModal = ({ onHide }) => {
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState(null);

  const handleSubmit = async (event) => {
    setFeedback({
      ok: "loading",
      message: "Loading...",
    });
    event.preventDefault();
    try {
      const response = await axios.post("/api/v1/password-reset-request", {
        email,
      });
      setFeedback({
        ok: true,
        message: "A reset link sent successfully to your email address. ",
      });

      setTimeout(() => {
        setFeedback(null);
        onHide();
      }, 1000);
    } catch (error) {
      setFeedback({ ok: false, message: "Failed to send reset link." });
    }
  };

  return (
    <Modal centered show={true} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group controlId="formEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          {feedback && (
            <div
              className={
                feedback.ok == true
                  ? "tw-bg-green-500 tw-text-white p-3 tw-rounded-md w-100 mt-3"
                  : feedback.ok == false
                  ? "tw-bg-red-500 tw-text-white p-3 tw-rounded-md w-100 mt-3"
                  : feedback.ok == "loading"
                  ? "tw-bg-datadike-blue tw-text-white p-3 tw-rounded-md w-100 mt-3 text-center tw-animate-pulse d-block"
                  : ""
              }
            >
              {feedback.message}

              {feedback.ok == "loading" && (
                <h3 className=" loading-icon-white tw-text-white mt-1">
                  <VscLoading />
                </h3>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ForgotModal;
