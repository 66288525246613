/**
 * Converte uma data UTC para o horário local do usuário.
 * @param {string} utcDate - A data em formato UTC.
 * @returns {string} - A data e hora no horário local como string.
 */
export function convertToLocalTime(utcDate) {
  if (!utcDate) return "";

  // Cria uma instância de Date com base na data UTC fornecida
  const date = new Date(utcDate);

  // Retorna a data formatada no fuso horário local do usuário
  return date.toLocaleString();
}

/**
 * Converte uma data UTC para o horário local do usuário.
 * @param {string} utcDate - A data em formato UTC.
 * @returns {string} - A data e hora no horário local como string.
 */

/**
 * Converte uma data UTC para o horário local do usuário e a formata no padrão ISO 8601 sem o sufixo "Z".
 * @param {Date} utcDate - A data em UTC que será convertida.
 * @returns {string} - A data e hora no horário local como string no formato ISO 8601.
 */
export function convertToLocalTimeReq(utcDate) {
  // Cria uma nova data baseada na data UTC fornecida
  const localDate = new Date(
    utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
  );

  // Formata a data local no padrão ISO 8601
  const formattedDate = localDate.toISOString().slice(0, 19);

  return formattedDate;
}

/**
 * Obtém o fuso horário do navegador no formato "Area/City".
 * @returns {string} - O fuso horário do navegador.
 */
export function getBrowserTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
