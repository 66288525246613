import React from "react";
import { Modal, Button } from "react-bootstrap";
import { t } from "i18next";

const ConfirmModal = ({ show, handleClose, handleConfirm }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Confirm Deletion")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("confirmModal.delete.policies.title")}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("Cancel")}
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
          {t("Delete")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
