import React, { useEffect, useState } from "react";
import { Card, Row, Table, Button, Dropdown } from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";
import { useSort } from "../../hooks/useSort";
import useSearch from "../../hooks/useSearch";

import { FaCheck, FaDownload, FaEye, FaPen, FaTrash } from "react-icons/fa";
//import devicesData from "../../data/devices";
//import { useGet } from "../../hooks/get";
import { MdCancel } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { badgesDefault } from "../../extraFunctions/badgesTw";
import { IoIosMore } from "react-icons/io";
import { convertToLocalTime } from "../../hooks/convertDate";
import PdfModal from "./PdfModal";
import { HiTrash } from "react-icons/hi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const DevicesList = ({ refreshState, onSelect }) => {
  const [terms, setTerms] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [editValue, setEditValue] = useState("");
  const [showPdfModal, setShowPdfModal] = useState({
    show: false,
    url: "",
  });
  const [selectedTerm, setSelectedTerm] = useState({});

  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/api/v1/terms/view-terms", {
        credentials: "include",
      });
      const data = await response.json();

      if (response.ok) {
        setLoading(false);

        const newData = data.map((term) => ({
          _id: term._id,
          description: term.description,
          uploadedBy: term.uploadedBy,
          deviceName: term.deviceName,
          deviceWithTerms: term.deviceWithTerms,

          uploadDate: convertToLocalTime(term.uploadDate),
        }));
        setSelectedTerm(newData[0]);
        setTerms(newData);
        onSelect(newData[0]);

        //
      } else {
        setError("Error fetching");
        setLoading(false);
        setTerms([]);
      }
    };

    /**{
        "_id": "66840475bafe48aa7fdf35a9",
        "description": "termo walter",
        "uploadedBy": "Walter Vieira Neto",
        "deviceName": "",
        "uploadDate": "2024-07-02T13:45:25.922Z"
    } */

    fetchData();
  }, [refreshState]); // Remova `loading` se desejar executar apenas na montagem

  const { sortedData, requestSort, sortConfig } = useSort(terms);
  const searchFields = [
    "description",
    "uploadedBy",
    "deviceName",
    "uploadDate",
  ];
  const { searchTerm, setSearchTerm, filteredData } = useSearch(
    sortedData,
    searchFields
  );

  const handleDelete = async (id, description) => {
    const response = await fetch(`/api/v1/terms/delete-term/${id}`, {
      method: "DELETE",

      body: JSON.stringify({
        description: description,
      }),
    });

    if (response.ok) {
      setTimeout(() => {
        setTerms(terms.filter((term) => term._id !== id));
      }, 300);
    }
  };

  const handleDownload = (termId) => {
    window.open(`/api/v1/terms/download-term/${termId}`);
  };

  const handleEdit = async (id, description) => {
    const response = await fetch(`/api/v1/terms/update-term/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        termId: id,
        newDescription: editValue,
        oldDescription: description,
      }),
    });

    if (response.ok) {
      setTerms(
        terms.map((term) =>
          term._id === id ? { ...term, description: editValue } : term
        )
      );
      setEditing(false);
    }
  };

  const handleSelectTerm = (rTerm) => {
    setSelectedTerm(terms.find((term) => term._id === rTerm._id));
    onSelect(rTerm);
  };

  if (loading) {
    return (
      <Card
        style={{
          minHeight: "280px",
          maxHeight: "500px",
        }}
      >
        <Card.Body className="jc ac">
          <div className="mw-100 mh-100">
            <h4
              style={{ fontSize: "25px" }}
              className="tw-animate-spin text-primary"
            >
              <VscLoading />
            </h4>
          </div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Row>
      {showPdfModal && showPdfModal.url && showPdfModal.show && (
        <PdfModal
          show={true}
          id={showPdfModal.url}
          onHide={() => setShowPdfModal(false)}
        />
      )}
      <Card
        style={{
          minHeight: "400px",
          maxHeight: "500px",
        }}
        className="custom-card-1"
      >
        <Card.Header className="card-table-header">
          <h5 className="mb-0"> {t("Terms List")}</h5>

          <div className="d-flex tw-justify-end">
            <input
              type="text"
              className="form-control w-50 me-1"
              placeholder={`${"Search..."}`}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </Card.Header>

        <Card.Body>
          <Table className="table-users" hover>
            <thead className="tw-bg-gray-600">
              <tr>
                <th onClick={() => requestSort("description")}>
                  {t("Description")}
                </th>
                <th onClick={() => requestSort("uploadedBy")}>
                  {t("Uploaded By")}
                </th>
                <th
                  className="center-text-th"
                  onClick={() => requestSort("deviceWithTerms")}
                >
                  {t("Attached To")}
                </th>

                <th onClick={() => requestSort("uploadDate")}>
                  {t("Upload Date")}
                </th>
                <th className="center-text-th">{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((doc, index) => (
                <tr
                  onClick={() => handleSelectTerm(doc)}
                  className={`${
                    selectedTerm._id == doc._id ? "table-secondary" : ""
                  }`}
                  key={doc._id}
                >
                  <td>
                    {editing === doc._id ? (
                      <div className="d-flex">
                        <Button
                          variant="danger"
                          onClick={() => {
                            setEditing(false);
                            setEditValue("");
                          }}
                        >
                          <MdCancel />
                        </Button>
                        <input
                          type="text"
                          className="form-control w-50 mx-2"
                          value={editValue}
                          onChange={(e) => setEditValue(e.target.value)}
                          onKeyPress={(e) =>
                            e.key === "Enter" &&
                            handleEdit(doc._id, doc.description)
                          }
                        />
                        <Button
                          variant="success"
                          onClick={() => {
                            handleEdit(doc._id, doc.description);
                          }}
                        >
                          <FaCheck />
                        </Button>
                      </div>
                    ) : (
                      <div className="d-flex">
                        <span className="me-2">{doc.description}</span>
                        <span
                          onClick={() => {
                            setEditing(doc._id);
                            setEditValue(doc.description);
                          }}
                          className="ms-1 tw-text-gray-400 cursor-pointer"
                        >
                          <FaPen />
                        </span>
                      </div>
                    )}
                  </td>
                  <td>{doc.uploadedBy}</td>
                  <td className="center-text-td">{doc.deviceWithTerms}</td>
                  <td>{doc.uploadDate}</td>
                  <td className="jc" style={{ minWidth: "90px" }}>
                    <button
                      onClick={() => {
                        setShowPdfModal({ show: true, url: doc._id });
                      }}
                      className="btn btn-info me-1 tw-text-white"
                    >
                      <FaEye />
                    </button>

                    <button
                      onClick={() => handleDownload(doc._id)}
                      className="btn btn-primary me-1"
                    >
                      <FaDownload />
                    </button>

                    {doc.deviceWithTerms > 0 ? (
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id={`tooltip-left`}>
                            {t(
                              "To delete a term, you must delete all devices from it first."
                            )}
                          </Tooltip>
                        }
                      >
                        <div>
                          <button
                            className="btn btn-danger me-1"
                            disabled
                            onClick={() => {}}
                          >
                            <FaTrash />
                          </button>
                        </div>
                      </OverlayTrigger>
                    ) : (
                      <button
                        className="btn btn-danger me-1"
                        onClick={() => {
                          if (doc.deviceWithTerms > 0) {
                            return;
                          }
                          handleDelete(doc._id, doc.description);
                        }}
                      >
                        <FaTrash />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default DevicesList;
