import React, { useContext, useState, useEffect } from "react";
import { SysadminContext } from "../../Context/SysadminContext";
import useSearch from "../../hooks/useSearch";
import { useGet } from "../../hooks/get";
import { Card, Col, Row } from "react-bootstrap";
import DownloadButton from "./DownloadButton";

import { VscLoading } from "react-icons/vsc";
import { MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
const Terms = () => {
  const lang = localStorage.getItem("lang");

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { selectedDevice } = useContext(SysadminContext);
  const [refreshState, setRefreshState] = useState(false);
  const { data: apiTerms, loading: apiLoading } = useGet(
    `/api/v1/terms/view-terms`,
    refreshState
  );
  const { data: deviceTerms, loading: deviceTermsLoading } = useGet(
    `/api/v1/sysadmin/device-terms/${selectedDevice?.deviceId}`,
    refreshState
  );

  const [availableTerms, setAvailableTerms] = useState([]);

  useEffect(() => {
    setLoading(true);

    if (apiTerms && deviceTerms && deviceTerms.length > 0) {
      const deviceTermsIds = deviceTerms.map((term) => term.termId);
      const filteredTerms = apiTerms.filter(
        (term) => !deviceTermsIds.includes(term._id)
      );
      setAvailableTerms(filteredTerms);
      setLoading(false);
    } else if (apiTerms) {
      setAvailableTerms(apiTerms);
      setLoading(false);
    }
  }, [apiTerms, deviceTerms]);

  const {
    filteredData: filteredTerms,
    searchTerm: termSearch,
    setSearchTerm: setTermSearch,
  } = useSearch(availableTerms, ["description"]);

  const addTermToDevice = async (termId) => {
    const response = await fetch("/api/v1/manage-device/attach-term", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        devices: [
          {
            deviceId: selectedDevice?.deviceId,
            deviceName: selectedDevice?.deviceName,
          },
        ],
        termId: termId,
      }),
    });

    if (response.ok) {
      setLoading(true);

      setTimeout(() => {
        setRefreshState(!refreshState);
      }, 500);
    }
  };

  const handleDelete = async (id) => {
    const response = await fetch("/api/v1/manage-device/remove-term", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        termId: id,
        devices: [
          {
            deviceId: selectedDevice.deviceId,
            deviceName: selectedDevice.deviceName,
          },
        ],
      }),
    });

    if (response.ok) {
      setLoading(true);
      setTimeout(() => {
        setRefreshState(false);
      }, 500);
    } else {
      console.error("Failed to delete devices", await response.text());
    }
  };

  if (loading) {
    return (
      <Card style={{ height: "740px" }} className="">
        <Card.Header>
          <h4 className="mb-0">{t("Terms")}</h4>
        </Card.Header>

        <Card.Body className="jc ac">
          <h4 className="loading-icon">
            <VscLoading className="" />
          </h4>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card style={{ height: "740px" }} className="">
      <Card.Header>
        <h4 className="mb-0">{t("Terms")}</h4>
      </Card.Header>

      <Card.Body>
        <Row>
          <Col
            style={{
              height: "620px",
            }}
            xl={6}
            className="mb-1"
          >
            <Card
              style={{
                height: "620px",
              }}
              className="sysadmin-cards"
            >
              <Card.Header>
                <h6 className="mb-0">{t("Available Terms")}</h6>
                <input
                  type="text"
                  className="form-control w-50"
                  placeholder={`${t("Search...")}`}
                  value={termSearch}
                  onChange={(e) => setTermSearch(e.target.value)}
                />
              </Card.Header>
              <Card.Body className="mh-100 overflow-auto">
                {availableTerms && availableTerms.length > 0 ? (
                  filteredTerms.map((term) => (
                    <div
                      className="tw-bg-gray-100 tw-rounded-md p-2 mb-1"
                      key={term._id}
                    >
                      <button
                        onClick={() => addTermToDevice(term._id)}
                        className="btn btn-gray me-2"
                      >
                        +
                      </button>
                      {term.description}
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                    {t("No terms available for")}
                    <span className="mx-1 text-primary">
                      {selectedDevice?.deviceName}
                    </span>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>

          <Col
            style={{
              height: "620px",
            }}
            xl={6}
            className="mb-1"
          >
            <Card
              style={{
                height: "620px",
              }}
              className="sysadmin-cards"
            >
              <Card.Header>
                {lang == "en" ? (
                  <h6 className="mb-0">
                    <span className="font-semibold me-1">
                      {selectedDevice?.deviceName}
                    </span>

                    {t("Terms")}
                  </h6>
                ) : (
                  <h6 className="mb-0">
                    {t("Terms")} em
                    <span className="font-semibold ms-1">
                      {selectedDevice?.deviceName}
                    </span>
                  </h6>
                )}

                <div //Pra ajustar o alinhamento da borda dos dois cards
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                    padding: "0.6rem",
                  }}
                ></div>
              </Card.Header>
              <Card.Body className="mh-100 overflow-auto">
                {deviceTerms && deviceTerms.length > 0 ? (
                  <div>
                    {deviceTerms.map((term) => (
                      <div
                        className="tw-bg-gray-100 tw-rounded-md p-2 mb-1 d-flex justify-content-between ac"
                        key={term.termId}
                      >
                        <div>
                          <button
                            onClick={() => handleDelete(term.termId)}
                            className="btn btn-gray me-2"
                          >
                            <MdDelete />
                          </button>
                          {term.termDescription}
                        </div>

                        <span>
                          <DownloadButton
                            see
                            download
                            fileName={`${selectedDevice.deviceName} term`}
                            url={`/api/v1/terms/download-term/${term.termId}`}
                          />
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center">
                    {t("No terms added to")}
                    <span className="mx-1 text-primary">
                      {selectedDevice?.deviceName}
                    </span>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Terms;
