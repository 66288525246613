import React from "react";
import { Modal, Button } from "react-bootstrap";
import { t } from "i18next";

const ConfirmDeleteEnterpriseModal = ({ show, handleClose, handleDelete }) => {
  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("confirmDeletion.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("confirmDeletion.message")}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("confirmDeletion.cancel")}
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          {t("confirmDeletion.delete")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteEnterpriseModal;
